import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PolicyApiService} from '../../../../services/api/policy/policy-api.service';

@Component({
  selector: 'policy-viewer',
  templateUrl: './policy-viewer.html',
  styleUrls: ['./policy-viewer.css']
})
export class PolicyViewer implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  constructor(
  ) {}


  ngOnInit(): void {

  }

  close(){
    this.remove.emit(null);
  }

}
