import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../../services/api/policy/policy-api.service';
import {ModalConfirmationComponent} from '../../../../shared/modal-confirmation/modal-confirmation.component';
import {DialogService} from '../../../../services/dialog-service/dialog.service';

@Component({
  selector: 'policy-restore-dialog',
  templateUrl: './policy-restore-dialog.html',
  styleUrls: ['./policy-restore-dialog.css']
})
export class PolicyRestoreDialog implements OnInit {

  @Input() data;

  policyData;
  assignmentData;

  restorePolicy = false;
  restoreAssignments = false;

  restoreInProgress = false;
  restoreFailed = false;
  restoreError = '';

  restoreSuccessful = false;


  @Output() remove = new EventEmitter<any>();

  constructor(private dialogService: DialogService, private viewReference: ViewContainerRef, private policyApi: PolicyApiService
  ) {}

  restore(){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure you want to restore this policy?', this.viewReference)
      .then(confirmed => {
        this.restoreInProgress = true;
        this.policyApi.restore(this.data.eido_policy_backup_id, this.restorePolicy, this.restoreAssignments)
          .then(response => {
            this.restoreInProgress = false;
            this.restoreSuccessful = true;
          })
          .catch(error => {
            this.restoreInProgress = false;
            this.restoreFailed = true;
            this.restoreError = error;
          });
      }, cancelled => null)
  }

  hideRestoreOptions(){
    if(this.restoreInProgress || this.restoreFailed || this.restoreSuccessful){
      return true;
    } else {
      false
    }
  }


  ngOnInit(): void {
    this.policyData = JSON.parse(this.data.policy_json);
    this.assignmentData = JSON.parse(this.data.assignments_json);
  }

  close(){
    this.remove.emit(null);
  }

}
