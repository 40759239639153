<div class="suggestion-input-wrapper">
  <div class="input-group">

    <input *ngIf="type == 'string'" type="text"
           [(ngModel)]="selectedValue"
           (ngModelChange)="onInputChange(); onValueChange($event)"
           (focus)="showSuggestions = true"
           (blur)="hideSuggestions()"
           name="userInput"
           class="form-control input-sm" />

    <input *ngIf="type == 'integer' || type == 'number' || type == 'double'" type="number"
           [(ngModel)]="selectedValue"
           (ngModelChange)="onInputChange(); onValueChange($event)"
           (focus)="showSuggestions = true"
           (blur)="hideSuggestions()"
           name="userInput"
           class="form-control input-sm" />

    <input *ngIf="type == 'date'" class="form-control input-sm" matInput [matDatepicker]="picker" [(ngModel)]="selectedValue"
           (ngModelChange)="onInputChange(); onValueChange($event)">

    <span *ngIf="type == 'date'" class="input-group-addon" (click)="picker.open()">
      <i class="fa fa-calendar"></i> <!-- This is the Font Awesome icon -->
    </span>
<!--    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>-->

    <mat-datepicker #picker></mat-datepicker>

<!--    <input  type="number"-->
<!--           -->
<!--           (focus)="showSuggestions = true"-->
<!--           (blur)="hideSuggestions()"-->
<!--           name="userInput"-->
<!--           class="form-control input-sm" />-->

    <span *ngIf="this.suggestions?.length && type == 'string'" class="input-group-addon" (click)="toggleDropdown()">
      <i class="fa fa-chevron-down"></i> <!-- This is the Font Awesome icon -->
    </span>
  </div>
  <ul *ngIf="showSuggestions && filteredSuggestions.length > 0" class="suggestions-dropdown">
    <li *ngFor="let suggestion of filteredSuggestions" (click)="selectSuggestion(suggestion)">
      {{ suggestion }}
    </li>
  </ul>
</div>

