import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from "@ag-grid-community/core";
import {NgForOf} from '@angular/common';

interface CustomButtonParams extends ICellRendererParams {
  buttonLabels: string[]; // Array of button texts
  onClick: (buttonLabel: string, data: any) => void; // Modified to accept button label
}

@Component({
  selector: 'custom-button-renderer',
  standalone: true,
  template: `
    <div class="">
      <span
        *ngFor="let label of buttonLabels"
        class="eido-action-button"
        (click)="onClickHandler(label)"
      >{{label}}</span>
    </div>
  `,
  imports: [
    NgForOf
  ],
  styles: [
    `
      .button-group {
        display: flex;
        gap: 8px;
      }
    `
  ]
})
export class ActionsParamsRenderer implements ICellRendererAngularComp {
  public buttonLabels!: string[]; // Array of labels
  public onClick!: (buttonLabel: string, data: any) => void;
  public data!: any;

  agInit(params: CustomButtonParams): void {
    this.buttonLabels = [];
    this.buttonLabels = params.buttonLabels;
    this.onClick = params.onClick;
    this.data = params.data;
  }

  refresh(params: CustomButtonParams): boolean {
    this.buttonLabels = [];
    this.buttonLabels = params.buttonLabels;
    this.data = params.data;
    return true;
  }

  onClickHandler(buttonLabel: string): void {
    // Pass the clicked button label and row data to the onClick function
    this.onClick(buttonLabel, this.data);
  }
}
