import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {ActivatedRoute} from '@angular/router';
import {NavigationApiService} from '../../services/api/navigation-api/navigation-api.service';
import {OptionsService} from '../../services/options-service/options.service';
import {PortalSettingsService} from '../../services/portal-settings/portal-settings.service';
import {PolicyApiService} from '../../services/api/policy/policy-api.service';
import {PolicyDialogComponent} from './policy-dialog/policy-dialog.component';


@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrl: './policies.component.css'
})
export class PoliciesComponent implements OnInit {

  public isLoading = true;

  // Filter blade settings
  public data = [];
  public filteredData = [];
  public filterOptions = [
    {columnName: 'policy_type_name', friendlyName: 'Policy Type', isExpanded: true, showAllCheckbox: true, showSearchOption: false, template: 'none', showCount: true},
    {columnName: 'customer_name', friendlyName: 'Customer', isExpanded: true, showAllCheckbox: true, showSearchOption: false, template: 'none', showCount: true},
    {columnName: 'managed_status', friendlyName: 'Managed', isExpanded: true, showAllCheckbox: false, showSearchOption: false, template: 'none', showCount: true},
    {columnName: 'sync_status', friendlyName: 'Template Status', isExpanded: true, showAllCheckbox: false, showSearchOption: false, template: 'none', showCount: true},
    {columnName: 'version_status', friendlyName: 'Version', isExpanded: true, showAllCheckbox: false, showSearchOption: false, template: 'none', showCount: true},
  ];
  public filterQueryParams = [];

  // Grid Settings
  public gridSettings = {
    actionButtons: ['View'],
    multiSelect: false,
    multiSelectActions: ['Create as template'],
    columns: [
      { field: 'customer_name', headerName: 'Customer', checkboxSelection: false, headerCheckboxSelection: false, },
      { field: 'policy_name', headerName: 'Name', checkboxSelection: false, headerCheckboxSelection: false, },
      { field: 'policy_type_name', headerName: 'Policy Type' },
      { field: 'sync_status', headerName: 'Template Status', cellRenderer: 'policySyncStatusRendererComponent' },
      { field: 'version_status', headerName: 'Template Version' },
      { field: 'policy_last_updated', headerName: 'Last Updated', cellRenderer: 'amTimeAgoRenderer' },
      { field: 'most_recent_backup_date', headerName: 'Last Backup', cellRenderer: 'amTimeAgoRenderer' }
    ]
  }

  constructor(private policyApiService: PolicyApiService, private dialogService: DialogService, private viewReference: ViewContainerRef,
              private route: ActivatedRoute, private navApi: NavigationApiService, public options: OptionsService, private portalSettings: PortalSettingsService) {

    this.getData();
  }

  ngOnInit(): void {

  }

  getData() {
    this.isLoading = true;

    this.policyApiService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    });

  }

  public rowClicked(data:any) {
    this.dialogService.createDialog(PolicyDialogComponent, data.eido_policy_id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {
        this.getData();

      })

  }

  public actionClicked(data:any) {

    if(data.action === 'View') {
      console.log(data);
      this.dialogService.createDialog(PolicyDialogComponent, data.rowData.eido_policy_id, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

  }

  public multiSelectAction(data:any) {

  }


}
