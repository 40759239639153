<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Intune Authorization Successful</h5>
  </div>

  <div class="modal-body">

    <div *ngIf="portalSettings.tenantSettings.msp_mode_enabled">
    <p>Link to Customer</p>
    <div class="row">
      <div class="col-sm m-b-10">
        <select [(ngModel)]="outputData.customer_id" (ngModelChange)="customerChanged()" class="form-control" aria-label="Select Customer...">
          <option value="">Select a customer</option>
          <option *ngFor="let row of customerList" [value]="row.id">{{ row.customer_name }}</option>
        </select>

      </div>
      <div style="width: 140px; margin-left: 10px">
        <button (click)="newCustomer()" type="button" class="btn btn-light-grey btn-small active">New Customer</button>
      </div>
    </div>

    </div>

  <div class="m-t-10">
    <p>Enter a friendly name for this Intune Tenant</p>
    <div class="col-sm ">
      <input [(ngModel)]="outputData.friendly_name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
    </div>
  </div>


  </div>






  <div class="modal-footer">
    <button (click)="save()" [disabled]="!outputData.friendly_name" class="btn btn-primary">Confirm</button>
<!--    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>-->
  </div>
</app-modal>
