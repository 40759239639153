import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../../services/api/policy/policy-api.service';
import {ModalConfirmationComponent} from '../../../../shared/modal-confirmation/modal-confirmation.component';
import {DialogService} from '../../../../services/dialog-service/dialog.service';

@Component({
  selector: 'policy-restore-dialog',
  templateUrl: './new-template-dialog.html',
  styleUrls: ['./new-template-dialog.css']
})
export class NewTemplateDialog implements OnInit {

  @Input() data;

  createInProgress = false;
  createFailed = false;
  createError = '';
  createSuccessful = false;


  @Output() remove = new EventEmitter<any>();

  constructor(private dialogService: DialogService, private viewReference: ViewContainerRef, private policyApi: PolicyApiService
  ) {}

  create(){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure you want to create a template from this policy?', this.viewReference)
      .then(confirmed => {
        this.createInProgress = true;
        this.policyApi.createTemplate(this.data.eido_policy_id)
          .then(response => {
            this.createInProgress = false;
            this.createSuccessful = true;
          })
          .catch(error => {
            this.createInProgress = false;
            this.createFailed = true;
            this.createError = error;
          });
      }, cancelled => null)
  }


  ngOnInit(): void {
    // this.policyName = this.data.policy_name;
    // this.policyDescription = this.data.policy_description;
  }

  close(){
    this.remove.emit(null);
  }

}
