import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {AlertsService} from '../../../../services/api/admin/alerts/alerts.service';
import {IntuneTenantsApiService} from '../../../../services/api/admin/intune-tenants/intune-tenants-api.service';
import {OptionsService} from '../../../../services/options-service/options.service';
import {ModalConfirmationComponent} from '../../../../shared/modal-confirmation/modal-confirmation.component';
import {DialogService} from '../../../../services/dialog-service/dialog.service';

@Component({
  selector: 'app-edit-threshold-dialog',
  templateUrl: './edit-threshold-dialog.component.html',
  styleUrls: ['./edit-threshold-dialog.component.css']
})
export class EditThresholdDialogComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  selectedThresholdType = 'main';

  loadedData;

  constructor(
    private intuneApiService: IntuneTenantsApiService,
    public optionsService: OptionsService, private dialogService: DialogService, private viewReference: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    }
  }

  getThresholdsForType(){
    return this.loadedData.thresholds.filter(threshold => threshold.threshold_type == this.selectedThresholdType);
  }


  countThresholdsForType(type){
    return this.loadedData.thresholds.filter(threshold => threshold.threshold_type == type).length;
  }

  getData(){
    this.isLoading = true;
    this.intuneApiService.getIntuneTenant(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  sync() {
    if (this.loadedId) {
      this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure you want to force a resync of this tenant?', this.viewReference)
        .then(confirmed => {
          this.intuneApiService.syncIntuneTenant(this.loadedId).then(response => {
            this.remove.emit("Saved");
          })
        }, cancelled => null)

    }
  }

  close(){
    this.remove.emit(null);
  }

  save() {
    if (this.loadedId) {
      this.intuneApiService.updateIntuneTenant(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })

    }
  }

}
