<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading">

  <div class="row m-b-10" style="
    border-bottom: solid 1px gainsboro;
    padding-bottom: 10px;
">
    <div class="col-xl-2 col-lg-3">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" [class.active]="filter_onlyShowOpenIssues == 1" (click)="filter_onlyShowOpenIssues = 1; switchDates(); refreshData();" class="btn btn-light-grey btn-small">Open</button>
        <button type="button" [class.active]="filter_onlyShowOpenIssues == 0" (click)="filter_onlyShowOpenIssues = 0; switchDates(); refreshData();" class="btn btn-light-grey btn-small">Resolved</button>
      </div>
    </div>

    <div class="col-xl-3 col-lg-4">
      <div *ngIf="filter_onlyShowOpenIssues == 0" class="btn-group" role="group" aria-label="Basic example">
        <button type="button" [class.active]="filter_showIssuesDate == '24h'" (click)="filter_showIssuesDate = '24h'; switchDates(); refreshData();" class="btn btn-light-grey btn-small">24 Hours</button>
        <button type="button" [class.active]="filter_showIssuesDate == '7d'" (click)="filter_showIssuesDate = '7d'; switchDates(); refreshData();" class="btn btn-light-grey btn-small">7 Days</button>
        <button type="button" [class.active]="filter_showIssuesDate == '30d'" (click)="filter_showIssuesDate = '30d'; switchDates(); refreshData();" class="btn btn-light-grey btn-small">30 Days</button>
        <button type="button" [class.active]="filter_showIssuesDate == '90d'" (click)="filter_showIssuesDate = '90d'; switchDates(); refreshData();" class="btn btn-light-grey btn-small">90 Days</button>
      </div>
    </div>

  </div>

  <app-eido-super-table [data]="data" [settings]="superTableSettings" [filterWidthClass]="'col-xl-3 col-4'"></app-eido-super-table>


</div>
