<div *ngIf="!isLoadingTenantSettings">
  <div *ngIf="!tenantSettings.intune_enabled" class="text-center" style="padding: 100px;">
    <i aria-hidden="true" class="fab fa-microsoft" style="
    font-size: 4em;
    margin-bottom: 20px;
    color: indianred;
"></i>    <h2>Intune integration has not been configured</h2>
    <h3>To set this up, <a [routerLink]="'/intune-config'">click here</a>, or go to Admin > Intune Configuration.</h3>

  </div>

  <div  class="row">
    <app-devices [hideFilter]="true" [view]="'IntuneCompliance'"></app-devices>
  </div>
</div>
