// status-icon-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';

@Component({
  selector: 'app-status-icon-renderer',
  standalone: true,
  template: `
    <span *ngIf="status == 1 || status == null" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>
    <span *ngIf="status == 2" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>
    <span *ngIf="status == 3" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i></span>
    <span *ngIf="status == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i></span>
  `,
  imports: [
    CommonModule
  ],
  styles: [`
    .issueCountBadge { /* Add your styles here */
    }
  `]
})
export class StatusIconRendererComponent implements ICellRendererAngularComp {
  public status: any;

  agInit(params: any): void {
    this.status = params.value;
  }

  refresh(params: any): boolean {
    this.status = params.value;
    return true;
  }
}
