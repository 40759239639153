import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../../services/api/policy/policy-api.service';
import {ModalConfirmationComponent} from '../../../../shared/modal-confirmation/modal-confirmation.component';
import {DialogService} from '../../../../services/dialog-service/dialog.service';
import {TemplatesApiService} from '../../../../services/api/templates/templates-api.service';

@Component({
  selector: 'link-template-dialog',
  templateUrl: './link-template-dialog.html',
  styleUrls: ['./link-template-dialog.css']
})
export class LinkTemplateDialog implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  public templateId = null;
  public templateVersionId = null;

  // all templates
  public allTemplates = [];

  // just templates filtered to the same policy type
  public templates = [];
  public versions = [];

  public suggestedLink = null;

  constructor(private dialogService: DialogService, private viewReference: ViewContainerRef, private policyApi: PolicyApiService, private templateApi: TemplatesApiService) {

  }

  save(){
    this.policyApi.link(this.data.eido_policy_id, this.templateId, this.templateVersionId).then(response => {
      this.remove.emit(null);
    })
  }

  ngOnInit(): void {
    this.templateApi.listAll().then(response => {
      this.allTemplates = response;

      this.templates = this.allTemplates.filter(template => { return template.policy_type == this.data.policy_type });
    })

    this.policyApi.getSuggestedLink(this.data.eido_policy_id).then(response => {
      this.suggestedLink = response;

      if(this.suggestedLink){
        this.templateId = this.suggestedLink.policy_template_id;
        this.refreshVersions();
        this.templateVersionId = this.suggestedLink.policy_template_version_id;
      }
    });
  }

  public refreshVersions(){
    if(!this.templateId){
      return;
    }
    this.templateApi.get(this.templateId).then(response => {
      this.versions = response.versions;
    })
  }

  close(){
    this.remove.emit(null);
  }

}
