<div class="row">
  <h3>Billing & Subscription</h3>
</div>
<div class="row">
  <div class="col">
    <div class="sectionBox text-center">
      <h4>You are being redirected to Stripe - our third party payment portal.</h4>
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</div>


