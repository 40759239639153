  <div style="height:100%; border-right: solid 1px #d7d7d7; margin-right: 10px; overflow-y: auto;">

        <span *ngIf="this.filteredData.length < data.length" style="
              font-size: 12px;
              float:right;
              background-color: #5555551c;
              padding: 5px 10px;
              border-radius: 10px;
              margin-right:10px;
        ">Filtering {{this.filteredData.length}} of {{data.length}}</span>

    <h6 class="m-t-5" style="margin-left:2px">Filter & Search</h6>

    <input [(ngModel)]="searchTerm" (ngModelChange)="searchChanged()"  type="text" class="form-control filterOptionsSearchBox" id="Alert_Name"
           placeholder="Search" autocomplete="off" style="background-color: #edeff1;border-radius: 20px;padding: 5px 14px;width: 97%; margin-bottom: 10px; margin-top: 10px;">

    <app-filter-options-view *ngFor="let filterOptions of filterOptions"
                             [columnName]="filterOptions.columnName"
                             [showSearchOption]="filterOptions.showSearchOption"
                             [showCount]="filterOptions.showCount"
                             [template]="filterOptions.template"
                             [showAllCheckbox]="filterOptions.showAllCheckbox"
                             [friendlyName]="filterOptions.friendlyName"
                             [isExpanded]="filterOptions.isExpanded"
                             (filteredValues)="filterChanged(filterOptions.columnName, $event)"
                             [options]="options[filterOptions.columnName]"
                             [isHidden]="filterOptions.hidden"
                             [key]="'id'"
                             [value]="'name'">
    </app-filter-options-view>
  </div>
