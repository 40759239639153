<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal  modalSize="modal-small">

  <div class="modal-body">

    <p style="font-size: 18px;" class="">Column Definitions</p>
    <hr>

    <p class=""><strong>Compliance %</strong></p>
    <p>Percentage of devices with no compliance issues</p>

    <p class="m-t-10"><strong>Config Health %</strong></p>
    <p>Percentage of devices with no config issues</p>

    <p class="m-t-10"><strong>App Health %</strong></p>
    <p>Percentage of devices with no app installation issues</p>

    <p class="m-t-10"><strong>Patch Health %</strong></p>
    <p>Percentage of devices with latest patches (if Windows), running a supported OS version, or Unknown. Drill down available</p>

    <p style="font-size: 18px;" class="m-t-20">Colour Definitions</p>
    <hr>
    <div class="dashboardCustomerComplianceLabel" style="width: 250px; margin-bottom: 10px"> Greater than 90%</div>
    <div class="dashboardCustomerComplianceLabel amber" style="width: 250px; margin-bottom: 10px"> Less than 90%</div>
    <div class="dashboardCustomerComplianceLabel red" style="width: 250px; margin-bottom: 10px"> Less than 80%</div>




  </div>
  <div class="modal-footer">
    <div (click)="close()" class="btn btn-cancel">Close</div>
  </div>

</app-modal>
