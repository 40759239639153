import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {MomentModule} from "ngx-moment";
import * as moment from 'moment';


@Component({
  selector: 'app-highchart-time-series',
  templateUrl: './highchart-time-series.component.html',
  styleUrls: ['./highchart-time-series.component.css']
})
export class HighchartTimeSeriesComponent implements OnInit {

  @Input() data = [];
  @Input() height = '250px';
  @Input() dataUnit = '%';
  @Input() yAxixMax = null;
  @Input() threshold = null;
  @Input() amberThreshold = null;
  @Input() redThreshold = null;
  @Input() reverseThreshold = false; // if enabled, high is good, low is bad
  @Input() graphType = 'area' || 'line';
  @Input() xAxisVisible = true;
  @Input() yAxisVisible = true;


  public testData = [];

  public showGraph = false;
  public options: any;

  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) {
    null
  } // optional function, defaults to null
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  private global: string;

  constructor() {
    var global = this;
  }


  // Fill in Zeros in chart/graph
//   var data = [];
//   data.push(datapoints.lineData[0]);
//
//   datapoints.lineData.forEach((point, i) => {
//   if (i > 0) {
//   var previousX = datapoints.lineData[i - 1][0],
//   distance = point[0] - previousX;
//   if (distance === 60000) {
//   data.push(datapoints.lineData[i]);
// } else {
//   data.push([previousX + 1, null])
//   data.push(datapoints.lineData[i]);
// }
// }
// })

  ngOnInit() {

    function bytes(bytes, label) {
      if (bytes == 0) return '';
      var s = ['Bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'];
      var e = Math.floor(Math.log(bytes)/Math.log(1000));
      var value = ((bytes/Math.pow(1000, Math.floor(e))).toFixed(2));
      e = (e<0) ? (-e) : e;
      if (label) value += ' ' + s[e];
      return value;
    }

    this.options = {
      credits: {
        enabled: false
      },
      time: {
        useUTC: false
      },
      chart: {
        height: this.height,
        type: 'column',
        style: {fontFamily: '"Poppins","Montserrat","Noto Sans","Helvetica Neue",Helvetica,Arial,sans-serif', fontSize: '16px'},
        backgroundColor: 'rgba(0,0,0,0)',
         // zoomType: 'x'
      },
      title: {
        text: null
      },
      xAxis: {
        visible: this.xAxisVisible,
        formatter: function () {
          return moment(this.x).calendar();
        },
        type: 'datetime',
        ordinal: true,
      },
      yAxis: {
        visible: this.yAxisVisible,
        labels: {
          format: '{value}' + this.dataUnit
        },
        title: {
          text: null
        },
        min: 0,
        endOnTick: true,
        gridLineWidth: 1,
        gridLineColor: '#f3f3f3'
      },
      tooltip: {},
      // tooltip: {
      //   backgroundColor: '#f7f7f7',
      //   borderColor: '#e0e0e0',
      //   borderRadius: 3,
      //   borderWidth: 1,
      //   headerFormat: '{point.key}<br/>'
      //
      // },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          //pointInterval: 300000,
          maxPointWidth: 5,
          borderWidth: 0
        },
        area: {
          // fillColor: '#557ea5',
          marker: {
            // fillColor: '#973838',
            radius: 1
          },
          lineWidth: 0,
          lineColor: '#7c7c7c',
          states: {
            hover: {
              radius: 2,
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      series: [{
        // color: 'indianred',
        type: this.graphType,
        // name: 'CPU Usage',
        data: this.data,
        // color: {
        //   linearGradient: [0, 0, 0, 100],
        //   stops: [[this.redThreshold, "indianred"], [this.amberThreshold, "#f1b36e"], [1, "#669a80"]]
        // },
        zones: []
      }],
      responsive: {
        rules: {
          condition: {
            maxHeight: '150px'
          }
        }
      }
    }

    if(this.reverseThreshold){
      this.options.series[0].zones = [
        { color: 'indianred', value: this.redThreshold },
        { color: '#f1b36e', value: this.amberThreshold },
        { color: '#669a80' },
      ]
    } else {
      this.options.series[0].zones = [
        { color: '#669a80', value: this.amberThreshold },
        { color: '#f1b36e', value: this.redThreshold },
        { color: 'indianred' },
      ]
    }

    if (this.yAxixMax) {
      if (this.dataUnit == '%') {
        this.options.yAxis.softMax = this.yAxixMax;
        this.options.yAxis.max = 100;
      } else {
        this.options.yAxis.softMax = this.yAxixMax;
      }
      //this.options.yAxis.ceiling = this.yAxixMax;
    }
    if (this.dataUnit == '%') {
      this.options.tooltip.formatter = function () {
        return '<b>' + moment(this.x).calendar() + '</b><br/>' + this.y + '%';
      }
    }

    if (this.dataUnit == 'ms') {
      this.options.tooltip.formatter = function () {
        return '<b>' + moment(this.x).calendar() + '</b><br/>' + this.y + 'ms';
      }
    }

    if (this.dataUnit == 'bps') {
      this.options.tooltip.formatter = function () {
        return '<b>' + moment(this.x).calendar() + '</b><br/>' + bytes(this.y, true);
      }

      this.options.yAxis.softMax = 10000000;

      this.options.yAxis.labels.formatter = function() { return bytes(this.value, true); }

    }


    this.showGraph = true;

  }

}
