import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../services/api/policy/policy-api.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {TemplatesApiService} from '../../../services/api/templates/templates-api.service';
import {IntuneTenantsApiService} from '../../../services/api/admin/intune-tenants/intune-tenants-api.service';
import {ModalConfirmationComponent} from '../../../shared/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-deploy-bulk-dialog.component.html',
  styleUrls: ['./template-deploy-bulk-dialog.component.css']
})
export class TemplateDeployBulkDialogComponent implements OnInit {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoadingTenants = true;
  tenants = [];

  selectedTenantId = null;


  constructor(private changeDetectorRef: ChangeDetectorRef,
    private templatesApiService: TemplatesApiService, private viewReference: ViewContainerRef, private dialogService: DialogService, private intuneTenantApi: IntuneTenantsApiService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.selectedTenantId = this.data.eido_intune_config_id;
      this.getData();
    }
  }

  getData(){
    this.isLoadingTenants = true;

    this.intuneTenantApi.listAll().then(response => {
      this.tenants = response;
      this.isLoadingTenants = false;
    })
  }

  deploy(){
    this.remove.emit(this.selectedTenantId);
  }

  close(){
    this.remove.emit(null);
  }




  protected readonly JSON = JSON;
}
