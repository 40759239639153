import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';
import {DeviceDetailsBladeComponent} from '../../portal/device-details-blade/device-details-blade.component';
import {OptionsService} from '../../services/options-service/options.service';
import {SharedFunctionsService} from '../../services/shared-functions/shared-functions.service';
import {DialogService} from '../../services/dialog-service/dialog.service';

@Component({
  selector: 'app-eido-side-filter',
  templateUrl: './eido-side-filter.component.html',
  styleUrl: './eido-side-filter.component.css'
})
export class EidoSideFilterComponent {

  searchTerm = '';
  private debounceTimer?: number; // Store the timeout reference

  @Input() data = [];
  @Input() filterOptions = [];
  @Input() filterQueryParams = [];

  filteredData = [];
  public appliedFilters = {}

  public options = [];
  private queryParamFilters = [];

  @Output() dataChanged = new EventEmitter();

  constructor(public optionsService: OptionsService, public sharedFunctions: SharedFunctionsService, private cdr: ChangeDetectorRef, private dialogService: DialogService, private viewReference: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.generateOptionsAndFilterData();
  }

  generateOptionsAndFilterData(){
    //this.generateOptions();
    this.filterData();
  }

  searchChanged(): void {
    if (this.debounceTimer) clearTimeout(this.debounceTimer); // Clear previous timeout
    this.debounceTimer = window.setTimeout(() => {
      this.filterData(); // Call the actual search function after the delay
    }, 500); // Delay in milliseconds, adjust as needed
  }

  public filterData() {
    console.time('Total FilterData Function Time');

    // Start filtering process
    console.time('Initialization Time');
    console.trace('Starting Filtering data...');
    this.filteredData = [];
    let generatedData = this.data;
    console.timeEnd('Initialization Time');

    // Apply filters
    console.time('Applying Filters Time');
    this.filterOptions.forEach(filterOption => {
      generatedData = generatedData.filter(row => {
        if (!this.appliedFilters[filterOption.columnName]) return true;
        if (this.appliedFilters[filterOption.columnName] === null) return true;
        if (this.appliedFilters[filterOption.columnName].length == 0) return false;
        if (this.appliedFilters[filterOption.columnName].includes(null) && (row[filterOption.columnName] == null || !row[filterOption.columnName])) return true;

        let rowValueLowerCase = row[filterOption.columnName] ? row[filterOption.columnName].toString().toLowerCase() : null;
        let filterValuesLowerCase = this.appliedFilters[filterOption.columnName].map(value => value ? value.toString().toLowerCase() : null);

        return filterValuesLowerCase.includes(rowValueLowerCase);
      });
    });
    console.timeEnd('Applying Filters Time');

    // Assign filtered data
    console.time('Assigning Filtered Data Time');
    this.filteredData = generatedData;
    console.timeEnd('Assigning Filtered Data Time');

    // Search data
    console.time('Searching Data Time');
    if (this.searchTerm) {
      this.filteredData = this.filteredData.filter(row => {
        return Object.values(row).some(value => {
          if (value == null) return false;
          return value.toString().toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      });
    }
    console.timeEnd('Searching Data Time');

    // Sort data
    console.time('Sorting Data Time');
    console.timeEnd('Sorting Data Time');

    // // Change detection
    // console.time('Change Detection Time');
    // this.cdr.detectChanges(); // Uncomment if needed
    // console.timeEnd('Change Detection Time');

    // Generate options
    console.time('Generating Options Time');
    this.generateOptions();
    console.timeEnd('Generating Options Time');

    console.log('Finished Filtering data...');
    console.timeEnd('Total FilterData Function Time');

    // Remove this line if change detection is not needed in the future - It can slow it down a bit..
    this.cdr.detectChanges();

    this.dataChanged.emit(this.filteredData);
  }



  public generateOptions() {

    let newOptions = [];

    this.filterOptions.forEach(filterOption => {
      let uniqueValues = new Map(); // Use a Map to keep track of unique, case-insensitive values

      this.data.forEach(object => {
        const value = object[filterOption.columnName];
        if(value != null && value !== '' && value !== undefined) {
          const lowerCaseValue = value.toString().toLowerCase(); // Convert value to lowercase for case-insensitive comparison
          if(!uniqueValues.has(lowerCaseValue)) {
            uniqueValues.set(lowerCaseValue, value); // Store the original case-sensitive value
          }
        }
      });

      // Clear the options array for this column and start again
      newOptions[filterOption.columnName] = [];

      // For each unique value, create an option in filterOptions.ColumnName
      uniqueValues.forEach((originalValue, _) => {
        newOptions[filterOption.columnName].push({
          id: originalValue,
          name: originalValue,
          count: filterOption.showCount ? this.filteredData.filter(data => data[filterOption.columnName]?.toString().toLowerCase() == originalValue.toLowerCase()).length : null
        });
      });

      // Add an "Unassigned" option for null or empty values
      if(this.data.filter(data => data[filterOption.columnName] == null || !data[filterOption.columnName]).length > 0) {
        newOptions[filterOption.columnName].push({
          id: null,
          name: "Unassigned",
          count: filterOption.showCount ? this.filteredData.filter(data => data[filterOption.columnName] == null || !data[filterOption.columnName]).length : null
        });
      }

      // Sort options by count
      newOptions[filterOption.columnName].sort((a, b) => {
        return b.count - a.count; // Simplified sorting by count
      });
    });

    this.options = newOptions;

    //this.cdr.detectChanges();

    console.log('Options Generated: '+this.options);
  }

  public filterChanged(filterName, filterValues){

    console.log('filterChanged: '+filterName+' '+filterValues);

    this.appliedFilters[filterName] = filterValues;

    console.log(this.appliedFilters)

    this.filterData();
  }

  public getQueryParams(row: any): object {
    let queryParams = {};
    if (this.queryParamFilters) {
      this.queryParamFilters.forEach(column => {
        if (row[column] !== undefined) {
          queryParams[`filter_${column}`] = row[column];
        }
      });
    }

    return queryParams;
  }

}
