import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationApiService {

  constructor(private apiService: ApiService) {
  }

  getUserPermissions() {
    return this.apiService.get(`/api/user-permissions`);
  }

  getTenantSettings() {
    return this.apiService.get(`/api/tenant-settings`);
  }


}
