<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal modalSize="modal-medium">
  <div class="p-20 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Link Policy to Template</h3>
  </div>

  <div class="modal-body" style="">

    <div class="row m-10">
      <p>Linking a discovered policy to a template does not automatically update the policy. All updates and re-deployments must be done manually.</p>
    </div>

    <div *ngIf="suggestedLink" style="margin: 15px 10px;background-color: #7ab79299;padding: 14px;font-size: 13px;border-radius: 8px;">
      <i style="font-size: 18px;    margin-right: 20px;" class="fas fa-sparkles"></i>
      <strong>Suggested Link:</strong> We have found a matching template and version - Please review before saving.
    </div>

    <div class="row m-10">
      <div class="col-sm-3 formHeader">
        Select Template
      </div>
      <div class="col-lg-4">
        <select [(ngModel)]="templateId" (ngModelChange)="refreshVersions()" class="form-control" aria-label="Default select example">
<!--          <option value="0">Select a template</option>-->
          <option *ngFor="let template of templates" [value]="template.policy_template_id">{{ template.policy_name }}</option>
        </select>
      </div>
    </div>

    <div class="row m-10">
      <div class="col-sm-3 formHeader">
        Select Template Version
      </div>
      <div class="col-lg-4">
        <select [(ngModel)]="templateVersionId" class="form-control" aria-label="Default select example">
<!--          <option value="0">Select a version</option>-->
          <option *ngFor="let version of versions" [value]="version.policy_template_version_id">{{ version.version_no }}</option>
        </select>
      </div>
    </div>

    <button (click)="save()" [disabled]="!templateId || !templateVersionId" class="btn btn-primary">Link</button>


  </div>


</app-modal>
