import {Component, Input} from '@angular/core';
import * as jsondiffpatch from 'jsondiffpatch';
import {NgForOf} from '@angular/common';

@Component({
  selector: 'app-json-compare',
  templateUrl: './json-compare.component.html',
  styleUrl: './json-compare.component.css'
})
export class JsonCompareComponent {
  @Input() json1: any;
  @Input() json2: any;
  delta: any;
  changes: any[] = [];

  ngOnChanges(): void {
    this.compareJson(this.json1, this.json2);
  }

  compareJson(obj1: any, obj2: any): void {
    const differ = jsondiffpatch.create();
    this.delta = differ.diff(obj1, obj2);
    this.processDelta(this.delta);
  }

  processDelta(delta: any, path: string = ''): void {
    Object.keys(delta).forEach(key => {
      // Skip the special _t property used by jsondiffpatch for arrays
      if (key === '_t') {
        return;
      }

      const value = delta[key];
      const currentPath = path ? `${path}.${key}` : key;

      if (Array.isArray(value)) {
        if (value.length === 1) {
          // Item added
          this.changes.push({ path: currentPath, change: 'Added', newValue: JSON.stringify(value[0]) });
        } else if (value.length === 2) {
          // Item modified
          this.changes.push({ path: currentPath, change: 'Modified', oldValue: JSON.stringify(value[0]), newValue: JSON.stringify(value[1]) });
        } else if (value.length === 3 && value[2] === 0) {
          // Item removed
          this.changes.push({ path: currentPath, change: 'Removed', oldValue: JSON.stringify(value[0]) });
        }
      } else if (typeof value === 'object' && value !== null) {
        // Recursively process nested objects and arrays
        this.processDelta(value, currentPath);
      }
    });
  }

  // processDelta(delta: any, path: string = ''): void {
  //   Object.keys(delta).forEach(key => {
  //     const value = delta[key];
  //     const currentPath = path ? `${path}.${key}` : key;
  //
  //     // Check for added or removed items
  //     if (key === '_t' && value === 'a') {
  //       // Array changes, look for added/removed items
  //       Object.keys(delta).forEach(arrayKey => {
  //         if (arrayKey !== '_t') {
  //           const arrayChange = delta[arrayKey];
  //           // Array item added (represented by an array with a single element)
  //           if (Array.isArray(arrayChange) && arrayChange.length === 1) {
  //             this.changes.push({ path: `${currentPath}[${arrayKey}]`, change: 'Added', newValue: arrayChange[0] });
  //           } else if (typeof arrayChange === 'object' && arrayChange['_t'] === 'a') {
  //             // Array item removed (represented by an object with key "_" )
  //             Object.keys(arrayChange).forEach(changeKey => {
  //               if (changeKey === '_0') {
  //                 this.changes.push({ path: `${currentPath}[${arrayKey}]`, change: 'Removed', oldValue: arrayChange[changeKey][0] });
  //               }
  //             });
  //           }
  //         }
  //       });
  //     } else if (Array.isArray(value)) {
  //       if (value.length === 1) {
  //         // Item added
  //         this.changes.push({ path: currentPath, change: 'Added', newValue: value[0] });
  //       } else if (value.length === 2) {
  //         // Item modified
  //         this.changes.push({ path: currentPath, change: 'Modified', oldValue: value[0], newValue: value[1] });
  //       } else if (value.length === 3 && value[2] === 0) {
  //         // Item removed
  //         this.changes.push({ path: currentPath, change: 'Removed', oldValue: value[0] });
  //       }
  //     } else if (typeof value === 'object') {
  //       // Recursively process nested objects
  //       this.processDelta(value, currentPath);
  //     }
  //   });
  // }

  // processDelta(delta: any, path: string = ''): void {
  //   Object.keys(delta).forEach(key => {
  //     const value = delta[key];
  //     const currentPath = path ? `${path}.${key}` : key;
  //
  //     // If value is an array, it represents a change
  //     if (Array.isArray(value)) {
  //       this.changes.push({ path: currentPath, change: 'Modified', oldValue: value[0], newValue: value[1] });
  //     } else if (typeof value === 'object') {
  //       // If value is an object, recursively process
  //       this.processDelta(value, currentPath);
  //     }
  //   });
  // }
}
