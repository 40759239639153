<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal modalSize="modal-large">
  <div class="p-20 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Changes
      <div class="btn-group m-l-10" role="group" aria-label="Basic example">
        <button type="button" [class.active]="showFriendly" (click)="showFriendly = !showFriendly" class="btn btn-light-grey btn-small">Friendly</button>
        <button type="button" [class.active]="!showFriendly" (click)="showFriendly = !showFriendly" class="btn btn-light-grey btn-small">Raw</button>
      </div>
    </h3>
  </div>
  <hr>

  <div class="p-5 p-b-0">





  <!--  <div class="row">-->
<!--    <code class="col">{{data.array1 | json}}</code>-->
<!--    <code class="col">{{data.array2 | json}}</code>-->
<!--  </div>-->

<!--Yes they are switched round!-->


    <div *ngIf="!showFriendly" class="row">
      <div class="col-6">
        <h4 style="margin-left:20px">Previous Backup</h4>
        <pre style="margin-left:10px; padding:10px">{{ data.array2 | jsonFormat }}</pre>
      </div>
      <div class="col-6" style="border-left: solid 3px lightgrey">
        <h4 style="margin-left:20px">This Backup</h4>
        <pre style="margin-left:10px; padding:10px">{{ data.array1 | jsonFormat }}</pre>
      </div>
    </div>




    <app-json-tree-compare *ngIf="showFriendly" [jsonData1]="data.array2" [jsonData2]="data.array1"></app-json-tree-compare>



<!--  <app-json-comparison *ngIf="showComparison"-->
<!--    [leftTree]=""-->
<!--    [rightTree]="data.array2"-->
<!--    [hideInitialStep]="false"-->
<!--    [hideReport]="false"-->
<!--    (differenceReport)="differenceReport($event)"-->
<!--  ></app-json-comparison>-->

  </div>
</app-modal>
