import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginComponent} from "./login/login.component";

import {PortalComponent} from './portal/portal.component';
import {UserComponent} from './portal/admin/team/team.component';
import {BillingComponent} from './portal/billing/billing.component';
import {DeviceDetailsComponent} from './portal/device-details/device-details.component';
import {SetPasswordComponent} from './set-password/set-password.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {GetStartedComponent} from './portal/get-started/get-started.component';
import {RootComponent} from './portal/root/root.component';
import {IntuneComplianceComponent} from './portal/intune-health/intune-compliance/intune-compliance.component';
import {IntuneConfigurationComponent} from './portal/admin/intune-configuration/intune-configuration.component';
import {IssuesComponent} from './portal/issues/issues.component';
import {RootDevicesComponent} from './portal/root/root-devices/root-devices.component';
import {RootAgentDeployComponent} from './portal/root/root-agent-deploy/root-agent-deploy.component';
import {AlertsComponent} from './portal/admin/alerts/alerts.component';
import {IntuneDeviceListComponent} from './portal/intune-health/device-list/intune-device-list.component';
import {DashboardComponent} from './portal/dashboard/dashboard.component';
import {ReportsComponent} from './portal/reports/reports.component';
import {CustomersComponent} from './portal/admin/customers/customers.component';
import {PortalContainerComponent} from './portal-container/portal-container.component';
import {SwitchingCustomersPageComponent} from './portal/switching-customers-page/switching-customers-page.component';
import {DashboardAllCustomersComponent} from './portal/dashboard-all-customers/dashboard-all-customers.component';
import {DashboardGridComponent} from './portal/dashboard-grid/dashboard-grid.component';
import {DevicesComponent} from './portal/devices/devices.component';
import {ReportingComponent} from './portal/reporting/reporting.component';
import {DiscoveredAppsComponent} from './portal/discovered-apps/discovered-apps.component';
import {PoliciesComponent} from './portal/policies/policies.component';
import {TemplatesComponent} from './portal/templates/templates.component';
import {RootUpdatesComponent} from './portal/root/root-updates/root-updates.component';
import {QueryHubComponent} from './portal/query-hub/query-hub.component';
import {PolicyManagementComponent} from './portal/policy-management/policy-management.component';
import {InsightsComponent} from './portal/insights/insights.component';

const routes: Routes = [
  {
    path: '',
    component: PortalContainerComponent,
    children: [
      {path: '', redirectTo: 'dashboard/default', pathMatch: 'full'},
      {path: 'dashboard', redirectTo: 'dashboard/default', pathMatch: 'full'},
      {path: 'dashboard/:dashboardId', component: DashboardGridComponent},
      {path: 'customers-dash', component: DashboardAllCustomersComponent},
      {path: 'switch', component: SwitchingCustomersPageComponent},
      {path: 'idevices', component: IntuneDeviceListComponent},
      {path: 'devices', component: DevicesComponent},
      {path: 'devices/:deviceId', component: DeviceDetailsComponent},
      {path: 'issues', component: IssuesComponent},
      {path: 'billing', component: BillingComponent},
      {path: 'reports', redirectTo: 'reports/inventory'},
      {path: 'reporting', component: ReportingComponent},
      {path: 'insights', component: InsightsComponent},
      {path: 'reporting-updates/:reportName', component: ReportingComponent},
      {path: 'reporting/:reportName', component: ReportingComponent},
      {path: 'get-started', component: GetStartedComponent},
      {path: 'intune-compliance', component: IntuneComplianceComponent},
      {path: 'root', component: RootComponent},
      {path: 'apps', component: DiscoveredAppsComponent},
      {path: 'policies', component: PoliciesComponent},
      {path: 'policy-management', component: PolicyManagementComponent},
      {path: 'query-hub', component: QueryHubComponent},
      {path: 'templates', component: TemplatesComponent},
      {path: 'root/devices', component: RootDevicesComponent},
      {path: 'root/updates', component: RootUpdatesComponent},
      {path: 'root/agent-deploy', component: RootAgentDeployComponent},
      {path: 'admin/alerts', component: AlertsComponent},
      {path: 'admin/intune-config', component: IntuneConfigurationComponent},
      {path: 'admin/customers', component: CustomersComponent},
      {path: 'admin/team', component: UserComponent},
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {path: '**', redirectTo: '/'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
