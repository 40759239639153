import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {RootApiService} from '../../../services/api/root-api/root-api.service';
import {Router} from '@angular/router';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {ModalConfirmationComponent} from '../../../shared/modal-confirmation/modal-confirmation.component';
import {timer} from 'rxjs';

@Component({
  selector: 'app-root-devices',
  templateUrl: './root-devices.component.html',
  styleUrls: ['./root-devices.component.css']
})
export class RootDevicesComponent implements OnInit {

  public isLoading = true;
  public data;

  private refreshTimer = timer(10000, 60000);
  private timerSubscription;

  constructor(private rootApi: RootApiService, private router: Router, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
    this.timerSubscription = this.refreshTimer.subscribe(val => this.getData());
  }

  getData(){
    this.rootApi.getDevices().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }
}
