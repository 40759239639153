import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-eido-autocomplete',
  templateUrl: './eido-autocomplete.component.html',
  styleUrl: './eido-autocomplete.component.css'
})
export class EidoAutocompleteComponent implements OnInit, OnChanges{
  @Input() suggestions: any[] = [];
  @Input() type: string = 'string';

  @Input() selectedValue: string = '';
  @Output() selectedValueChange = new EventEmitter<string>(); // Notifies parent of changes

  showSuggestions: boolean = false;
  filteredSuggestions: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.filteredSuggestions = [];

    if(!this.suggestions || this.suggestions.length === 0){
      console.log('Suggestions array is empty.');
    } else {
      this.filteredSuggestions = this.suggestions;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
        // Check if `suggestions` or `userInput` changed
    if (changes['suggestions'] || changes['selectedValue']) {
      this.resetAndFilterSuggestions();
    }
  }

  resetAndFilterSuggestions(): void {
    // Clear filteredSuggestions
    this.filteredSuggestions = [];

    // Check if suggestions are defined and not empty
    if (this.suggestions && this.suggestions.length > 0) {
      // Filter suggestions based on userInput
      this.filterSuggestions();
    } else {
      console.log('Suggestions array is undefined or empty.');
    }
  }


  onInputChange(): void {
    this.filterSuggestions();
    this.showSuggestions = true;
  }

  onValueChange(event: any): void {
    this.selectedValueChange.emit(this.selectedValue); // Ensure this is emitted
  }

  toggleDropdown(){
    this.showSuggestions = !this.showSuggestions;
    // if filteredSuggestions is empty - show all suggestions
    if(this.filteredSuggestions.length === 0){
      this.filteredSuggestions = this.suggestions;
    }
  }

  filterSuggestions(): void {
    // Ensure the suggestions array is defined
    if (!this.suggestions) {
      console.error('Suggestions array is undefined.');
      return;
    }

    // Convert the user input to a lower-case string to simplify comparison
    // If selectedValue is null or undefined, treat it as an empty string
    const inputValue = (this.selectedValue ?? '').toString().toLowerCase();

    // Filter suggestions based on the lower-case user input
    this.filteredSuggestions = this.suggestions.filter(suggestion => {
      // Convert the suggestion to a string if it's not null or undefined, otherwise treat it as an empty string
      // This handles various data types (numbers, strings, etc.) and ensures operations like toLowerCase() work
      const suggestionStr = (suggestion ?? '').toString().toLowerCase();
      return suggestionStr.includes(inputValue);
    });
  }

  selectSuggestion(suggestion: string): void {
    this.selectedValue = suggestion; // This updates the bound property and reflects in the parent component due to ngModel.
    this.showSuggestions = false; // Hide the suggestions dropdown.
    this.onValueChange('');
  }

  hideSuggestions(): void {
    // Use a timeout to delay hiding so the click event on the suggestion can be registered
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200);
  }
}
