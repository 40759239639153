import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiUserService} from '../../../../services/api/user-api/user-api.service';
import {AlertsService} from '../../../../services/api/admin/alerts/alerts.service';
import {OptionsService} from '../../../../services/options-service/options.service';

@Component({
  selector: 'app-alerts-component-dialog',
  templateUrl: './alerts-component-dialog.component.html',
  styleUrls: ['./alerts-component-dialog.component.css']
})
export class AlertsComponentDialogComponent implements OnInit {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  loadedData;


  alertTypes = [
    {id:'email', name: 'Email'},
    {id:'slack', name: 'Slack'},
    {id:'teams', name: 'Teams'},
    {id:'webhook', name: 'Webhook'},
    {id:'servicenow', name: 'Service Now'},
    {id:'sdesk', name: 'sDesk'}
  ];

  alertSeverities = [
    {id:3, name: 'Amber'},
    {id:4, name: 'Red'}
  ];

  constructor(
    private apiAlertsService: AlertsService,
    public optionsService: OptionsService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {



      // Load Default Data
      this.loadedData = {
        alert_name: '',
        alert_severity: 3,
        alert_type: 'email',
        alert_on_intune_app: true,
        alert_on_intune_compliance: true,
        alert_on_intune_config: true,
        email_address: "",
        webhook_auth_header_required: false,
        webhook_auth_header: "",
        webhook_url: "",
        customer_id: null,
        servicenow_url: "",
        servicenow_username: "",
        servicenow_password: "",
        servicenow_use_device_primary_user_caller: true,
        servicenow_fallback_caller_email: "",
        servicenow_desc_template: "Severity: {IssueSeverity}\n" +
          "Issue Type: {IssueType}\n" +
          "Issue: {IssueName}\n" +
          "Issue Started: {IssueStart}\n" +
          "Issue Ended: {IssueEnd}\n" +
          "\n" +
          "Customer: {EidoCustomerName}\n" +
          "\n" +
          "Device Name: {DeviceName}\n" +
          "User Name: {PrimaryUserName}\n" +
          "User Email: {PrimaryUserEmail}\n" +
          "Device OS: {DeviceOperatingSystem}\n" +
          "Device Serial: {DeviceSerialNumber}\n" +
          "Device Last Intune Sync: {DeviceIntuneLastSync}\n" +
          "Eido Link: {EidoDeviceLink}",
        servicenow_short_desc_template: "{DeviceName} - [{IssueSeverity}] Intune {IssueType} Issue: {IssueName} for user: ({PrimaryUserName})",
        servicenow_assignment_group_name: "",
        servicenow_assign_to_email: "",
        servicenow_impact: "",
        servicenow_urgency: "",
        sdesk_webhook_url: "",
        sdesk_short_desc_template: "{DeviceName} - [{IssueSeverity}] Intune {IssueType} Issue: {IssueName} for user: ({PrimaryUserName})",
        sdesk_desc_template: "Severity: {IssueSeverity}\n" +
          "Issue Type: {IssueType}\n" +
          "Issue: {IssueName}\n" +
          "Issue Started: {IssueStart}\n" +
          "Issue Ended: {IssueEnd}\n" +
          "\n" +
          "Customer: {EidoCustomerName}\n" +
          "\n" +
          "Device Name: {DeviceName}\n" +
          "User Name: {PrimaryUserName}\n" +
          "User Email: {PrimaryUserEmail}\n" +
          "Device OS: {DeviceOperatingSystem}\n" +
          "Device Serial: {DeviceSerialNumber}\n" +
          "Device Last Intune Sync: {DeviceIntuneLastSync}\n" +
          "Eido Link: {EidoDeviceLink}",
        sdesk_use_device_primary_user_requester: true,
        sdesk_group_name: "",
        sdesk_status_name: "",
        sdesk_impact_name: "",
        sdesk_urgency_name: ""
      }

      this.isLoading = false;

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAlertsService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAlertsService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAlertsService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }

}
