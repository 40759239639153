import { Component, OnInit } from '@angular/core';
import {AuthApiService} from '../services/api/authentication/auth-api-service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  public showSuccessMessage = false;
  public showFailedValidationMessage = false;

  public data = {
    EmailAddress : '',
    Name: '',
    CompanyName: ''
  }

  constructor(private authApi: AuthApiService) { }

  submit(){

    if(!this.data.EmailAddress || !this.data.Name || !this.data.CompanyName){
      this.showFailedValidationMessage = true;
      return;
    }

    this.authApi.signup(this.data).then(response => {
      this.showSuccessMessage = true;
    })

  }


  ngOnInit(): void {
  }

}
