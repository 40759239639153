<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Select a widget to add</h5>
  </div>
  <div class="modal-body">

    <div class="row">
      <div *ngFor="let widget of data" (click)="this.selectedWidget = widget" class="col-4">
        <div class="card m-1">
          <div class="card-body divLink"  [class.widget-selected]="selectedWidget == widget">
            <i *ngIf="widget.widget == 'tile'" class="fal fa-sigma float-end widget-type-icon" style="color:#0D3B66"></i>
<!--            <i *ngIf="widget.widget == 'tile'" class="fal fa-square float-end widget-type-icon"></i>-->
            <i *ngIf="widget.widget == 'top'" class="fad fa-sort-size-up float-end widget-type-icon" style="color:#A54657"></i>
            <i *ngIf="widget.widget == 'table'" class="fad fa-list-ol float-end widget-type-icon" style="color:#3007ad"></i>
            <i *ngIf="widget.widget == 'chart'" class="fad fa-chart-pie float-end widget-type-icon" style="color:#EE964B"></i>
            <i *ngIf="widget.widget == 'breakdown'" class="fal fa-percentage float-end widget-type-icon" style="color:#0D3B66"></i>

            <p>{{widget.title}}</p>
<!--            <div *ngIf="widget.widget == 'tile'" class="widget-type-label" style="background-color: #0D3B66">{{widget.widget | titlecaseString }}</div>-->
<!--            <div *ngIf="widget.widget == 'top'" class="widget-type-label" style="background-color: #A54657">{{widget.widget | titlecaseString }}</div>-->
<!--            <div *ngIf="widget.widget == 'table'" class="widget-type-label" style="background-color: #009FB7">{{widget.widget | titlecaseString }}</div>-->
<!--            <div *ngIf="widget.widget == 'chart'" class="widget-type-label" style="background-color: #EE964B">{{widget.widget | titlecaseString }}</div>-->
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary">Create</div>
    <div (click)="close()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>





<!--// Blade-->

<!--<app-blade-right size="blade-medium">-->
<!--  <div class="p-10 p-b-0">-->
<!--    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>-->
<!--    <h3>New </h3>-->
<!--  </div>-->
<!--  <hr>-->


<!--  <div class="container p-0">-->

<!--  </div>-->
<!--</app-blade-right>-->
