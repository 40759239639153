import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {PolicyApiService} from '../../services/api/policy/policy-api.service';
import {GetStartedApiService} from '../../services/api/get-started/get-started-api.service';
import {any} from 'codelyzer/util/function';
import {AgentSoftwareDownloadApiService} from '../../services/api/deploy/agent-software-download-api.service';
import {ModalConfirmationComponent} from '../../shared/modal-confirmation/modal-confirmation.component';
import {ApiUserService} from '../../services/api/user-api/user-api.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {CustomersComponentDialog} from '../admin/customers/team-create-edit-dialog/customers-create-edit-dialog.component';
import {
  IntuneChoosePermissionsComponent
} from '../admin/intune-configuration/intune-choose-permissions/intune-choose-permissions.component';


@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent implements OnInit {

  public getStartedStep;
  public inviteTeamData = [
    {user_name: '', email: ''},
    {user_name: '', email: ''},
    {user_name: '', email: ''}
  ];

  public intuneError = null;
  public intuneErrorDescription = null;

  constructor(private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private policyApi: PolicyApiService,
              private getStartedApi: GetStartedApiService,
              private agentDownloadApi: AgentSoftwareDownloadApiService,
              private teamApi: ApiUserService,
              private toastr: ToastrService,
              private route: ActivatedRoute
  ) {

    this.refreshGetStartedData();

  }

  refreshGetStartedData() {
    this.getStartedApi.get().then(response => {
      this.getStartedStep = response.get_started_step;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.state) {
        this.getStartedApi.handleIntuneResponse(params.tenant, params.state, params.admin_consent, params.error, params.error_description).then(response => {
          if (params.error) {
            this.intuneError = params.error;
            this.intuneErrorDescription = params.error_description;
          }

          this.refreshGetStartedData();

        });
      }
    });
  }

  setMspMode(setMspMode:any) {
    this.getStartedApi.setMspMode(setMspMode).then(response => {
      this.refreshGetStartedData();
    });
  }

  grantIntuneAccess() {
      // Replace null with Component
      this.dialogService.createDialog(IntuneChoosePermissionsComponent, null, this.viewReference)
        .then(accessScope => {
          this.getStartedApi.grantIntuneAccess(accessScope.toString(), 'get-started').then(response => {
            this.goToLink(response.intuneAuthoriseUrl, true);
          });
        })
  }


  skipIntuneAccess() {
    this.getStartedApi.skipIntune().then(response => this.refreshGetStartedData());
  }

  inviteUsers() {

    if (!this.inviteUsersFormValid()) {
      return;
    }

    var usersToInvite = this.inviteTeamData.filter(user => user.user_name && user.email);

    var invitedUserCount = 0;
    usersToInvite.forEach(user => {
      this.teamApi.create(user).then(response => {
        invitedUserCount++;
      });
    });

    this.getStartedApi.invitedTeam(invitedUserCount).then(response => this.refreshGetStartedData());
  }

  goToLink(url: string, sameWindow = false) {
    if (sameWindow) {
      window.open(url, '_self');
    } else {
      window.open(url, '_blank');
    }
  }

  inviteUsersFormValid() {

    var valid = true;
    this.inviteTeamData.forEach(user => {
      if (user.user_name || user.email) {
        if (!user.user_name || !user.email) {
          valid = false;
        }
        if (!this.validateEmail(user.email)) {
          valid = false;
        }
      }
    });

    console.log('REUTRNING:' + valid);
    return valid;
  }

  skipInviteUsers() {
    this.getStartedApi.invitedTeam(null).then(response => this.refreshGetStartedData());
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


}
