<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<h3>Root</h3>

<div class="col-12 m-b-20 " style="margin-top: 22px;">
  <ul class="nav dialogTabs">
    <li class="nav-item divLink" (click)="tab = 'customer'">
      <a class="nav-link-thread" [class.active]="tab == 'customer'">
        Customers ({{totalCustomers}})
      </a>
    </li>
    <li class="nav-item divLink" (click)="tab = 'trial'">
      <a class="nav-link-thread" [class.active]="tab == 'trial'">
        Trials ({{totalTrials}})
      </a>
    </li>

    <li class="nav-item divLink" (click)="tab = 'expired-trial'">
      <a class="nav-link-thread" [class.active]="tab == 'expired-trial'">
        Expired Trials
      </a>
    </li>

  </ul>
</div>

<div *ngIf="!isLoading">
  <div class="row m-t-20">
    <div class="col"><h4 style="color: #bdbdbd;">COMPANY NAME</h4></div>
    <div class="col-1"><h4 style="color: #bdbdbd;">USERS</h4></div>
    <div class="col-1"><h4 style="color: #bdbdbd;">DEVICES</h4></div>
    <div class="col-2"><h4 style="color: #bdbdbd;">LAST ACTIVE</h4></div>
    <div class="col-2"><h4 style="color: #bdbdbd;">TRIAL DAYS LEFT</h4></div>
    <div class="col-2"><h4 style="color: #bdbdbd;">LOGIN</h4></div>
    <!--    <div class="col-3"><h4 style="color: #bdbdbd;">LAST ACTIVITY</h4></div>-->
  </div>

  <div *ngFor="let row of this.getTenants()" class="divLink sectionBox row">
    <div class="col"><h4>{{row.tenant_name}}</h4></div>
    <div class="col-1 p-l-5"><h4>{{row.user_count}}</h4></div>
    <div class="col-1 p-l-5"><h4>{{row.device_count}}</h4></div>
    <div class="col-2 p-l-5"><h4>{{row.last_active}}</h4></div>
    <div class="col-2 p-l-5"><h4>{{row.trial_days_left}}</h4></div>
    <div class="col-2 p-l-5">

      <i (click)="deleteTenant(row.id)" class="fal fa-times align-middle float-end" style="background-color: indianred; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px; text-align: center;" aria-hidden="true"></i>
      <div (click)="switchTenant(row.id)" style="
      background: #e3e3e3;
      padding: 3px;
      border-radius: 10px;
      text-align: center;
      font-size: 16px;
      max-width: 180px;">Switch</div>


    </div>
  </div>
</div>
