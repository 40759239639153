import {Component, ViewContainerRef} from '@angular/core';
import {PolicyDialogComponent} from '../policies/policy-dialog/policy-dialog.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {PolicyApiService} from '../../services/api/policy/policy-api.service';
import {multicast} from 'rxjs';
import {TemplateViewDialogComponent} from '../templates/template-view/template-view-dialog.component';
import {TemplateEditDialogComponent} from '../templates/template-edit/template-edit-dialog.component';
import {TemplateDeployDialogComponent} from '../templates/template-deploy-dialog/template-deploy-dialog.component';
import {ModalConfirmationComponent} from '../../shared/modal-confirmation/modal-confirmation.component';
import {TemplatesApiService} from '../../services/api/templates/templates-api.service';
import {TemplateDeployBulkDialogComponent} from '../templates/template-deploy-bulk-dialog/template-deploy-bulk-dialog.component';

@Component({
  selector: 'app-policy-management',
  templateUrl: './policy-management.component.html',
  styleUrl: './policy-management.component.css'
})
export class PolicyManagementComponent {

  public selectedTab = 'managed';

  public isLoadingPolicies = true;
  public isLoadingTemplates = true;

  public managedPolicies = [];
  public unmanagedPolicies = [];
  public templates = [];

  // bulk actions
  public bulkActionName = '';
  public bulkChangeShowProgressWindow = false;
  public bulkChangeInProgress = false;
  public bulkChangePromises = [];

  public bulkChangeTotalCount = 0;
  public bulkChangeInProgressCount = 0;
  public bulkChangeCompletedCount = 0;
  public bulkChangeFailedCount = 0;

  constructor(private dialogService: DialogService, private viewReference: ViewContainerRef, private policyApiService: PolicyApiService, private templatesApiService: TemplatesApiService) {
    this.getData();
  }

  public managedPoliciesGridSettings = {
    actionButtons: ['View'],
    multiSelect: false,
    multiSelectActions: [],
    columns: [
      { field: 'policy_name', headerName: 'Name', checkboxSelection: false, headerCheckboxSelection: false, },
      { field: 'customer_name', headerName: 'Customer', checkboxSelection: false, headerCheckboxSelection: false, },
      { field: 'policy_type_name', headerName: 'Policy Type' },
      { field: 'sync_status', headerName: 'Status', cellRenderer: 'policySyncStatusRendererComponent' },
      { field: 'version_status', headerName: 'Template Version' },
      { field: 'policy_last_updated', headerName: 'Last Updated', cellRenderer: 'amTimeAgoRenderer' },
      { field: 'most_recent_backup_date', headerName: 'Last Backup', cellRenderer: 'amTimeAgoRenderer' }
    ]
  }

  public discoveredPoliciesGridSettings = {
    actionButtons: ['View'],
    multiSelect: true,
    multiSelectActions: ['Convert to template'],
    columns: [
      { field: 'policy_name', headerName: 'Name', checkboxSelection: true, headerCheckboxSelection: true, },
      { field: 'customer_name', headerName: 'Customer', checkboxSelection: false, headerCheckboxSelection: false, },
      { field: 'policy_type_name', headerName: 'Policy Type' },
      { field: 'sync_status', headerName: 'Status', cellRenderer: 'policySyncStatusRendererComponent' },
      { field: 'version_status', headerName: 'Template Version' },
      { field: 'policy_last_updated', headerName: 'Last Updated', cellRenderer: 'amTimeAgoRenderer' },
      { field: 'most_recent_backup_date', headerName: 'Last Backup', cellRenderer: 'amTimeAgoRenderer' }
    ]
  }

  public templatesGridSettings = {
    actionButtons: ['View', 'Edit', 'Deploy'],
    multiSelect: false,
    multiSelectActions: ['Delete', 'Deploy'],
    columns: [
      { field: 'policy_name', headerName: 'Friendly Name', checkboxSelection: true, headerCheckboxSelection: true, },
      { field: 'policy_type_name', headerName: 'Policy Type' },
      { field: 'versions', headerName: 'Versions' },
      { field: 'deployments', headerName: 'Deployments' },
      { field: 'configDrifts', headerName: 'Config Drifts' },
      { field: 'behind', headerName: 'Policies Behind Latest Version' },

      { field: 'parent_policy_name', headerName: 'Master Policy Name' },
      { field: 'created_at', headerName: 'Created', cellRenderer: 'amTimeAgoRenderer' },
      { field: 'updated_at', headerName: 'Last Updated', cellRenderer: 'amTimeAgoRenderer' }
    ]
  }

  public resetBulkChange() {
    this.bulkChangeTotalCount = 0;
    this.bulkChangeInProgressCount = 0;
    this.bulkChangeCompletedCount = 0;
    this.bulkChangeFailedCount = 0;
    this.bulkChangeShowProgressWindow = false;
    this.bulkChangeInProgress = false;
    this.bulkChangePromises = [];
    this.bulkActionName = '';
  }

  getData() {
    this.isLoadingPolicies = true;
    this.isLoadingTemplates = true;

    this.policyApiService.listAll().then(response => {
      this.managedPolicies = response.filter(policy => policy.managed_status === 'Managed');
      this.unmanagedPolicies = response.filter(policy => policy.managed_status === 'Unmanaged');
      this.isLoadingPolicies = false;
    });

    this.templatesApiService.listAll().then(response => {
      this.templates = response;
      this.isLoadingTemplates = false;
    });

  }

  // Policy Actions
  public managedPoliciesGridSettingsRowClicked(data:any) {
    this.dialogService.createDialog(PolicyDialogComponent, data.eido_policy_id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {
        this.getData();

      })

  }

  public managedPoliciesGridSettingsActionClicked(data:any) {

    if(data.action === 'View') {
      console.log(data);
      this.dialogService.createDialog(PolicyDialogComponent, data.rowData.eido_policy_id, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

  }

  public managedPoliciesGridSettingsMultiSelectAction(data: any) {
    if (data.action === 'Convert to template') {
      console.log(data);
      this.dialogService.createDialog(ModalConfirmationComponent, `Are you sure you want to convert ${data.selectedRows.length} policies to a template?`, this.viewReference)
        .then(confirmed => {

          this.resetBulkChange();

          this.bulkActionName = 'Converting policies to templates';
          this.bulkChangeShowProgressWindow = true;
          this.bulkChangeInProgress = true;
          this.bulkChangePromises = []; // Initialize the array

          data.selectedRows.forEach(row => {
            let newPromise = this.policyApiService.createTemplate(row.eido_policy_id).then(response => {
              // Mark the item as completed
              const item = this.bulkChangePromises.find(item => item.promise === newPromise);
              if (item) {
                item.status = 'completed';
                this.bulkChangeCompletedCount ++;
                this.bulkChangeInProgressCount --;
              }
            }, error => {
                // Mark the item as completed

              const item = this.bulkChangePromises.find(item => item.promise === newPromise);
              if (item) {
                item.status = 'failed';
                this.bulkChangeFailedCount ++;
                this.bulkChangeInProgressCount --;
              }
            });

            this.bulkChangePromises.push({
              promise: newPromise,
              policyName: row.policy_name,
              status: 'inProgress'
            });

            this.bulkChangeInProgressCount ++;
            this.bulkChangeTotalCount ++;
          });

          // Wait for all promises to resolve
          Promise.all(this.bulkChangePromises.map(item => item.promise)).then(response => {
            this.getData();
            this.bulkChangeInProgress = false;
          });

        }, cancelled => null);
    }
  }

  // Template Actions
  public templatesGridSettingsRowClicked(data:any) {
    this.dialogService.createDialog(PolicyDialogComponent, data.eido_policy_id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })

  }

  public templatesGridSettingsActionClicked(data:any) {

    if(data.action === 'View') {
      console.log(data);
      // Replace null with Component
      this.dialogService.createDialog(TemplateViewDialogComponent, data.rowData.policy_template_id, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

    if(data.action === 'Edit') {
      console.log(data);
      // Replace null with Component
      this.dialogService.createDialog(TemplateEditDialogComponent, data.rowData.policy_template_id, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

    if(data.action === 'Deploy') {
      console.log(data);
      // Replace null with Component

      // prepare payload
      let payload = {
        template_id: data.rowData.policy_template_id,
        policy_uuid: null,
        eido_intune_config_id: null
      }

      this.dialogService.createDialog(TemplateDeployDialogComponent, payload, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

  }

  public templatesGridSettingsMultiSelectAction(data:any) {



    if (data.action === 'Delete') {
      console.log(data);
      this.dialogService.createDialog(ModalConfirmationComponent, `Are you sure you want to delete ${data.selectedRows.length} templates?`, this.viewReference)
        .then(confirmed => {

          this.resetBulkChange();

          this.bulkActionName = 'Deleting Templates';
          this.bulkChangeShowProgressWindow = true;
          this.bulkChangeInProgress = true;
          this.bulkChangePromises = []; // Initialize the array

          data.selectedRows.forEach(row => {

            // Update Action Here
            let newPromise = this.templatesApiService.delete(row.policy_template_id).then(response => {

              // Mark the item as completed
              const item = this.bulkChangePromises.find(item => item.promise === newPromise);
              if (item) {
                item.status = 'completed';
                this.bulkChangeCompletedCount ++;
                this.bulkChangeInProgressCount --;
              }
            }, error => {
              // Mark the item as completed

              const item = this.bulkChangePromises.find(item => item.promise === newPromise);
              if (item) {
                item.status = 'failed';
                this.bulkChangeFailedCount ++;
                this.bulkChangeInProgressCount --;
              }
            });

            this.bulkChangePromises.push({
              promise: newPromise,
              policyName: row.policy_name,
              status: 'inProgress'
            });

            this.bulkChangeInProgressCount ++;
            this.bulkChangeTotalCount ++;
          });

          // Wait for all promises to resolve
          Promise.all(this.bulkChangePromises.map(item => item.promise)).then(response => {
            this.getData();
            this.bulkChangeInProgress = false;
          });

        }, cancelled => null);
    }

    if (data.action === 'Deploy') {
      console.log(data);
      this.dialogService.createDialog(TemplateDeployBulkDialogComponent, data.selectedRows, this.viewReference)
        .then(confirmedTenantId => {

          console.log('Deploying to Tenant ID: ', confirmedTenantId);

          this.resetBulkChange();

          this.bulkActionName = 'Deploying Templates to Tenant';
          this.bulkChangeShowProgressWindow = true;
          this.bulkChangeInProgress = true;
          this.bulkChangePromises = []; // Initialize the array

          data.selectedRows.forEach(row => {

            // Update Action Here
            let newPromise = this.templatesApiService.deploy(row.latest_version_id, confirmedTenantId).then(response => {

              // Mark the item as completed
              const item = this.bulkChangePromises.find(item => item.promise === newPromise);
              if (item) {
                item.status = 'completed';
                this.bulkChangeCompletedCount ++;
                this.bulkChangeInProgressCount --;
              }
            }, error => {
              // Mark the item as completed

              const item = this.bulkChangePromises.find(item => item.promise === newPromise);
              if (item) {
                item.status = 'failed';
                this.bulkChangeFailedCount ++;
                this.bulkChangeInProgressCount --;
              }
            });

            this.bulkChangePromises.push({
              promise: newPromise,
              policyName: row.policy_name,
              status: 'inProgress'
            });

            this.bulkChangeInProgressCount ++;
            this.bulkChangeTotalCount ++;
          });

          // Wait for all promises to resolve
          Promise.all(this.bulkChangePromises.map(item => item.promise)).then(response => {
            this.getData();
            this.bulkChangeInProgress = false;
          });

        }, cancelled => null);
    }




  }

  protected readonly Math = Math;
}
