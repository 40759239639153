import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {CustomersComponentDialog} from './team-create-edit-dialog/customers-create-edit-dialog.component';
import {ModalConfirmationComponent} from '../../../shared/modal-confirmation/modal-confirmation.component';
import {ApiUserService} from '../../../services/api/user-api/user-api.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {ApiCustomerService} from '../../../services/api/admin/customers/customer-api.service';
import {OptionsService} from '../../../services/options-service/options.service';

@Component({
  selector: 'app-team',
  templateUrl: './customers.html'
})
export class CustomersComponent implements OnInit {

  data = [];
  isLoading = true;

  constructor(private apiCustomerService: ApiCustomerService, private dialogService: DialogService, private viewReference: ViewContainerRef, private optionsService: OptionsService) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiCustomerService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(CustomersComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
        this.optionsService.loadCustomers();

      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(CustomersComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
        this.optionsService.loadCustomers();

      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiCustomerService.delete(id).then(response => {
          this.getData();
          this.optionsService.loadCustomers();

        })
      }, cancelled => null)
  }



}
