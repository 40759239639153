<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div (click)="grantIntuneAccess()" class="btn btn-primary float-end">Link New Tenant</div>
<h3>Intune Tenants</h3>

<div *ngIf="!isLoading">
  <div class="row m-t-20" style="padding-left:10px">
    <div class="col-4"><h4 style="color: #bdbdbd;">FRIENDLY NAME</h4></div>
    <div class="col-2"><h4 style="color: #bdbdbd;">LAST SYNC</h4></div>
    <div class="col-2"><h4 style="color: #bdbdbd;">LAST BACKUP</h4></div>
    <div class="col-2"><h4 style="color: #bdbdbd;">ACCESS</h4></div>

  </div>

  <div *ngFor="let row of this.tenants" class="divLink sectionBox row">
    <div class="col-4"><h4>{{row.intune_tenant_friendly_name}}</h4></div>
    <div class="col-2 p-l-5"><h4><app-friendly-time [utcTimeData]="row.discovery_last_sync"></app-friendly-time></h4></div>
    <div class="col-2 p-l-5"><h4>
      <app-friendly-time *ngIf="row.backup_enabled" [utcTimeData]="row.backup_last_date"></app-friendly-time>
      <span *ngIf="!row.backup_enabled">Disabled</span>

      <span *ngIf="row.backup_enabled && !row.backup_last_date">Never</span>


    </h4>

    </div>

    <div class="col-2 p-l-5">
      <h4>{{row.access_scope}}</h4>

    </div>
    <div class="col">

    </div>
    <div class="col">

    <i (click)="delete(row.id)" class="fas fa-trash float-end fa-15x m-l-10 p-1  divLink" style="
    margin-top: 6px;
"></i>

    <div (click)="edit(row.id)" class="btn btn-primary float-end m-r-20">Edit</div>

    </div>
  </div>
</div>
