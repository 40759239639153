import { Injectable } from '@angular/core';
import {ApiCustomerService} from '../api/admin/customers/customer-api.service';
import {CustomDashboardsService} from '../api/custom-dashboards/custom-dashboards.service';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  public customers = [];
  public dashboards = [];
  public status = [
    {id: 1, name: 'Unknown'},
    {id: 2, name: 'Green'},
    {id: 3, name: 'Amber'},
    {id: 4, name: 'Red'}
  ]

  constructor(private customerApi: ApiCustomerService, private customDashboardApi: CustomDashboardsService) {
    //this.loadCustomers(); - This is now only called after permissions are returned - as doesnt need to be called for non-msp users
    this.loadDashboards();
  }

  loadCustomers(){
    this.customerApi.listAll().then(response => {
      this.customers = response;
    })
  }

  loadDashboards(){
    console.log("loading dashboards2");

    this.customDashboardApi.listAll().then(response => {
      this.dashboards = response;
    })
  }
}
