import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../services/authentication/authentication.service';

@Component({
  selector: 'app-portal-container',
  templateUrl: './portal-container.component.html',
  styleUrls: ['./portal-container.component.css']
})
export class PortalContainerComponent implements OnInit {

  constructor(public auth: AuthenticationService) {
    this.auth.checkLoggedIn();
  }

  ngOnInit(): void {
  }

}
