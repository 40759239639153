// percent-badge-patch-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-percent-badge-patch-renderer',
  standalone: true,
  template: `
    <span *ngIf="percent != null" class="m-0 text-shorten numberBadge" [ngClass]="{
      'green': percent == 100,
      'amber': percent > 90 && percent < 100,
      'red': percent <= 90
    }">{{percent}} %</span>
  `,
  imports: [
    NgClass
  ],
  styles: [`
    .numberBadge { /* Add your styles here */
    }
  `]
})
export class PercentBadgePatchRendererComponent implements ICellRendererAngularComp {
  public percent: any;

  agInit(params: any): void {
    this.percent = params.value;
  }

  refresh(params: any): boolean {
    this.percent = params.value;
    return true;
  }
}
