<div class="row">
  <div class="col-6">
    <h4 style="margin-left:20px">Previous Backup</h4>
    <app-json-tree [data]="jsonData1" [level]="level"></app-json-tree>
  </div>
  <div class="col-6" style="border-left: solid 1px lightgrey">
    <h4 style="margin-left:20px">This Backup</h4>
    <app-json-tree [data]="jsonData2" [comparisonData]="compareObjects(jsonData1, jsonData2)" [level]="level" [comparisonMode]="true"></app-json-tree>
  </div>
</div>
