
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>






<div *ngIf="!isLoading">
  <div class="row">
    <h3>
      <span  *ngIf="data.intune_device_type == 'Windows'" [matTooltip]="'Windows'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" style="color:#1b5e7d" class=" text-center device-by-os-icon"><i class="fab fa-windows"></i></span>
      <span *ngIf="data.intune_device_type == 'Android (Mobile)'" [matTooltip]="'Android (Mobile)'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" style="color:#7d9339" class=" text-center device-by-os-icon"><i class="fab fa-android"></i></span>
      <span *ngIf="data.intune_device_type == 'iOS (Mobile)' || data.intune_device_type == 'MacOS'" [matTooltip]="'Apple/IOS'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" style="color:#999999" class=" text-center device-by-os-icon"><i class="fab fa-apple"></i></span>
      <span *ngIf="data.intune_device_type == 'Linux'" [matTooltip]="'Linux'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" style="color:#FCC624" class=" text-center device-by-os-icon"><i class="fab fa-linux"></i></span>
      <span *ngIf="data.intune_device_type == 'BlackBerry OS'" [matTooltip]="'BlackBerry OS'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" style="color:#005387" class=" text-center device-by-os-icon"><i class="fab fa-blackberry"></i></span>
      <span *ngIf="data.intune_device_type == 'Chrome OS'" [matTooltip]="'Chrome OS'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" style="color:#2669db" class=" text-center device-by-os-icon"><i class="fab fa-chrome"></i></span>

      {{data.computer_name}}</h3>
    <h4>{{data.intune_user_display_name}} ({{data.intune_user_email}})</h4>
  </div>

  <div class="row">
    <div class="col" (click)="this.currentMetricsSubTab = 'details'">
      <div  class="dashboardTile divLink">

        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">
          <i class="fal fa-sync fa-3x green"></i>
        </div>

        <h6 class="label">Last Sync</h6>
        <h5 class="value" style="font-size: 22px">{{data.intune_last_sync | amTimeAgo}}</h5>
      </div>
    </div>
    <div class="col" (click)="this.currentMetricsSubTab = 'compliance'">
      <div  class="dashboardTile divLink">

        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">
          <i *ngIf="data.intune_compliance_status == 1" style="color: grey" class="fa-3x fal fa-exclamation-triangle align-middle p-0"></i>
          <i *ngIf="data.intune_compliance_status == 2" class="fal fa-shield-alt fa-3x green"></i>
          <i *ngIf="data.intune_compliance_status == 3" style="color: #e8a962" class="fa-3x fal fa-exclamation-triangle align-middle p-0"></i>
          <i *ngIf="data.intune_compliance_status == 4" style="color: indianred" class="fa-3x fal fa-exclamation-triangle align-middle p-0"></i>
        </div>
        <h6 class="label">Compliance Status</h6>
        <h5 *ngIf="data.intune_compliance_status == 1" class="value" style="font-size: 22px">Unknown</h5>
        <h5 *ngIf="data.intune_compliance_status == 2" class="value" style="font-size: 22px">Compliant</h5>
        <h5 *ngIf="data.intune_compliance_status == 3" class="value" style="font-size: 22px">Non Compliant</h5>
        <h5 *ngIf="data.intune_compliance_status == 4" class="value" style="font-size: 22px">Non Compliant</h5>
      </div>
    </div>

    <div class="col" (click)="this.currentMetricsSubTab = 'patches'">
      <div  class="dashboardTile divLink">

        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">
          <i *ngIf="data.patch_status == 1" style="color: grey" class="fa-3x fal fa-exclamation-triangle align-middle p-0"></i>
          <i *ngIf="data.patch_status == 2" class="fal fa-shield-alt fa-3x green"></i>
          <i *ngIf="data.patch_status == 3" style="color: #e8a962" class="fa-3x fal fa-exclamation-triangle align-middle p-0"></i>
          <i *ngIf="data.patch_status == 4" style="color: indianred" class="fa-3x fal fa-exclamation-triangle align-middle p-0"></i>
        </div>
        <h6 class="label">Patch Status</h6>
        <h5 class="value" style="font-size: 22px">{{data.patch_status_name}}</h5>
      </div>
    </div>
<!--    <div class="col">-->
<!--      <div  class="dashboardTile divLink">-->

<!--        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">-->
<!--          <i *ngIf="data.intune_is_encrypted == 'true'" class="fal fa-lock fa-3x green"></i>-->
<!--          <i *ngIf="data.intune_is_encrypted != 'true'" style="color: indianred" class="fal fa-lock-open fa-3x"></i>-->
<!--        </div>-->

<!--        <h6 class="label">Encryption Status</h6>-->
<!--        <h5 class="value" style="font-size: 22px">{{data.intune_is_encrypted == 'true' ? 'Encrypted' : 'Not Encrypted'}}</h5>-->
<!--      </div>-->

<!--    </div>-->
    <div class="col">
      <div  class="dashboardTile divLink" (click)="this.currentMetricsSubTab = 'warranty'">

        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">
          <i *ngIf="data.warranty_status == 'Active'" class="fal fa-check fa-3x green"></i>
          <i *ngIf="data.warranty_status == 'Expired'" style="color: indianred" class="fal fa-times fa-3x"></i>
          <i *ngIf="data.warranty_status == 'Unknown' || !data.warranty_status" style="color: grey" class="fal fa-question fa-3x"></i>
        </div>

        <h6 class="label">Warranty Status</h6>
        <h5 class="value" style="font-size: 22px">{{data.warranty_status}}</h5>
      </div>

    </div>
  </div>

  <div class="row m-t-20">
    <div class="col-12">
      <div class="row ">
        <div class="col-12">
          <div *ngIf="currentMetricsTab == 'intune'" class="">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="deviceDetailsTopTab" (click)="currentMetricsSubTab = 'details'" [class.active]="currentMetricsSubTab == 'details'">
                    Details
                  </div>
                  <div class="deviceDetailsTopTab" *ngIf="data.warranty_expiry_date" (click)="currentMetricsSubTab = 'warranty'" [class.active]="currentMetricsSubTab == 'warranty'">
                    Warranty
                  </div>
                  <div class="deviceDetailsTopTab" (click)="currentMetricsSubTab = 'patches'" [class.active]="currentMetricsSubTab == 'patches'">
                    Patches

                    <span *ngIf="data.patch_status >= 3"
                          [style.background-color]="getStatusColor(data.patch_status)"
                          style=" color: white;    padding: 8px;    border-radius: 25px;    padding-top: 2px;    padding-bottom: 2px; margin-left: 3px;">
                      <i style="color: white; font-size: 14px" class=" fas fa-exclamation-triangle align-middle p-0"></i>

                    </span>

                  </div>
                  <div class="deviceDetailsTopTab" (click)="currentMetricsSubTab = 'compliance'" [class.active]="currentMetricsSubTab == 'compliance'">
                    Compliance
                    <span *ngIf="data.compliance_issues"
                          [style.background-color]="getStatusColor(data.intune_compliance_status)"
                          style=" color: white;    padding: 8px;    border-radius: 25px;    padding-top: 2px;    padding-bottom: 2px; margin-left: 3px;">
                      {{data.compliance_issues}}
                    </span>
                  </div>
                  <div class="deviceDetailsTopTab" (click)="currentMetricsSubTab = 'config-policies'" [class.active]="currentMetricsSubTab == 'config-policies'">
                    Config Policies
                    <span *ngIf="data.config_issues"
                          [style.background-color]="getStatusColor(data.intune_config_status)"
                          style="color: white;    padding: 8px;    border-radius: 25px;    padding-top: 2px;    padding-bottom: 2px; margin-left: 3px;">
                      {{data.config_issues}}
                    </span>
                  </div>
                  <div class="deviceDetailsTopTab" (click)="currentMetricsSubTab = 'managed-apps'" [class.active]="currentMetricsSubTab == 'managed-apps'">
                    Managed Apps
                    <span *ngIf="data.app_issues"
                          [style.background-color]="getStatusColor(data.intune_app_status)"
                          style="color: white;    padding: 8px;    border-radius: 25px;    padding-top: 2px;    padding-bottom: 2px; margin-left: 3px;">
                      {{data.app_issues}}
                    </span>
                  </div>
                  <div class="deviceDetailsTopTab" (click)="currentMetricsSubTab = 'all-apps'" [class.active]="currentMetricsSubTab == 'all-apps'">
                    All Apps
                    <span style="    background-color: #95b9a7;    color: white;    padding: 8px;    border-radius: 25px;    padding-top: 2px;    padding-bottom: 2px; margin-left: 3px;">
                      {{data.discovered_apps ? data.discovered_apps.length : 0}}
                    </span>
                  </div>
                </div>
                <!--              Class to use: noTopLeftRadius-->
                <div class="sectionBox noTopLeftRadius">

                  <div *ngIf="currentMetricsSubTab == 'details'" class="row">

                    <div class="col-xl-6">

                      <div *ngFor="let cat of this.intuneDataCategories">
                        <div (click)="toggleCategory(cat.categoryName)" class="collapsableHeader divLink m-10"  style="background-color: #d5d7da52;padding: 9px 12px;font-size: 16px;border-radius: 10px;">
                          <i class="fas m-r-10" [ngClass]="cat.isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                          {{cat.categoryName}}
                        </div>
                        <div *ngIf="cat.isExpanded">
                          <div *ngFor="let field of this.intuneDataSchema">
                            <div *ngIf="field.category == cat.categoryName" class="row" style="padding: 8px 12px;font-size: 14px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
                            <div class="col-5">
                              <span><strong>{{field.friendlyName}} <i *ngIf="field.showTooltip" class="fas fa-question-circle" [matTooltip]="field.description" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i></strong></span>
                            </div>
                            <div class="col-7">
                              <span *ngIf="field.field == 'osVersion'"> {{ this.data.os_friendly_name }} - </span>

                              <span>{{this.intuneDeviceData[field.field]}}</span>

                            </div>
                          </div>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>

                  <div *ngIf="currentMetricsSubTab == 'warranty'" class="row">

                    <div class="col-xl-6">

                      <div>
                        <div class="collapsableHeader divLink m-10"  style="background-color: #d5d7da52;padding: 9px 12px;font-size: 16px;border-radius: 10px;">
                          Warranty Details
                        </div>
                        <div>
                          <div >
                            <div class="row" style="padding: 8px 12px;font-size: 14px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
                              <div class="col-5">
                                <span><strong>Purchase Date</strong></span>
                              </div>
                              <div class="col-7">
                                <span>{{data.warranty_ship_date}} ({{data.warranty_ship_date | amTimeAgo}})</span>

                              </div>
                            </div>
                          </div>
                          <div >
                            <div class="row" style="padding: 8px 12px;font-size: 14px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
                              <div class="col-5">
                                <span><strong>Warranty Expiry</strong></span>
                              </div>
                              <div class="col-7">
                                <span>{{data.warranty_expiry_date}} ({{data.warranty_expiry_date | amTimeAgo}})
                                                            <i *ngIf="!isDateGreaterThanToday(data.warranty_expiry_date)" class="fas fa-exclamation-triangle fa-12x" style="color:indianred"></i>

                                </span>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>





                      <h4 class="m-t-20">Warranty Breakdown:</h4>


                      <div class="row m-t-20">
                        <div class="col-7"><h4 style="color: #bdbdbd;">NAME</h4></div>
                        <div class="col"><h4 style="color: #bdbdbd;">START DATE</h4></div>
                        <div class="col"><h4 style="color: #bdbdbd;">END DATE</h4></div>
                      </div>


                      <div  *ngFor="let row of data.warranty_data?.entitlements">
                        <div class="row divLink"
                             style="background-color: #d4d7da52; padding: 8px 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
                          <div class="col-7">
                            <span> {{row.serviceLevelDescription}} <span style="font-size: 11px"> ({{row.entitlementType | titlecaseString}})</span></span>
                          </div>
                          <div class="col"><span> {{row.startDate | amTimeAgo}}</span></div>
                          <div class="col"><span>{{row.endDate | amTimeAgo}}
                            <i *ngIf="!isDateGreaterThanToday(row.endDate)" class="fas fa-exclamation-triangle fa-12x" style="color:indianred"></i>
                          </span>
                          </div>

                        </div>
                      </div>



                    </div>




                  </div>

                  <div *ngIf="currentMetricsSubTab == 'compliance'">

                    <div class="row m-t-20">
                      <div class="col-4"><h4 style="color: #bdbdbd;">NAME</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">STATE</h4></div>
                      <div class="col-4"><h4 style="color: #bdbdbd;">ASSIGNMENT</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">INTUNE STATE</h4></div>
                    </div>


                    <div  *ngFor="let row of getIntuneDrillDown('compliance')">
                      <div (click)="toggleL4Expand(row.metric_type, row.metric_name)" class="row divLink"
                           style="background-color: #d4d7da52; padding: 12px; font-size: 16px; margin-top: 4px; border-radius: 12px">
                        <div class="col-4">
                          <span>  <i class="fas m-r-10" [ngClass]="isL4Expanded(row.metric_type, row.metric_name) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                            {{row.metric_name}}</span>
                        </div>
                        <div class="col-2 " ><span>
                          <i *ngIf="row.status == 1" class="fas fa-question align-middle" style="background-color: #a1a1a1; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 2" class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 3" class="fal fa-times align-middle" style="background-color: #e8a962; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 4" class="fal fa-times align-middle" style="background-color: indianred; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                        </span></div>
                        <div class="col-4"><span>{{row.assignment}}</span></div>
                        <div class="col-2"><span>{{row.state}}</span></div>

                      </div>


                        <div *ngIf="isL4Expanded(row.metric_type, row.metric_name)">
                          <div *ngFor="let row of getIntuneL5DrillDown(row.metric_type, row.metric_name)" class="row divLink"
                               style="background-color: #ffffff; padding: 8px 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
                            <div class="col-4"><span class="p-l-20">{{row.metric_name}}</span></div>
                            <div class="col-2 " ><span>
                            <i *ngIf="row.status == 1" class="fas fa-question align-middle" style="background-color: #a1a1a1; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 2" class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 3" class="fal fa-times align-middle" style="background-color: #e8a962; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 4" class="fal fa-times align-middle" style="background-color: indianred; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          </span></div>
                            <div class="col-4"><span>{{row.assignment}}</span></div>
                            <div class="col-2"><span>{{row.state}}</span></div>

                          </div>
                        </div>
                    </div>



                  </div>

                  <div *ngIf="currentMetricsSubTab == 'config-policies'">

                    <div class="row m-t-20">
                      <div class="col-4"><h4 style="color: #bdbdbd;">NAME</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">STATE</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">ASSIGNMENT</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">INTUNE STATE</h4></div>
                    </div>

                    <div  *ngFor="let row of getIntuneDrillDown('config')">
                      <div (click)="toggleL4Expand(row.metric_type, row.metric_name)" class="row divLink"
                           style="background-color: #d4d7da52; padding: 12px; font-size: 16px; margin-top: 4px; border-radius: 12px">
                        <div class="col-4">
                          <span>  <i class="fas m-r-10" [ngClass]="isL4Expanded(row.metric_type, row.metric_name) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                            {{row.metric_name}}</span>
                        </div>
                        <div class="col-2 " ><span>
                          <i *ngIf="row.status == 1" class="fas fa-question align-middle" style="background-color: #a1a1a1; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 2" class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 3" class="fal fa-times align-middle" style="background-color: #e8a962; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 4" class="fal fa-times align-middle" style="background-color: indianred; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                        </span></div>
                        <div class="col-4"><span>{{row.assignment}}</span></div>
                        <div class="col-2"><span>{{row.state}}</span></div>

                      </div>


                      <div *ngIf="isL4Expanded(row.metric_type, row.metric_name)">
                        <div *ngFor="let row of getIntuneL5DrillDown(row.metric_type, row.metric_name)" class="row divLink"
                             style="background-color: #ffffff; padding: 8px 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
                          <div class="col-4"><span class="p-l-20">{{row.metric_name}}</span></div>
                          <div class="col-2 " ><span>
                            <i *ngIf="row.status == 1" class="fas fa-question align-middle" style="background-color: #a1a1a1; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 2" class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 3" class="fal fa-times align-middle" style="background-color: #e8a962; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 4" class="fal fa-times align-middle" style="background-color: indianred; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          </span></div>
                          <div class="col-4"><span>{{row.assignment}}</span></div>
                          <div class="col-2"><span>{{row.state}}</span></div>

                        </div>
                      </div>
                    </div>


                  </div>

                  <div *ngIf="currentMetricsSubTab == 'managed-apps'">
                    <div class="row m-t-20">
                      <div class="col-4"><h4 style="color: #bdbdbd;">NAME</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">STATE</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">ASSIGNMENT</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">INTUNE STATE</h4></div>
                      <div class="col-2"><h4 style="color: #bdbdbd;">APP INTENT</h4></div>
                    </div>


                    <div  *ngFor="let row of getIntuneDrillDown('app')">
                      <div (click)="toggleL4Expand(row.metric_type, row.metric_name)" class="row divLink"
                           style="background-color: #d4d7da52; padding: 12px; font-size: 16px; margin-top: 4px; border-radius: 12px">
                        <div class="col-4">
                          <span>  <i class="fas m-r-10" [ngClass]="isL4Expanded(row.metric_type, row.metric_name) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                            {{row.metric_name}}</span>
                        </div>
                        <div class="col-2 " ><span>
                          <i *ngIf="row.status == 1" class="fas fa-question align-middle" style="background-color: #a1a1a1; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 2" class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 3" class="fal fa-times align-middle" style="background-color: #e8a962; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          <i *ngIf="row.status == 4" class="fal fa-times align-middle" style="background-color: indianred; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                        </span></div>
                        <div class="col-2"><span>{{row.assignment}}</span></div>
                        <div class="col-2"><span>{{row.state}}</span></div>
                        <div class="col-2"><span>{{row.app_intent}}</span></div>
                      </div>


                      <div *ngIf="isL4Expanded(row.metric_type, row.metric_name)">
                        <div *ngFor="let row of getIntuneL5DrillDown(row.metric_type, row.metric_name)" class="row divLink"
                             style="background-color: #ffffff; padding: 8px 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
                          <div class="col-4"><span class="p-l-20">{{row.metric_name}}</span></div>
                          <div class="col-2 " ><span>
                            <i *ngIf="row.status == 1" class="fas fa-question align-middle" style="background-color: #a1a1a1; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 2" class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 3" class="fal fa-times align-middle" style="background-color: #e8a962; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                            <i *ngIf="row.status == 4" class="fal fa-times align-middle" style="background-color: indianred; color: white; padding: 10px 15px; padding-top: 6px; padding-bottom: 6px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
                          </span></div>
                          <div class="col-2"><span>{{row.assignment}}</span></div>
                          <div class="col-2"><span>{{row.state}}</span></div>
                          <div class="col-2"><span>{{row.app_intent}}</span></div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div *ngIf="currentMetricsSubTab == 'all-apps'">
                    <app-eido-super-table [data]="data.discovered_apps" [settings]="discoveredAppsSuperTableSettings" [hideFilter]="true"></app-eido-super-table>
<!--                  </div>-->
<!--                  <div *ngIf="currentMetricsSubTab == 'all-apps'">-->
<!--                    <div class="row m-t-20">-->
<!--                      <div class="col-4"><h4 style="color: #bdbdbd;">NAME</h4></div>-->
<!--                      <div class="col-4"><h4 style="color: #bdbdbd;">VERSION</h4></div>-->
<!--                    </div>-->

<!--                    <div *ngFor="let row of decodeJson(data.intune_discovered_apps)" class="row" style="background-color: #f9f9f9; padding: 12px; font-size: 16px; margin-top: 4px; border-radius: 12px">-->
<!--                      <div class="col-4"><span>{{row.ApplicationName}}</span></div>-->
<!--                      <div class="col-4"><span>{{row.ApplicationVersion}}</span></div>-->
<!--                    </div>-->
<!--                  </div>-->

                </div>

                  <div *ngIf="currentMetricsSubTab == 'patches'">
                    <app-eido-super-table [data]="data.patches" [settings]="data.patches_super_table_settings" [hideFilter]="true"></app-eido-super-table>
                    <!--                  </div>-->
                    <!--                  <div *ngIf="currentMetricsSubTab == 'all-apps'">-->
                    <!--                    <div class="row m-t-20">-->
                    <!--                      <div class="col-4"><h4 style="color: #bdbdbd;">NAME</h4></div>-->
                    <!--                      <div class="col-4"><h4 style="color: #bdbdbd;">VERSION</h4></div>-->
                    <!--                    </div>-->

                    <!--                    <div *ngFor="let row of decodeJson(data.intune_discovered_apps)" class="row" style="background-color: #f9f9f9; padding: 12px; font-size: 16px; margin-top: 4px; border-radius: 12px">-->
                    <!--                      <div class="col-4"><span>{{row.ApplicationName}}</span></div>-->
                    <!--                      <div class="col-4"><span>{{row.ApplicationVersion}}</span></div>-->
                    <!--                    </div>-->
                    <!--                  </div>-->

                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>

</div>
