<app-loading-spinner *ngIf="isLoadingData || isLoadingStats" ></app-loading-spinner>

<div *ngIf="!isLoadingData && !isLoadingStats">

  <div class="row m-b-5">
    <div class="col">
      <i (click)="fullScreen()" class="far fa-expand-wide fa-12x float-end divLink" style="background: #ffffff;
    padding: 5px 13px;
    font-size: 18px;
    border-radius: 15px;
    border-radius: 10px;
    border: 1px solid #cbcbcb;"></i>
      <div *ngIf="editMode" (click)="cancelEdit()" class="btn btn-primary btn-small float-end">Cancel</div>
      <div *ngIf="editMode" (click)="saveEdit()" class="btn btn-primary btn-small float-end">Save</div>
      <div *ngIf="editMode" (click)="openDialog()" class="btn btn-primary btn-small float-end" style="margin-right: 40px">Add Item</div>
      <div *ngIf="editMode" (click)="this.dashboard.widget_json = []" class="btn btn-primary btn-small float-end">Clear</div>
      <div *ngIf="editMode && dashboardId != 'create' && dashboardId != 'default'" (click)="deleteDashboard()" class="btn btn-cancel btn-small float-end">Delete</div>

      <div *ngIf="!editMode" (click)="toggleEdit()" class="btn btn-primary btn-small float-end">Edit</div>
      <div id="dashboard-name">
        <h5 *ngIf="!editMode" >{{dashboard.name}}</h5>
        <input  *ngIf="editMode" [(ngModel)]="dashboard.name" type="text" class="form-control" id="Alert_Name" placeholder="Name" autocomplete="off" style="
    width: 18vw;
    background-color: #f6f8f9;
    color: #4f4f4f;
">

      </div>
    </div>
  </div>

  <div class="row">

    <div id="parent-grid" style="
      height: calc(100vh - 75px);
      margin: 0;
      margin-top: 5px
    ">
      <gridster [class.hideGridLines]="!editMode" [options]="options" style="  background: #f0f0f0; /* Replace with your desired color or gradient */">
        <gridster-item [item]="item" *ngFor="let item of dashboard.widget_json" style="background: white!important">

          <i class="fas fa-trash-alt float-end p-5 m-1 divLink" *ngIf="this.editMode" (mousedown)="removeItem($event, item)"
             (touchstart)="removeItem($event, item)"></i>

          <app-widget-visual [item]="item" [editMode]="editMode"></app-widget-visual>

        </gridster-item>
      </gridster>


    </div>
  </div>

</div>
