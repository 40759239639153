<!--<div class="col divLink deviceDetailsTab" ><h3>Intune Health & Compliance</h3></div>-->

<div *ngIf="!isLoading">

  <div class="row">
    <div id="dashboard-name"><h5>Default Dashboard <i (click)="fullScreen()" class="far fa-expand-wide fa-12x divLink float-end m-r-10"></i></h5></div>
  </div>

  <div class="row">
    <div style="width: 230px;">
      <div class="dashboardTile divLink">

                <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">
                  <i class="fas fa-shield-check fa-3x green" aria-hidden="true"></i>
                </div>

<!--        <i class=""></i>-->
        <h5 class="value">{{this.data.intune_breakdown.intune_compliance_percent}} %</h5><h6 class="label">Compliant</h6>
      </div>
    </div>

    <div style="width: 150px;">
      <div class="dashboardTile divLink">
        <h5 class="value">{{this.data.intune_breakdown.device_count}}</h5><h6 class="label">Devices</h6>
      </div>
    </div>
    <div style="width: 150px;">
      <div class="dashboardTile divLink">
        <h5 class="value">{{this.data.intune_breakdown.device_inactive_count}}</h5><h6 class="label">Inactive</h6>
      </div>
    </div>

    <div style="width: 150px;">
      <div class="dashboardTile divLink">
        <h5 class="value">{{this.data.intune_breakdown.device_lost_count}}</h5><h6 class="label">Lost</h6>
      </div>
    </div>

    <div class="col">
      <div class="dashboardTile divLink" [routerLink]="['/intune-health/devices']" [queryParams]="">

        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
          <div *ngIf="this.data.intune_breakdown.intune_issues_compliance_red" style=" font-size: 11px;background: indianred; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_breakdown.intune_issues_compliance_red}} Urgent</div>
          <div *ngIf="this.data.intune_breakdown.intune_issues_compliance_amber" style="font-size: 11px;background: #eaaf6d; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_issues_compliance_amber}} Medium</div>
        </div>
        <!--        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">-->
        <!--          <i class="fas fa-shield-alt fa-3x green" aria-hidden="true"></i>-->
        <!--        </div>-->
        <h5 class="value">{{this.data.intune_breakdown.intune_issues_compliance_total}}</h5><h6 class="label">Compliance Issues</h6>
      </div>
    </div>

    <div class="col">
      <div class="dashboardTile divLink" [routerLink]="['/intune-health/devices']" [queryParams]="">
        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
          <div *ngIf="this.data.intune_breakdown.intune_issues_config_red" style=" font-size: 11px;background: indianred; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_breakdown.intune_issues_config_red}} Urgent</div>
          <div *ngIf="this.data.intune_breakdown.intune_issues_config_amber" style="font-size: 11px;background: #eaaf6d; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_breakdown.intune_issues_config_amber}} Medium</div>
        </div>
        <h5 class="value">{{this.data.intune_breakdown.intune_issues_config_total}}</h5><h6 class="label">Config Issues</h6>
      </div>
    </div>

    <div class="col">
      <div class="dashboardTile divLink" [routerLink]="['/intune-health/devices']" [queryParams]="">
        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
          <div *ngIf="this.data.intune_breakdown.intune_issues_app_red" style=" font-size: 11px;background: indianred; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_breakdown.intune_issues_app_red}} Urgent</div>
          <div *ngIf="this.data.intune_breakdown.intune_issues_app_amber" style="font-size: 11px;background: #eaaf6d; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_breakdown.intune_issues_app_amber}} Medium</div>
        </div>
        <h5 class="value">{{this.data.intune_breakdown.intune_issues_app_total}}</h5><h6 class="label">App Issues</h6>
      </div>
    </div>

  </div>

<!--  <div class="row">-->
<!--    <div class="dashboard-status-container">-->
<!--      <div [style.width.%]="5" *ngIf="true"-->
<!--           [routerLink]="['/devices']" [queryParams]="{online: 1, status: 4}"-->
<!--           class="divLink dashboard-status-bar red">-->
<!--        <div class="statistic">19</div>-->
<!--        <div class="label">Unhealthy</div>-->
<!--      </div>-->
<!--      <div [style.width.%]="80"-->
<!--           [routerLink]="['/devices']" [queryParams]="{online: 1, status: 2}"-->
<!--           class="divLink dashboard-status-bar green">-->
<!--        <div class="statistic">300</div>-->
<!--        <div class="label">Healthy</div>-->
<!--      </div>-->
<!--      <div [style.width.%]="10"-->
<!--           [routerLink]="['/devices']" [queryParams]="{online: 0}"-->
<!--           class="divLink dashboard-status-bar lightGreen">-->
<!--        <div class="statistic">9</div>-->
<!--        <div class="label">Offline</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->





  <div class="row">
    <div class="col-8" style="padding-right: 15px">


<!--      <div id="compliance-trend">-->
<!--        <div class="row">-->
<!--          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">-->
<!--            <p style="color: #818f95;font-size: 18px;">COMPLIANCE TREND</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="sectionBox" style="height: 150px">-->
<!--          <div class="row" style="height: 110px; border-top: 2px #1024354a dashed;">-->
<!--            <div class="columnContainer col"-->
<!--                 [matTooltip]="[0 + ' Issues']"-->
<!--                 [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"><div [style.height.px]="100" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="100" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="99" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="89" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="92" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="89" class="trendGraph-column amber"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="85" class="trendGraph-column amber"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="89" class="trendGraph-column amber"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="89" class="trendGraph-column amber"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="100" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="99" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="89" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="92" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--            <div class="columnContainer col"><div [style.height.px]="95" class="trendGraph-column"></div></div>-->
<!--          </div>-->
<!--          <div class="row" style="margin-top: 4px">-->
<!--            <div class="col text-center trendGraph-border-right">1</div>-->
<!--            <div class="col text-center trendGraph-border-right">2</div>-->
<!--            <div class="col text-center trendGraph-border-right">3</div>-->
<!--            <div class="col text-center trendGraph-border-right">4</div>-->
<!--            <div class="col text-center trendGraph-border-right">5</div>-->
<!--            <div class="col text-center trendGraph-border-right">6</div>-->
<!--            <div class="col text-center trendGraph-border-right">7</div>-->
<!--            <div class="col text-center trendGraph-border-right">8</div>-->
<!--            <div class="col text-center trendGraph-border-right">9</div>-->
<!--            <div class="col text-center trendGraph-border-right">10</div>-->
<!--            <div class="col text-center trendGraph-border-right">11</div>-->
<!--            <div class="col text-center trendGraph-border-right">12</div>-->
<!--            <div class="col text-center trendGraph-border-right">13</div>-->
<!--            <div class="col text-center trendGraph-border-right">14</div>-->
<!--            <div class="col text-center trendGraph-border-right">15</div>-->
<!--            <div class="col text-center trendGraph-border-right">16</div>-->
<!--            <div class="col text-center trendGraph-border-right">17</div>-->
<!--            <div class="col text-center trendGraph-border-right">18</div>-->
<!--            <div class="col text-center trendGraph-border-right">19</div>-->
<!--            <div class="col text-center trendGraph-border-right">20</div>-->
<!--            <div class="col text-center trendGraph-border-right">21</div>-->
<!--            <div class="col text-center trendGraph-border-right">22</div>-->
<!--            <div class="col text-center trendGraph-border-right">23</div>-->
<!--            <div class="col text-center trendGraph-border-right">24</div>-->
<!--            <div class="col text-center trendGraph-border-right">25</div>-->
<!--            <div class="col text-center trendGraph-border-right">26</div>-->
<!--            <div class="col text-center trendGraph-border-right">27</div>-->
<!--            <div class="col text-center trendGraph-border-right">28</div>-->
<!--            <div class="col text-center trendGraph-border-right">29</div>-->
<!--            <div class="col text-center trendGraph-border-right">30</div>-->
<!--            <div class="col text-center trendGraph-border-right">31</div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->




      <div id="top-issues">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">TOP ISSUES (ALL)</p>
          </div>
        </div>
        <div class="p-0" style="border-radius: 15px;padding: 3px !important;">
          <div *ngFor="let issue of data.top_issues" class="row" style="
    background-color: white;
    border-radius: 4px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    border: 0 solid #e9ecef;
    /* border-radius: 0.2rem; */
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);">
            <div class="col" style="padding: 8px 8px 10px 8px;">
              <!--                <div class="dashboard-problematic-badge float-end" style="max-width:150px;" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)">{{issue.device_count}}</div>-->
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->

<!--              <div class="float-end" style="padding: 6px; font-size: 15px;">{{issue.device_count}}</div>-->
              <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
              <div style="margin-right: 6px; margin-left: 6px">
                <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
              </div>

            </div>
<!--            <div class="col-1 text-center dashboard-top-issues-badge" style="">-->
<!--              {{issue.device_count}}-->
<!--            </div>-->
            <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
            {{issue.device_count}}
            </div>
            <!--              <div class="col-2">-->
            <!--              </div>-->
          </div>
        </div>

      </div>

      <div id="top-issues-compliance">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">TOP ISSUES (COMPLIANCE)</p>
          </div>
        </div>
        <div class="p-0" style="border-radius: 15px;padding: 3px !important;">
          <div *ngFor="let issue of data.top_issues_compliance" class="row" style="
    background-color: white;
    border-radius: 4px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    border: 0 solid #e9ecef;
    /* border-radius: 0.2rem; */
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);">
            <div class="col" style="padding: 8px 8px 10px 8px;">
              <!--                <div class="dashboard-problematic-badge float-end" style="max-width:150px;" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)">{{issue.device_count}}</div>-->
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->

              <!--              <div class="float-end" style="padding: 6px; font-size: 15px;">{{issue.device_count}}</div>-->
              <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
              <div style="margin-right: 6px; margin-left: 6px">
                <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
              </div>

            </div>
            <!--            <div class="col-1 text-center dashboard-top-issues-badge" style="">-->
            <!--              {{issue.device_count}}-->
            <!--            </div>-->
            <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
              {{issue.device_count}}
            </div>
            <!--              <div class="col-2">-->
            <!--              </div>-->
          </div>
        </div>

      </div>

      <div id="top-issues-config">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">TOP ISSUES (CONFIG)</p>
          </div>
        </div>
        <div class="p-0" style="border-radius: 15px;padding: 3px !important;">
          <div *ngFor="let issue of data.top_issues_config" class="row" style="
    background-color: white;
    border-radius: 4px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    border: 0 solid #e9ecef;
    /* border-radius: 0.2rem; */
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);">
            <div class="col" style="padding: 8px 8px 10px 8px;">
              <!--                <div class="dashboard-problematic-badge float-end" style="max-width:150px;" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)">{{issue.device_count}}</div>-->
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->

              <!--              <div class="float-end" style="padding: 6px; font-size: 15px;">{{issue.device_count}}</div>-->
              <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
              <div style="margin-right: 6px; margin-left: 6px">
                <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
              </div>

            </div>
            <!--            <div class="col-1 text-center dashboard-top-issues-badge" style="">-->
            <!--              {{issue.device_count}}-->
            <!--            </div>-->
            <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
              {{issue.device_count}}
            </div>
            <!--              <div class="col-2">-->
            <!--              </div>-->
          </div>
        </div>

      </div>

      <div id="top-issues-app">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">TOP ISSUES (APPS)</p>
          </div>
        </div>
        <div class="p-0" style="border-radius: 15px;padding: 3px !important;">
          <div *ngFor="let issue of data.top_issues_app" class="row" style="
    background-color: white;
    border-radius: 4px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    border: 0 solid #e9ecef;
    /* border-radius: 0.2rem; */
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);">
            <div class="col" style="padding: 8px 8px 10px 8px;">
              <!--                <div class="dashboard-problematic-badge float-end" style="max-width:150px;" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)">{{issue.device_count}}</div>-->
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->

              <!--              <div class="float-end" style="padding: 6px; font-size: 15px;">{{issue.device_count}}</div>-->
              <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
              <div style="margin-right: 6px; margin-left: 6px">
                <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
              </div>

            </div>
            <!--            <div class="col-1 text-center dashboard-top-issues-badge" style="">-->
            <!--              {{issue.device_count}}-->
            <!--            </div>-->
            <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
              {{issue.device_count}}
            </div>
            <!--              <div class="col-2">-->
            <!--              </div>-->
          </div>
        </div>

      </div>


    </div>
    <div class="col-4 p-r-5">
<!--      <div id="intune-sync">-->
<!--        <div class="row">-->
<!--          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">-->
<!--            <p style="color: #818f95;font-size: 18px;">INTUNE SYNC</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="sectionBox p-0">-->
<!--          <div class="row m-b-5">-->
<!--          <div class="col" style="padding: 8px;">-->

<!--            <i *ngIf="data.sync_status.status == 1" style="-->
<!--    color: #9bb8a7;-->
<!--     font-size: 31px;-->
<!--              padding: 4px;-->
<!--              padding-top: 8px;-->
<!--              padding-left: 20px;-->
<!--" class="fas fa-check-circle float-end"></i>-->
<!--            <i *ngIf="data.sync_status.status == 0" style="-->
<!--              color: indianred;-->
<!--              font-size: 31px;-->
<!--              padding: 4px;-->
<!--              padding-top: 8px;-->
<!--              padding-left: 20px;-->
<!--" class="fas fa-times-circle float-end"></i>-->

<!--            <div style="padding:10px">-->
<!--              <strong>Last Sync:  </strong>-->
<!--              {{data.sync_status.last_sync ? (data.sync_status.last_sync | amTimeAgo) : "Never"}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div id="longest-issues">-->
<!--        <div class="row">-->
<!--          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">-->
<!--            <p style="color: #818f95;font-size: 18px;">LONGEST ISSUES</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="sectionBox">-->
<!--          <div *ngFor="let issue of data.long_running_issues" class="row m-b-5">-->
<!--            <div class="col-8">-->
<!--              &lt;!&ndash;            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>&ndash;&gt;-->
<!--              <div class="dashboard-problematic-label">{{issue.computer_name}} ({{issue.intune_metric_type}})</div>-->
<!--            </div>-->
<!--            <div class="col-4">-->
<!--              <div class="dashboard-problematic-badge" [ngClass]="statusColour(issue.severity)">{{issue.start_date | amTimeAgo}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


      <div id="new-devices">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">NEW DEVICES</p>
          </div>
        </div>
        <div class="sectionBox p-0">
          <div *ngFor="let issue of data.new_devices" class="row" style="">
            <div class="col-9">
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->
              <div class="dashboard-problematic-label" style="padding: 11px;">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.created_at | amTimeAgo}}</div>
            </div>
          </div>
        </div>
      </div>

      <div id="inactive-devices">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">INACTIVE DEVICES</p>
          </div>
        </div>
        <div class="sectionBox p-0">
          <div *ngFor="let issue of data.inactive_devices" class="row" style="">
            <div class="col-9">
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->
              <div class="dashboard-problematic-label" style="padding: 11px;">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.intune_last_sync | amTimeAgo}}</div>
            </div>
          </div>
        </div>
      </div>

 <div id="longest-non-compliant-devices">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">LONGEST NON COMPLIANT</p>
          </div>
        </div>
        <div class="sectionBox p-0">
          <div *ngFor="let issue of data.longest_non_compliant" class="row" style="">
            <div class="col-9">
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->
              <div class="dashboard-problematic-label" style="padding: 11px;">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.min_issue_start_date | amTimeAgo}}</div>
            </div>
          </div>
        </div>
      </div>

      <div id="devices-with-most-issues">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">DEVICES WITH MOST ISSUES</p>
          </div>
        </div>
        <div class="sectionBox p-0">
          <div *ngFor="let issue of data.devices_with_most_issues" class="row" style="">
            <div class="col-9">
              <!--            <div class="dashboard-problematic-label">{{issue.intune_metric_type}}</div>-->
              <div class="dashboard-problematic-label" style="padding: 11px;">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.issue_count}}</div>
            </div>
          </div>
        </div>
      </div>




      <div id="os-breakdown">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">DEVICES BY OS</p>
          </div>
        </div>

        <div class="sectionBox p-0">

          <div class="row">
            <div class="col text-center device-by-os-icon"><i class="fab fa-windows"></i></div>
            <div class="col text-center device-by-os-icon"><i class="fab fa-android"></i></div>
            <div class="col text-center device-by-os-icon"><i class="fab fa-apple"></i></div>
            <div class="col text-center device-by-os-icon"><i class="fab fa-linux"></i></div>
            <div class="col text-center device-by-os-icon"><i class="fab fa-blackberry"></i></div>
            <div class="col text-center device-by-os-icon"><i class="fab fa-chrome"></i></div>
          </div>

          <app-highchart-pie [inputData]="data.device_by_os" [title]="'OS Breakdown'"></app-highchart-pie>
        </div>
      </div>


      <div id="manufacturer-breakdown">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">DEVICES BY MANUFACTURER</p>
          </div>
        </div>

        <div class="sectionBox p-0">
          <app-highchart-pie [inputData]="data.device_by_manufacturer" [title]="'OS Breakdown'"></app-highchart-pie>
        </div>
      </div>

      <div id="device_by_model">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">DEVICES BY MODEL</p>
          </div>
        </div>

        <div class="sectionBox p-0">
          <app-highchart-pie [inputData]="data.device_by_model" [title]="'OS mODEL'"></app-highchart-pie>
        </div>
      </div>
      <div id="device-by-encryption-status">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">ENCRYPTION</p>
          </div>
        </div>

        <div class="sectionBox p-0">
          <app-highchart-pie [inputData]="data.device_by_encryption_status" [title]="'Encryption Status'"></app-highchart-pie>
        </div>
      </div>

      <div id="device_by_owner_type">
        <div class="row">
          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
            <p style="color: #818f95;font-size: 18px;">DEVICES BY OWNERSHIP</p>
          </div>
        </div>

        <div class="sectionBox p-0">
          <app-highchart-pie [inputData]="data.device_by_owner_type" [title]="'Encryption Status'"></app-highchart-pie>
        </div>
      </div>


<!--      <div id="os-breakdown">-->
<!--        <div class="row">-->
<!--          <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">-->
<!--            <p style="color: #818f95;font-size: 18px;">DEVICES BY OS</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="sectionBox p-0">-->

<!--          <div class="row" style="height: 250px; flex-wrap: wrap-reverse;">-->
<!--            <div class="col text-center device-by-os-icon"><i class="fab fa-windows"></i></div>-->
<!--            <div class="col text-center device-by-os-icon"><i class="fab fa-android"></i></div>-->
<!--            <div class="col text-center device-by-os-icon"><i class="fab fa-apple"></i></div>-->
<!--            <div class="col text-center device-by-os-icon"><i class="fab fa-linux"></i></div>-->
<!--            <div class="col text-center device-by-os-icon"><i class="fab fa-blackberry"></i></div>-->
<!--            <div class="col text-center device-by-os-icon"><i class="fab fa-chrome"></i></div>-->
<!--          </div>-->

<!--&lt;!&ndash;          <app-highchart-pie [inputData]="data.device_by_os" [title]="'OS Breakdown'"></app-highchart-pie>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->


    </div>

  </div>













</div>
