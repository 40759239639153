import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonFormat',
})
export class JsonFormatPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    // Convert the JSON object to a formatted string
    return JSON.stringify(value, null, 2);
  }

}
