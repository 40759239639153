import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-json-tree-compare',
  templateUrl: './json-tree-compare.component.html',
  styleUrls: ['./json-tree-compare.component.css']
})
export class JsonTreeCompareComponent {
  @Input() jsonData1: any;
  @Input() jsonData2: any;
  @Input() level: number = 0;

  expandedStates: { [key: string]: boolean } = {};

  // Determines if the value is an object or an array for rendering purposes
  isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  // Toggles the expanded state for a given key
  toggleExpand(key: string): void {
    this.expandedStates[key] = !this.expandedStates[key];
  }

  // Compares two objects and returns a result with added, removed, and changed properties
  compareObjects(obj1: any, obj2: any) {
    const comparisonResult = {};
    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    allKeys.forEach(key => {
      if (!obj2.hasOwnProperty(key)) {
        comparisonResult[key] = { value: obj1[key], status: 'removed' };
      } else if (!obj1.hasOwnProperty(key)) {
        comparisonResult[key] = { value: obj2[key], status: 'added' };
      } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        comparisonResult[key] = { value: obj2[key], status: 'changed' };
      } else {
        comparisonResult[key] = { value: obj2[key], status: 'same' };
      }
    });

    return comparisonResult;
  }



  // compareObjectsForChildrenNeedsWork(obj1: any, obj2: any): any {
  //   const compareValues = (val1: any, val2: any): any => {
  //     if (Array.isArray(val1) && Array.isArray(val2)) {
  //       return compareArrays(val1, val2);
  //     } else if (isObject(val1) && isObject(val2)) {
  //       return this.compareObjects(val1, val2); // Recursive call for nested objects
  //     } else if (val1 !== val2) {
  //       return { value: val2, status: 'changed' };
  //     } else {
  //       return { value: val2, status: 'same' };
  //     }
  //   };
  //
  //   const compareArrays = (arr1: any[], arr2: any[]): any[] => {
  //     const maxLength = Math.max(arr1.length, arr2.length);
  //     const result = [];
  //
  //     for (let i = 0; i < maxLength; i++) {
  //       if (i >= arr1.length) {
  //         result.push({ value: arr2[i], status: 'added' });
  //       } else if (i >= arr2.length) {
  //         result.push({ value: arr1[i], status: 'removed' });
  //       } else {
  //         result.push(compareValues(arr1[i], arr2[i])); // Recursive call for elements in arrays
  //       }
  //     }
  //     return result;
  //   };
  //
  //   const isObject = (value: any): boolean => {
  //     return value !== null && typeof value === 'object' && !Array.isArray(value);
  //   };
  //
  //   const comparisonResult: any = {};
  //   const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
  //
  //   allKeys.forEach(key => {
  //     if (!obj2.hasOwnProperty(key)) {
  //       comparisonResult[key] = { value: obj1[key], status: 'removed' };
  //     } else if (!obj1.hasOwnProperty(key)) {
  //       comparisonResult[key] = { value: obj2[key], status: 'added' };
  //     } else {
  //       comparisonResult[key] = compareValues(obj1[key], obj2[key]);
  //     }
  //   });
  //
  //   return comparisonResult;
  // }

  protected readonly Object = Object;
}
