<div *ngIf="isLoading">Loading...</div>

<div *ngIf="!isLoading" class="row" style="margin-top: 30px">


  <div class="row">
    <div class="col">
      <h3>Agent Module</h3>

      <div class="row m-t-20" style="margin-left:5px">
        <div class="col-4"><h4 style="color: #bdbdbd;">VERSION</h4></div>
        <div class="col-4"><h4 style="color: #bdbdbd;">DEPLOY DATE</h4></div>
        <div class="col-4"><h4 style="color: #bdbdbd;">CURRENT VERSION</h4></div>
      </div>

      <div *ngFor="let version of this.getModuleVersions('EidoAgent')">

        <div class="divLink sectionBox row">
          <div class="col-4"><h4>{{version.version_no}}</h4></div>
          <div class="col-4 p-l-5"><h4>{{version.deploy_date}}</h4></div>
          <div class="col-2 p-l-5"><h4>
            <div *ngIf="!version.is_current" (click)="deployModule(version.module, version.id)" style="margin: -5px;" class="btn btn-primary">Deploy</div>
            <i *ngIf="version.is_current" class="fas fa-check-circle"></i>
          </h4></div>
        </div>
      </div>

    </div>
    <div class="col">
      <h3>Agent Auto Updater Module</h3>

      <div class="row m-t-20" style="margin-left:5px">
        <div class="col-4"><h4 style="color: #bdbdbd;">VERSION</h4></div>
        <div class="col-4"><h4 style="color: #bdbdbd;">DEPLOY DATE</h4></div>
        <div class="col-4"><h4 style="color: #bdbdbd;">CURRENT VERSION</h4></div>
      </div>

      <div *ngFor="let version of this.getModuleVersions('EidoAgentAutoUpdater')">

        <div class="divLink sectionBox row">
          <div class="col-4"><h4>{{version.version_no}}</h4></div>
          <div class="col-4 p-l-5"><h4>{{version.deploy_date}}</h4></div>
          <div class="col-2 p-l-5"><h4>
            <div *ngIf="!version.is_current" (click)="deployModule(version.module, version.id)" style="margin: -5px;" class="btn btn-primary">Deploy</div>
            <i *ngIf="version.is_current" class="fas fa-check-circle"></i>
          </h4></div>
        </div>
      </div>

    </div>

    </div>
  </div>



