import {Component, Input, OnInit} from '@angular/core';
import 'moment/locale/en-gb';
import 'moment';

@Component({
  selector: 'app-friendly-time',
  templateUrl: './friendly-time.component.html',
  styleUrls: ['./friendly-time.component.css']
})
export class FriendlyTimeComponent implements OnInit {

  @Input() utcTimeData;

  constructor() { }

  ngOnInit(): void {
  }

}
