<div *ngIf="percent" class="row">
  <div style="width: 50px">{{percent.toFixed(0) | number }}%</div>
  <div class="col p-t-5 p-l-10 p-r-20">

    <div  class="dashboard-location-container" style="
    background-color: #cbcbcb;
    border-radius: 10px;
">

      <div [style.width.%]="percent"
           [matTooltip]="[percent+' %']" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"
           class="divLink dashboard-location-bar" [ngClass]="getStatusColor()"></div>
    </div>


  </div>
</div>
