<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal modalSize="modal-large">
  <div class="p-20 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Changes

    </h3>
  </div>
  <hr>

  <div class="p-5 p-b-0">

    <ngx-monaco-diff-editor [options]="options" [originalModel]="originalModel" [modifiedModel]="modifiedModel" style="height: 75vh"></ngx-monaco-diff-editor>

  </div>
</app-modal>



