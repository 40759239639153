<div class="dashboard-location-container">
  <div (click)="redirectToPage(link, redLinkParams)"
        [style.width.%]="(red / total) * 100" *ngIf="red > 0"
       [matTooltip]="red+' red devices'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"
       class="divLink dashboard-location-bar red">
    <p class="dashboard-location-container-percentage">{{Math.round((red / total) * 100)}}%</p>
  </div>
  <div (click)="redirectToPage(link, amberLinkParams)"
       [style.width.%]="(amber / total) * 100" *ngIf="amber > 0"
       [matTooltip]="amber+' amber devices'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"
       class="divLink dashboard-location-bar amber">
    <p class="dashboard-location-container-percentage">{{Math.round((amber / total) * 100)}}%</p>
  </div>
  <div *ngIf="green() > 0"  (click)="redirectToPage(link, greenLinkParams)"  [style.width.%]="(green() / total) * 100"
       [matTooltip]="green()+' green devices'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"
       class="divLink dashboard-location-bar green">
    <p class="dashboard-location-container-percentage">{{Math.round((green() / total) * 100)}}%</p>
  </div>
</div>
