<div *ngIf="isLoading">Loading...</div>

<span class="root-red" style="color: white; margin-right: 10px; padding: 10px">Red = Online, not up to date</span>
<span class="root-green" style="color: white; margin-right: 10px; padding: 10px">Green = Online, up to date</span>
<span class="root-grey" style="color: white; margin-right: 10px; padding: 10px">Grey = Offline</span>
<span class="root-dead" style="color: white; margin-right: 10px; padding: 10px">Light grey = Offline for 14+ days</span>
<span class="root-blue" style="color: white; margin-right: 10px; padding: 10px">Blue = Custom agent version set</span>

<div *ngIf="!isLoading" class="row" style="margin-top: 30px">

  <div *ngFor="let device of data" class="col-2">
    <div style="padding: 5px; margin: 5px; color: white; font-size: 11px;" class="row"
    [ngClass]="device.is_online && device.EidoAgent_ToDate && device.EidoAgentAutoUpdater_ToDate ? 'root-green' : 'root-red'"
         [class.root-grey]="!device.is_online"
         [class.root-dead]="device.is_dead"
         [class.root-blue]="device.EidoAgent_OverRide || device.EidoAgentAutoUpdater_OverRide">
      <div class="col-8">
        <p>{{device.computer_name}}</p>
      </div>
    </div>
  </div>
</div>
