import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-highchart-pie',
  templateUrl: './highchart-pie.component.html',
  styleUrls: ['./highchart-pie.component.css']
})
export class HighchartPieComponent implements OnInit {

  @Input() inputData: [any];
  @Input() useStandardColours: Boolean = false;

  @Output() onClick = new EventEmitter<any>();


  public showGraph = false;

  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartOptions;
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) { null } // optional function, defaults to null
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false

  ngOnInit(): void {

    // Colours:
    this.inputData.forEach(row => {
      if(!row.hasOwnProperty('Colour')){
          row.Colour = this.assignColour(row.name);
      }
    })

    let arrayOfColours = this.inputData.map(a => a.Colour);

    if(this.useStandardColours){
      arrayOfColours = [
        "#db946d",
        "#70ab9c",
        "#e59b98",
        "#a7a02d",];
    }

    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'rgba(0,0,0,0)',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {fontFamily: '"Poppins","Montserrat","Noto Sans","Helvetica Neue",Helvetica,Arial,sans-serif'}

      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)',
        style: {
          fontSize: '11px',
        }
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          borderWidth: 5,
          borderColor: 'white',
          allowPointSelect: true,
          cursor: 'pointer',
          size: '70%',
          point: {
            events: {
              click: (event) => this.onSliceClick(event)
            }
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f} %',
            style: {
              fontFamily: '"Poppins","Montserrat","Noto Sans","Helvetica Neue",Helvetica,Arial,sans-serif',
              fontSize: '12px',
              border: 'none',
              textOutline: 'none',
              color: '#858c90',
              fontWeight: 'none'
            }
          },

        }
      },
      series: [{
        name: 'Options',
        type: 'pie',
        colorByPoint: true,
        data: this.inputData,
        colors: arrayOfColours
      }]
    };

    this.showGraph = true;
  }

  public onSliceClick(event) {
    this.onClick.emit(event.point.name);
  }

  public assignColour(value) {

    if (typeof value !== 'string') {
      return "blue"; // Return 'blue' for non-string inputs
    }

    const colorMap = {
      'a': "f94144",
      'b': "f3722c",
      'c': "f8961e",
      'd': "4d908e",
      'e': "f9c74f",
      'f': "90be6d",
      'g': "43aa8b",
      'h': "4d908e",
      'i': "577590",
      'j': "277da1",
      'k': "a9def9",
      'l': "f94144",
      'm': "f3722c",
      'n': "f8961e",
      'o': "f9844a",
      'p': "f9c74f",
      'q': "90be6d",
      'r': "43aa8b",
      's': "4d908e",
      't': "577590",
      'u': "277da1",
      'v': "a9def9",
      'w': "f94144",
      'x': "f3722c",
      'y': "f8961e",
      'z': "f9844a",
      // You can add more mappings as needed
    };

    const firstLetter = value.charAt(0).toLowerCase();
    const assignedColor = colorMap[firstLetter] || "defaultColor"; // Default color if not matched

    return "#" + assignedColor;
  }



}
