import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiCustomerService} from '../../../services/api/admin/customers/customer-api.service';
import {Dialog} from '../../../shared/dialog/dialog';

@Component({
  selector: 'app-msp-dashboard-all-customers-tab-help',
  templateUrl: './windows-update-status-help.html',
  styleUrls: ['./windows-update-status-help.css']
})
export class WindowsUpdateStatusHelp implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  constructor() {}


  ngOnInit(): void {

  }

  close(){
    this.remove.emit(null);
  }


}
