<div *ngIf="showGraph">
  <highcharts-chart [Highcharts]="Highcharts"
                    [constructorType]="chartConstructor"
                    [options]="options"
                    [callbackFunction]="chartCallback"
                    [(update)]="updateFlag"
                    [oneToOne]="oneToOneFlag"
                    [runOutsideAngular]="runOutsideAngular"
                    style="height: {{this.height}}; display: block;">
  </highcharts-chart>
</div>

<!--<div id="chartContainer" style="max-width: 700px;"></div>-->
