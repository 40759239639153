import { Injectable } from '@angular/core';
import {ApiCustomerService} from '../api/admin/customers/customer-api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerSelectorService {

  public selectedCustomerId = null;
  public selectedCustomerName = '';

  constructor() {
  }

  setCustomer(customerId: any, customerName: string){
    console.log("CustomerSelectorService: Calling setCustomerId")
    this.selectedCustomerId = customerId;
    this.selectedCustomerName = customerName;
  }
}
