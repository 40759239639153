// status-icon-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-policy-sync-status-renderer',
  standalone: true,
  template: `
    <span *ngIf="status == 'Unmanaged'"  [matTooltip]="'Policy was not deployed using Eido'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>
    <span *ngIf="status == 'In Sync'" [matTooltip]="'Policy is in sync'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>
    <span *ngIf="status == 'Out of sync'" [matTooltip]="'Policy is out of sync with the template'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i></span>
    <!--    <span *ngIf="status == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i></span>-->
  `,
  imports: [
    CommonModule,
    MatTooltip
  ],
  styles: [`
    .issueCountBadge { /* Add your styles here */
    }
  `]
})
export class PolicySyncStatusRendererComponent implements ICellRendererAngularComp {
  public status: any;

  agInit(params: any): void {
    this.status = params.value;
  }

  refresh(params: any): boolean {
    this.status = params.value;
    return true;
  }
}
