import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {DeviceApiService} from '../../../services/api/device/device-api.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {OfficeApiService} from '../../../services/api/office/office-api.service';
import {ActivatedRoute} from '@angular/router';
import {ApplicationApiService} from '../../../services/api/application/application-api.service';
import {NavigationApiService} from '../../../services/api/navigation-api/navigation-api.service';
import moment from 'moment/moment';
import {SelectionModel} from '@angular/cdk/collections';
import {OptionsService} from '../../../services/options-service/options.service';
import {PortalSettingsService} from '../../../services/portal-settings/portal-settings.service';

@Component({
  selector: 'app-device-list',
  templateUrl: './intune-device-list.component.html',
  styleUrls: ['./intune-device-list.component.css']
})
export class IntuneDeviceListComponent implements OnInit {

  public isLoading = true;

  public data = [];
  public sortedData = [];

  @Input() public orderBy = 'status';
  @Input() public orderDesc = true;

  public filter_selectedStatus = new SelectionModel(
    true,   // multiple selection or not
    [1,2,3,4] // initial selected values
  );

  public filter_customerIds = [];

  // Tenant Settings
  public isLoadingTenantSettings = true;
  public tenantSettings;

  constructor(private deviceApiService: DeviceApiService, private dialogService: DialogService, private viewReference: ViewContainerRef,
              private route: ActivatedRoute, private navApi: NavigationApiService, public options: OptionsService, private portalSettings: PortalSettingsService) {

    this.getData();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

      switch(params.status){
        case  "all":
          this.filter_selectedStatus = new SelectionModel(true, [1,2,3,4]);
          break;
        case "green":
          this.filter_selectedStatus = new SelectionModel(true, [1,2]);
          break;
        case "amber":
          this.filter_selectedStatus = new SelectionModel(true, [3]);
          break;
        case "red":
          this.filter_selectedStatus = new SelectionModel(true, [4]);
          break;
        case "unhealthy":
          this.filter_selectedStatus = new SelectionModel(true, [3,4]);
          break;

      }
    });
  }


  getData() {
    this.deviceApiService.allDevices().then(response => {
      this.data = response;
      this.sortData();
      this.isLoading = false;
    });

    this.navApi.getTenantSettings().then(response => {
      this.tenantSettings = response;
      this.isLoadingTenantSettings = false;
    })
  }

  changeOrder(sortColumn) {
    console.log('Calling ChangeOrder, ordering by: ' + sortColumn);
    if (this.orderBy == sortColumn) {
      this.orderDesc = !this.orderDesc;
      this.data = this.data.reverse();

      //this.sortData();
    } else {
      this.orderBy = sortColumn;
      this.sortData();
    }
  }

  decodeJson(string){
    return JSON.parse(string);
  }

  sortData() {

    console.log("OrderBy: "+this.orderBy);

    // this.data.sort((a, b) => {
    //   return (a[this.orderBy] > b[this.orderBy]) ? 1 : -1;}
    // );

    this.data.sort((a, b) => {
      a = a[this.orderBy];
      b = b[this.orderBy];

      if (typeof a === 'string') {
        a = a.toLowerCase();
      }

      if (typeof b === 'string') {
        b = b.toLowerCase();
      }

      // equal items sort equally
      if (a === b) {
        return 0;
      }

      // nulls sort after anything else
      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      if (!this.orderDesc) {
        return a < b ? -1 : 1;
      }

      // if descending, highest sorts first
      return a < b ? 1 : -1;
    });



    // this.data.sort(this.compareFn(a,b));



  }


  intuneDaysBetweenDates(date){
    var start = moment(date);
    var end = moment(Date.now());
    return end.diff(start, "days")
  }

  getFilteredDevices() {
    let filteredDevices = this.data;

    // STATUS FILTERING....
    filteredDevices = filteredDevices.filter(device =>
      this.filter_selectedStatus.selected.includes(device.intune_status)
    );

    // CUSTOMER FILTERING....
    if (this.filter_customerIds.length > 0) {
      filteredDevices = filteredDevices.filter(device =>
        this.filter_customerIds.includes(device.customer_id)
      );
    }

    return filteredDevices;
  }


  statusColour(statusNumber) {

    switch (statusNumber) {
      case 1:
        return 'grey';
      case 2:
        return 'green';
      case 3:
        return 'amber';
      case 4:
        return 'red';
      default:
        return 'grey';
    }
  }

  getDeviceRow(deviceId) {
    return this.data.find(device => device.id == deviceId);
  }
}
