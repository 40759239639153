<ag-grid-angular [style.height]="viewPortHeight ? viewPortHeight : (this.selectedRows.length > 0  && multiSelect) ? 'calc(100vh - 75px)' : 'calc(100vh - 25px)'"
                 class="ag-theme-quartz"
                 [autoSizeStrategy]="{type: 'fitCellContents'}"
                 [rowData]="data"
                 [columnDefs]="columns"
                 [rowSelection]="'multiple'"
                 [suppressMultiSort]="true"
                 [pagination]="true"
                 [suppressCsvExport]="false"
                 (gridReady)="onGridReady($event)"
                 (rowClicked)="rowClicked($event)"
                 [components]="frameworkComponents"
                 paginationPageSize="100"
                 [paginationPageSizeSelector]="false"
                 (selectionChanged)="onSelectionChanged()"
                 [suppressCellFocus]="true"
                  [suppressRowClickSelection]="true"
                 animateRows="false">
</ag-grid-angular>

<div *ngIf="this.selectedRows.length > 0 && multiSelect" class="action-bar">

  <span style="margin-right:20px; margin-top: 3px">{{this.selectedRows.length}} selected: </span>

  <span *ngFor="let bulkAction of multiSelectActions"
    class="eido-action-button"
    (click)="multiSelectActionClicked(bulkAction)"
  >{{bulkAction}}</span>

<!--  <button (click)="multiSelectActionClicked('action1')">Action 1</button>-->
<!--  <button (click)="multiSelectActionClicked('action2')">Action 2</button>-->
<!--  &lt;!&ndash; Add more buttons as needed &ndash;&gt;-->
</div>
