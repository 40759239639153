// Import Modules / Dependencies
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {HighchartsChartModule} from 'highcharts-angular';
import {FormsModule} from "@angular/forms";
import {AppRoutingModule} from './app-routing.module';
import {MomentModule} from 'ngx-moment';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {QuillModule} from 'ngx-quill';
import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ToastrModule} from 'ngx-toastr';
import {GridsterComponent, GridsterItemComponent} from 'angular-gridster2';


// Import Components
import {AppComponent} from './app.component';
import {BladeRightComponent} from './shared/blade-right/blade-right.component';
import {LoginComponent} from './login/login.component';
import {ModalComponent} from './shared/modal/modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoadingSpinnerComponent} from './shared/loading-spinner/loading-spinner.component';
import {HighchartPieComponent} from './shared/highchart-pie/highchart-pie.component';
import {HighchartColumnComponent} from './shared/highchart-column/highchart-column.component';
import {LoadingDotsComponent} from './shared/loading-dots/loading-dots.component';
import {ModalConfirmationComponent} from './shared/modal-confirmation/modal-confirmation.component';
import {DialogComponentComponent} from './templates/dialog-component/dialog-component.component';
import {ParentOpeningDialogComponent} from './templates/parent-opening-dialog/parent-opening-dialog.component';

import {MatSelectModule} from "@angular/material/select";

import {FriendlyTimeComponent} from './shared/friendly-time/friendly-time.component';

import {MatInputModule} from "@angular/material/input";

import {TableModule} from "primeng/table";
import {ButtonModule} from 'primeng/button';

import {HighchartTimeSeriesComponent} from './shared/highchart-time-series/highchart-time-series.component';
import { PortalComponent } from './portal/portal.component';
import {RaiseABugDialogComponent} from './shared/raise-a-bug-dialog/raise-a-bug-dialog.component';
import { UserComponent} from './portal/admin/team/team.component';
import { BillingComponent } from './portal/billing/billing.component';
import { DeviceDetailsComponent } from './portal/device-details/device-details.component';
import {UserComponentDialog} from './portal/admin/team/team-create-edit-dialog/team-create-edit-dialog.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { GetStartedComponent } from './portal/get-started/get-started.component';

import { RootComponent } from './portal/root/root.component';
import { IntuneComplianceComponent } from './portal/intune-health/intune-compliance/intune-compliance.component';
import { IntuneConfigurationComponent } from './portal/admin/intune-configuration/intune-configuration.component';
import { IssuesComponent } from './portal/issues/issues.component';
import { RootDevicesComponent } from './portal/root/root-devices/root-devices.component';
import { RootAgentDeployComponent } from './portal/root/root-agent-deploy/root-agent-deploy.component';
import { AlertsComponent } from './portal/admin/alerts/alerts.component';
import { AlertsComponentDialogComponent } from './portal/admin/alerts/alerts-component-dialog/alerts-component-dialog.component';
import { IntuneTenantFriendlyNameComponent } from './portal/admin/intune-configuration/intune-tenant-friendly-name/intune-tenant-friendly-name.component';
import { EditThresholdDialogComponent } from './portal/admin/intune-configuration/edit-threshold-dialog/edit-threshold-dialog.component';
import { IntuneDeviceListComponent } from './portal/intune-health/device-list/intune-device-list.component';
import { HighchartPieSemiComponent } from './shared/highchart-pie-semi/highchart-pie-semi.component';
import { IntuneDrillDownModalComponent } from './portal/device-details/intune-drill-down-modal/intune-drill-down-modal.component';
import { DashboardComponent } from './portal/dashboard/dashboard.component';
import { VerticalPercentIndicatorComponent } from './shared/vertical-percent-indicator/vertical-percent-indicator.component';
import { ReportsComponent } from './portal/reports/reports.component';
import { BatteryPercentageBarComponent } from './portal/reports/battery-percentage-bar/battery-percentage-bar.component';
import {CustomersComponent} from './portal/admin/customers/customers.component';
import {CustomersComponentDialog} from './portal/admin/customers/team-create-edit-dialog/customers-create-edit-dialog.component';
import { PortalContainerComponent } from './portal-container/portal-container.component';
import { SwitchingCustomersPageComponent } from './portal/switching-customers-page/switching-customers-page.component';
import {EidoMultiSelect} from './shared/eido-multi-select/eido-multi-select.component';
import {DashboardAllCustomersComponent} from './portal/dashboard-all-customers/dashboard-all-customers.component';
import { MspDashboardAllCustomersTabHelpComponent } from './portal/dashboard-all-customers/msp-dashboard-all-customers-tab-help/msp-dashboard-all-customers-tab-help.component';
import {DashboardGridComponent} from './portal/dashboard-grid/dashboard-grid.component';
import {WidgetSelectorComponent} from './portal/dashboard-grid/widget-selector/widget-selector.component';
import {FilterOptionsViewComponent} from './shared/filter-options-view/filter-options-view.component';
import {EidoSuperTableComponent} from './shared/eido-super-table/eido-super-table.component';
import {DevicesComponent} from './portal/devices/devices.component';
import {ReportingComponent} from './portal/reporting/reporting.component';
import {TitlecaseStringPipe} from './shared/pipes/titlecase-string.pipe';
import {
  IntuneChoosePermissionsComponent
} from './portal/admin/intune-configuration/intune-choose-permissions/intune-choose-permissions.component';
import {DiscoveredAppsComponent} from './portal/discovered-apps/discovered-apps.component';
import {PoliciesComponent} from './portal/policies/policies.component';
import {PolicyDialogComponent} from './portal/policies/policy-dialog/policy-dialog.component';
import {JsonTreeComponent} from './shared/json-tree/json-tree.component';
import {CamelCaseToSpacePipe} from './shared/pipes/camel-case-to-space-pipe.pipe';
import {BackupComparisonDialogComponent} from './portal/policies/policy-dialog/backup-comparison/backup-comparison-dialog.component';
import {MatCard, MatCardSubtitle, MatCardTitle} from '@angular/material/card';
import {JsonCompareComponent} from './shared/json-compare/json-compare.component';
import {JsonTreeCompareComponent} from './shared/json-tree-compare/json-tree-compare.component';
import {PolicyViewer} from './portal/policies/policy-dialog/policy-viewer/policy-viewer';
import {JsonFormatPipe} from './shared/pipes/json-format.pipe';
import {PolicyRestoreDialog} from './portal/policies/policy-dialog/restore-dialog/policy-restore-dialog';
import {NewTemplateDialog} from './portal/policies/policy-dialog/new-template-dialog/new-template-dialog';
import {TemplatesComponent} from './portal/templates/templates.component';
import {EidoFilterPipe} from './shared/pipes/eido-filter.pipe';
import {DeviceDetailsBladeComponent} from './portal/device-details-blade/device-details-blade.component';
import {AppDrillDownDialog} from './portal/discovered-apps/app-dialog/app-drill-down-dialog';
import {PercentBarComponent} from './shared/percent-bar/percent-bar.component';
import {RootUpdatesComponent} from './portal/root/root-updates/root-updates.component';
import {WindowsUpdateStatusHelp} from './portal/dashboard-grid/windows-update-status-help/windows-update-status-help';
import {QueryHubComponent} from './portal/query-hub/query-hub.component';
import {EidoSuperTableServerSideComponent} from './shared/eido-super-table-server-side/eido-super-table-server-side.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {EidoAutocompleteComponent} from './shared/eido-autocomplete/eido-autocomplete.component';
import {MatIcon} from '@angular/material/icon';
import {AgGridAngular} from 'ag-grid-angular';
import {EidoDropdownTreeComponent} from './shared/eido-dropdown-tree/eido-dropdown-tree.component';
import {EidoGridComponent} from './shared/eido-grid/eido-grid.component';
import {EidoSideFilterComponent} from './shared/eido-side-filter/eido-side-filter.component';
import {TemplateEditDialogComponent} from './portal/templates/template-edit/template-edit-dialog.component';
import {TemplateDeployDialogComponent} from './portal/templates/template-deploy-dialog/template-deploy-dialog.component';
import {MonacoEditorModule } from 'ngx-monaco-editor-v2';
import {JsonEditorComponent} from './shared/json-editor/json-editor.component';
import {TemplateViewDialogComponent} from './portal/templates/template-view/template-view-dialog.component';
import {EidoJsonCompareComponent} from './shared/eido-json-compare/eido-json-compare.component';
import {LinkTemplateDialog} from './portal/policies/policy-dialog/link-template-dialog/link-template-dialog';
import {PolicyManagementComponent} from './portal/policy-management/policy-management.component';
import {TemplateDeployBulkDialogComponent} from './portal/templates/template-deploy-bulk-dialog/template-deploy-bulk-dialog.component';
import {InsightsComponent} from './portal/insights/insights.component';
import {EidoColumnChartComponent} from './shared/eido-column-chart/eido-column-chart.component';
import {ClickOutsideDirective} from './shared/click-outside.directive';
import {WidgetVisualComponent} from './portal/dashboard-grid/widget-visual/widget-visual.component';

var toolbarOptions = [
  ['bold', 'italic', 'underline'],        // toggled buttons
  ['clean'],                                      // remove formatting button
  ['link', 'image'],
  // ['blockquote', 'code-block'],

  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction
  //
  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  //
  [{'color': []}],          // dropdown with defaults from theme
  // [{ 'font': [] }],
  // [{ 'align': [] }],

];


@NgModule({
  declarations: [
    AppComponent,
    BladeRightComponent,
    LoginComponent,
    ModalComponent,
    LoadingSpinnerComponent,
    HighchartPieComponent,
    HighchartColumnComponent,
    LoadingDotsComponent,
    ModalConfirmationComponent,
    DialogComponentComponent,
    ParentOpeningDialogComponent,
    FriendlyTimeComponent,
    HighchartTimeSeriesComponent,
    PortalComponent,
    RaiseABugDialogComponent,
    BillingComponent,
    DeviceDetailsComponent,
    UserComponent,
    UserComponentDialog,
    SetPasswordComponent,
    SignUpComponent,
    GetStartedComponent,
    RootComponent,
    IntuneComplianceComponent,
    IntuneConfigurationComponent,
    IssuesComponent,
    RootDevicesComponent,
    RootAgentDeployComponent,
    AlertsComponent,
    AlertsComponentDialogComponent,
    IntuneTenantFriendlyNameComponent,
    EditThresholdDialogComponent,
    IntuneDeviceListComponent,
    HighchartPieSemiComponent,
    IntuneDrillDownModalComponent,
    DashboardComponent,
    VerticalPercentIndicatorComponent,
    ReportsComponent,
    BatteryPercentageBarComponent,
    CustomersComponent,
    CustomersComponentDialog,
    PortalContainerComponent,
    SwitchingCustomersPageComponent,
    EidoMultiSelect,
    DashboardAllCustomersComponent,
    MspDashboardAllCustomersTabHelpComponent,
    DashboardGridComponent,
    WidgetSelectorComponent,
    FilterOptionsViewComponent,
    EidoSuperTableComponent,
    DevicesComponent,
    ReportingComponent,
    TitlecaseStringPipe,
    IntuneChoosePermissionsComponent,
    DiscoveredAppsComponent,
    PoliciesComponent,
    PolicyDialogComponent,
    JsonTreeComponent,
    CamelCaseToSpacePipe,
    BackupComparisonDialogComponent,
    JsonCompareComponent,
    JsonTreeCompareComponent,
    PolicyViewer,
    JsonFormatPipe,
    PolicyRestoreDialog,
    NewTemplateDialog,
    TemplatesComponent,
    EidoFilterPipe,
    DeviceDetailsBladeComponent,
    AppDrillDownDialog,
    PercentBarComponent,
    RootUpdatesComponent,
    WindowsUpdateStatusHelp,
    QueryHubComponent,
    EidoSuperTableServerSideComponent,
    EidoAutocompleteComponent,
    EidoDropdownTreeComponent,
    EidoGridComponent,
    EidoSideFilterComponent,
    TemplateEditDialogComponent,
    TemplateDeployDialogComponent,
    JsonEditorComponent,
    TemplateViewDialogComponent,
    EidoJsonCompareComponent,
    LinkTemplateDialog,
    PolicyManagementComponent,
    TemplateDeployBulkDialogComponent,
    InsightsComponent,
    EidoColumnChartComponent,
    ClickOutsideDirective,
    WidgetVisualComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HighchartsChartModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MomentModule,
    MatSelectModule,
    AngularMultiSelectModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: toolbarOptions
      }
    }),
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTooltipModule,
    ToastrModule.forRoot(),
    TableModule,
    ButtonModule,
    GridsterComponent, GridsterItemComponent, MatCard, MatCardTitle, MatCardSubtitle, AutoCompleteModule, MatIcon, AgGridAngular, MonacoEditorModule.forRoot(),
    // ToastrModule added
    // MatTableModule,
    // MatSortModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en-GB'},
  ],
  exports: [
    HighchartPieComponent,
    HighchartPieComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {


}
