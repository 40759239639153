import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../services/api/policy/policy-api.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {TemplatesApiService} from '../../../services/api/templates/templates-api.service';
import {IntuneTenantsApiService} from '../../../services/api/admin/intune-tenants/intune-tenants-api.service';
import {ModalConfirmationComponent} from '../../../shared/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-deploy-dialog.component.html',
  styleUrls: ['./template-deploy-dialog.component.css']
})
export class TemplateDeployDialogComponent implements OnInit {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoadingTenants = true;
  tenants;

  selectedTenantId;
  selectedTemplateVersionId;
  deployToPolicyUuid; // Not required when deploying new template - only when updating existing policy with a new version
  deployToPolicyName; // Not required when deploying new template - only when updating existing policy with a new version - If null it should say "Deploying new policy"

  isLoading = true;
  loadedId = null;

  isSuccessful = false;

  templateDeployInProgress = false;

  templateDeployFailed = false;
  templateDeployFailedErrorPayload;

  originalPolicyData;
  policyData;

  loadedData;

  editorOptions = {
    readOnly: true,
    theme: 'vs-dark',
    language: 'json',
    minimap: { enabled: false }, // Hide minimap
    overviewRulerLanes: 0 // Hide overview ruler
  };

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private templatesApiService: TemplatesApiService, private viewReference: ViewContainerRef, private dialogService: DialogService, private intuneTenantApi: IntuneTenantsApiService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data.template_id;
      this.deployToPolicyUuid = this.data.policy_uuid;
      this.selectedTenantId = this.data.eido_intune_config_id;
      this.getData();
    }
  }

  getData(){
    this.isLoading = true;
    this.isLoadingTenants = true;

    this.templatesApiService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })

    this.intuneTenantApi.listAll().then(response => {
      this.tenants = response;
      this.isLoadingTenants = false;
    })
  }

  policyVersionChanged(){

    let template = this.loadedData.versions.find(version => this.selectedTemplateVersionId == version.policy_template_version_id);

    this.originalPolicyData = JSON.stringify(JSON.parse(template.policy_json), null, 2);
    this.policyData = JSON.stringify(JSON.parse(template.policy_json), null, 2);
  }

  toggleEdit() {
    this.isLoading = true;
    this.editorOptions.readOnly = !this.editorOptions.readOnly;
    this.changeDetectorRef.detectChanges();
    this.isLoading = false;
  }

  isPolicyJsonValid(): boolean {
    try {
      JSON.parse(this.policyData);
      return true;
    } catch (e) {
      return false;
    }
  }

  isJsonOverridden(){
    return this.originalPolicyData !== this.policyData;
  }

  deploy(){

    if(this.templateDeployInProgress){
      return;
    }

    if(!this.isPolicyJsonValid()){
      return;
    }

    if(this.selectedTenantId === null || this.selectedTenantId === undefined){
      return;
    }

    this.templateDeployInProgress = true;


      this.templatesApiService.deploy(this.selectedTemplateVersionId, this.selectedTenantId, this.isJsonOverridden(), this.policyData, this.deployToPolicyUuid).then(response => {
      this.isSuccessful = true;
      this.templateDeployInProgress = false;
    }).catch(errorPayload => {

      console.log(errorPayload);

      this.templateDeployFailedErrorPayload = errorPayload.error;
      this.templateDeployFailed = true;
    })

  }

  close(){
    this.remove.emit(null);
  }




  protected readonly JSON = JSON;
}
