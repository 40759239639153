import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {SharedFunctionsService} from '../../services/shared-functions/shared-functions.service';
import {ActivatedRoute} from '@angular/router';
import {filter, Subscription} from 'rxjs';

@Component({
  selector: 'app-filter-options-view',
  templateUrl: './filter-options-view.component.html',
  styleUrl: './filter-options-view.component.css'
})
export class FilterOptionsViewComponent implements OnInit, OnChanges {

  @Input() key = 'id';
  @Input() value = 'name';
  @Input() columnName = 'columnName';
  @Input() isHidden = false;
  @Input() isExpanded = true;
  @Input() showAllCheckbox = true;
  @Input() showSearchOption = true;
  @Input() showCount = true;
  @Input() template = 'default';
  @Input() friendlyName = 'UNKNOWN';
  @Input() options = [];
  @Output() filteredValues = new EventEmitter<any>();

  public filteredOptions = [];

  public searchTerm = '';

  public showSearch = false;

  private selectedChangedSubscription: Subscription;

  public filteredButNotFound = [];

  private filterApplied = false;

  public selected = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  constructor(public sharedFunctions: SharedFunctionsService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.filteredOptions = this.options.slice(0, 25); // No search term, display first 25 options

    console.time('FilterOptionsViewComponent name: '+this.columnName);

    this.checkAll(false);

    // when selected is changed call onfilterChange
    this.extractQueryParams();

    console.timeEnd('FilterOptionsViewComponent name: '+this.columnName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.updateFilteredOptions();
    }
  }

  updateFilteredOptions() {
    this.filteredOptions = this.options.slice(0, 25);
    // Any additional logic to apply filters or process options
  }

  filterOptions() {
    let term = this.searchTerm;
    if (!term) {
      this.filteredOptions = this.options.slice(0, 25); // No search term, display first 25 options
    } else {
      this.filteredOptions = this.options.filter(option =>
        this.convertToLowerCaseIfString(option[this.value]).includes(term.toLowerCase())
      );
    }
  }

  public extractQueryParams(){
    if (this.selectedChangedSubscription) {
      this.selectedChangedSubscription.unsubscribe();
    }

    let paramName = 'filter_'+this.columnName;

    if(this.route.snapshot.queryParams[paramName]){
      console.log('found query param for '+paramName);


      this.selected.clear();
      this.route.snapshot.queryParams[paramName].split(',').forEach(x => {

        // If the name is "Unassigned" then set it to null
        if(x == 'Unassigned') {
          x = null;
        }

        console.log('found '+x);

        this.selected.select(this.convertToLowerCaseIfString(x));
      })

      // loop through selected and if not found in options add to filteredButNotFound
      this.selected.selected.forEach(selected => {
        if(!this.options.find(option => this.convertToLowerCaseIfString(option[this.key]) == selected)){
          this.filteredButNotFound.push(selected);
        }
      })

      this.onFilterChange();
    }

    this.selectedChangedSubscription = this.selected.changed.subscribe(s => this.onFilterChange());

  }

  convertToLowerCaseIfString(value){
    if(typeof value == 'string'){
      return value.toLowerCase();
    } else {
      return value;
    }
  }

  clearFilterButton(event: MouseEvent): void {
    event.stopPropagation();
    //this.selected.clear();
    this.checkAll();
  }

  toggleSearch(event: MouseEvent): void {
    event.stopPropagation();
    this.showSearch = !this.showSearch;
  }
  checkboxIsAllSelected() {


    return this.selected.selected.length == (this.options.length + this.filteredButNotFound.length);
  }

  checkboxToggleAll() {
    if (this.checkboxIsAllSelected()) {
      this.selected.clear();
    } else {
      this.checkAll();
    }
  }

  checkAll(triggerFilterChange = true){
    if (this.selectedChangedSubscription) {
      this.selectedChangedSubscription.unsubscribe();
    }

    this.selected.clear();

    this.filterApplied = false;
    this.options.forEach(option => {
      this.selected.select(this.convertToLowerCaseIfString(option[this.key]));
    })

    this.filteredButNotFound.forEach(option => {
      this.selected.select(this.convertToLowerCaseIfString(option));
    })


    this.selectedChangedSubscription = this.selected.changed.subscribe(s => this.onFilterChange());

    if(triggerFilterChange){
      this.onFilterChange();
    }
  }

  only(key: any) {
    if (this.selectedChangedSubscription) {
      this.selectedChangedSubscription.unsubscribe();
    }

    this.selected.clear();
    this.selected.select(this.convertToLowerCaseIfString(key));

    this.selectedChangedSubscription = this.selected.changed.subscribe(s => this.onFilterChange());
    this.onFilterChange();
  }


  onFilterChange(){
    console.time('onFilterChange name: '+this.columnName);

    console.log('onFilterChange: selected length: '+this.selected.selected.length + ' options length: '+this.options.length);
    console.log('onFilterChange: selected: '+JSON.stringify(this.selected.selected));
    console.log('onFilterChange: options: '+JSON.stringify(this.options));


    if(this.checkboxIsAllSelected()){
      this.filterApplied = false;
      this.filteredValues.emit(null);
    } else {
      this.filterApplied = true;
      this.filteredValues.emit(this.selected.selected);
    }

    console.timeEnd('onFilterChange name: '+this.columnName);


  }

  protected readonly filter = filter;
}
