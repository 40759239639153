import { Component, OnInit } from '@angular/core';
import {timer} from 'rxjs';
import {DashboardApiService} from '../../services/api/dashboard/dashboard-api-service.service';
import {DeviceApiService} from '../../services/api/device/device-api.service';
import {OfficeApiService} from '../../services/api/office/office-api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public isLoading = true;
  public data;

  public topIssuesMax = 10;


  ngOnInit(): void {
    this.refreshData();
  }

  constructor(private dashboardApiService: DashboardApiService) {

    // Subscribe to the timer
     //this.timerSubscription = this.refreshTimer.subscribe(val => this.refreshData());
  }

  public refreshData(){
    this.dashboardApiService.getDashboardStats().then(response => {
      this.data = response;
      // work out top issues max value
      this.data.top_issues.forEach(issue => {
        if (issue.device_count > this.topIssuesMax) {
          this.topIssuesMax = issue.device_count;
        }
      });

      this.isLoading = false;
    });
  }

  statusColour(statusNumber) {
    switch (statusNumber) {
      case 1:
        return 'grey';
      case 2:
        return 'green';
      case 3:
        return 'amber';
      case 4:
        return 'red';
      default:
        return 'grey';
    }
  }

  fullScreen(){
    // /* Get the documentElement (<html>) to display the page in fullscreen */
    // var elem = document.documentElement;
    //
    // elem.requestFullscreen();


    if (!document.fullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }

  }

}
