import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceApiService {

  private deviceApiUri = '/api/device';

  constructor(private api: ApiService) { }

  public allDevices(uemonly = 0){
    return this.api.get(this.deviceApiUri, {"uemonly" : uemonly});
  }

  public featuresEnabled(){
    return this.api.get(`${this.deviceApiUri}/features`);
  }

  public deviceStatus(deviceId){
    return this.api.get(`${this.deviceApiUri}/${deviceId}`);
  }

  public delete(deviceId){
    return this.api.delete(`${this.deviceApiUri}/${deviceId}`);
  }

  public computerMetrics(deviceId, timeWindow){
    return this.api.get(`${this.deviceApiUri}/${deviceId}/metrics/computer`, {'timeWindow':timeWindow});
  }

  public networkMetrics(deviceId, timeWindow){
    return this.api.get(`${this.deviceApiUri}/${deviceId}/metrics/network`, {'timeWindow':timeWindow});
  }

  public appMetrics(deviceId, applicationId, timeWindow){
    return this.api.get(`${this.deviceApiUri}/${deviceId}/metrics/application/${applicationId}`, {'timeWindow':timeWindow});
  }

  public updatePolicy(deviceId, policyId){
    return this.api.put(`${this.deviceApiUri}/${deviceId}/update-policy`, {policy_id: policyId});
  }
}
