<h1>Welcome to eido! Let's get started!</h1>


<!-- Step 1 - Msp Mode -->
<div class="row m-t-20">
  <div class="col-9">
    <div class="sectionBox p-0">
      <div class="row">
        <div class="col-1 text-center" [style.background-color]="getStartedStep == 1 ? '#95b9a7' : '#95b9a799'" style="font-size: 45px;background-color: #95b9a7;border-radius: 10px;padding: 15px;color: white;">1</div>
        <div class="col p-20">
          <i *ngIf="getStartedStep > 1 || getStartedStep == null" style="font-size: 60px; color:#95b9a7 " class="fas fa-check-circle float-end"></i>
          <!--          <div *ngIf="getStartedStep == 1" (click)="skipIntuneAccess()" class="btn btn-primary float-end" style="background-color: #ffffff; border: 2px solid #95b9a7; color: #95b9a7; font-size: 13px;">Skip</div>-->
          <div *ngIf="getStartedStep == 1" (click)="setMspMode(0)" class="btn btn-primary float-end" style="margin-right: 15px">Internal IT Team</div>
          <div *ngIf="getStartedStep == 1" (click)="setMspMode(1)" class="btn btn-primary float-end" style="margin-right: 15px">MSP</div>

          <h3>How will you be using eido?</h3>
          <p>MSP Mode allows you to create multiple customers, and more.</p>

          <div *ngIf="intuneError" style="
    background: indianred;
    color: white;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
">Error: {{intuneError}} <br>
            {{intuneErrorDescription}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Step 1 - Intune -->
<div class="row m-t-20">
  <div class="col-9">
    <div class="sectionBox p-0">
      <div class="row">
        <div class="col-1 text-center" [style.background-color]="getStartedStep == 2 ? '#95b9a7' : '#95b9a799'" style="font-size: 45px;background-color: #95b9a7;border-radius: 10px;padding: 15px;color: white;">2</div>
        <div class="col p-20">
          <i *ngIf="getStartedStep > 2 || getStartedStep == null" style="font-size: 60px; color:#95b9a7 " class="fas fa-check-circle float-end"></i>
          <div *ngIf="getStartedStep == 2" (click)="skipIntuneAccess()" class="btn btn-primary float-end" style="background-color: #ffffff; border: 2px solid #95b9a7; color: #95b9a7; font-size: 13px;">Skip</div>
          <div *ngIf="getStartedStep == 2" (click)="grantIntuneAccess()" class="btn btn-primary float-end" style="margin-right: 15px">Grant Access</div>

          <h3>Link to Intune</h3>
          <p>eido requires access to your Intune Tenant, You will need to be a global admin to grant us access.</p>

          <div *ngIf="intuneError" style="
    background: indianred;
    color: white;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
">Error: {{intuneError}} <br>
          {{intuneErrorDescription}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Step 4 - Invite Team -->
<div class="row m-t-20">
  <div class="col-9">
    <div class="sectionBox p-0">
      <div class="row">
        <div class="col-1 text-center" [style.background-color]="getStartedStep == 3 ? '#95b9a7' : '#95b9a799'" style="font-size: 45px;background-color: #95b9a7;border-radius: 10px;padding: 15px;color: white;">3</div>

        <div class="col p-20">
          <i *ngIf="getStartedStep > 3 || getStartedStep == null" style="font-size: 60px; color:#95b9a7 " class="fas fa-check-circle float-end"></i>

          <h3>Invite your team</h3>
          <p>You can add more users later under the team section</p>
        </div>
      </div>
    </div>
    <div *ngIf="getStartedStep == 3" class="sectionBox">

      <div *ngFor="let user of inviteTeamData" class="row p-10">
        <div class="col-2 formHeader text-center p-5">Name
          <i *ngIf="!user.user_name && user.email" [matTooltip]="'Name is required'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="fas fa-exclamation-circle text-danger"></i>
        </div>
        <div class="col-3">
          <input [(ngModel)]="user.user_name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
        </div>
        <div class="col-2 formHeader text-center p-5">Email Address
          <i *ngIf="user.user_name && !user.email" [matTooltip]="'Email is required'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="fas fa-exclamation-circle text-danger"></i>
          <i *ngIf="user.email && !validateEmail(user.email)" [matTooltip]="'Email format is incorrect'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="fas fa-at text-danger"></i>
        </div>
        <div class="col-3">
          <input [(ngModel)]="user.email" type="text" class="form-control" id="Email" placeholder="Email Address" autocomplete="off">
        </div>
      </div>

      <div (click)="skipInviteUsers()" class="btn btn-primary float-end" style="background-color: #ffffff; border: 2px solid #95b9a7; color: #95b9a7; font-size: 13px;">Skip</div>
      <div (click)="inviteUsers()" [class.disabled]="!inviteUsersFormValid()" class="btn btn-primary float-end" style=""> Invite</div>

      <div style="height: 50px"></div>




    </div>
  </div>
</div>

<div *ngIf="getStartedStep == null" class="row m-t-20">
  <div class="col-9">
    <div class="sectionBox p-0">
      <div class="row">
        <div class="col text-center divLink" [routerLink]="'/dashboard'" style="font-size: 35px;background-color: #95b9a7;border-radius: 10px;padding: 15px;color: white;">Go to the Dashboard</div>
      </div>
    </div>
  </div>
</div>















<!--ARCHIVED-->








<!--&lt;!&ndash; Step 2 - Choose Apps &ndash;&gt;-->
<!--<div class="row m-t-20">-->
<!--  <div class="col-9">-->
<!--    <div class="sectionBox p-0">-->
<!--      <div class="row">-->
<!--        <div class="col-1 text-center" [style.background-color]="getStartedStep == 2 ? '#95b9a7' : '#95b9a799'" style="font-size: 45px;background-color: #95b9a7;border-radius: 10px;padding: 15px;color: white;">2</div>-->
<!--        <div class="col p-20">-->
<!--          <i *ngIf="getStartedStep > 2 || getStartedStep == null" style="font-size: 60px; color:#95b9a7 " class="fas fa-check-circle float-end"></i>-->
<!--          <div *ngIf="getStartedStep >= 2" (click)="openApplicationDialog()" class="btn btn-primary float-end" style="margin-right: 15px">Select Applications</div>-->
<!--          <h3>Select the cloud apps you would like to monitor</h3>-->
<!--          <p>Don't worry, you can monitor more later on in the <strong>Policies</strong> section</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--&lt;!&ndash; Step 3 - Install MSI &ndash;&gt;-->
<!--<div class="row m-t-20">-->
<!--  <div class="col-9">-->
<!--    <div class="sectionBox p-0">-->
<!--      <div class="row">-->
<!--        <div class="col-1 text-center" [style.background-color]="getStartedStep == 2 ? '#95b9a7' : '#95b9a799'" style="font-size: 45px;background-color: #95b9a7;border-radius: 10px;padding: 15px;color: white;">3</div>-->

<!--        <div class="col p-20">-->
<!--          <i *ngIf="getStartedStep > 2 || getStartedStep == null" style="font-size: 60px; color:#95b9a7 " class="fas fa-check-circle float-end"></i>-->

<!--          <div class="float-end">-->
<!--            <div *ngIf="getStartedStep >= 3" (click)="downloadAgentSoftware()" class="btn btn-primary" style="margin-right: 15px"><i class="fab fa-windows"></i> Windows - Download</div>-->
<!--            <div *ngIf="getStartedStep >= 3" (click)="null" class="btn btn-primary" style="background-color: #a9b6c3;margin-right: 15px"><i class="fab fa-apple"></i> Mac - Coming Soon!</div>-->
<!--          </div>-->

<!--          <h3>Download the agent</h3>-->
<!--          <p>Install the agent on your computer, or a test computer to start collecting data</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
