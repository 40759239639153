import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-widget-selector',
  templateUrl: 'widget-selector.component.html',
})
export class WidgetSelectorComponent implements OnInit {

  @Input() data = [];
  @Output() remove = new EventEmitter<any>();

  public selectedWidget = {};

  constructor() { }

  ngOnInit(): void {
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    this.remove.emit(this.selectedWidget);
  }

}
