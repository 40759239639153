import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {OptionsService} from '../../services/options-service/options.service';
import * as moment from 'moment/moment';
import {AlertsComponentDialogComponent} from '../admin/alerts/alerts-component-dialog/alerts-component-dialog.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {AppDrillDownDialog} from './app-dialog/app-drill-down-dialog';
import {DeviceDetailsBladeComponent} from '../device-details-blade/device-details-blade.component';

@Component({
  selector: 'app-discovered-apps',
  templateUrl: './discovered-apps.component.html',
  styleUrl: './discovered-apps.component.css'
})
export class DiscoveredAppsComponent implements OnInit{
  @Input() view = 'default'; // default | recent

  constructor(private apiService: ApiService, public optionsService: OptionsService, public dialogService: DialogService, public viewReference: ViewContainerRef) { }

  data = [];
  isLoading = true;


  public superTableSettings;

  ngOnInit(): void {

    // SELECT app_name, c.name as app_category_name, r.application_risk_category_name, rs.risk_level, count(*) as install_count

    this.superTableSettings = {
      topMargin: 75,
      filterOptions: [
        {columnName: 'metered_status', friendlyName: 'Is Metered', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'default'},
        {columnName: 'customer_name', friendlyName: 'Customer', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'default'},
      ],
      columns: [
        {columnName: 'customer_name', friendlyName: 'Customer', isSortable: true, template: 'default', widthCol: 'col-1'},
        {columnName: 'app_name', friendlyName: 'App Name', isSortable: true, template: 'default', widthCol: 'col'},
        // {columnName: 'min_version_name', friendlyName: 'Min Ver', isSortable: true, template: 'default', widthCol: 'col-1'},
        // {columnName: 'min_version_name', friendlyName: 'Min Ver', isSortable: true, template: 'default', widthCol: 'col-1'},
         {columnName: 'version_count', friendlyName: 'Versions', isSortable: true, template: 'numberBadge', widthCol: 'col-1'},
        {columnName: 'install_count', friendlyName: 'Installs', isSortable: true, template: 'numberBadge', widthCol: 'col-1'},
        {columnName: 'metered_license_usage_percent', friendlyName: 'Usage %', isSortable: true, template: 'percentBadge', widthCol: 'col-1'},

      ],
      sortByColumn: 'install_count',
      sortByAsc: true
    };

    this.refreshData();
  }

  rowClicked(rowData:any) {
    console.log('policy clicked', JSON.stringify(rowData));

    this.dialogService.createDialog(AppDrillDownDialog, rowData.application_hash, this.viewReference)
      .then(dialogSaved => {
      }, dialogCancelled => {
      })
  }

  refreshData(){
    this.isLoading = true;
    this.apiService.get("/api/apps").then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }


}
