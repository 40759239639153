import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class GetStartedApiService {

  apiUri = '/api/get-started'

  constructor(private apiService: ApiService) {
  }

  get() {
    return this.apiService.get(`${this.apiUri}`);
  }

  setMspMode(mspMode) {
    return this.apiService.post(`/api/get-started/msp-mode`, {msp_mode: mspMode});
  }

  grantIntuneAccess(accessScope:string, returnUrl = 'get-started'){
    return this.apiService.get(`/api/intune/authorise`, {"ReturnUrl" : returnUrl, "access_scope": accessScope});
  }

  skipIntune(){
    return this.apiService.post(`/api/get-started/intune/skip`);
  }

  handleIntuneResponse(tenant, state, admin_consent, error, error_description, friendly_name = null, customer_id = null){
    let body = {
      tenant: tenant,
      friendly_name: friendly_name,
      state: state,
      admin_consent: admin_consent,
      error: error,
      error_description: error_description,
      customer_id: customer_id
    }
    return this.apiService.post(`/api/intune/response`, body);
  }

  applicationsAdded(appsAdded) {
    let body = {
      applications_added: appsAdded
    }
    return this.apiService.post(`${this.apiUri}/applications`, body);
  }

  agentDownloaded(agentDownloaded) {
    let body = {
      downloaded_agent: agentDownloaded
    }
    return this.apiService.post(`${this.apiUri}/downloaded-agent`, body);
  }

  invitedTeam(noOfInvites) {
    let body = {
      users_invited: noOfInvites
    }
    return this.apiService.post(`${this.apiUri}/invite-team`, body);
  }

}
