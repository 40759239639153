import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../services/api/policy/policy-api.service';
import {BackupComparisonDialogComponent} from './backup-comparison/backup-comparison-dialog.component';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {PolicyViewer} from './policy-viewer/policy-viewer';
import {PolicyRestoreDialog} from './restore-dialog/policy-restore-dialog';
import {NewTemplateDialog} from './new-template-dialog/new-template-dialog';
import {EidoJsonCompareComponent} from '../../../shared/eido-json-compare/eido-json-compare.component';
import {TemplateDeployDialogComponent} from '../../templates/template-deploy-dialog/template-deploy-dialog.component';
import {LinkTemplateDialog} from './link-template-dialog/link-template-dialog';
import {ModalConfirmationComponent} from '../../../shared/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'policy-dialog-component-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.css']
})
export class PolicyDialogComponent implements OnInit {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  policyData;

  loadedData;

  expand = {
    details: true,
    rawPolicy: false,
    backups: true
  };

  constructor(
    private policyApiService: PolicyApiService, private viewReference: ViewContainerRef, private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    }
  }

  getData(){
    this.isLoading = true;
    this.policyApiService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;

      this.policyData = JSON.parse(this.loadedData.policy_json);
    })
  }

  close(){
    this.remove.emit(null);
  }

  backup(){
    this.isLoading = true;
    this.policyApiService.backup(this.loadedId).then(response => {
      this.getData();
    })
  }

  createTemplate() {

    // Replace null with Component
    this.dialogService.createDialog(NewTemplateDialog, this.loadedData, this.viewReference).then(response => {
      this.getData();
    }).catch(error => {
      this.getData();
    });

  }

  linkToTemplate() {
    // Replace null with Component
    this.dialogService.createDialog(LinkTemplateDialog, this.loadedData, this.viewReference).then(response => {
      this.getData();
    }).catch(error => {
      this.getData();
    });

  }

  removeTemplateLink() {
    this.dialogService.createDialog(ModalConfirmationComponent, `Are you sure you want to unlink this policy from the template?`, this.viewReference)
      .then(confirmed => {
        this.isLoading = true;
        this.policyApiService.removeTemplateLink(this.loadedId).then(response => {
          this.getData();
        })
      }, cancelled => null)
  }

  openPolicyViewer() {

    // Replace null with Component
    this.dialogService.createDialog(PolicyViewer, this.policyData, this.viewReference);

  }

  restoreBackup(backupData:any) {

    // Replace null with Component
    this.dialogService.createDialog(PolicyRestoreDialog, backupData, this.viewReference).then(response => {
      this.getData();
    }).catch(error => {
      this.getData();
    });

  }

  // openPolicyComparison(rowData:any) {
  //   console.log('policy clicked', JSON.stringify(rowData));
  //
  //   let data = {
  //     array1: JSON.parse(rowData.policy_json),
  //     array2: JSON.parse(rowData.previous_policy_json)
  //   }
  //
  //   // Replace null with Component
  //   this.dialogService.createDialog(BackupComparisonDialogComponent, data, this.viewReference);
  //
 // }
openPolicyComparison(rowData:any) {

  let data = {
    leftJson: rowData.previous_policy_json,
    rightJson: rowData.policy_json
  }

  // Replace null with Component
  this.dialogService.createDialog(EidoJsonCompareComponent, data, this.viewReference);

  }

  comparePolicyToCurrentTemplateVersion() {

    let data = {
      leftJson: this.loadedData.generalised_policy_json,
      rightJson: this.loadedData.template_policy_json
    }

    // Replace null with Component
    this.dialogService.createDialog(EidoJsonCompareComponent, data, this.viewReference);

  }

  deploy(){
    // prepare payload
    let payload = {
      template_id: this.loadedData.created_from_template_id,
      policy_uuid: this.loadedData.policy_uuid,
      eido_intune_config_id: this.loadedData.eido_intune_config_id
    }

    this.dialogService.createDialog(TemplateDeployDialogComponent, payload, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {
        this.getData();
      })
  }


  openAssignmentComparison(rowData:any) {
    console.log('policy clicked', JSON.stringify(rowData));

    let data = {
      array1: JSON.parse(rowData.assignments_json),
      array2: JSON.parse(rowData.previous_assignments_json)
    }

    // Replace null with Component
    this.dialogService.createDialog(BackupComparisonDialogComponent, data, this.viewReference);

  }



  protected readonly JSON = JSON;
}
