import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import { TableModule } from 'primeng/table';
import {ApiService} from '../../services/api/api.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  public showColumnSelection = false;

  public reportName = '';
  public reportType = '';
  public availableColumns = [];
  public selectedColumns = [];

  public data = [];

  constructor(private route: ActivatedRoute, private apiService: ApiService) { }

  toggleColumn(columnData){
    if(this.isColumnSelected(columnData)){

      this.selectedColumns.forEach((element,index)=>{
        if(element.field==columnData.field) this.selectedColumns.splice(index,1);
      });


    } else {
      this.selectedColumns.push(columnData);
    }

  }

  isColumnSelected(columnData){
    return this.selectedColumns.find(col => col.field == columnData.field);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.reportName = params.get("reportName");

      this.getReportData(this.reportName);
    })
  }

  getReportData(reportName){
    let apiUrl = `/api/reports/${reportName}`
    this.apiService.get(apiUrl).then(response => {
      this.data = response.data;
      this.availableColumns = response.availableColumns;
      this.selectedColumns = response.selectedColumns;
    })
  }

  parseFieldInData(data: any, field: string) {
    return field.includes('.')
      ? field.split('.').reduce((acc: any, obj: any) => (acc && acc[obj]) || null, data)
      : data[field];
  }

  parseFieldInData2(data: any, field: string) {
    const fieldParts = field.split('.');
    let currentObject = data;
    for (let i = 0; i < fieldParts.length; i++) {
      const currentField = fieldParts[i];
      if (currentObject !== null && currentObject[currentField] !== undefined) {
        currentObject = currentObject[currentField];
      } else {
        return null;
      }
    }
    return currentObject;
  }


  daysBetween(dateTimeStamp) {

    if(!dateTimeStamp){
      return null;
    }

    // Convert the dateTimeStamp to a Date object
    var inputDate = new Date(dateTimeStamp);

    // Get the time difference in milliseconds between the inputted date and the current date
    var timeDiff = Date.now() - inputDate.getTime();

    // Convert the time difference from milliseconds to days
    var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
  }

}
