import { Component, OnInit } from '@angular/core';
import {NavigationApiService} from '../../../services/api/navigation-api/navigation-api.service';

@Component({
  selector: 'app-intune-compliance',
  templateUrl: './intune-compliance.component.html',
  styleUrls: ['./intune-compliance.component.css']
})
export class IntuneComplianceComponent implements OnInit {

  public tenantSettings;
  public isLoadingTenantSettings = true;

  constructor(private navApi: NavigationApiService) {

    this.navApi.getTenantSettings().then(response => {
      this.tenantSettings = response;
      this.isLoadingTenantSettings = false;
    })

  }

  ngOnInit(): void {
  }

}
