import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DiffEditorModel, MonacoEditorModule} from 'ngx-monaco-editor-v2';

@Component({
  selector: 'app-eido-json-compare',
  templateUrl: './eido-json-compare.component.html',
  styleUrl: './eido-json-compare.component.css'
})
export class EidoJsonCompareComponent implements OnInit{

  @Input() data;
  @Output() remove = new EventEmitter<any>();


  // editorOptions = {
  //   readOnly: true,
  //   theme: 'vs-dark',
  //   language: 'json',
  //   minimap: { enabled: false }, // Hide minimap
  //   overviewRulerLanes: 0 // Hide overview ruler
  // };

  options = {
    theme: 'vs-dark',
    language: 'json',
  };

  originalModel: DiffEditorModel = {
    code: 'loading...',
    language: 'json'
  };

  modifiedModel: DiffEditorModel = {
    code: 'Loading...',
    language: 'json'
  };


  constructor() {}

  ngOnInit(): void {
    this.originalModel.code = JSON.stringify(JSON.parse(this.data.leftJson), null, 2);
    this.modifiedModel.code = JSON.stringify(JSON.parse(this.data.rightJson), null, 2);
  }

  close(){
    this.remove.emit(null);
  }

}
