import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesApiService {

  private apiUrl = '/api/templates';

  constructor(private api: ApiService) { }

  listAll() {
    return this.api.get(this.apiUrl);
  }

  get(id) {
    return this.api.get(`${this.apiUrl}/${id}`);
  }

  deploy(templateVersionId, intuneTenantId, isJsonOverride = false, overriddenPolicyJson = null, policy_uuid = null) {
    let body = {
      json_override: isJsonOverride,
      policy_json: overriddenPolicyJson,
      eido_intune_config_id: intuneTenantId,
      policy_uuid: policy_uuid
    };
    return this.api.post(`${this.apiUrl}/version/${templateVersionId}/deploy`, body);
  }

  update(id: any, data: any) {
    return this.api.post(`${this.apiUrl}/${id}`, data);
  }
  newVersion(id: any) {
    return this.api.post(`${this.apiUrl}/${id}/new-version`);
  }

  delete(id: any) {
    return this.api.delete(`${this.apiUrl}/${id}`);
  }

}
