import {Component, Input} from '@angular/core';
import {CamelCaseToSpacePipe} from '../pipes/camel-case-to-space-pipe.pipe';

@Component({
  selector: 'app-json-tree',
  templateUrl: './json-tree.component.html',
  styleUrl: './json-tree.component.css'
})
export class JsonTreeComponent {
  @Input() data: any;
  @Input() comparisonData: any;
  @Input() level: number = 0; // Optional: Used for indentation styling
  @Input() comparisonMode: boolean = false;


  expandedStates: { [key: string]: boolean } = {};

  isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  toggleExpand(key: string): void {
    // Toggle the expanded state for the clicked key
    this.expandedStates[key] = !this.expandedStates[key];
  }

  getColor(key: any) {

    if(!this.comparisonMode) {
      return;
    }

    let keyStatus = this.comparisonData[key]?.status

    if (keyStatus === 'added') {
      return '#21ab685e'; // Color for added properties
    } else if (keyStatus === 'removed') {
      return '#cd5c5c6e'; // Color for removed properties
    } else if (keyStatus === 'changed') {
      return '#ffa50066'; // Color for changed properties
    } else if (keyStatus === 'childChanged') {
      return 'yellow'; // Color for changed properties
    }
    return 'white'; // Default color
  }

  protected readonly Object = Object;
}
