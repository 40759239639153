// ticket-id-url-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-ticket-id-url-renderer',
  standalone: true,
  template: `
    <p class="m-0 text-shorten">
      <a *ngIf="ticketId" (click)="$event.stopPropagation()" [href]="ticketUrl">{{ticketId}} <i class="fas fa-external-link"></i></a>
    </p>
  `
})
export class TicketIdUrlRendererComponent implements ICellRendererAngularComp {
  public ticketId: string | null = null;
  public ticketUrl: string | null = null;

  agInit(params: any): void {
    this.ticketId = params.value;
    this.ticketUrl = params.data.ticket_url;
  }

  refresh(params: any): boolean {
    this.ticketId = params.value;
    this.ticketUrl = params.data.ticket_url;
    return true;
  }
}
