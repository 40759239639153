import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {SelectionModel} from '@angular/cdk/collections';
import { AutoCompleteModule } from 'primeng/autocomplete';


@Component({
  selector: 'app-query-hub',
  templateUrl: './query-hub.component.html',
  styleUrl: './query-hub.component.css'
})

export class QueryHubComponent implements OnInit{

  public tenants = [];
  public selectedTenant = null;
  public selectedTenantFriendlyName = '';
  public isLoadingTenants = true;

  public schema = null;
  public isLoadingSchema = true;

  public availableColumnCollections = [];
  public availableColumns = [];

  public isLoadingResults = true;

  public queryResults = [];
  public transformedPieColumnChartData = [];

  public selectedTab = 'Filter';

  namesArray: string[] = ['Alice', 'Bob', 'Charlie', 'Diana', 'Evan', 'Fiona', 'George', 'Hannah', 'Ian', 'Julia'];

  public uniqueFieldValuesCache = [];
  public uniqueFieldValuesFilteredCache = [];

  // public mongoDbOperators = [
  //   { operator: '$eq', friendlyName: 'Equals' },
  //   { operator: '$ne', friendlyName: 'Not Equals' },
  //   { operator: '$gt', friendlyName: 'Greater Than' },
  //   { operator: '$gte', friendlyName: 'Greater Than or Equal To' },
  //   { operator: '$lt', friendlyName: 'Less Than' },
  //   { operator: '$lte', friendlyName: 'Less Than or Equal To' },
  //   { operator: '$in', friendlyName: 'In' },
  //   { operator: '$nin', friendlyName: 'Not In' },
  //   { operator: '$regex', friendlyName: 'Matches Regular Expression' },
  //   { operator: '$isNullOrEmpty', friendlyName: 'Is Null/Empty' },
  //   { operator: '$isNotNullOrEmpty', friendlyName: 'Is Not Null/Empty' },
  //   //
  //   // { operator: '$exists', friendlyName: 'Exists' },
  // ];

  public mongoDbOperators = {
    string: [
      { operator: '$eq', friendlyName: 'Equals' },
      { operator: '$ne', friendlyName: 'Not Equals' },
      { operator: '$regex', friendlyName: 'Matches Regular Expression' },
      { operator: '$in', friendlyName: 'In' },
      { operator: '$nin', friendlyName: 'Not In' },
      { operator: '$isNullOrEmpty', friendlyName: 'Is Null/Empty' },
      { operator: '$isNotNullOrEmpty', friendlyName: 'Is Not Null/Empty' },
    ],
    number: [
      { operator: '$eq', friendlyName: 'Equals' },
      { operator: '$ne', friendlyName: 'Not Equals' },
      { operator: '$gt', friendlyName: 'Greater Than' },
      { operator: '$gte', friendlyName: 'Greater Than or Equal To' },
      { operator: '$lt', friendlyName: 'Less Than' },
      { operator: '$lte', friendlyName: 'Less Than or Equal To' },
      { operator: '$in', friendlyName: 'In' },
      { operator: '$nin', friendlyName: 'Not In' },
      { operator: '$isNullOrEmpty', friendlyName: 'Is Null/Empty' },
      { operator: '$isNotNullOrEmpty', friendlyName: 'Is Not Null/Empty' },
    ],
    date: [
      { operator: 'date_$eq', friendlyName: 'Equals' },
      { operator: 'date_$ne', friendlyName: 'Not Equals' },
      { operator: 'date_$gt', friendlyName: 'Greater Than' },
      { operator: 'date_$gte', friendlyName: 'Greater Than or Equal To' },
      { operator: 'date_$lt', friendlyName: 'Less Than' },
      { operator: 'date_$lte', friendlyName: 'Less Than or Equal To' },
      { operator: 'date_$isNullOrEmpty', friendlyName: 'Is Null/Empty' },
      { operator: 'date_$isNotNullOrEmpty', friendlyName: 'Is Not Null/Empty' },
    ],
    boolean: [
      { operator: 'bool_true', friendlyName: 'Is True' },
      { operator: 'bool_false', friendlyName: 'Is False' },
      { operator: '$isNullOrEmpty', friendlyName: 'Is Null/Empty' },
      { operator: '$isNotNullOrEmpty', friendlyName: 'Is Not Null/Empty' },
    ],
  }

  public query = {
    visualisation: 'table',
    eido_intune_tenant_id: null,
    collection:'',
    collectionFriendlyName:'',
    fields: [],
    filters: [],
    groupBy: null,
    orderBy: {
      field: null,
      sortAsc: -1
    },
    page: 1
  }

  public selectedFields = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  constructor(private api: ApiService) {
    this.api.get('/api/query-hub/tenants').then(response => {
      this.tenants = response;
      this.isLoadingTenants = false;
    })
  }

  public changeTenant(eido_intune_tenant_id: any) {
    this.query.eido_intune_tenant_id = eido_intune_tenant_id;
    this.getSchema();
  }

  public getSchema(){
    this.api.get(`/api/query-hub/query/schema/${this.query.eido_intune_tenant_id}`).then(response => {
      this.schema = response;
      this.isLoadingSchema = false;
    })
  }

  columnChanged(event) {

    console.log('Column Changed: ' + event.column);
    console.log('Sort Asc: ' + event.sortAsc);

    this.query.orderBy.field = event.column;
    this.query.orderBy.sortAsc = event.sortAsc;
    this.query.page = 1;

    console.log('Column Changed');
    console.log(event.column);
    console.log(event.sortAsc);

    this.execute();
  }

  groupByChanged($event){
    if(this.query.groupBy){
      this.query.orderBy.field = this.query.groupBy;
    }
  }

  getOrderByColumns(){

    // let columns = [];
    // let columnCollections = [];
    //
    // let selectedCollectionForQuery = this.schema.collections.find(collection => collection.name === this.query.collection);
    //
    // columnCollections.push(selectedCollectionForQuery);
    //
    // if(this.query.collection){
    //   selectedCollectionForQuery.fields.forEach(field => {
    //     columns.push({
    //       field: field.field,
    //       type: field.type,
    //       column_collection: field.column_collection,
    //       column_collection_friendly_name: selectedCollectionForQuery.friendly_name,
    //       name: field.name
    //     })
    //   });
    // }

    if(this.query.groupBy){
      let columns = [];

      let groupByColumn = this.availableColumns.find(column => column.field == this.query.groupBy);

      columns.push(groupByColumn);
      columns.push({
        field: 'count',
        type: 'number',
        column_collection: groupByColumn.column_collection,
        column_collection_friendly_name: groupByColumn.column_collection_friendly_name,
        name: 'Count'
      })

      console.log(columns);

      return columns;

    } else {
      return this.availableColumns;
    }

  }
  pageChanged(pageNo) {
    this.query.page = pageNo;

    this.execute();
  }

  search(event, fieldName) {
    this.uniqueFieldValuesCache[fieldName].forEach(value => {
      if (value.toLowerCase().includes(event.query.toLowerCase())) {

        // create array if doesnt exist
        if (!this.uniqueFieldValuesFilteredCache[fieldName]) {
          this.uniqueFieldValuesFilteredCache[fieldName] = [];
        }

        this.uniqueFieldValuesFilteredCache[fieldName].push(value);
      }
    });
  }

  changeCollection(collectionName:string, collectionFriendlyName:any){

    // Resets all the components!
    this.isLoadingSchema = true;

    // Reset all these fields when changing collection
    this.query.collection = collectionName;
    this.query.collectionFriendlyName = collectionFriendlyName;
    this.query.fields = [];
    this.query.filters = [];
    this.query.groupBy = null;
    this.query.orderBy = {
      field: null,
      sortAsc: -1
    };
    this.query.page = 1;

    this.queryResults = [];
    this.uniqueFieldValuesCache = []

    this.selectedTab = 'Filter';

    this.getSchemaColumns();
    this.checkAllColumns(collectionName);

    // Resets all the components!
    this.isLoadingSchema = false;
  }

  addFilter(){
    this.query.filters.push({
      field: null,
      operator: '$eq',
      value: null,
      arrayValues: []
    })
  }

  addArrayValue(filter: any){
    filter.arrayValues.push({value: ""});
  }

  deleteFilter(index: number): void {
    if (index > -1) { // Check if the index is valid
      this.query.filters.splice(index, 1); // Remove the item at the specified index
    }
  }

  filterFieldChanged(filter) {
    this.getUniqueFieldValues(filter);
  }

  // getSchemaColumns(){
  //   if(this.query.collection){
  //     let fields = this.schema.collections.find(collection => collection.name === this.query.collection).fields;
  //     console.log(fields);
  //     return fields;
  //   } else {
  //     return [];
  //   }
  //
  // }

  getSchemaColumns(){
    let columns = [];
    let columnCollections = [];

    let selectedCollectionForQuery = this.schema.collections.find(collection => collection.name === this.query.collection);

    columnCollections.push(selectedCollectionForQuery);

    if(this.query.collection){
      selectedCollectionForQuery.fields.forEach(field => {
        columns.push({
          field: field.field,
          type: field.type,
          column_collection: field.column_collection,
          column_collection_friendly_name: selectedCollectionForQuery.friendly_name,
          name: field.name
        })
      });
    }

    // columns = columns.concat(this.schema.collections.filter(collection => collection.allow_join === true).flatMap(collection => collection.fields));

    // Make a list of fields we can join on
    this.schema.collections.filter(collection => collection.allow_join === true && collection.name != this.query.collection).forEach(collection => {
      columnCollections.push(collection);

      collection.fields.forEach(field => {

        // // Add the collection name to the field name for joining columns
        columns.push({
          field: collection.name + '.' + field.field,
          type: field.type,
          column_collection: field.column_collection,
          column_collection_friendly_name: collection.friendly_name,
          name: field.name
        })

      })
    })

    this.availableColumns = columns;
    this.availableColumnCollections = columnCollections;


    console.log(columns);

    return columns;
  }

  getColumns(columnCollectionName){
    return this.availableColumns.filter(column => column.column_collection === columnCollectionName);
  }

  toggleColumn(field){

    let exists = this.query.fields.find(f => f.field == field.field);

    if(exists){
      this.query.fields = this.query.fields.filter(f => f != field);
    } else {
      this.query.fields.push(field);
    }
  }

  isColumnChecked(field){
    return this.query.fields.find(f => f.field == field.field);
  }

  isAllColumnsChecked(columnCollectionName){
    return this.getColumns(columnCollectionName).every(column => this.query.fields.includes(column.field));
  }

  checkAllColumns(columnCollectionName) {
    console.log('Toggling all columns for: ' + columnCollectionName);

    // Fetch all columns that belong to the specified columnCollectionName
    const columns = this.getColumns(columnCollectionName);

    // Check if all columns are already in the query.fields
    const allSelected = columns.every(column => this.query.fields.find(field => field.field === column.field));

    if (allSelected) {
      // If all are selected, filter them out
      this.query.fields = this.query.fields.filter(field => !columns.find(column => column.field === field.field));
      console.log('All columns deselected for: ' + columnCollectionName);
    } else {
      // If not all are selected, add missing columns
      columns.forEach(column => {
        if (!this.query.fields.some(field => field.field === column.field)) {
          this.query.fields.push(column);
          console.log('Adding field: ' + column.field);
        }
      });
    }

    console.log('Current fields: ' + this.query.fields.map(field => field.field).join(', '));
  }

  getUniqueFieldValues(field){

    let collection = this.query.collection;
    let splitField = null;

    // if field is in this format collection.field - then override the collection
    if(field.includes('.')){
      collection = field.split('.')[0];
      splitField = field.split('.')[1];
    }

    if(!this.uniqueFieldValuesCache[field]){
      this.api.post('/api/query-hub/query/unique-values', {field: splitField ?? field, collection: collection, eido_intune_tenant_id: this.query.eido_intune_tenant_id}).then(response => {
        this.uniqueFieldValuesCache[field] = response;
      })
    }
    return this.uniqueFieldValuesCache[field];
  }

  getColumnType(field: any){
    return this.availableColumns.find(column => column.field == field).type;
  }

  execute(exportToCsv = false){
    this.isLoadingResults = true;
    this.selectedTab = 'Results';

    console.log('Executing Query');
    console.log(this.query);

    if(exportToCsv){
      this.api.post('/api/query-hub/query', this.query, {exportCsv:exportToCsv}, "blob").then(response => {
        if (exportToCsv) {
          // Create a blob from the response
          const blob = new Blob([response], { type: 'text/csv;charset=UTF-8' });

          // Create a link element
          const link = document.createElement('a');

          // Set the download attribute with a filename
          link.href = window.URL.createObjectURL(blob);
          link.download = 'export.csv';

          // Append the link to the body
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Remove the link from the body
          document.body.removeChild(link);
        } else {
          this.queryResults = response;
        }

        this.isLoadingResults = false;
      })

    } else {
      this.api.post('/api/query-hub/query', this.query).then(response => {
        this.queryResults = response;

        if(this.query.visualisation == 'pie' || this.query.visualisation == 'bar'){
          this.transformedPieColumnChartData = response.results.map(item => ({
            name: item[this.query.groupBy],
            y: item.count
          }));
        }
        this.isLoadingResults = false;
      })
    }
  }

  ngOnInit() {

  }

}
