<div class="filterParent">
  <div (click)="isExpanded = !isExpanded" class="filterTitle divLink" [class.grey]="isHidden && !(!checkboxIsAllSelected() && options.length > 0) && !isExpanded">
    <i class="fas" [ngClass]="isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
    <span class="m-l-5">{{friendlyName}}

<!--      <i *ngIf="showSearchOption && options.length > 10 && isExpanded" (click)="toggleSearch($event)" class="fas fa-search divLink" style="-->
<!--    margin-left: 10px;-->
<!--    color: #808080a6;-->
<!--    font-size: 12px;-->
<!--    margin:7px;-->
<!--"></i>-->

    </span>

    <span *ngIf="!checkboxIsAllSelected() && options.length > 0" (click)="clearFilterButton($event)" class="filterClear float-end">
              <i class="fas fa-filter"></i>
              <i class="fas fa-times m-l-5"></i>
            </span>



  </div>
  <input *ngIf="options.length > 25 && isExpanded" [(ngModel)]="searchTerm" (ngModelChange)="filterOptions()"  type="text" class="form-control filterOptionsSearchBox" id="Alert_Name" placeholder="Filter values" autocomplete="off" style="
    background-color: #f6f7f7;
    color: #4f4f4f;
">
  <div *ngIf="isExpanded" class="filterOptions">

    <div *ngIf="showAllCheckbox" class="filterItem">
      <input [ngModel]="checkboxIsAllSelected()" (change)="this.checkboxToggleAll()" class="form-check-input" id="alert_on_intune_compliance_iss1ues" type="checkbox">
      <span class="title">All</span>
    </div>

    <div *ngFor="let field of this.filteredButNotFound" class="filterItem">
      <input [ngModel]="selected.isSelected(convertToLowerCaseIfString(field))" (change)="this.selected.toggle(convertToLowerCaseIfString(field))" class="form-check-input" id="not_found_filters" type="checkbox">
      <span class="title">{{field | titlecaseString }} <span *ngIf="showCount" class="filterOptionsCountBadge">0</span>  </span>
      <span (click)="only(field)" class="filterOnlyButton float-end divLink">Only</span>
    </div>

    <div *ngIf="template == 'default'">
      <div *ngFor="let customer of filteredOptions" class="filterItem">
        <input [ngModel]="selected.isSelected(convertToLowerCaseIfString(customer[key]))" (change)="selected.toggle(convertToLowerCaseIfString(customer[key]))" class="form-check-input" id="defaultdefault{{i}}" type="checkbox">
        <span class="title">{{ customer[value] }} <span *ngIf="showCount" class="filterOptionsCountBadge" [class.active]="customer.count">{{ customer.count }}</span></span>
        <span (click)="only(customer[key])" class="filterOnlyButton float-end divLink">Only</span>
      </div>
    </div>

    <div *ngIf="template == 'titleCase'">
      <div *ngFor="let customer of filteredOptions" class="filterItem">
        <input [ngModel]="selected.isSelected(convertToLowerCaseIfString(customer[key]))" (change)="this.selected.toggle(convertToLowerCaseIfString(customer[key]))" class="form-check-input" id="titleCasetitleCase" type="checkbox">
        <span class="title">{{customer[value] | titlecaseString }} <span class="filterOptionsCountBadge" [class.active]="customer.count">{{customer.count}}</span>  </span>
        <span (click)="only(customer[key])" class="filterOnlyButton float-end divLink">Only</span>
      </div>
    </div>

    <div *ngIf="template == 'none'">
      <div *ngFor="let customer of filteredOptions" class="filterItem">
        <input [ngModel]="selected.isSelected(convertToLowerCaseIfString(customer[key]))" (change)="this.selected.toggle(convertToLowerCaseIfString(customer[key]))" class="form-check-input" id="nonenone" type="checkbox">
        <span class="title">{{customer[value] }} <span *ngIf="showCount" class="filterOptionsCountBadge" [class.active]="customer.count">{{customer.count}}</span>  </span>
        <span (click)="only(customer[key])" class="filterOnlyButton float-end divLink">Only</span>
      </div>
    </div>

    <div *ngIf="template == 'status'">
      <div *ngFor="let customer of filteredOptions" class="filterItem">
        <input [ngModel]="selected.isSelected(convertToLowerCaseIfString(customer[key]))" (change)="this.selected.toggle(convertToLowerCaseIfString(customer[key]))" class="form-check-input" id="statusstatus" type="checkbox">

        <span class="title">
          {{customer[value] | titlecaseString }} <span *ngIf="showCount" class="filterOptionsCountBadge" [ngClass]="customer[value].toLowerCase()" [class.active]="customer.count">{{customer.count}}</span>  </span>
        <span (click)="only(customer[key])" class="filterOnlyButton float-end divLink">Only</span>
      </div>
    </div>

    <p *ngIf="this.filteredOptions.length < this.options.length">Showing {{this.filteredOptions.length}} of {{this.options.length}}</p>

  </div>
</div>

