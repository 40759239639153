<div *ngIf="!path" class="p-20">
  <h2>Built-in Reports</h2>

  <div class="row" style="margin-top:50px">

    <div *ngFor="let report of this.availableReports" class="col-3 divLink  reportCard p-20" [routerLink]="['/reporting',report.route]">
      <div class="row">
        <div class="col-2">
          <i class="fa fa-file-text-o fa-2x" style="    color: #2e65a3ba;" aria-hidden="true"></i>
        </div>
        <div class="col">
          <h4>{{report.name}}</h4>
          <p>{{report.description}}</p>
        </div>
      </div>
    </div>


  </div>

</div>

<app-loading-spinner *ngIf="path && isLoading" ></app-loading-spinner>


<div *ngIf="path && !isLoading" class="">
  <app-eido-super-table [data]="reportData" [settings]="superTableSettings"></app-eido-super-table>
</div>
