import {Component, ComponentFactoryResolver, Injectable, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../shared/dialog/dialog";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private resolver: ComponentFactoryResolver ) {}

  createDialog(component, data, entry: ViewContainerRef)  {

    //console.log("Calling createDialog");

    const resolver = this.resolver;

    return new Promise(function(resolve, reject) {
      const factory = resolver.resolveComponentFactory<Dialog>(component);
      const injectedComponent = entry.createComponent<Dialog>(factory);

      injectedComponent.instance.data = data;
      injectedComponent.instance.remove.subscribe(returnData => {
        injectedComponent.destroy();

        if(returnData){
          resolve(returnData);
        } else {
          reject(returnData);
        }
      })
    })
  }

}
