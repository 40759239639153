<div class="suggestion-input-wrapper" (clickOutside)="hideSuggestions()">
  <div class="input-group divLink">

    <input type="text"
           [value]="getSelectedValue()"
           (focus)="showSuggestions = true"

           [readOnly]="true"
           name="userInput"
           class="form-control input-sm" />

    <span>
        <i *ngIf="selectedValue" class="fas fa-times" style="
           margin-left: -29px;
           position: absolute;
           margin-top: -14px;
           padding: 7px;
           font-size: 16px;
           color: slategrey;
" (click)="selectSuggestion(null)"></i>
    </span>


    <span *ngIf="this.options?.length" class="input-group-addon"  (click)="toggleDropdown($event)">
      <i *ngIf="!showSuggestions" class="fa fa-chevron-down"></i>
      <i *ngIf="showSuggestions" class="fa fa-chevron-up"></i>
    </span>



  </div>
  <ul *ngIf="showSuggestions && filteredSuggestions.length > 0"  class="suggestions-dropdown">
    <ng-container *ngFor="let category of groupedSuggestions | keyvalue">
      <li class="category" (click)="toggleExpand(category.key)">
        {{ category.key }}

        <i class="float-end fa m-r-10" style="font-size: 13px;margin-top: 3px;" [ngClass]="{'fa-plus': !isExpanded(category.key), 'fa-minus': isExpanded(category.key)}"></i>
      </li>
      <ul *ngIf="isExpanded(category.key)">
        <li *ngFor="let suggestion of category.value" (click)="selectSuggestion(suggestion[optionsValue])">
          {{ suggestion[optionsLabel] }}
        </li>
      </ul>
    </ng-container>
  </ul>
</div>

