<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal [modalSize]="'modal-small'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Link a new Intune Tenant</h5>
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
  </div>

  <div class="modal-body">

    <h4>Choose your access level</h4>

    <div class="selectSection" (click)="save('read-write')">
      <h4>Read-Write Access </h4>
      <p>Grants read-write access to Intune devices, policies, and the ability to deploy and restore templates and configuration items. User access remains read-only.</p>
    </div>

    <div class="selectSection" (click)="save('read-only')">
      <h4>Read-Only Access
<!--        <span style="-->
<!--    font-size: 14px;-->
<!--    margin-left: 10px;-->
<!--    background-color: #608f78;-->
<!--    color: white;-->
<!--    padding: 2px 12px;-->
<!--    border-radius: 20px;-->
<!--" >Recommended</span>-->
      </h4>
      <p>This level provides read-only access to Intune devices, policies, and users.</p>
    </div>

    <div class="selectSection" (click)="save('limited')">
      <h4>Limited Access</h4>
      <p> Offers read-only access to managed devices and Intune configurations, with no access to user options.</p>
    </div>

  </div>

</app-modal>
