<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div (click)="create()" class="btn btn-primary float-end">Create Alert</div>
<h3>Alerts</h3>

<div *ngIf="portalSettings.showMspFeatures()" class="col-12 m-b-20 " style="margin-top: 22px;">
  <ul class="nav dialogTabs">
    <li class="nav-item divLink" (click)="tab = 'all'">
      <a class="nav-link-thread" [class.active]="tab == 'all'">
        All Alerts
      </a>
    </li>
    <li class="nav-item divLink" (click)="tab = 'tenant'">
      <a class="nav-link-thread" [class.active]="tab == 'tenant'">
        Tenant Alerts
      </a>
    </li>

    <li class="nav-item divLink" (click)="tab = 'customer'">
      <a class="nav-link-thread" [class.active]="tab == 'customer'">
        Customer Alerts
      </a>
    </li>

  </ul>
</div>


<div *ngIf="!isLoading">
  <div class="row m-t-20">
    <div class="col-4"><h4 style="color: #bdbdbd;">FRIENDLY NAME</h4></div>
    <div class="col-4"><h4 style="color: #bdbdbd;">TYPE</h4></div>
    <!--    <div class="col-3"><h4 style="color: #bdbdbd;">LAST ACTIVITY</h4></div>-->
  </div>

  <div *ngFor="let row of this.getFilteredData()" class="divLink sectionBox row">
    <div class="col-4" (click)="edit(row.id)"><h4>{{row.alert_name}}</h4></div>
    <div class="col-4 p-l-5" (click)="edit(row.id)"><h4>{{row.alert_type}}
      <span class="text-danger badge" *ngIf="row.never_logged_in">Never logged in</span>
    </h4></div>
    <!--    <div class="col-3 p-l-5" (click)="edit(row.id)"><h4>{{row.last_activity}}</h4></div>-->
    <div class="col">
      <i (click)="delete(row.id)" class="fas fa-trash float-end fa-15x m-l-10 p-1 divLink"></i>
      <i (click)="edit(row.id)" class="fas fa-cog float-end fa-15x p-1 divLink"></i>
    </div>
  </div>
</div>
