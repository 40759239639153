import {Component, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';
import {PolicyDialogComponent} from '../policies/policy-dialog/policy-dialog.component';
import {ModalConfirmationComponent} from '../../shared/modal-confirmation/modal-confirmation.component';
import {ApiService} from '../../services/api/api.service';
import moment from 'moment';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrl: './insights.component.css'
})
export class InsightsComponent implements OnInit {

  protected readonly Math = Math;

  constructor(private apiService: ApiService) {
  }

  ngOnInit() {
    this.getDaysSinceFirstDevice();
  }

  getDaysSinceFirstDevice(){
    this.apiService.get('api/insights/daysSinceFirstDevice').then(response => {
      this.daysSinceFirstDevice = response.days_since_first_device;
      this.isLoadingDaysSinceFirstDevice = false;

      this.runReport();

    });
  }

  toggleShow(){
    this.reportDataLoaded = false;
    // add or remove gridSettings.columns for the columns that are not selected in the reportPayload.show
    this.gridSettings.columns.forEach(column => {
      column.hide = !this.reportPayload.show[column.field];
    });

    this.reportDataLoaded = true;
  }

  public daysSinceFirstDevice = 0;
  public isLoadingDaysSinceFirstDevice = true;

  public loadedReportData = [];
  public isLoadingReport = false;
  public reportDataLoaded = false;

  public groupByTypes = [
    {value: 'customer', viewValue: 'Customer'},
    {value: 'device', viewValue: 'Device'},
  ];

  public dateTypes = [
    {value: 'lastWeek', viewValue: 'Last Week'},
    {value: 'lastMonth', viewValue: 'Last Month'},
    {value: 'last30Days', viewValue: 'Last 30 Days'},
    {value: 'last3Month', viewValue: 'Last 3 Months'},
    {value: 'last6Month', viewValue: 'Last 6 Months'},
    {value: 'last12Month', viewValue: 'Last 12 Months'},
    {value: 'custom', viewValue: 'Custom'},
  ];

  public gridSettings = {
    actionButtons: [],
    multiSelect: false,
    multiSelectActions: [],
    columns: [
      {field: 'name', headerName: 'Name', checkboxSelection: false, headerCheckboxSelection: false, hide: false},
      {field: 'compliance_healthy_percent', headerName: 'Avg Compliance %', cellRenderer: 'percentBadgeRenderer', hide: false},
      {field: 'config_healthy_percent', headerName: 'Avg Config Health %', cellRenderer: 'percentBadgeRenderer', hide: false},
      {field: 'app_healthy_percent', headerName: 'Avg App Health %', cellRenderer: 'percentBadgeRenderer', hide: false},
      {field: 'patch_healthy_percent', headerName: 'Avg Patch Health %', cellRenderer: 'percentBadgeRenderer', hide: false},
    ]
  };

  public switchReportType(){
    this.isLoadingReport = true;
    this.loadedReportData = [];
    this.isLoadingReport = false;
    this.reportDataLoaded = false;

    this.runReport();
  }

  public switchTrendBy(){
    if(this.reportPayload.trendBy === 'day') {
      this.reportPayload.trendPeriod = 30;
    } else if(this.reportPayload.trendBy === 'month') {
      this.reportPayload.trendPeriod = 6;
    }
  }

  public reportPayload = {
    type: 'trend',

    // Report Settings
    reportGroupBy: 'device',
    reportDateSetting: 'last30Days',
    reportDateFrom: moment().subtract(30, 'days').toDate(),
    reportDateTo: moment().subtract(1, 'days').toDate(),

    // Trend Settings
    trendBy: 'day',
    trendPeriod: 30,

    show: {
      compliance_healthy_percent: true,
      config_healthy_percent: true,
      app_healthy_percent: true,
      patch_healthy_percent: true,
      name: true
    },
    exclude: {
      amber: true,
      red: false,
    }
  };

  runReport() {

    if(this.daysSinceFirstDevice < 6){
      return;
    }

    this.toggleShow();

    this.isLoadingReport = true;
    this.loadedReportData = [];
    this.reportDataLoaded = false;


    this.apiService.post('api/insights', this.reportPayload).then(response => {
      this.loadedReportData = response;
      this.isLoadingReport = false;
      this.reportDataLoaded = true;
    });
  }

  reportDateSettingChanged() {

  // public dateTypes = [
  //     {value: 'yesterday', viewValue: 'Yesterday'},
  //     {value: 'lastWeek', viewValue: 'Last Week'},
  //     {value: 'lastMonth', viewValue: 'Last Month'},
  //     {value: 'last3Month', viewValue: 'Last 3 Months'},
  //     {value: 'last6Month', viewValue: 'Last 6 Months'},
  //     {value: 'last12Month', viewValue: 'Last 12 Months'},
  //     {value: 'custom', viewValue: 'Custom'},
  //   ];


    switch (this.reportPayload.reportDateSetting) {
      case 'lastWeek':
        this.reportPayload.reportDateFrom = moment().subtract(1, 'weeks').startOf('week').toDate();
        this.reportPayload.reportDateTo = moment().subtract(1, 'weeks').endOf('week').toDate();
        break;
      case 'lastMonth':
        this.reportPayload.reportDateFrom = moment().subtract(1, 'months').startOf('month').toDate();
        this.reportPayload.reportDateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case 'last3Month':
        this.reportPayload.reportDateFrom = moment().subtract(3, 'months').startOf('month').toDate();
        this.reportPayload.reportDateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case 'last6Month':
        this.reportPayload.reportDateFrom = moment().subtract(6, 'months').startOf('month').toDate();
        this.reportPayload.reportDateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case 'last12Month':
        this.reportPayload.reportDateFrom = moment().subtract(12, 'months').startOf('month').toDate();
        this.reportPayload.reportDateTo = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      case 'last30Days':
        this.reportPayload.reportDateFrom = moment().subtract(30, 'days').toDate();
        this.reportPayload.reportDateTo = moment().subtract(1, 'days').toDate();
        break;
      default:
    }
  }


  getPercentStatus(percent: any) {
    if (percent < 80) {
      return 'red';
    } else if (percent < 90) {
      return 'amber';
    } else {
      return 'green';
    }
  }

  public gridRowClicked(data: any) {
    // this.dialogService.createDialog(PolicyDialogComponent, data.eido_policy_id, this.viewReference)
    //   .then(dialogSaved => {
    //     this.getData();
    //   }, dialogCancelled => {
    //     this.getData();
    //
    //   })

  }

  public gridActionClicked(data: any) {

    // if(data.action === 'View') {
    //   console.log(data);
    //   this.dialogService.createDialog(PolicyDialogComponent, data.rowData.eido_policy_id, this.viewReference)
    //     .then(dialogSaved => {
    //       this.getData();
    //     }, dialogCancelled => {
    //       this.getData();
    //     })
    // }

  }

  public gridMultiSelectAction(data: any) {
    // if (data.action === 'Convert to template') {
    //   console.log(data);
    //   this.dialogService.createDialog(ModalConfirmationComponent, `Are you sure you want to convert ${data.selectedRows.length} policies to a template?`, this.viewReference)
    //     .then(confirmed => {
    //
    //       this.resetBulkChange();
    //
    //       this.bulkActionName = 'Converting policies to templates';
    //       this.bulkChangeShowProgressWindow = true;
    //       this.bulkChangeInProgress = true;
    //       this.bulkChangePromises = []; // Initialize the array
    //
    //       data.selectedRows.forEach(row => {
    //         let newPromise = this.policyApiService.createTemplate(row.eido_policy_id).then(response => {
    //           // Mark the item as completed
    //           const item = this.bulkChangePromises.find(item => item.promise === newPromise);
    //           if (item) {
    //             item.status = 'completed';
    //             this.bulkChangeCompletedCount ++;
    //             this.bulkChangeInProgressCount --;
    //           }
    //         }, error => {
    //           // Mark the item as completed
    //
    //           const item = this.bulkChangePromises.find(item => item.promise === newPromise);
    //           if (item) {
    //             item.status = 'failed';
    //             this.bulkChangeFailedCount ++;
    //             this.bulkChangeInProgressCount --;
    //           }
    //         });
    //
    //         this.bulkChangePromises.push({
    //           promise: newPromise,
    //           policyName: row.policy_name,
    //           status: 'inProgress'
    //         });
    //
    //         this.bulkChangeInProgressCount ++;
    //         this.bulkChangeTotalCount ++;
    //       });
    //
    //       // Wait for all promises to resolve
    //       Promise.all(this.bulkChangePromises.map(item => item.promise)).then(response => {
    //         this.getData();
    //         this.bulkChangeInProgress = false;
    //       });
    //
    //     }, cancelled => null);
    // }
  }

}
