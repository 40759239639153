<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal modalSize="modal-large">
  <div class="p-20 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Restore Backup</h3>
  </div>

  <div style="margin: 20px;background-color: #ffedcd;padding: 9px;font-size: 12px;margin-top: 0px;border-radius: 5px;">
    <strong>Disclaimer:</strong> Please note that the feature for restoring policies and assignments is currently in its BETA phase. We advise users to proceed with caution and thoroughly review the provided payload to ensure it meets your requirements before proceeding.
    <br>
    Eido will run a backup before, and after the restore process to ensure that the system is in a consistent state.
  </div>

  <div *ngIf="restoreInProgress" style="margin: 20px;">
    <app-loading-spinner> </app-loading-spinner>
  </div>

  <div *ngIf="restoreFailed" style="margin: 20px;background-color: indianred;padding: 9px;font-size: 13px; color:white;margin-top: 0px;border-radius: 5px;">
    <strong>Restore Failed:</strong> {{restoreError}}
  </div>

  <div *ngIf="restoreSuccessful" style="margin: 20px;background-color: #82a996;padding: 9px;font-size: 13px; color:white;margin-top: 0px;border-radius: 5px;">
    Restore Successful!
  </div>




  <div style="margin:20px">

    <div class="row m-t-20">
      <div class="col-sm-3 formHeader">
        Backup Date
      </div>
      <div class="col-sm">
       {{data.created_at}}
      </div>
    </div>

    <div class="row m-t-20">
      <div class="col-sm-3 formHeader">
        Restore Policy
      </div>
      <div class="col-sm">
        <div class="form-check form-switch form-switch-md">
          <input [(ngModel)]="restorePolicy" [disabled]="hideRestoreOptions()" class="form-check-input" type="checkbox" id="restorePolicy" checked>
          <label class="form-check-label" for="restorePolicy" style="margin-top: 5px;">

          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader">
        Restore Assignments
      </div>
      <div class="col-sm">
        <div class="form-check form-switch form-switch-md">
          <input [(ngModel)]="restoreAssignments" [disabled]="hideRestoreOptions()" class="form-check-input" type="checkbox" id="restoreAssignments" checked>
          <label class="form-check-label" for="restoreAssignments" style="margin-top: 5px;">

          </label>
        </div>
      </div>
    </div>

    <div *ngIf="!hideRestoreOptions()" (click)="!restoreAssignments && !restorePolicy ? null : restore()" class="btn btn-primary" [class.disabled]="!restoreAssignments && !restorePolicy">Confirm Restore</div>


  </div>

  <hr *ngIf="restoreAssignments || restorePolicy">

  <div class="row">


<div *ngIf="restorePolicy" class="col-6">
  <h4 style="margin-left:20px ;">Review Policy</h4>
  <pre style="margin:10px; padding:10px  ; background: #add8e638;">{{ policyData | jsonFormat }}</pre>
</div>

  <div *ngIf="restoreAssignments" class="col-6">

  <h4 style="margin-left:20px;  ">Review Assignments</h4>
  <pre style="margin:10px; padding:10px  ; background: #add8e638;">{{ assignmentData | jsonFormat }}</pre>

  </div>


  </div>
</app-modal>
