import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PolicyApiService} from '../../../../services/api/policy/policy-api.service';

@Component({
  selector: 'backup-comparison-dialog',
  templateUrl: './backup-comparison-dialog.component.html',
  styleUrls: ['./backup-comparison-dialog.component.css']
})
export class BackupComparisonDialogComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  public showFriendly = true;

  public diffReport: any;

  public showComparison = false;

  constructor(
    private policyApiService: PolicyApiService
  ) {}


  ngOnInit(): void {

  }


  close(){
    this.remove.emit(null);
  }

  differenceReport(event) {
    console.log(event);
    this.diffReport = event;
  }


  protected readonly JSON = JSON;
}
