// status-badge-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-status-badge-renderer',
  standalone: true,
  template: `
    <div class="statusBadge m-0" [ngClass]="getStatusColor(params.value)"></div>`,
  imports: [
    NgClass
  ],
  styles: [`
    .statusBadge { /* Add your styling here */
    }
  `]
})
export class StatusBadgeRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public getStatusColor(value: string): string {
    // Replace with your shared function logic or any custom logic
    return `class-based-on-${value}`;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
