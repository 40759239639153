<div *ngIf="!portalSettings.isLoading" class="d-flex" id="wrapper" [class.toggled]="!sideBarNavOpen">





  <!-- Sidebar -->
  <button (click)="sideBarNavOpen = !sideBarNavOpen" class="navbar-button d-block" id="menu-toggle-mobile" style="position:fixed; z-index: 1000">
    <i class="fas fa-bars fa-12x"></i></button>
  <div class="sideBarMakeFixed" id="sidebar-wrapper">
    <img class="m-b-20 mx-auto d-block" src="/assets/img/logo_dark.svg" style="max-width: 240px; max-height: 60px" alt="eido Logo">


    <!-- Begin Free Trial Countdown -->
    <div *ngIf="portalSettings.tenantSettings.trial_days_left > 0" style="margin-right: 5px;"
         class="free-trial-bar divLink" id="free-trial-countdown">
      <i class="fas fa-info-circle fa-15x" style="color: #ff7f50b3; margin-right: 10px"></i>
      <span>{{portalSettings.tenantSettings.trial_days_left}} Days left of your free trial</span>
    </div>

    <div *ngIf="portalSettings.tenantSettings.trial_days_left < 0 && !portalSettings.permissions.is_root" style="margin-right: 5px;"
         class="free-trial-bar divLink" id="free-trial-expired">
      <i class="fas fa-info-circle fa-15x" style="color: #ff7f50b3; margin-right: 10px"></i>
      <span>Your trial has expired</span>

      <app-modal modalSize="modal-medium">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Your trial has expired</h5>
        </div>
        <div class="modal-body">
          <p style="font-size: 14px">Would you like to continue using Eido? Convert to a paid subscription using the link below, or contact us on <a style="color: #283b71" href="mailto:hello@eido.cloud">hello&#64;eido.cloud</a> for assistance.</p>

        </div>
        <div class="modal-footer">
          <div class="btn btn-primary" routerLink="/billing">Upgrade Now</div>
        </div>
      </app-modal>

    </div>
    <!-- End Free Trial Countdown -->

    <!-- Show Device Warning -->
    <div *ngIf="!portalSettings.tenantSettings.trial_days_left && portalSettings.tenantSettings.used_devices > portalSettings.tenantSettings.billing_devices" style="margin-right: 5px;"
         class="free-trial-bar divLink" id="used_devices">
      <i class="fas fa-info-circle fa-15x" style="color: #ff7f50b3; margin-right: 10px"></i>
      <span>{{portalSettings.tenantSettings.used_devices}} devices used out of {{portalSettings.tenantSettings.billing_devices}}</span>
    </div>
    <!-- Show Device Warning -->

<!--    <h5 (click)="toggleDarkTheme()"> Show Dark</h5>-->


    <div *ngIf="portalSettings.tenantSettings.msp_mode_enabled">
      <div (click)="showCustomerFilter = !showCustomerFilter" class="customerFilter" [class.selected]="portalSettings.mspCustomerSwitch.selectedCustomerId">
        {{portalSettings.mspCustomerSwitch.selectedCustomerId ? portalSettings.mspCustomerSwitch.selectedCustomerName : 'All Customers'}}
        <i class="far fa-chevron-down float-end" [ngClass]="showCustomerFilter ? 'fa-chevron-up' : 'fa-chevron-down'" style="margin-right: 0px;color: #bdbdbd5c;font-size: 15px;margin-top: 3px;"></i>
      </div>

      <div id="customer-filter" *ngIf="showCustomerFilter">
        <div  (click)="changeCustomerFilter(null, null)"  [class.active]="portalSettings.mspCustomerSwitch.selectedCustomerId == null" class="customerFilterList">
          <span>All Customers</span>
        </div>

        <div *ngFor="let customer of this.optionsService.customers" (click)="changeCustomerFilter(customer.id, customer.customer_name)" [class.active]="customer.id == portalSettings.mspCustomerSwitch.selectedCustomerId" class="customerFilterList">
          <span>{{customer.customer_name}}</span>
        </div>

      </div>
    </div>


    <div id="main-navigation" *ngIf="!showCustomerFilter" style="padding-bottom: 10px">
      <hr>

      <ul>

        <li *ngIf="getStartedStep">
          <a routerLink="/get-started" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><i class="fas fa-play"></i>
            <span> Get Started </span></a>
        </li>

        <li *ngIf="portalSettings.showMspFeatures()">
          <a routerLink="/customers-dash" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><i class="fas fa-building"></i>
            <span> Customers </span></a>
        </li>

        <li>
          <a class="divLink" (click)="dashboardExpanded = !dashboardExpanded" [ngClass]="{'active': isActive('/dashboard') || dashboardExpanded}"  ><i class="fas fa-th-large"></i>
            <i class="fas float-end "  [ngClass]="dashboardExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            <span> Dashboards </span></a>
        </li>

        <div *ngIf="dashboardExpanded" style="margin-left: 0px">

          <li class="subMenuItem" *ngFor="let dash of optionsService.dashboards" [routerLink]="['/dashboard', dash.is_default? 'default' : dash.id]" routerLinkActive="active">
            <div  >
              <span> {{dash.name}} </span></div>
          </li>

          <li class="subMenuItem" style="border-bottom: none !important">
            <div  [routerLink]="['/dashboard/create']" routerLinkActive="active">
              <span> Create New + </span></div>
          </li>

          <hr>
        </div>



        <li>
          <a routerLink="/devices" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="fal fa-laptop"></i>
            <span> Devices </span></a>
        </li>
        <li>
          <a routerLink="/apps" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="fas fa-browser"></i>
            <span> Discovered Apps </span></a>
        </li>




          <li (click)="winUpdateExpanded = !winUpdateExpanded" class="divLink">
            <a [ngClass]="winUpdateExpanded ? 'active' : ''">
              <i class="fas fa-heartbeat"></i>
              <span> OS Health
                <i class="fas float-end "  [ngClass]="winUpdateExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i></span></a>
          </li>

          <div *ngIf="winUpdateExpanded" style="margin-left: 0px">

            <li class="subMenuItem" style="border-bottom: none !important" [routerLink]="['/reporting-updates/windows-updates-devices']" routerLinkActive="active">
              <div>
                <span> Patch Compliance </span>
              </div>
            </li>

            <li class="subMenuItem" style="border-bottom: none !important" [routerLink]="['/reporting-updates/windows-updates-os']" routerLinkActive="active">
              <div>
                <span> OS Compliance </span>
              </div>
            </li>

<!--            <li class="subMenuItem" style="border-bottom: none !important" [routerLink]="['/reporting-updates/windows-updates-patches']" routerLinkActive="active">-->
<!--              <div>-->
<!--                <span> Patch Compliance </span>-->
<!--              </div>-->
<!--            </li>-->

          </div>
        <hr>
        <li>
          <a routerLink="/policy-management" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fas fa-cog"></i>
            <span> Policy Management </span>
          </a>
        </li>
        <hr>

<!--        <li>-->
<!--          <a routerLink="/query-hub" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"><i class="fal fa-chart-network"></i>-->
<!--            <span> Query Hub </span></a>-->
<!--        </li>-->

<!--        <hr>-->

        <li>
          <a routerLink="/issues" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fas fa-bell-on"></i>
            <span> Issues </span>
          </a>
        </li>

        <li>
          <a routerLink="/reporting" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fal fa-chart-bar"></i>
            <span> Reports </span>
          </a>
        </li>

        <li>
          <a routerLink="/insights" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
            <i class="fas fa-lightbulb-on"></i>
            <span> Trends</span>
          </a>
        </li>

        <hr>

        <div *ngIf="portalSettings.permissions.is_admin">
          <li (click)="adminExpanded = !adminExpanded"  class="divLink">
            <a [ngClass]="adminExpanded ? 'active' : ''"><i class="fas fa-cogs"></i>
              <span> Admin  <i class="fas float-end "  [ngClass]="adminExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i></span></a>
          </li>

          <div *ngIf="adminExpanded" style="margin-left: 0px">

            <li>
              <a routerLink="/admin/alerts" routerLinkActive="active">
                <i class="fal fa-bell-on"></i>
                <span> Alerts </span></a>
            </li>

            <li>
              <a routerLink="/admin/team" routerLinkActive="active">
                <i class="fal fa-users"></i>
                <span> Team </span></a>
            </li>

            <li *ngIf="portalSettings.showMspFeatures()">
              <a routerLink="/admin/customers" routerLinkActive="active">
                <i class="fal fa-building"></i>
                <span> Customers </span></a>
            </li>

            <li *ngIf="portalSettings.permissions.is_admin && portalSettings.mspCustomerSwitch.selectedCustomerId == null && !portalSettings.permissions.customer_id">
              <a routerLink="/admin/intune-config" routerLinkActive="active">
                <i class="fab fa-microsoft"></i>
                <span> Intune Tenants </span></a>
            </li>

          </div>
          <hr>

        </div>

        <li *ngIf="portalSettings.permissions.is_admin && portalSettings.mspCustomerSwitch.selectedCustomerId == null && !portalSettings.permissions.customer_id">
          <a routerLink="/billing" routerLinkActive="active"><i class="fas fa-credit-card"></i>
            <span> Billing <i class="fas fa-external-link float-end" style="color: #bac1c766;font-size: 15.5px;"></i></span></a>
        </li>

      </ul>
    </div>

    <div style="position: fixed; bottom: 0; width: 250px; background-color: #0d151e;">
      <hr>
      <div style="color: #b5b5b5; cursor:pointer;
    display: block;
        padding: 0px 5px 5px;
    margin: 3px 10px;
    border-left: 3px solid transparent;
    font-size: 12px;">
        <a (click)="logout()">
          <i class="fas fa-user" style="background-color: #0000004d;
    padding: 8px;
    border-radius: 50%;
    font-size: 13px;
    margin-right: 8px;"></i> Logout</a>
      </div>

      </div>
  </div>
  <!-- /#sidebar-wrapper -->


  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="container-fluid p-0">

      <div style="margin:9px">

        <router-outlet></router-outlet>

      </div>
    </div>
  </div>
  <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->

<!-- Menu Toggle Script -->
