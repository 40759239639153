import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from "@ag-grid-community/core";
import { Component } from '@angular/core';

interface CustomButtonParams extends ICellRendererParams {
  onClick: (data: any) => void; // Now expects a data parameter
  buttonLabel: string; // To customize button text
}

@Component({
  selector: 'custom-button-renderer',
  standalone: true,
  template: `<div class="btn btn-primary btn-small" (click)="onClick(data)">{{buttonLabel}}</div>`, // Use the buttonLabel for dynamic text
})
export class CustomButtonComponent implements ICellRendererAngularComp {
  public onClick!: (data: any) => void;
  public buttonLabel!: string;
  public data!: any; // To store the row data

  agInit(params: CustomButtonParams): void {
    this.onClick = params.onClick;
    this.buttonLabel = params.buttonLabel;
    this.data = params.data;
  }

  refresh(params: CustomButtonParams): boolean {
    // Update internal state if necessary
    return false;
  }
}
