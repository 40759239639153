<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>
<h1 *ngIf="isLoading"></h1>


<div *ngIf="!isLoading">
  <div class="row">
    <div *ngIf="!hideFilter" [ngClass]="filterWidthClass">
      <div style="height:calc(100vh - {{settings.topMargin}}px); border-right: solid 1px #d7d7d7; margin-right: 10px; overflow-y: auto;">


        <span *ngIf="this.filteredData.length < data.length" style="
              font-size: 12px;
              float:right;
              background-color: #5555551c;
              padding: 5px 10px;
              border-radius: 10px;
              margin-right:10px;
        ">Filtering {{this.filteredData.length}} of {{data.length}}</span>

        <h6 class="m-t-5" style="margin-left:2px">Filter & Search</h6>

        <input [(ngModel)]="searchTerm" (ngModelChange)="searchChanged()"  type="text" class="form-control filterOptionsSearchBox" id="Alert_Name"
               placeholder="Search" autocomplete="off" style="background-color: #edeff1;border-radius: 20px;padding: 5px 14px;width: 97%; margin-bottom: 10px; margin-top: 10px;">

        <app-filter-options-view *ngFor="let filterOptions of settings.filterOptions"
                                 [columnName]="filterOptions.columnName"
                                 [showSearchOption]="filterOptions.showSearchOption"
                                 [showCount]="filterOptions.showCount"
                                 [template]="filterOptions.template"
                                 [showAllCheckbox]="filterOptions.showAllCheckbox"
                                 [friendlyName]="filterOptions.friendlyName"
                                 [isExpanded]="filterOptions.isExpanded"
                                 (filteredValues)="filterChanged(filterOptions.columnName, $event)"
                                 [options]="options[filterOptions.columnName]"
                                 [isHidden]="filterOptions.hidden"
                                 [key]="'id'"
                                 [value]="'name'">
        </app-filter-options-view>
      </div>
    </div>
    <div class="col">

      <div *ngIf="showNewTable">
        <ag-grid-angular style="height: calc(100vh - 50px);"
                         class="ag-theme-quartz"
                         [rowData]="paginatedData"
                         [columnDefs]="settings.columns"
                         [suppressMultiSort]="true"
                         animateRows="true">
        </ag-grid-angular>
      </div>

      <div *ngIf="!showNewTable" id="issue-summary-columns" style="">
        <div class="row" style="padding: 0px 10px 0px 10px; margin-right: 27px;">
          <div (click)="changeOrder(col.columnName)" *ngFor="let col of this.settings.columns" class="divLink" [ngClass]="col.widthCol ? col.widthCol : ''" [style.width]="col.widthPx ? col.widthPx : ''">

            <div [class.text-center]="col.icon" [ngSwitch]="col.template">

              <div *ngSwitchCase="'statusBadge' || 'statusIcon' || 'issueCount'">
                <p style="color: #9f9f9f; font-size: 16px"><i [ngClass]="'fas fa-traffic-light'"></i>
                  <i *ngIf="settings.sortByColumn == col.columnName && settings.sortByAsc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
                  <i *ngIf="settings.sortByColumn == col.columnName && settings.sortByAsc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
                </p>
              </div>

              <div *ngSwitchCase="'issueCount'">
                <p style="color: #9f9f9f; font-size: 16px"><i [ngClass]="col.icon"  [matTooltip]="col.friendlyName" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>
                  <i *ngIf="settings.sortByColumn == col.columnName && settings.sortByAsc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
                  <i *ngIf="settings.sortByColumn == col.columnName && settings.sortByAsc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
                </p>
              </div>

              <div *ngSwitchDefault>
                <p style="color: #9f9f9f; font-size: 16px">{{col.friendlyName}}
                  <i *ngIf="settings.sortByColumn == col.columnName && settings.sortByAsc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
                  <i *ngIf="settings.sortByColumn == col.columnName && settings.sortByAsc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
                </p>
              </div>

          </div>
        </div>
      </div>


      <div style="height: calc(100vh - {{settings.topMargin}}px); overflow-y: auto; border-right: solid 1px #d7d7d7; margin-right: 10px">
        <div *ngFor="let row of this.paginatedData"
             [routerLink]="getDynamicLink(row)" [queryParams]="getQueryParams(row)"
             (click)="click(row)"
             class="divLink sectionBox row"
             style="padding: 8px 10px!important;font-size: 12px;">
          <div *ngFor="let col of this.settings.columns" [ngClass]="col.widthCol ? col.widthCol : ''" [style.width]="col.widthPx ? col.widthPx : ''">

            <div [ngSwitch]="col.template">

              <div *ngSwitchCase="'amTimeAgo'">
                <p class="m-0">{{(row[col.columnName]) ? (row[col.columnName] | amTimeAgo) : ''}}</p>
              </div>

              <div *ngSwitchCase="'statusBadge'">
                  <div class="statusBadge m-0" [ngClass]="sharedFunctions.getStatusColor(row[col.columnName])"></div>
              </div>

              <div *ngSwitchCase="'statusIcon'">
                <span *ngIf="row[col.columnName] == 1 || row[col.columnName] == null" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>
                <span *ngIf="row[col.columnName] == 2" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>
                <span *ngIf="row[col.columnName] == 3" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i></span>
                <span *ngIf="row[col.columnName] == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i></span>
              </div>

              <div *ngSwitchCase="'appRiskLevel'">
                <div *ngIf="row[col.columnName] == null" class="appStatusBadge green m-0 p-l-5 p-r-5">No Risk</div>
                <div *ngIf="row[col.columnName] == 1" class="appStatusBadge amber m-0 p-l-5 p-r-5">Low</div>
                <div *ngIf="row[col.columnName] == 2" class="appStatusBadge amberred m-0 p-l-5 p-r-5">Medium</div>
                <div *ngIf="row[col.columnName] == 3" class="appStatusBadge red m-0 p-l-5 p-r-5">High</div>
                <div *ngIf="row[col.columnName] == 4" class="appStatusBadge darkRed m-0 p-l-5 p-r-5">Very High</div>
              </div>

              <div *ngSwitchCase="'default'">
                <p class="m-0 text-shorten">
                  {{row[col.columnName]}}
                </p>
              </div>
              <div *ngSwitchCase="'bytes'">
                <p class="m-0 text-shorten">
                  {{this.sharedFunctions.formatBytes(row[col.columnName])}}
                </p>
              </div>
              <div *ngSwitchCase="'titleCase'">
                <p class="m-0 text-shorten">
                  {{row[col.columnName] | titlecaseString}}
                </p>
              </div>
              <div *ngSwitchCase="'numberBadge'">
                <span class="m-0 text-shorten numberBadge">
                  {{row[col.columnName]}}
                </span>
              </div>
              <div *ngSwitchCase="'percentBadge'">
                <span *ngIf="row[col.columnName] != null" class="m-0 text-shorten numberBadge" [ngClass]="{
         'green': row[col.columnName] <= 75,
         'amber': row[col.columnName] > 75 && row[col.columnName] <= 90,
         'red': row[col.columnName] > 90
       }">
                  {{row[col.columnName]}} %
                </span>
              </div>
              <div *ngSwitchCase="'percentBadgePatch'">
  <span *ngIf="row[col.columnName] != null" class="m-0 text-shorten numberBadge" [ngClass]="{
       'green': row[col.columnName] == 100,
       'amber': row[col.columnName] > 90 && row[col.columnName] < 100,
       'red': row[col.columnName] <= 90
     }">
    {{row[col.columnName]}} %
  </span>
              </div>
              <div *ngSwitchCase="'percent'">
                <p class="m-0 text-shorten">
                  {{row[col.columnName]}}%
                </p>
              </div>

              <div *ngSwitchCase="'ticketIdUrl'">
                <p class="m-0 text-shorten">
                  <a *ngIf="row[col.columnName]" (click)="$event.stopPropagation()" [href]="row.ticket_url">
                  {{row[col.columnName]}} <i class="fas fa-external-link"></i>
                  </a>
                </p>
              </div>

              <div *ngSwitchCase="'issue_type'">
                <span class="dashboard-problematic-label">{{row.intune_metric_name}}    <span class="dashboard-problematic-tag">{{row.intune_metric_type | titlecaseString }}</span></span>
              </div>

              <div *ngSwitchCase="'issueCount'">
                <span *ngIf="row[col.statusColumn] == 1 || row[col.statusColumn] == null" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>
                <span *ngIf="row[col.statusColumn] == 2" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>
                <span *ngIf="row[col.statusColumn] == 3" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i>
<!--                  {{row[col.columnName]}}-->
                </span>
                <span *ngIf="row[col.statusColumn] == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i>
<!--                  {{row[col.columnName]}}-->
                </span>
              </div>

              <div *ngSwitchCase="'statusLabel'">
                <span *ngIf="row[col.columnName] != null" style="font-size: 12px; border: none;" class="m-0 text-shorten numberBadge" [ngClass]="{
         'grey': row[col.statusColumn] == 1,
         'green': row[col.statusColumn] == 2,
         'amber': row[col.statusColumn] == 3,
         'red': row[col.statusColumn] == 4
       }">
                  {{row[col.columnName]}}
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
        <div style="margin-top: 7px">
          <span (click)="previousPage()" class="divLink" style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">
            <i class="fas fa-chevron-left" aria-hidden="true"></i>
          </span>
          <span style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">Page {{currentPage}} of {{totalPages}}</span>
          <span (click)="nextPage()" class="divLink" style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">
            <i class="fas fa-chevron-right" aria-hidden="true"></i>
          </span>


          <span (click)="sharedFunctions.exportToCsv('export.csv', this.data)" class="divLink" style="font-size: 12px; margin-top:-3px; float:right; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">
            Export as CSV
          </span>


        </div>

    </div>
  </div>




</div>
</div>
