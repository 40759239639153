import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {AuthenticationService} from "../authentication/authentication.service";
import {Router} from "@angular/router";
import {saveAs} from "file-saver";
import {ToastrService} from "ngx-toastr";
import {CustomerSelectorService} from '../customer-selector/customer-selector.service';


@Injectable({
  providedIn: 'root',
})
export class ApiService {

  // The loaded version of SDesk
  private loadedVersion: string;

  // if forceRefresh is true, then a message should appear asking the user to hard-refresh
  // If the loaded version (JS Version) does not match the back-end version (Sent via header) then the portal has been released/upgraded during this session
  private forceRefresh: boolean;

  constructor(private http: HttpClient, private authService: AuthenticationService, private toastr: ToastrService, private customerSelectorService: CustomerSelectorService) {
    this.loadedVersion = '{VersionNumber}'; // this will be replaced when compiling SDesk
    this.forceRefresh = false;
  }

  // the main component will call this to determine if refresh modal needs to be shown
  public isRefreshNeeded() {
    return this.forceRefresh;
  }

  public get(uri, params?, returnBody = true, responseType: "json" | "arraybuffer" | "blob" | "text" = "json") {
    return this.newHttpRequest('GET', uri, null, params, returnBody, responseType);
  }

  public post(uri, body?, params?, responseType: "json" | "arraybuffer" | "blob" | "text" = "json") {
    return this.newHttpRequest('POST', uri, body, params, true, responseType);
  }

  public put(uri, body?, params?) {
    return this.newHttpRequest('PUT', uri, body, params);
  }

  public patch(uri, body?, params?) {
    return this.newHttpRequest('PATCH', uri, body, params);
  }

  public delete(uri, body?, params?) {
    return this.newHttpRequest('DELETE', uri, body, params);
  }

  public download(uri, method, body?, params?) {
    this.newHttpRequest(method, uri, body, params, false, "arraybuffer").then(response => {
      let fileName = response.headers.get('x-filename');
      let contentType = response.headers.get('content-type');
      let data = response.body;

      const blob = new Blob([data], {type: contentType});

      saveAs(blob, fileName);
    })
  }

  private newHttpRequest(type, uri, body?, params?, returnBody = true, responseType: "json" | "arraybuffer" | "blob" | "text" = "json") {
    return new Promise<any>((resolve, reject) => {
      this.http.request(type, uri, {
        observe: 'response',
        headers: this.getHttpRequestHeaders(),
        params: params,
        responseType: responseType,
        body: body
      }).subscribe(response => {

        //this.toastr.success('Successful API Call', uri);


        // Request was successful
        console.log(`Calling ${uri}: Successful Status code: ${response.status}`);

        const apiVersion = response.headers.get('SDeskVersion');

        if (this.loadedVersion != apiVersion) {
          this.forceRefresh = true;
        }

        if (returnBody) {
          resolve(<any>response.body);
        } else {
          resolve(<any>response);
        }

      }, error => {
        // Request failed
        console.error(`Calling ${uri}: Failed with Error Code: ${error.status}`);
        console.error(error);

        // Genuine error message sent from SDesk

        if (error.status == 400) {
          // ToDo: We want to show 400 errors to the user, popup ?
          const errorMessage = error?.error.error;

          this.toastr.warning(errorMessage, 'Error');

        } else if (error.status == 401) {
          // User is not authenticated, token expired or account has been disabled
          // Remove cached auth token
          // Redirect to login page
          this.authService.removeAuthTokenAndRedirectToLogin();
        } else {
          this.toastr.error(`${error.status} Error calling Eido platform.`, 'Error');
        }


        if (returnBody) {
          reject(<any>error?.error);
        } else {
          reject(<any>error);
        }
      })
    });
  }

  private getHttpRequestHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${this.authService.returnAuthToken()}`);

    if (this.customerSelectorService.selectedCustomerId) {
      console.log("Adding X-CustomerId header: " + this.customerSelectorService.selectedCustomerId);
      headers = headers.append('X-CustomerId', this.customerSelectorService.selectedCustomerId.toString());
    }

    return headers;
  }

}
