import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OptionsService} from '../../../../services/options-service/options.service';
import {Dialog} from '../../../../shared/dialog/dialog';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-intune-choose-permissions',
  templateUrl: './intune-choose-permissions.component.html',
  styleUrl: './intune-choose-permissions.component.css'
})
export class IntuneChoosePermissionsComponent implements Dialog {
  @Input() data = "";
  @Output() remove = new EventEmitter<any>();

  constructor(public optionsService: OptionsService) { }

  ngOnInit(): void {
  }

  save(permission:string){
      this.remove.emit(permission);
  }

  close() {
    this.remove.emit(null);
  }
}
