import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {ActivatedRoute} from '@angular/router';
import {NavigationApiService} from '../../services/api/navigation-api/navigation-api.service';
import {OptionsService} from '../../services/options-service/options.service';
import {PortalSettingsService} from '../../services/portal-settings/portal-settings.service';
import {TemplatesApiService} from '../../services/api/templates/templates-api.service';
import {PolicyDialogComponent} from '../policies/policy-dialog/policy-dialog.component';
import {TemplateEditDialogComponent} from './template-edit/template-edit-dialog.component';
import {TemplateDeployDialogComponent} from './template-deploy-dialog/template-deploy-dialog.component';
import {ModalConfirmationComponent} from '../../shared/modal-confirmation/modal-confirmation.component';
import {TemplateViewDialogComponent} from './template-view/template-view-dialog.component';

@Component({
  selector: 'app-policies',
  templateUrl: './templates.component.html',
  styleUrl: './templates.component.css'
})
export class TemplatesComponent implements OnInit{
  public isLoading = true;

  // Filter blade settings
  public data = [];
  public filteredData = [];
  public filterOptions = [
    {columnName: 'policy_type_name', friendlyName: 'Policy Type', isExpanded: true, showAllCheckbox: true, showSearchOption: false, template: 'none'},
  ];
  public filterQueryParams = [];

  // Grid Settings
  public gridSettings = {
    actionButtons: ['View', 'Edit', 'Deploy'],
    multiSelect: false,
    multiSelectActions: ['Delete'],
    columns: [
      { field: 'policy_name', headerName: 'Name', checkboxSelection: true, headerCheckboxSelection: true, },
      { field: 'policy_type_name', headerName: 'Policy Type' },
      { field: 'deployments', headerName: 'Deployments' },
      { field: 'configDrifts', headerName: 'Config Drifts' },
      { field: 'behind', headerName: 'Policies Behind Latest Version' },
      { field: 'created_at', headerName: 'Created', cellRenderer: 'amTimeAgoRenderer' },
      { field: 'updated_at', headerName: 'Last Updated', cellRenderer: 'amTimeAgoRenderer' }
    ]
  }


  constructor(private templatesApiService: TemplatesApiService, private dialogService: DialogService, private viewReference: ViewContainerRef,
              private route: ActivatedRoute, private navApi: NavigationApiService, public options: OptionsService, private portalSettings: PortalSettingsService) {

    this.getData();
  }

  ngOnInit(): void {

  }

  public rowClicked(data:any) {
    this.dialogService.createDialog(PolicyDialogComponent, data.eido_policy_id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })

  }

  public actionClicked(data:any) {

    if(data.action === 'View') {
      console.log(data);
      // Replace null with Component
      this.dialogService.createDialog(TemplateViewDialogComponent, data.rowData.policy_template_id, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

    if(data.action === 'Edit') {
      console.log(data);
      // Replace null with Component
      this.dialogService.createDialog(TemplateEditDialogComponent, data.rowData.policy_template_id, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

    if(data.action === 'Deploy') {
      console.log(data);
      // Replace null with Component

      // prepare payload
      let payload = {
        template_id: data.rowData.policy_template_id,
        policy_uuid: null,
        eido_intune_config_id: null
      }

      this.dialogService.createDialog(TemplateDeployDialogComponent, payload, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

  }

  public multiSelectAction(data:any) {
    if(data.action === 'Delete') {
      console.log(data);
      this.dialogService.createDialog(ModalConfirmationComponent, `Are you sure you want to delete ${data.selectedRows.length} records?`, this.viewReference)
        .then(confirmed => {
          // data.rowData.forEach(row => {
          //   this.templatesApiService.delete(row.policy_template_id).then(response => this.getData())
          // })

          // delete but return promises and getdata after all promises
          let deletePromises = [];

          data.selectedRows.forEach(row => {
            let deletePromise = this.templatesApiService.delete(row.policy_template_id);
            deletePromises.push(deletePromise);
          });

          Promise.all(deletePromises).then(response => {
            this.getData();
          });

        }, cancelled => null)

    }

  }


  getData() {
    this.isLoading = true;

    this.templatesApiService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    });

  }
}
