import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {DeviceApiService} from '../../services/api/device/device-api.service';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {ActivatedRoute} from '@angular/router';
import {NavigationApiService} from '../../services/api/navigation-api/navigation-api.service';
import {OptionsService} from '../../services/options-service/options.service';
import {PortalSettingsService} from '../../services/portal-settings/portal-settings.service';
import moment from 'moment';
import {PolicyDialogComponent} from '../policies/policy-dialog/policy-dialog.component';
import {DeviceDetailsComponent} from '../device-details/device-details.component';
import {DeviceDetailsBladeComponent} from '../device-details-blade/device-details-blade.component';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrl: './devices.component.css'
})
export class DevicesComponent implements OnInit{

  public isLoading = true;
  public data = [];
  public superTableSettings;

  constructor(private deviceApiService: DeviceApiService, private dialogService: DialogService, private viewReference: ViewContainerRef,
              private route: ActivatedRoute, private navApi: NavigationApiService, public options: OptionsService, private portalSettings: PortalSettingsService) {

    this.getData();
  }

  ngOnInit(): void {

    this.superTableSettings = {
      topMargin: 85,
      // dataLink: '/devices',
      // dataLinkColumns: ['device_id'],
      filterOptions: [
        {columnName: 'status_name', friendlyName: 'Device Status', isExpanded: true, showAllCheckbox: false, showSearchOption: false, template: 'status', showCount:true},
        {columnName: 'customer_name', friendlyName: 'Customer', isExpanded: false, showAllCheckbox: true, showSearchOption: true, template: 'default', hidden:true, showCount:true},
        {columnName: 'intune_ownership', friendlyName: 'Ownership', isExpanded: false, showAllCheckbox: true, showSearchOption: true, template: 'titleCase', hidden:true, showCount:true},
        {columnName: 'intune_os_name', friendlyName: 'Operating System', isExpanded: false, showAllCheckbox: true, showSearchOption: true, template: 'default', hidden:true, showCount:true},
        {columnName: 'intune_device_category', friendlyName: 'Device Category', isExpanded: false, showAllCheckbox: true, showSearchOption: true, template: 'default', hidden:true, showCount:true},
        {columnName: 'intune_compliance_status_name', friendlyName: 'Compliance Status', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'status', hidden:true, showCount:true },
        {columnName: 'intune_config_status_name', friendlyName: 'Config Status', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'status', hidden:true, showCount:true },
        {columnName: 'intune_app_status_name', friendlyName: 'App Status', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'status', hidden:true, showCount:true },
        {columnName: 'intune_patch_status_name', friendlyName: 'OS Patch Status', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'status', hidden:true, showCount:true },
        {columnName: 'intune_manufacturer_name', friendlyName: 'Device Manufacturer', isExpanded: false, showAllCheckbox: true, showSearchOption: true, template: 'default', hidden:true, showCount:true },
        {columnName: 'intune_model_name', friendlyName: 'Device Model', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:true },
        {columnName: 'intune_is_encrypted', friendlyName: 'Encrypted State', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:true },
        {columnName: 'device_inactive_state', friendlyName: 'Inactive', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount:true },
        {columnName: 'intune_lost_mode', friendlyName: 'Lost Mode', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount:true },

        {columnName: 'azure_display_name', friendlyName: 'Primary User', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:false },
        {columnName: 'azure_office_location', friendlyName: 'User > Office', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:true },
        {columnName: 'azure_city', friendlyName: 'User > City', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:false },
        // {columnName: 'azure_company_name', friendlyName: 'User - Company Name', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true },
        {columnName: 'azure_country', friendlyName: 'User > Country', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:false },
        {columnName: 'azure_department', friendlyName: 'User > Department', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:true },
        {columnName: 'azure_state', friendlyName: 'User > State', isExpanded: false, showAllCheckbox: false, showSearchOption: true, template: 'default', hidden:true, showCount:false },
      ],
      columns: [
        {columnName: 'status', friendlyName: ' ', isSortable: true, template: 'statusBadge', widthPx: '50px'},
        {columnName: 'customer_name', friendlyName: 'Customer', isSortable: true, template: 'default', widthCol: 'col'},
        {columnName: 'intune_user_display_name', friendlyName: 'User', isSortable: true, template: 'default', widthCol: 'col'},
        {columnName: 'computer_name', friendlyName: 'Device', isSortable: true, template: 'default', widthCol: 'col-2'},
        {columnName: 'intune_last_sync', friendlyName: 'Intune Last Sync', isSortable: true, template: 'amTimeAgo', widthCol: 'col'},
        {columnName: 'compliance_issues', icon: 'fas fa-shield-check', friendlyName: 'Compliance', isSortable: true, template: 'issueCount', widthPx:'60px', statusColumn: 'intune_compliance_status'},
        {columnName: 'config_issues', icon: 'fas fa-cog', friendlyName: 'Config Policies', isSortable: true, template: 'issueCount', widthPx: '60px', statusColumn: 'intune_config_status'},
        {columnName: 'app_issues', icon: 'fas fa-browser', friendlyName: 'Managed Apps', isSortable: true, template: 'issueCount', widthPx: '60px', statusColumn: 'intune_app_status'},
        {columnName: 'patch_issues', icon: 'fas fa-box-up', friendlyName: 'OS Patch Status', isSortable: true, template: 'issueCount', widthPx: '60px', statusColumn: 'intune_patch_status'},
      ],
      sortByColumn: 'status',
      sortByAsc: true
    };
  }


  getData() {
    this.deviceApiService.allDevices().then(response => {
      console.time('DevicesComponent.getData()');
      this.data = response;
      this.isLoading = false;
      console.timeEnd('DevicesComponent.getData()');
      console.log('DevicesComponent.getData() response done');
    });

  }

  rowClicked(rowData:any) {
    console.log('policy clicked', JSON.stringify(rowData));

    // Replace null with Component
    this.dialogService.createDialog(DeviceDetailsBladeComponent, rowData.device_id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })

  }
}
