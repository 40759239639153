<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading && !isLoadingTenants" size="blade-large">

  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedData.policy_name}}</h3>
  </div>
  <hr>

  <div *ngIf="templateDeployInProgress && !templateDeployFailed">
    <h5>Deploying Template to Intune...</h5>
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div *ngIf="templateDeployFailed">
    <h5>Deployment Failed</h5>

    <div style="padding: 10px;" class="alert alert-danger" role="alert">
      <i class="fas fa-exclamation-triangle m-r-5"></i> {{templateDeployFailedErrorPayload.error}}
    </div>

  </div>

  <div *ngIf="!templateDeployInProgress">



  <div *ngIf="isSuccessful" style="margin: 20px;background-color: #82a996;padding: 9px;font-size: 13px; color:white;margin-top: 0px;border-radius: 5px;">
    This template has been successfully deployed to the tenant. Please login to Intune and set the assignments.
  </div>

  <div *ngIf="!isSuccessful" class="row m-10">
    <div class="col-sm-3 formHeader">
      Select Intune Tenant
    </div>
    <div class="col-lg-4">
      <select [(ngModel)]="selectedTenantId" class="form-control" aria-label="Default select example">
        <option value="0">Select a Tenant</option>
        <option *ngFor="let tenant of tenants" [value]="tenant.id">{{ tenant.intune_tenant_friendly_name }}</option>
      </select>
    </div>
  </div>

    <div *ngIf="!isSuccessful" class="row m-10">
      <div class="col-sm-3 formHeader">
        Select Template Version
      </div>
      <div class="col-lg-4">
        <select [(ngModel)]="selectedTemplateVersionId" (ngModelChange)="policyVersionChanged()" class="form-control" aria-label="Default select example">
          <option value="0">Select a version</option>
          <option *ngFor="let version of loadedData.versions" [value]="version.policy_template_version_id">{{ version.version_no }} <span *ngIf="version.version_no == loadedData.latest_version_no">(Latest)</span></option>
        </select>
      </div>
    </div>

  <hr>


  <div *ngIf="!isSuccessful" class="p-0">

    <div class="row">
      <h4 style="padding-left:10px ;">Review Policy
        <button (click)="toggleEdit()" type="button" class="btn btn-light-grey btn-small active float-end">Toggle Edit</button>

      </h4>

      <span style="margin-left:10px ; margin-bottom:10px" *ngIf="this.editorOptions.readOnly"><i class="fas fa-lock m-r-5"></i> Policy is read only</span>
      <span style="margin-left:10px ; margin-bottom:10px" *ngIf="!this.editorOptions.readOnly"><i class="fas fa-edit m-r-5"></i>You can now edit the policy before deploying</span>

      <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="policyData" style="height: 55vh;"></ngx-monaco-editor>

      <!--      Show json valid or not valid-->
      <div *ngIf="!isPolicyJsonValid()" style="padding: 10px;" class="alert alert-danger" role="alert">
        <i class="fas fa-exclamation-triangle m-r-5"></i> The JSON is not valid. Please correct the JSON before deploying.
      </div>

      <!--      Show json valid or not valid-->
      <div *ngIf="isJsonOverridden()" style="padding: 10px;" class="alert alert-danger" role="alert">
        <i class="fas fa-exclamation-triangle m-r-5"></i> Warning: The JSON has been edited and differs from the original template. Please review your changes before deploying.
      </div>

      <div *ngIf="isPolicyJsonValid()" style="padding: 10px;" class="alert alert-success" role="alert">
        <i class="fas fa-check m-r-5"></i> The JSON is valid.
      </div>


<!--      <pre style="margin:10px; padding:10px  ; background: #add8e638;">{{ policyData | jsonFormat }}</pre>-->
    </div>




    <div class="m-t-20">
      <button *ngIf="!isSuccessful" (click)="selectedTenantId ? deploy() : null" type="button" class="btn btn-light-grey active float-end" [class.disabled]="!selectedTenantId || !isPolicyJsonValid() || templateDeployInProgress">Deploy Template</button>

      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>
  </div>

  </div>
</app-blade-right>
