import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AgentSoftwareDownloadApiService {

  private apiUrl = '/api/agent-software';

  constructor(private api: ApiService) { }

  public getSoftwareDownloadUrls(){
    return this.api.get(`${this.apiUrl}`);
  }

}
