<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>
<h1 *ngIf="isLoading"></h1>


<div *ngIf="!isLoading">
  <div class="row">
    <div class="col">



      <ag-grid-angular style="height: calc(100vh - {{settings.topMargin}}px);"
        class="ag-theme-quartz"
        [rowData]="data"
        [columnDefs]="columns"
                       [defaultColDef]="defaultColDef"
                       [suppressMultiSort]="true"
                       (sortChanged)="sortchanged($event)"
                       [sortingOrder]="sortingOrder"
                       animateRows="false"
                       serverSideSortAllLevels="false"
                       serverSideEnableClientSideSort="false"
      >
      </ag-grid-angular>

      <div style="margin-top: 7px">
        <span (click)="previousPage()" class="divLink" style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">
          <i class="fas fa-chevron-left" aria-hidden="true"></i>
        </span>
        <span style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">Page {{currentPage}} of {{totalPages}}</span>
        <span (click)="nextPage()" class="divLink" style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">
          <i class="fas fa-chevron-right" aria-hidden="true"></i>
        </span>


        <span *ngIf="showExportCsv" (click)="sharedFunctions.exportToCsv('export.csv', this.data)" class="divLink" style="font-size: 12px; margin-top:-3px; float:right; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">
          Export as CSV
        </span>


      </div>




<!--      <div id="issue-summary-columns" >-->

<!--        <div style=" overflow-x: auto; white-space: nowrap;">-->
<!--          <div style="padding: 0px 10px 0px 10px; margin-right: 27px;">-->
<!--            <div (click)="changeOrder(col.columnName)" *ngFor="let col of this.settings.columns" class="divLink" style="min-width: 350px; display: inline-block" [ngClass]="col.widthCol ? col.widthCol : ''" [style.width]="col.widthPx ? col.widthPx : ''">-->

<!--              <div [class.text-center]="col.icon" [ngSwitch]="col.template">-->

<!--                <div *ngSwitchCase="'statusBadge' || 'statusIcon' || 'issueCount'">-->
<!--                  <p style="color: #9f9f9f; font-size: 16px"><i [ngClass]="'fas fa-traffic-light'"></i>-->
<!--                    <i *ngIf="this.sortByColumn == col.columnName && this.sortByAsc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>-->
<!--                    <i *ngIf="this.sortByColumn == col.columnName && this.sortByAsc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>-->
<!--                  </p>-->
<!--                </div>-->

<!--                <div *ngSwitchCase="'issueCount'">-->
<!--                  <p style="color: #9f9f9f; font-size: 16px"><i [ngClass]="col.icon"  [matTooltip]="col.friendlyName" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>-->
<!--                    <i *ngIf="this.sortByColumn == col.columnName && this.sortByAsc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>-->
<!--                    <i *ngIf="this.sortByColumn == col.columnName && this.sortByAsc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>-->
<!--                  </p>-->
<!--                </div>-->

<!--                <div *ngSwitchDefault>-->
<!--                  <p style="color: #9f9f9f; font-size: 16px">{{col.friendlyName}}-->
<!--                    <i *ngIf="this.sortByColumn == col.columnName && this.sortByAsc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>-->
<!--                    <i *ngIf="this.sortByColumn == col.columnName && this.sortByAsc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>-->
<!--                  </p>-->
<!--                </div>-->

<!--            </div>-->
<!--          </div>-->
<!--          </div>-->

<!--          <div style="padding: 0px 10px 0px 10px; margin-right: 27px; height: calc(100vh - {{settings.topMargin}}px); overflow-y: auto; overflow-x:hidden; display: inline-block; ">-->
<!--            <div *ngFor="let row of this.data"-->
<!--                 (click)="click(row)"-->
<!--                 class="divLink sectionBox"-->
<!--                 style="padding: 8px 10px!important;font-size: 12px; display: flex;">-->
<!--              <div *ngFor="let col of this.settings.columns" style="width: 350px; " [ngClass]="col.widthCol ? col.widthCol : ''" [style.width]="col.widthPx ? col.widthPx : ''">-->

<!--                <div [ngSwitch]="col.template">-->

<!--                  <div *ngSwitchCase="'amTimeAgo'">-->
<!--                    <p class="m-0">{{(row[col.columnName]) ? (row[col.columnName] | amTimeAgo) : ''}}</p>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'statusBadge'">-->
<!--                    <div class="statusBadge m-0" [ngClass]="sharedFunctions.getStatusColor(row[col.columnName])"></div>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'statusIcon'">-->
<!--                    <span *ngIf="row[col.columnName] == 1 || row[col.columnName] == null" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>-->
<!--                    <span *ngIf="row[col.columnName] == 2" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>-->
<!--                    <span *ngIf="row[col.columnName] == 3" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i></span>-->
<!--                    <span *ngIf="row[col.columnName] == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i></span>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'appRiskLevel'">-->
<!--                    <div *ngIf="row[col.columnName] == null" class="appStatusBadge green m-0 p-l-5 p-r-5">No Risk</div>-->
<!--                    <div *ngIf="row[col.columnName] == 1" class="appStatusBadge amber m-0 p-l-5 p-r-5">Low</div>-->
<!--                    <div *ngIf="row[col.columnName] == 2" class="appStatusBadge amberred m-0 p-l-5 p-r-5">Medium</div>-->
<!--                    <div *ngIf="row[col.columnName] == 3" class="appStatusBadge red m-0 p-l-5 p-r-5">High</div>-->
<!--                    <div *ngIf="row[col.columnName] == 4" class="appStatusBadge darkRed m-0 p-l-5 p-r-5">Very High</div>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'default'">-->
<!--                    <p class="m-0 text-shorten">-->
<!--                      {{row[col.columnName]}}-->
<!--                    </p>-->
<!--                  </div>-->
<!--                  <div *ngSwitchCase="'bytes'">-->
<!--                    <p class="m-0 text-shorten">-->
<!--                      {{this.sharedFunctions.formatBytes(row[col.columnName])}}-->
<!--                    </p>-->
<!--                  </div>-->
<!--                  <div *ngSwitchCase="'titleCase'">-->
<!--                    <p class="m-0 text-shorten">-->
<!--                      {{row[col.columnName] | titlecaseString}}-->
<!--                    </p>-->
<!--                  </div>-->
<!--                  <div *ngSwitchCase="'numberBadge'">-->
<!--                    <span class="m-0 text-shorten numberBadge">-->
<!--                      {{row[col.columnName]}}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div *ngSwitchCase="'percentBadge'">-->
<!--                    <span *ngIf="row[col.columnName] != null" class="m-0 text-shorten numberBadge" [ngClass]="{-->
<!--             'green': row[col.columnName] <= 75,-->
<!--             'amber': row[col.columnName] > 75 && row[col.columnName] <= 90,-->
<!--             'red': row[col.columnName] > 90-->
<!--           }">-->
<!--                      {{row[col.columnName]}} %-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div *ngSwitchCase="'percentBadgePatch'">-->
<!--      <span *ngIf="row[col.columnName] != null" class="m-0 text-shorten numberBadge" [ngClass]="{-->
<!--           'green': row[col.columnName] == 100,-->
<!--           'amber': row[col.columnName] > 90 && row[col.columnName] < 100,-->
<!--           'red': row[col.columnName] <= 90-->
<!--         }">-->
<!--        {{row[col.columnName]}} %-->
<!--      </span>-->
<!--                  </div>-->
<!--                  <div *ngSwitchCase="'percent'">-->
<!--                    <p class="m-0 text-shorten">-->
<!--                      {{row[col.columnName]}}%-->
<!--                    </p>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'ticketIdUrl'">-->
<!--                    <p class="m-0 text-shorten">-->
<!--                      <a *ngIf="row[col.columnName]" (click)="$event.stopPropagation()" [href]="row.ticket_url">-->
<!--                        {{row[col.columnName]}} <i class="fas fa-external-link"></i>-->
<!--                      </a>-->
<!--                    </p>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'issue_type'">-->
<!--                    <span class="dashboard-problematic-label">{{row.intune_metric_name}}    <span class="dashboard-problematic-tag">{{row.intune_metric_type | titlecaseString }}</span></span>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'issueCount'">-->
<!--                    <span *ngIf="row[col.statusColumn] == 1 || row[col.statusColumn] == null" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>-->
<!--                    <span *ngIf="row[col.statusColumn] == 2" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>-->
<!--                    <span *ngIf="row[col.statusColumn] == 3" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i>-->
<!--                      &lt;!&ndash;                  {{row[col.columnName]}}&ndash;&gt;-->
<!--                    </span>-->
<!--                    <span *ngIf="row[col.statusColumn] == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i>-->
<!--                      &lt;!&ndash;                  {{row[col.columnName]}}&ndash;&gt;-->
<!--                    </span>-->
<!--                  </div>-->

<!--                  <div *ngSwitchCase="'statusLabel'">-->
<!--                    <span *ngIf="row[col.columnName] != null" style="font-size: 12px; border: none;" class="m-0 text-shorten numberBadge" [ngClass]="{-->
<!--             'grey': row[col.statusColumn] == 1,-->
<!--             'green': row[col.statusColumn] == 2,-->
<!--             'amber': row[col.statusColumn] == 3,-->
<!--             'red': row[col.statusColumn] == 4-->
<!--           }">-->
<!--                      {{row[col.columnName]}}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->


<!--&lt;!&ndash;          <div style="height: calc(100vh - {{settings.topMargin}}px); overflow-y: auto; overflow-x:none; border-right: solid 1px #d7d7d7; margin-right: 10px; ">&ndash;&gt;-->
<!--&lt;!&ndash;            <div *ngFor="let row of this.data"&ndash;&gt;-->
<!--&lt;!&ndash;                 (click)="click(row)"&ndash;&gt;-->
<!--&lt;!&ndash;                 class="divLink sectionBox"&ndash;&gt;-->
<!--&lt;!&ndash;                 style="padding: 8px 10px!important;font-size: 12px; display: flex;">&ndash;&gt;-->
<!--&lt;!&ndash;              <div *ngFor="let col of this.settings.columns" style="min-width: 200px; " [ngClass]="col.widthCol ? col.widthCol : ''" [style.width]="col.widthPx ? col.widthPx : ''">&ndash;&gt;-->

<!--&lt;!&ndash;                <div [ngSwitch]="col.template">&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'amTimeAgo'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="m-0">{{(row[col.columnName]) ? (row[col.columnName] | amTimeAgo) : ''}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'statusBadge'">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="statusBadge m-0" [ngClass]="sharedFunctions.getStatusColor(row[col.columnName])"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'statusIcon'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.columnName] == 1 || row[col.columnName] == null" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.columnName] == 2" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.columnName] == 3" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i></span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.columnName] == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i></span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'appRiskLevel'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div *ngIf="row[col.columnName] == null" class="appStatusBadge green m-0 p-l-5 p-r-5">No Risk</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div *ngIf="row[col.columnName] == 1" class="appStatusBadge amber m-0 p-l-5 p-r-5">Low</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div *ngIf="row[col.columnName] == 2" class="appStatusBadge amberred m-0 p-l-5 p-r-5">Medium</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div *ngIf="row[col.columnName] == 3" class="appStatusBadge red m-0 p-l-5 p-r-5">High</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div *ngIf="row[col.columnName] == 4" class="appStatusBadge darkRed m-0 p-l-5 p-r-5">Very High</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'default'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="m-0 text-shorten">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{row[col.columnName]}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div *ngSwitchCase="'bytes'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="m-0 text-shorten">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{this.sharedFunctions.formatBytes(row[col.columnName])}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div *ngSwitchCase="'titleCase'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="m-0 text-shorten">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{row[col.columnName] | titlecaseString}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div *ngSwitchCase="'numberBadge'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="m-0 text-shorten numberBadge">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{row[col.columnName]}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div *ngSwitchCase="'percentBadge'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.columnName] != null" class="m-0 text-shorten numberBadge" [ngClass]="{&ndash;&gt;-->
<!--&lt;!&ndash;             'green': row[col.columnName] <= 75,&ndash;&gt;-->
<!--&lt;!&ndash;             'amber': row[col.columnName] > 75 && row[col.columnName] <= 90,&ndash;&gt;-->
<!--&lt;!&ndash;             'red': row[col.columnName] > 90&ndash;&gt;-->
<!--&lt;!&ndash;           }">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{row[col.columnName]}} %&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div *ngSwitchCase="'percentBadgePatch'">&ndash;&gt;-->
<!--&lt;!&ndash;      <span *ngIf="row[col.columnName] != null" class="m-0 text-shorten numberBadge" [ngClass]="{&ndash;&gt;-->
<!--&lt;!&ndash;           'green': row[col.columnName] == 100,&ndash;&gt;-->
<!--&lt;!&ndash;           'amber': row[col.columnName] > 90 && row[col.columnName] < 100,&ndash;&gt;-->
<!--&lt;!&ndash;           'red': row[col.columnName] <= 90&ndash;&gt;-->
<!--&lt;!&ndash;         }">&ndash;&gt;-->
<!--&lt;!&ndash;        {{row[col.columnName]}} %&ndash;&gt;-->
<!--&lt;!&ndash;      </span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div *ngSwitchCase="'percent'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="m-0 text-shorten">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{row[col.columnName]}}%&ndash;&gt;-->
<!--&lt;!&ndash;                    </p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'ticketIdUrl'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <p class="m-0 text-shorten">&ndash;&gt;-->
<!--&lt;!&ndash;                      <a *ngIf="row[col.columnName]" (click)="$event.stopPropagation()" [href]="row.ticket_url">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{row[col.columnName]}} <i class="fas fa-external-link"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--&lt;!&ndash;                    </p>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'issue_type'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="dashboard-problematic-label">{{row.intune_metric_name}}    <span class="dashboard-problematic-tag">{{row.intune_metric_type | titlecaseString }}</span></span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'issueCount'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.statusColumn] == 1 || row[col.statusColumn] == null" class="issueCountBadge grey m-0"><i class="fas fa-question"></i></span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.statusColumn] == 2" class="issueCountBadge green m-0"><i class="fas fa-check"></i></span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.statusColumn] == 3" class="issueCountBadge amber m-0"><i class="fas fa-exclamation-triangle"></i>&ndash;&gt;-->
<!--&lt;!&ndash;    &lt;!&ndash;                  {{row[col.columnName]}}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.statusColumn] == 4" class="issueCountBadge red m-0"><i class="fas fa-exclamation-triangle"></i>&ndash;&gt;-->
<!--&lt;!&ndash;    &lt;!&ndash;                  {{row[col.columnName]}}&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->

<!--&lt;!&ndash;                  <div *ngSwitchCase="'statusLabel'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span *ngIf="row[col.columnName] != null" style="font-size: 12px; border: none;" class="m-0 text-shorten numberBadge" [ngClass]="{&ndash;&gt;-->
<!--&lt;!&ndash;             'grey': row[col.statusColumn] == 1,&ndash;&gt;-->
<!--&lt;!&ndash;             'green': row[col.statusColumn] == 2,&ndash;&gt;-->
<!--&lt;!&ndash;             'amber': row[col.statusColumn] == 3,&ndash;&gt;-->
<!--&lt;!&ndash;             'red': row[col.statusColumn] == 4&ndash;&gt;-->
<!--&lt;!&ndash;           }">&ndash;&gt;-->
<!--&lt;!&ndash;                      {{row[col.columnName]}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->

<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div style="margin-top: 7px">-->
<!--          <span (click)="previousPage()" class="divLink" style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">-->
<!--            <i class="fas fa-chevron-left" aria-hidden="true"></i>-->
<!--          </span>-->
<!--          <span style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">Page {{currentPage}} of {{totalPages}}</span>-->
<!--          <span (click)="nextPage()" class="divLink" style="font-size: 12px; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">-->
<!--            <i class="fas fa-chevron-right" aria-hidden="true"></i>-->
<!--          </span>-->


<!--          <span (click)="sharedFunctions.exportToCsv('export.csv', this.data)" class="divLink" style="font-size: 12px; margin-top:-3px; float:right; background-color: #5555551c; padding: 5px 10px; border-radius: 10px; margin-right: 10px;">-->
<!--            Export as CSV-->
<!--          </span>-->


<!--        </div>-->

<!--    </div>-->
  </div>




</div>
</div>
