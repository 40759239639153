import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {OptionsService} from '../../services/options-service/options.service';
import * as moment from 'moment/moment';
import {SharedFunctionsService} from '../../services/shared-functions/shared-functions.service';
import {ActivatedRoute} from '@angular/router';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {DeviceDetailsBladeComponent} from '../../portal/device-details-blade/device-details-blade.component';

@Component({
  selector: 'app-eido-super-table',
  templateUrl: './eido-super-table.component.html',
  styleUrl: './eido-super-table.component.css'
})
export class EidoSuperTableComponent implements OnInit {

  searchTerm = '';
  private debounceTimer?: number; // Store the timeout reference

  @Input() hideFilter = false;
  @Input() data = [];
  @Input() showNewTable = false;
  @Input() settings = {
    topMargin: 25,
    onClickOpen: null,
    dataLink: '/devices/',
    dataLinkColumns: ['device_id', 'intune_metric_type'],
    dataLinkSuperTableQueryFilterColumn:['device_id'],
    filterOptions: [
      {columnName: 'id', friendlyName: 'id', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'default',  hidden:false, showCount:false}
    ],
    columns: [
      {columnName: 'id', friendlyName: 'id', isSortable: true, template: 'default', widthCol: 'col-2', widthPx: null, statusColumn: '', icon:''}
    ],
    sortByColumn: '',
    sortByAsc: true
  };

  @Input() filterWidthClass = 'col-xl-2 col-4'

  @Output() rowClicked = new EventEmitter();

  filteredData = [];
  isLoading = false;

  public appliedFilters = {}

  public options = [];

  private queryParamFilters = [];


  // Pagination
  public rowsPerPage = 100;
  public currentPage = 1;
  public totalPages = 1;
  public paginatedData: any[] = [];

  constructor(public optionsService: OptionsService, public sharedFunctions: SharedFunctionsService, private cdr: ChangeDetectorRef, private dialogService: DialogService, private viewReference: ViewContainerRef) {
  }

  // ngOnChanges(){
  //
  //   console.log('ngOnChanges called for eido-super-table');
  //
  //   this.generateOptionsAndFilterData();
  //   //this.appliedFilters = {}
  //
  //
  // }

  ngOnInit(): void {
    this.generateOptionsAndFilterData();
    //this.isLoading = false;
  }

  generateOptionsAndFilterData(){
    //this.generateOptions();
    this.filterData();
  }

  searchChanged(): void {
    if (this.debounceTimer) clearTimeout(this.debounceTimer); // Clear previous timeout
    this.debounceTimer = window.setTimeout(() => {
      this.filterData(); // Call the actual search function after the delay
    }, 500); // Delay in milliseconds, adjust as needed
  }

  public filterData() {
    console.time('Total FilterData Function Time');

    // Start filtering process
    console.time('Initialization Time');
    console.trace('Starting Filtering data...');
    this.filteredData = [];
    let generatedData = this.data;
    console.timeEnd('Initialization Time');

    // Apply filters
    console.time('Applying Filters Time');
    this.settings.filterOptions.forEach(filterOption => {
      generatedData = generatedData.filter(row => {
        if (!this.appliedFilters[filterOption.columnName]) return true;
        if (this.appliedFilters[filterOption.columnName] === null) return true;
        if (this.appliedFilters[filterOption.columnName].length == 0) return false;
        if (this.appliedFilters[filterOption.columnName].includes(null) && (row[filterOption.columnName] == null || !row[filterOption.columnName])) return true;

        let rowValueLowerCase = row[filterOption.columnName] ? row[filterOption.columnName].toString().toLowerCase() : null;
        let filterValuesLowerCase = this.appliedFilters[filterOption.columnName].map(value => value ? value.toString().toLowerCase() : null);

        return filterValuesLowerCase.includes(rowValueLowerCase);
      });
    });
    console.timeEnd('Applying Filters Time');

    // Assign filtered data
    console.time('Assigning Filtered Data Time');
    this.filteredData = generatedData;
    console.timeEnd('Assigning Filtered Data Time');

    // Search data
    console.time('Searching Data Time');
    if (this.searchTerm) {
      this.filteredData = this.filteredData.filter(row => {
        return Object.values(row).some(value => {
          if (value == null) return false;
          return value.toString().toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      });
    }
    console.timeEnd('Searching Data Time');

    // Sort data
    console.time('Sorting Data Time');
    this.sortChanged();
    console.timeEnd('Sorting Data Time');

    // Paginate data
    console.time('Paginating Data Time');
    this.paginateData(true);
    console.timeEnd('Paginating Data Time');

    // // Change detection
    // console.time('Change Detection Time');
    // this.cdr.detectChanges(); // Uncomment if needed
    // console.timeEnd('Change Detection Time');

    // Generate options
    console.time('Generating Options Time');
    this.generateOptions();
    console.timeEnd('Generating Options Time');

    console.log('Finished Filtering data...');
    console.timeEnd('Total FilterData Function Time');

    // Remove this line if change detection is not needed in the future - It can slow it down a bit..
    this.cdr.detectChanges();
  }

  paginateData(resetPage = false) {
    console.time('paginateData Time');

    if (resetPage) {
      this.currentPage = 1;
    }
    const startIndex = (this.currentPage - 1) * this.rowsPerPage;
    const endIndex = startIndex + this.rowsPerPage;
    this.paginatedData = this.filteredData.slice(startIndex, endIndex);

    this.totalPages = Math.ceil(this.filteredData.length / this.rowsPerPage);
    console.timeEnd('paginateData Time');
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginateData();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginateData();
    }
  }

  public changeOrder(sortColumn: string) {
    console.log('Calling ChangeOrder, ordering by: ' + sortColumn);
    if (this.settings.sortByColumn == sortColumn) {
      this.settings.sortByAsc = !this.settings.sortByAsc;
      //this.sortChanged();

      this.filteredData = this.filteredData.reverse();

      //this.sortData();
    } else {
      this.settings.sortByColumn = sortColumn;
      this.sortChanged();
    }

    this.paginateData(true);
  }

  public sortChanged(){
    console.time('sortChanged Function Time');

    this.sharedFunctions.sortArrayOfObjects(this.filteredData, this.settings.sortByColumn, this.settings.sortByAsc);

    console.timeEnd('sortChanged Function Time');

  }

  public generateOptions() {

    let newOptions = [];

    this.settings.filterOptions.forEach(filterOption => {
      let uniqueValues = new Map(); // Use a Map to keep track of unique, case-insensitive values

      this.data.forEach(object => {
        const value = object[filterOption.columnName];
        if(value != null && value !== '' && value !== undefined) {
          const lowerCaseValue = value.toString().toLowerCase(); // Convert value to lowercase for case-insensitive comparison
          if(!uniqueValues.has(lowerCaseValue)) {
            uniqueValues.set(lowerCaseValue, value); // Store the original case-sensitive value
          }
        }
      });

      // Clear the options array for this column and start again
      newOptions[filterOption.columnName] = [];

      // For each unique value, create an option in filterOptions.ColumnName
      uniqueValues.forEach((originalValue, _) => {
        const originalValueStr = originalValue?.toString();
        newOptions[filterOption.columnName].push({
          id: originalValue,
          name: originalValue,
          count: filterOption.showCount ? this.filteredData.filter(data => data[filterOption.columnName]?.toString().toLowerCase() == originalValueStr.toLowerCase()).length : null
        });
      });

      // Add an "Unassigned" option for null or empty values
      if(this.data.filter(data => data[filterOption.columnName] == null || !data[filterOption.columnName]).length > 0) {
        newOptions[filterOption.columnName].push({
          id: null,
          name: "Unassigned",
          count: filterOption.showCount ? this.filteredData.filter(data => data[filterOption.columnName] == null || !data[filterOption.columnName]).length : null
        });
      }

      // Sort options by count
      newOptions[filterOption.columnName].sort((a, b) => {
        return b.count - a.count; // Simplified sorting by count
      });
    });

    this.options = newOptions;

    //this.cdr.detectChanges();

    console.log('Options Generated: '+this.options);
  }

  public filterChanged(filterName, filterValues){

    console.log('filterChanged: '+filterName+' '+filterValues);

    this.appliedFilters[filterName] = filterValues;

    console.log(this.appliedFilters)

    this.filterData();
  }

  public getDynamicLink(row: any): string {

    if(!this.settings.dataLink){
      return null;
    }

    // Start with the base link, ensuring it starts with '/'
    let link = this.settings.dataLink.startsWith('/') ? this.settings.dataLink : '/' + this.settings.dataLink;

    // Append additional segments based on dataLinkColumns
    this.settings.dataLinkColumns.forEach(column => {
      if (row[column] !== undefined) {
        link += '/' + row[column];
      }
    });

    return link;
  }

  public getQueryParams(row: any): object {
    let queryParams = {};
    if (this.settings.dataLinkSuperTableQueryFilterColumn) {
      this.settings.dataLinkSuperTableQueryFilterColumn.forEach(column => {
        if (row[column] !== undefined) {
          queryParams[`filter_${column}`] = row[column];
        }
      });
    }

    return queryParams;
  }

  public click(row: any){

    if(this.settings.onClickOpen == 'device') {
        this.dialogService.createDialog(DeviceDetailsBladeComponent, row.device_id, this.viewReference);
        return;
    }

    console.log('Row clicked: '+row);
    this.rowClicked.emit(row);
  }



}
