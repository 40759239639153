// number-badge-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-number-badge-renderer',
  standalone: true,
  template: `<span class="m-0 text-shorten numberBadge">{{number}}</span>`,
  styles: [`
    .numberBadge { /* Add your styles here */ }
  `]
})
export class NumberBadgeRendererComponent implements ICellRendererAngularComp {
  public number: any;

  agInit(params: any): void {
    this.number = params.value;
  }

  refresh(params: any): boolean {
    this.number = params.value;
    return true;
  }
}
