import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import * as moment from 'moment';
import {OptionsService} from '../../services/options-service/options.service';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css']
})
export class IssuesComponent implements OnInit {

  @Input() view = 'default'; // default | recent

  constructor(private apiService: ApiService, public optionsService: OptionsService) { }

  data = [];
  isLoading = true;

  filter_onlyShowOpenIssues = 1;
  filter_showIssuesDate = '24h';

  filter_dateTo = null;

  public superTableSettings;

  ngOnInit(): void {

    this.superTableSettings = {
      topMargin: 140,
      onClickOpen: 'device',
      filterOptions: [
        {columnName: 'severity_name', friendlyName: 'Status', isExpanded: true, showAllCheckbox: false, showSearchOption: false, template: 'status', showCount: true},
        {columnName: 'customer_name', friendlyName: 'Customer', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'default', showCount: true},
        {columnName: 'intune_metric_type', friendlyName: 'Issue Type', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'titleCase', showCount: true},
        {columnName: 'intune_metric_name', friendlyName: 'Policy', isExpanded: false, showAllCheckbox: true, showSearchOption: true, template: 'default', showCount: true},
        {columnName: 'computer_name', friendlyName: 'Device', isExpanded: false, showAllCheckbox: true, showSearchOption: true, template: 'default', showCount: false},

        {columnName: 'azure_display_name', friendlyName: 'Primary User', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount: false },
        {columnName: 'azure_office_location', friendlyName: 'User > Office', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount: true },
        {columnName: 'azure_city', friendlyName: 'User > City', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount: true },
        // {columnName: 'azure_company_name', friendlyName: 'User - Company Name', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true },
        {columnName: 'azure_country', friendlyName: 'User > Country', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount: true },
        {columnName: 'azure_department', friendlyName: 'User > Department', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount: true },
        {columnName: 'azure_state', friendlyName: 'User > State', isExpanded: false, showAllCheckbox: false, showSearchOption: false, template: 'default', hidden:true, showCount: true },
      ],
      columns: [
        {columnName: 'id', friendlyName: '#', isSortable: true, template: 'default', widthPx: '75px'},
        {columnName: 'severity', friendlyName: ' ', isSortable: true, template: 'statusBadge', widthPx: '50px'},
        {columnName: 'customer_name', friendlyName: 'Customer', isSortable: true, template: 'default', widthCol: 'col-2'},
        {columnName: 'computer_name', friendlyName: 'Device', isSortable: true, template: 'default', widthCol: 'col-2'},
        {columnName: 'issue_type', friendlyName: 'Issue Type', isSortable: true, template: 'issue_type', widthCol: 'col-4'},
        // {columnName: 'intune_metric_type', friendlyName: 'Policy Type', isSortable: true, template: 'default', widthCol: 'col-2'},
        // {columnName: 'intune_metric_name', friendlyName: 'Policy', isSortable: true, template: 'default', widthCol: 'col-2'},
        {columnName: 'start_date', friendlyName: 'Start', isSortable: true, template: 'amTimeAgo', widthCol: 'col'},
        {columnName: 'end_date', friendlyName: 'End', isSortable: true, template: 'amTimeAgo', widthCol: 'col'},
        {columnName: 'ticket_id', friendlyName: 'Ticket #', isSortable: true, template: 'ticketIdUrl', widthCol: 'col'},

      ],
      sortByColumn: 'start_date',
      sortByAsc: true
    };

    this.refreshData();
    this.switchDates();
  }

  refreshData(){
    this.isLoading = true;
    this.apiService.get("/api/issues", {'filter_onlyShowOpenIssues' : this.filter_onlyShowOpenIssues, 'filter_dateTo' : this.filter_dateTo?.toISOString()}).then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  switchDates(){

    if(this.filter_onlyShowOpenIssues == 1){
      this.filter_dateTo = undefined;
    } else {
      switch(this.filter_showIssuesDate) {
        case "24h":
          this.filter_dateTo = moment().subtract(24, 'hours').toDate();
          break;
        case "7d":
          this.filter_dateTo = moment().subtract(7, 'days').toDate();

          break;
        case "30d":
          this.filter_dateTo = moment().subtract(30, 'days').toDate();

          break;
        case "90d":
          this.filter_dateTo = moment().subtract(90, 'days').toDate();
          break;
        default:
      }
    }
  }


}
