<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Alert</h3>
  </div>
  <hr>


  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Friendly Name
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.alert_name" type="text" class="form-control" id="Alert_Name" placeholder="Name" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Alert Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <select [(ngModel)]="loadedData.alert_type" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.alertTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
    </div>

<!--    <div class="row">-->
<!--      <div class="col-sm-3 formHeader alignMiddle p-10">-->
<!--        Customer-->
<!--      </div>-->
<!--      <div class="col-sm p-10">-->
<!--        <select [(ngModel)]="loadedData.customer_id" class="form-control" aria-label="Default select example">-->
<!--          <option [value]="null" >All Customers</option>-->
<!--          <option *ngFor="let customer of optionsService.customers" [value]="customer.id">{{ customer.customer_name }}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->


    <div *ngIf="loadedData.alert_type == 'email'">
      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Email <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.email_address" type="text" class="form-control" id="Email" placeholder="Email Address" autocomplete="off">
        </div>
      </div>
    </div>

    <div *ngIf="loadedData.alert_type == 'webhook' || loadedData.alert_type == 'slack' || loadedData.alert_type == 'teams'">
      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Webhook URL <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.webhook_url" type="text" class="form-control" id="Webhook URL" placeholder="Webhook URL" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Webhook Authorization Header Required
        </div>
        <div class="col-sm p-10">
          <div class="form-check form-switch form-switch-md">
            <input [(ngModel)]="loadedData.webhook_auth_header_required"  class="form-check-input" type="checkbox" id="webhook_auth_header_required" checked>
            <label class="form-check-label" for="webhook_auth_header_required" style="margin-top: 5px;">

            </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="loadedData.webhook_auth_header_required">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Header Secret
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.webhook_auth_header" type="password" class="form-control" id="webhook_auth_header" placeholder="" autocomplete="off">
        </div>
      </div>
    </div>

    <div *ngIf="loadedData.alert_type == 'servicenow'">
      <hr>
      <h5 class="p-10">ServiceNow Settings</h5>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          URL <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.servicenow_url" type="text" class="form-control" id="servicenow_url" placeholder="e.g: https://yourinstance.service-now.com/api/now/table/incident" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Username <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.servicenow_username" type="text" class="form-control" id="servicenow_username" placeholder="admin" autocomplete="off">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Password <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.servicenow_password" type="password" class="form-control" id="servicenow_password" placeholder="" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Use Device Primary user as ServiceNow Caller
        </div>
        <div class="col-sm">
          <div class="form-check form-switch form-switch-md">
            <input [(ngModel)]="loadedData.servicenow_use_device_primary_user_caller"  class="form-check-input" type="checkbox" id="servicenow_use_device_primary_user_caller" checked>
            <label class="form-check-label" for="webhook_auth_header_required" style="margin-top: 5px;">

            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Fallback Caller Email <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.servicenow_fallback_caller_email" type="text" class="form-control" id="servicenow_fallback_caller_email" placeholder="e.g: intunealerts@company.com" autocomplete="off">
        </div>
      </div>


      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Short Description Template <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.servicenow_short_desc_template" type="text" class="form-control" id="servicenow_short_desc_template" placeholder="" autocomplete="off">
        </div>
      </div>



      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Description Template <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <textarea [(ngModel)]="loadedData.servicenow_desc_template" class="form-control" id="servicenow_desc_template" name="servicenow_desc_template" rows="4" cols="50"></textarea>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Assignment Group Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.servicenow_assignment_group_name" type="text" class="form-control" id="servicenow_assignment_group_name" placeholder="e.g: First Line" autocomplete="off">
        </div>
      </div>


      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Assign To Agent Email
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.servicenow_assign_to_email" type="text" class="form-control" id="servicenow_assign_to_email" placeholder="e.g: engineer1@company.com" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Impact
        </div>
        <div class="col-sm ">
          <input [(ngModel)]="loadedData.servicenow_impact" type="text" class="form-control" id="servicenow_impact" placeholder="e.g: 2 - Medium" autocomplete="off">
        </div>
      </div>


      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Urgency
        </div>
        <div class="col-sm ">
          <input [(ngModel)]="loadedData.servicenow_urgency" type="text" class="form-control" id="servicenow_urgency" placeholder="e.g: 1 - High" autocomplete="off">
        </div>
      </div>

    </div>

    <div *ngIf="loadedData.alert_type == 'sdesk'">
      <hr>
      <h5 class="p-10">sDesk Settings</h5>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Webhook URL <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.sdesk_webhook_url" type="text" class="form-control" id="sdesk_webhook_url" placeholder="e.g: https://yourinstance.sdesk.co.uk/webhook/3494ccif9u493dfeijwrw3d" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Use Device Primary user as sDesk Requester
        </div>
        <div class="col-sm">
          <div class="form-check form-switch form-switch-md">
            <input [(ngModel)]="loadedData.sdesk_use_device_primary_user_requester"  class="form-check-input" type="checkbox" id="sdesk_use_device_primary_user_requester" checked>
            <label class="form-check-label" for="webhook_auth_header_required" style="margin-top: 5px;">

            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Short Description Template <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.sdesk_short_desc_template" type="text" class="form-control" id="sdesk_short_desc_template" placeholder="" autocomplete="off">
        </div>
      </div>



      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Description Template <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <textarea [(ngModel)]="loadedData.sdesk_desc_template" class="form-control" id="sdesk_desc_template" name="sdesk_desc_template" rows="4" cols="50"></textarea>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Assignment Group Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.sdesk_group_name" type="text" class="form-control" id="sdesk_group_name" placeholder="e.g: Intune Team" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Status Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm">
          <input [(ngModel)]="loadedData.sdesk_status_name" type="text" class="form-control" id="sdesk_status_name" placeholder="e.g: Open" autocomplete="off">
        </div>
      </div>


      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Impact
        </div>
        <div class="col-sm ">
          <input [(ngModel)]="loadedData.sdesk_impact_name" type="text" class="form-control" id="sdesk_impact_name" placeholder="e.g: Medium" autocomplete="off">
        </div>
      </div>


      <div class="row">
        <div class="col-sm-5 formHeader alignMiddle p-10">
          Urgency
        </div>
        <div class="col-sm ">
          <input [(ngModel)]="loadedData.sdesk_urgency_name" type="text" class="form-control" id="sdesk_urgency_name" placeholder="e.g: High" autocomplete="off">
        </div>
      </div>

    </div>





    <hr>
    <h5 class="p-10">Alert On</h5>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Severity
      </div>
      <div class="col-sm p-10">
        <select [(ngModel)]="loadedData.alert_severity" class="form-control" data-style="btn-white">
          <option *ngFor="let option of this.alertSeverities" [label]="option.name" [value]="option.id" selected="selected"></option>
        </select>
      </div>
    </div>


    <p class="p-10 formHeader ">Intune</p>

    <div class="row">

<!--      <div class="col-sm-4 p-10">-->
<!--        <input [(ngModel)]="loadedData.alert_on_intune_device_added" class="form-check-input" id="alert_on_intune_device_added" type="checkbox">-->
<!--        <label for="alert_on_intune_device_added" class="p-l-10">New Device Discovered</label>-->
<!--      </div>-->
      <div class="col-sm-4 p-10">
        <input [(ngModel)]="loadedData.alert_on_intune_compliance" class="form-check-input" id="alert_on_intune_compliance_issues" type="checkbox">
        <label for="alert_on_intune_compliance_issues" class="p-l-10">Compliance Issues</label>
      </div>
      <div class="col-sm-4 p-10">
        <input [(ngModel)]="loadedData.alert_on_intune_config" class="form-check-input" id="alert_on_intune_config_policy_issues" type="checkbox">
        <label for="alert_on_intune_config_policy_issues" class="p-l-10">Config Policy Issues</label>
      </div>
      <div class="col-sm-4 p-10">
        <input [(ngModel)]="loadedData.alert_on_intune_app" class="form-check-input" id="alert_on_intune_managed_app_install_issue" type="checkbox">
        <label for="alert_on_intune_managed_app_install_issue" class="p-l-10">Managed App Issues</label>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
