<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>



<div *ngIf="!isLoading" class="row">
  <div>
    <h3>Devices</h3>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button [class.active]="this.filter_selectedStatus.isSelected(2)" (click)="this.filter_selectedStatus.toggle(2);this.filter_selectedStatus.toggle(1)" type="button" class="btn btn-light-grey">
        Green
      </button>

      <button [class.active]="this.filter_selectedStatus.isSelected(3)" (click)="this.filter_selectedStatus.toggle(3)" type="button" class="btn btn-light-grey">
        Amber
      </button>

      <button [class.active]="this.filter_selectedStatus.isSelected(4)" (click)="this.filter_selectedStatus.toggle(4)" type="button" class="btn btn-light-grey">
        Red
      </button>

    </div>

    <app-eido-multi-select *ngIf="portalSettings.showMspFeatures();" class="m-l-20" [data]="this.options.customers" [filterName]="'Customers'" [clearFilterOptionName]="'All Customers'" [selectedData]="[]" (selectedDataChange)="filter_customerIds = $event" [label]="'customer_name'" [key]="'id'" [multiSelect]="false"></app-eido-multi-select>
  </div>

  <div class="row m-t-10">
    <div class="col-6 row">
      <div (click)="changeOrder('logged_on_user')" class="col-3 p-5 divLink">
        <h4 style="color: #bdbdbd;">USER
          <i *ngIf="orderBy == 'logged_on_user' && orderDesc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
          <i *ngIf="orderBy == 'logged_on_user' && orderDesc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
        </h4>
      </div>
      <div (click)="changeOrder('computer_name')" class="col-6 p-5 divLink">
        <h4 style="color: #bdbdbd;margin-left: -5px;">COMPUTER
          <i *ngIf="orderBy == 'computer_name' && orderDesc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
          <i *ngIf="orderBy == 'computer_name' && orderDesc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
        </h4>
      </div>

      <div (click)="changeOrder('intune_last_sync')" class="col-3 p-5 divLink" [matTooltip]="'Last time this device synchronised with Intune'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'">
        <h4 style="color: #bdbdbd;">LAST SYNC

          <i *ngIf="orderBy == 'intune_last_sync' && orderDesc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
          <i *ngIf="orderBy == 'intune_last_sync' && orderDesc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
        </h4>
      </div>

    </div>


    <div class="col row">

      <div (click)="changeOrder('compliance_issues')" class="text-center divLink col">
        <h4 style="color: #bdbdbd;">COMPLIANCE POLICIES

          <i *ngIf="orderBy == 'compliance_issues' && orderDesc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
          <i *ngIf="orderBy == 'compliance_issues' && orderDesc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
        </h4>
      </div>

      <div (click)="changeOrder('config_issues')" class="text-center divLink col">
        <h4 style="color: #bdbdbd;">CONFIG POLICIES

          <i *ngIf="orderBy == 'config_issues' && orderDesc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
          <i *ngIf="orderBy == 'config_issues' && orderDesc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
        </h4>
      </div>

      <div (click)="changeOrder('app_issues')" class="text-center divLink col">
        <h4 style="color: #bdbdbd;">MANAGED APPS

          <i *ngIf="orderBy == 'app_issues' && orderDesc == false" class="fa fa-solid fa-chevron-down m-l-5"></i>
          <i *ngIf="orderBy == 'app_issues' && orderDesc == true" class="fa fa-solid fa-chevron-up m-l-5"></i>
        </h4>
      </div>

    </div>

  </div>

  <div  *ngFor="let device of getFilteredDevices()" class="row divLink" style="margin-bottom: 5px">
    <div [routerLink]="['/devices/', device.id]" [queryParams]="{'tab': 'intune'}" role="link" class="col-6">
      <div class="sectionBox hover m-0 m-r-5">
        <div class="row">
          <div class="col-3">
            <h4 class="align-middle">{{device.logged_on_user}}</h4>
          </div>
          <div class="col-6">
            <p class="align-middle">
              <i *ngIf="device.is_online && device.agent_sync_enabled" [matTooltip]="'Online'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="fas fa-circle p-r-5 icon-green" style="font-size: 19px;"></i>
              <i *ngIf="!device.is_online && device.agent_sync_enabled" [matTooltip]="'Offline'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="fas fa-circle p-r-5 icon-grey" style="font-size: 19px;"></i>
              {{device.computer_name}}
            </p>
          </div>
          <div class="col-3">
            <p class="align-middle">
              {{device.intune_last_sync | amTimeAgo}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col row">
      <div [routerLink]="['/devices/', device.id]" [queryParams]="{'tab': 'intune', 'subTab' : 'compliance'}"  class="col rightArrowBox first last step7 m-r-5" [ngClass]="statusColour(device.intune_compliance_status)">
        <span style="font-size: 14px" *ngIf="device.compliance_issues == 1">{{device.compliance_issues}} issue</span>
        <span style="font-size: 14px" *ngIf="device.compliance_issues > 1">{{device.compliance_issues}} issues</span>
      </div>

      <div [routerLink]="['/devices/', device.id]" [queryParams]="{'tab': 'intune', 'subTab' : 'config-policies'}"  class="col rightArrowBox first last step7 m-r-5" [ngClass]="statusColour(device.intune_config_status)">
        <span style="font-size: 14px" *ngIf="device.config_issues == 1">{{device.config_issues}} issue</span>
        <span style="font-size: 14px" *ngIf="device.config_issues > 1">{{device.config_issues}} issues</span>
      </div>

      <div [routerLink]="['/devices/', device.id]" [queryParams]="{'tab': 'intune', 'subTab' : 'managed-apps'}"  class="col rightArrowBox first last step7 m-r-5" [ngClass]="statusColour(device.intune_app_status)">
        <span style="font-size: 14px" *ngIf="device.app_issues == 1">{{device.app_issues}} issue</span>
        <span style="font-size: 14px" *ngIf="device.app_issues > 1">{{device.app_issues}} issues</span>
      </div>
    </div>


  </div>
</div>
