<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading">

  <div class="row">
    <div class="col-2" style="height: calc(100vh - 25px);">
      <app-eido-side-filter [data]="data" [filterQueryParams]="filterQueryParams" [filterOptions]="filterOptions" (dataChanged)="filteredData = $event"></app-eido-side-filter>
    </div>
    <div class="col">

      <app-eido-grid
        [columns]="gridSettings.columns"
        [data]="filteredData"
        [actionButtons]="gridSettings.actionButtons"
        [multiSelect]="true"
        [multiSelectActions]="gridSettings.multiSelectActions"
        (rowClick)="rowClicked($event)"
        (actionButtonClick)="actionClicked($event)"
        (multiSelectAction)="multiSelectAction($event)"

      ></app-eido-grid>
    </div>
  </div>
</div>
