import {Injectable} from '@angular/core';
import {NavigationApiService} from '../api/navigation-api/navigation-api.service';
import {CustomerSelectorService} from '../customer-selector/customer-selector.service';
import {OptionsService} from '../options-service/options.service';

@Injectable({
  providedIn: 'root'
})
export class PortalSettingsService {

  public isLoading = true;

  public permissions;
  public tenantSettings;

  public mspCustomerSwitch: CustomerSelectorService;

  public darkMode = false;



  constructor(private navApi: NavigationApiService, private customerSelector: CustomerSelectorService, private optionsService: OptionsService) {
    this.mspCustomerSwitch = this.customerSelector;

  }

  public refreshPermissionsAndSettings(){
    let promises = [];

    let userPermissions = this.navApi.getUserPermissions().then(response => {
      this.permissions = response;
      console.log("PortalSettingsService: Permissions loaded");
    })

    promises.push(userPermissions);

    let tenantSettings = this.navApi.getTenantSettings().then(response => {
      this.tenantSettings = response;

      if(this.tenantSettings.msp_mode_enabled){
        this.optionsService.loadCustomers();
      }
      console.log("PortalSettingsService: Settings loaded");
    })

    promises.push(tenantSettings);

    Promise.all(promises).then(
      () => {
        console.log("PortalSettingsService: Permissions and Settings loaded")
        this.isLoading = false;
      }
    );

  }

  public showMspFeatures(){
    return this.tenantSettings.msp_mode_enabled && this.mspCustomerSwitch.selectedCustomerId == null;
  }

}
