import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

import {interval} from 'rxjs';
import {DeviceApiService} from '../../services/api/device/device-api.service';
import {delay} from 'rxjs/operators';
import {OfficeApiService} from '../../services/api/office/office-api.service';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {ModalConfirmationComponent} from '../../shared/modal-confirmation/modal-confirmation.component';
import moment from 'moment/moment';
import {IntuneDrillDownModalComponent} from './intune-drill-down-modal/intune-drill-down-modal.component';
import {SharedFunctionsService} from '../../services/shared-functions/shared-functions.service';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.css']
})
export class DeviceDetailsComponent implements OnInit {

  public deviceId: number;
  @Input() deviceIdInput: number;
  data;
  @Output() remove = new EventEmitter<any>();

  public isLoading: boolean = true;

  public currentMetricsTab = 'intune';
  public currentMetricsSubTab = 'details';

  public intuneDeviceData;

  public expandedIntuneL4 = [];

  public discoveredAppsSuperTableSettings = {
    topMargin: 370,
    // dataLink: '/devices',
    // dataLinkColumns: ['device_id'],
    filterOptions: [
      // {columnName: 'ApplicationPublisher', friendlyName: 'Publisher', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'default'},
      // {columnName: 'ApplicationShortVersion', friendlyName: 'ApplicationShortVersion', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'default'},

    ],
    columns: [
      {columnName: 'app_name', friendlyName: 'Application Name', isSortable: true, template: 'default', widthCol: 'col'},
      {columnName: 'version_name', friendlyName: 'Application Version', isSortable: true, template: 'default', widthCol: 'col'},
    ],
    sortByColumn: 'app_name',
    sortByAsc: false
  };

  public intuneDataCategories = [
      {'categoryName': 'Device Properties', 'isExpanded': true},
      {'categoryName': 'Hardware Properties', 'isExpanded': false},
      {'categoryName': 'Enrollment', 'isExpanded': false},
      {'categoryName': 'Advanced Properties', 'isExpanded': false},
      {'categoryName': 'Mobile Only', 'isExpanded': false}
  ];

  public intuneDataSchema = [
    {'field': 'id', 'friendlyName': 'Device ID', 'category': 'Advanced Properties', 'description': 'Unique identifier for the device', 'showTooltip': false},
    {'field': 'imei', 'friendlyName': 'IMEI', 'category': 'Mobile Only', 'description': 'International Mobile Equipment Identity', 'showTooltip': true},
    {'field': 'meid', 'friendlyName': 'MEID', 'category': 'Mobile Only', 'description': 'Mobile Equipment Identifier - MEID (Mobile Equipment Identifier): Similar to the IMEI, it\'s a unique identifier for CDMA phones. It\'s used to identify a device on a network and is mainly found in the U.S. and parts of Asia.\n' +
            '\n', 'showTooltip': true},
    {'field': 'udid', 'friendlyName': 'UDID', 'category': 'Mobile Only', 'description': 'Unique Device Identifier - This is specific to Apple devices. It\'s a unique identifier for iPhones, iPads, and iPod Touches, used by developers and system administrators to identify specific devices.', 'showTooltip': true},
    {'field': 'iccid', 'friendlyName': 'ICCID', 'category': 'Mobile Only', "description": "Integrated Circuit Card Identifier", 'showTooltip': true},
    {'field': 'model', 'friendlyName': 'Model', 'category': 'Hardware Properties', 'description': 'Model of the device', 'showTooltip': false},
    {'field': 'notes', 'friendlyName': 'Notes', 'category': 'Advanced Properties', 'description': 'Additional notes about the device', 'showTooltip': false},
    {'field': 'userId', 'friendlyName': 'User ID', 'category': 'Advanced Properties', 'description': 'Identifier of the user associated with the device', 'showTooltip': false},
    {'field': 'joinType', 'friendlyName': 'Join Type', 'category': 'Enrollment', 'description': 'Type of enrollment used for the device', 'showTooltip': false},
    {'field': 'osVersion', 'friendlyName': 'OS Version', 'category': 'Device Properties', 'description': 'Operating system version of the device', 'showTooltip': false},
    {'field': 'ownerType', 'friendlyName': 'Owner Type', 'category': 'Device Properties', 'description': 'Type of ownership (personal or corporate)', 'showTooltip': true},
    {'field': 'skuFamily', 'friendlyName': 'SKU Family', 'category': 'Hardware Properties', 'description': 'Stock Keeping Unit family', 'showTooltip': false},
    {'field': 'skuNumber', 'friendlyName': 'SKU Number', 'category': 'Hardware Properties', 'description': 'Stock Keeping Unit number', 'showTooltip': false},
    {'field': 'deviceName', 'friendlyName': 'Device Name', 'category': 'Device Properties', 'description': 'Name of the device', 'showTooltip': false},
    {'field': 'deviceType', 'friendlyName': 'Device Type', 'category': 'Hardware Properties', 'description': 'Type of the device (e.g., windowsRT)', 'showTooltip': true},
    {'field': 'jailBroken', 'friendlyName': 'Jail Broken', 'category': 'Advanced Properties', 'description': 'Indicates if the device is jailbroken', 'showTooltip': true},
    {'field': 'chassisType', 'friendlyName': 'Chassis Type', 'category': 'Hardware Properties', 'description': 'Type of device chassis', 'showTooltip': false},
    {'field': 'easDeviceId', 'friendlyName': 'EAS Device ID', 'category': 'Advanced Properties', 'description': 'Exchange ActiveSync Device ID', 'showTooltip': true},
    {'field': 'isEncrypted', 'friendlyName': 'Is Encrypted', 'category': 'Hardware Properties', 'description': 'Indicates if the device is encrypted', 'showTooltip': false},
    {'field': 'phoneNumber', 'friendlyName': 'Phone Number', 'category': 'Mobile Only', 'description': 'Phone number associated with the device', 'showTooltip': false},
    {'field': 'easActivated', 'friendlyName': 'EAS Activated', 'category': 'Advanced Properties', 'description': 'Indicates if Exchange ActiveSync is activated', 'showTooltip': true},
    // {'field': 'emailAddress', 'friendlyName': 'Email Address', 'category': 'Device Properties', 'description': 'Email address associated with the device', 'showTooltip': false},
    {'field': 'isSupervised', 'friendlyName': 'Is Supervised (IOS)', 'category': 'Advanced Properties', 'description': 'Indicates if the device is supervised using Apple (IOS Devices only)', 'showTooltip': true},
    {'field': 'manufacturer', 'friendlyName': 'Manufacturer', 'category': 'Hardware Properties', 'description': 'Manufacturer of the device', 'showTooltip': false},
    {'field': 'serialNumber', 'friendlyName': 'Serial Number', 'category': 'Hardware Properties', 'description': 'Serial number of the device', 'showTooltip': false},
    {'field': 'aadRegistered', 'friendlyName': 'AAD Registered', 'category': 'Enrollment', 'description': 'Indicates if the device is Azure AD registered', 'showTooltip': true},
    {'field': 'lostModeState', 'friendlyName': 'Lost Mode State', 'category': 'Advanced Properties', 'description': 'State of the lost mode feature', 'showTooltip': false},
    {'field': 'wiFiMacAddress', 'friendlyName': 'WiFi MAC Address', 'category': 'Hardware Properties', 'description': 'MAC address of the device’s WiFi', 'showTooltip': false},
    {'field': 'azureADDeviceId', 'friendlyName': 'Azure AD Device ID', 'category': 'Advanced Properties', 'description': 'Azure Active Directory Device ID', 'showTooltip': false},
    {'field': 'complianceState', 'friendlyName': 'Compliance State', 'category': 'Advanced Properties', 'description': 'Compliance state of the device', 'showTooltip': false},
    {'field': 'managementAgent', 'friendlyName': 'Management Agent', 'category': 'Advanced Properties', 'description': 'Type of management agent installed', 'showTooltip': false},
    {'field': 'managementState', 'friendlyName': 'Management State', 'category': 'Advanced Properties', 'description': 'Management state of the device', 'showTooltip': false},
    {'field': 'operatingSystem', 'friendlyName': 'Operating System', 'category': 'Device Properties', 'description': 'Operating system of the device', 'showTooltip': false},
    {'field': 'roleScopeTagIds', 'friendlyName': 'Role Scope Tag IDs', 'category': 'Advanced Properties', 'description': 'Scope tags for role-based access control', 'showTooltip': true},
    // {'field': 'userDisplayName', 'friendlyName': 'User Display Name', 'category': 'Device Properties', 'description': 'Display name of the user associated with the device', 'showTooltip': false},
    {'field': 'enrolledDateTime', 'friendlyName': 'Enrolled Date Time', 'category': 'Enrollment', 'description': 'Date and time the device was enrolled', 'showTooltip': true},
    {'field': 'lastSyncDateTime', 'friendlyName': 'Last Sync Date Time', 'category': 'Advanced Properties', 'description': 'Last synchronization date and time with intune', 'showTooltip': true},
    {'field': 'autopilotEnrolled', 'friendlyName': 'Autopilot Enrolled', 'category': 'Advanced Properties', 'description': 'Indicates if the device is enrolled in Autopilot', 'showTooltip': false},
    {'field': 'azureADRegistered', 'friendlyName': 'Azure AD Registered', 'category': 'Enrollment', 'description': 'Indicates if the device is registered with Azure AD', 'showTooltip': true},
    {'field': 'managedDeviceName', 'friendlyName': 'Managed Device Name', 'category': 'Advanced Properties', 'description': 'Name of the managed device', 'showTooltip': false},
    {'field': 'subscriberCarrier', 'friendlyName': 'Subscriber Carrier', 'category': 'Mobile Only', 'description': 'Mobile subscriber carrier', 'showTooltip': false},
    // {'field': 'userPrincipalName', 'friendlyName': 'User Principal Name', 'category': 'Enrollment', 'description': 'Principal name of the user associated with the device', 'showTooltip': false},
    {'field': 'chromeOSDeviceInfo', 'friendlyName': 'Chrome OS Device Info', 'category': 'Mobile Only', 'description': 'Information specific to Chrome OS devices', 'showTooltip': false},
    {'field': 'ethernetMacAddress', 'friendlyName': 'Ethernet MAC Address', 'category': 'Hardware Properties', 'description': 'MAC address of the device’s Ethernet connection', 'showTooltip': false},
    {'field': 'managementFeatures', 'friendlyName': 'Management Features', 'category': 'Advanced Properties', 'description': 'Features available for device management', 'showTooltip': false},
    {'field': 'deviceActionResults', 'friendlyName': 'Device Action Results', 'category': 'Advanced Properties', 'description': 'Results of actions performed on the device', 'showTooltip': false},
    {'field': 'exchangeAccessState', 'friendlyName': 'Exchange Access State', 'category': 'Advanced Properties', 'description': 'Access state of the device in Exchange', 'showTooltip': true},
    // {'field': 'hardwareInformation', 'friendlyName': 'Hardware Information', 'category': 'Hardware Properties', 'description': 'Detailed hardware information of the device', 'showTooltip': false},
    {'field': 'retireAfterDateTime', 'friendlyName': 'Retire After Date Time', 'category': 'Advanced Properties', 'description': 'Date and time after which the device should be retired', 'showTooltip': true},
    {'field': 'deviceEnrollmentType', 'friendlyName': 'Device Enrollment Type', 'category': 'Enrollment', 'description': 'Type of enrollment used for the device', 'showTooltip': true},
    {'field': 'specificationVersion', 'friendlyName': 'Specification Version', 'category': 'Advanced Properties', 'description': 'Version of the specification used', 'showTooltip': false},
    {'field': 'easActivationDateTime', 'friendlyName': 'EAS Activation Date Time', 'category': 'Advanced Properties', 'description': 'Date and time when Exchange ActiveSync was activated', 'showTooltip': true},
    {'field': 'enrollmentProfileName', 'friendlyName': 'Enrollment Profile Name', 'category': 'Enrollment', 'description': 'Name of the profile used for enrollment', 'showTooltip': true},
    {'field': 'physicalMemoryInBytes', 'friendlyName': 'Physical Memory in Bytes', 'category': 'Hardware Properties', 'description': 'Physical memory of the device in bytes', 'showTooltip': false},
    {'field': 'processorArchitecture', 'friendlyName': 'Processor Architecture', 'category': 'Hardware Properties', 'description': 'Architecture of the device’s processor', 'showTooltip': false},
    {'field': 'bootstrapTokenEscrowed', 'friendlyName': 'Bootstrap Token Escrowed', 'category': 'Advanced Properties', 'description': 'Indicates if the bootstrap token is escrowed', 'showTooltip': false},
    {'field': 'managedDeviceOwnerType', 'friendlyName': 'Managed Device Owner Type', 'category': 'Enrollment', 'description': 'Type of owner for the managed device', 'showTooltip': false},
    {'field': 'deviceRegistrationState', 'friendlyName': 'Device Registration State', 'category': 'Enrollment', 'description': 'Registration state of the device', 'showTooltip': false},
    {'field': 'freeStorageSpaceInBytes', 'friendlyName': 'Free Storage Space in Bytes', 'category': 'Hardware Properties', 'description': 'Amount of free storage space on the device in bytes', 'showTooltip': false},
    {'field': 'activationLockBypassCode', 'friendlyName': 'Activation Lock Bypass Code', 'category': 'Advanced Properties', 'description': 'Code to bypass activation lock', 'showTooltip': false},
    {'field': 'totalStorageSpaceInBytes', 'friendlyName': 'Total Storage Space in Bytes', 'category': 'Hardware Properties', 'description': 'Total storage space on the device in bytes', 'showTooltip': false},
    {'field': 'androidSecurityPatchLevel', 'friendlyName': 'Android Security Patch Level', 'category': 'Mobile Only', 'description': 'Security patch level of the Android device', 'showTooltip': true},
    {'field': 'deviceCategoryDisplayName', 'friendlyName': 'Device Category Display Name', 'category': 'Enrollment', 'description': 'Display name of the device category', 'showTooltip': false},
    {'field': 'exchangeAccessStateReason', 'friendlyName': 'Exchange Access State Reason', 'category': 'Advanced Properties', 'description': 'Reason for the current Exchange access state', 'showTooltip': true},
    {'field': 'windowsActiveMalwareCount', 'friendlyName': 'Windows Active Malware Count', 'category': 'Advanced Properties', 'description': 'Count of active malware on Windows devices', 'showTooltip': true},
    {'field': 'partnerReportedThreatState', 'friendlyName': 'Partner Reported Threat State', 'category': 'Advanced Properties', 'description': 'Threat state reported by partners', 'showTooltip': true},
    // {'field': 'remoteAssistanceSessionUrl', 'friendlyName': 'Remote Assistance Session URL', 'category': 'Advanced Properties', 'description': 'URL for the remote assistance session', 'showTooltip': false},
    {'field': 'azureActiveDirectoryDeviceId', 'friendlyName': 'Azure Active Directory Device ID', 'category': 'Advanced Properties', 'description': 'Azure AD Device ID', 'showTooltip': false},
    // {'field': 'deviceHealthAttestationState', 'friendlyName': 'Device Health Attestation State', 'category': 'Advanced Properties', 'description': 'State of the device’s health attestation', 'showTooltip': false},
    {'field': 'requireUserEnrollmentApproval', 'friendlyName': 'Require User Enrollment Approval', 'category': 'Enrollment', 'description': 'Indicates if user enrollment approval is required', 'showTooltip': false},
    {'field': 'windowsRemediatedMalwareCount', 'friendlyName': 'Windows Remediated Malware Count', 'category': 'Advanced Properties', 'description': 'Count of remediated malware on Windows devices', 'showTooltip': false},
    {'field': 'exchangeLastSuccessfulSyncDateTime', 'friendlyName': 'Exchange Last Successful Sync DateTime', 'category': 'Advanced Properties', 'description': 'Last successful synchronization date and time with Exchange', 'showTooltip': true},
    {'field': 'managementCertificateExpirationDate', 'friendlyName': 'Management Certificate Expiration Date', 'category': 'Advanced Properties', 'description': 'Expiration date of the management certificate', 'showTooltip': false},
    {'field': 'remoteAssistanceSessionErrorDetails', 'friendlyName': 'Remote Assistance Session Error Details', 'category': 'Advanced Properties', 'description': 'Error details for the remote assistance session', 'showTooltip': false},
    {'field': 'configurationManagerClientHealthState', 'friendlyName': 'Configuration Manager Client Health State', 'category': 'Advanced Properties', 'description': 'Health state of the Configuration Manager client', 'showTooltip': false},
    {'field': 'configurationManagerClientInformation', 'friendlyName': 'Configuration Manager Client Information', 'category': 'Advanced Properties', 'description': 'Information about the Configuration Manager client', 'showTooltip': false},
    {'field': 'complianceGracePeriodExpirationDateTime', 'friendlyName': 'Compliance Grace Period Expiration DateTime', 'category': 'Advanced Properties', 'description': 'Expiration date and time of the compliance grace period', 'showTooltip': true},
    {'field': 'preferMdmOverGroupPolicyAppliedDateTime', 'friendlyName': 'Prefer MDM Over Group Policy Applied DateTime', 'category': 'Advanced Properties', 'description': 'Date and time when MDM preference over Group Policy was applied', 'showTooltip': true},
    {'field': 'configurationManagerClientEnabledFeatures', 'friendlyName': 'Configuration Manager Client Enabled Features', 'category': 'Advanced Properties', 'description': 'Enabled features in the Configuration Manager client', 'showTooltip': true},
    {'field': 'deviceFirmwareConfigurationInterfaceManaged', 'friendlyName': 'Device Firmware Configuration Interface Managed', 'category': 'Advanced Properties', 'description': 'Indicates if the device firmware configuration interface is managed', 'showTooltip': true}
  ];

  constructor(private route: ActivatedRoute, private deviceApiService: DeviceApiService, private dialogService: DialogService, private viewReference: ViewContainerRef, public sharedFunctions: SharedFunctionsService) { }


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.deviceId = parseInt(params.get("deviceId"));

      if(!this.deviceId){
        this.deviceId = this.deviceIdInput;
      }

      this.getDeviceStatusInfo();
    })

    this.route.queryParams.subscribe(params => {
      if(params.subTab){
        this.currentMetricsSubTab = params.subTab;
      }
      if(params.app){
        //this.loadApplicationMetrics(params.app);
      }
    });

  }

  toggleCategory(categoryName){
    let category = this.intuneDataCategories.find(x => x.categoryName == categoryName);
    category.isExpanded = !category.isExpanded;
  }

  isDateGreaterThanToday(dateString) {
    if(!dateString){
      return false;
    }

    let date = new Date(dateString);
    return date > new Date();
  }

  getIntuneDrillDown(type){
    return this.data.intune_l4_status.filter(x => x.metric_type == type);
  }

  getIntuneL5DrillDown(type, metric_name){
    return this.data.intune_l5_status.filter(x => x.metric_type == type && x.metric_name == metric_name);
  }

  isL4Expanded(type, metric_name){
    let index = type + '_' + metric_name;

    return this.expandedIntuneL4.includes(index);
  }

  toggleL4Expand(type, metric_name){
    let index = type + '_' + metric_name;

    // add to array if not already in it and remove if already in it
    if(this.expandedIntuneL4.includes(index)) {
      this.expandedIntuneL4.splice(this.expandedIntuneL4.indexOf(index), 1);
    }
    else {
      this.expandedIntuneL4.push(index);
    }


  }


  getStatusColor(status){

    switch (status) {
      case 1:
        return "#a1a1a1";
      case 2:
        return "#95b9a7";
      case 3:
        return "#e8a962";
      case 4:
        return "indianred";
    }
  }

  getDeviceStatusInfo(){
    this.isLoading = true;
    this.deviceApiService.deviceStatus(this.deviceId).then(response => {
      this.data = response;
      this.intuneDeviceData = this.decodeJson(this.data.intune_device_data);
      this.isLoading = false;
    })
  }

  getThreshold(metric_key, colour){
    let threshold = this.data.policy.thresholds.find(policy => policy.metric_key == metric_key);

    if(!threshold){
      return 1000;
    }

    switch (colour) {
      case 'red':
        return threshold.red_threshold;
      case 'amber':
        return threshold.amber_threshold;
    }
  }

  statusColour(statusNumber, showGreyIfDeviceOffline = false){

    if(!this.data.is_online && showGreyIfDeviceOffline){
      return "grey";
    }

    switch (statusNumber) {
      case 1:
        return "grey"
      case 2:
        return "green";
      case 3:
        return "amber";
      case 4:
        return "red";
      default:
        return "grey";
    }
  }

  intuneDaysBetweenDates(date){
    var start = moment(date);
    var end = moment(Date.now());
    return end.diff(start, "days")
  }

  openIntuneDrillDownModal(name){


    this.dialogService.createDialog(IntuneDrillDownModalComponent, name, this.viewReference)
      .then(dialogSaved => {
        }
        , dialogCancelled => {

        })
  }

  decodeJson(string){
    return JSON.parse(string);
  }

}
