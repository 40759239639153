import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class RootApiService {

  apiUri = '/api/root'

  constructor(private apiService: ApiService) {
  }

  get() {
    return this.apiService.get(`${this.apiUri}`);
  }

  getDevices() {
    return this.apiService.get(`${this.apiUri}/devices`);
  }

  getAgentVersions() {
    return this.apiService.get(`${this.apiUri}/agent-versions`);
  }

  switchTenant(id) {
    return this.apiService.post(`${this.apiUri}/switch-tenant/${id}`);
  }
  deploySoftware(id, module) {
    return this.apiService.post(`${this.apiUri}/deploy-agent-version`, null, {"version_id" : id, "module": module});
  }

  deleteTenant(id) {
    return this.apiService.delete(`${this.apiUri}/tenant/${id}`);
  }

}
