import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class BillingApiService {
  apiUri = '/api/billing'

  constructor(private apiService: ApiService) {
  }

  getStripeUrl() {
    return this.apiService.get(`${this.apiUri}/customer-portal`);
  }

}
