import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../services/api/policy/policy-api.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {TemplatesApiService} from '../../../services/api/templates/templates-api.service';
import {IntuneTenantsApiService} from '../../../services/api/admin/intune-tenants/intune-tenants-api.service';


@Component({
  selector: 'app-template-dialog-edit',
  templateUrl: './template-edit-dialog.component.html',
  styleUrls: ['./template-edit-dialog.component.css']
})
export class TemplateEditDialogComponent implements OnInit {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  versionsExpanded = [];


  policyData;
  prettyJson;

  loadedData;

  constructor(
    private templatesApiService: TemplatesApiService, private viewReference: ViewContainerRef, private dialogService: DialogService, private intuneTenantApi: IntuneTenantsApiService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    }
  }

  getData(){
    this.isLoading = true;

    this.templatesApiService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })

  }

  save(){
    this.loadedData.policy_json = this.prettyJson;
    this.templatesApiService.update(this.loadedId, this.loadedData).then(response => {
      this.close();
    })
  }


  close(){
    this.remove.emit(null);
  }

  toggleVersion(version){
    if(this.versionsExpanded.includes(version)){
      this.versionsExpanded = this.versionsExpanded.filter(item => item !== version);
    } else {
      this.versionsExpanded.push(version);
    }
  }

  isVersionExpanded(version){
    return this.versionsExpanded.includes(version);
  }




  protected readonly JSON = JSON;
}
