<div class="row justify-content-md-center">
  <div class="m-t-10 col-md-8 col-lg-4 col-xl-3 loginFormWrapper">
    <img alt="Logo" style="max-height:70px; margin-bottom: 20px" class="mx-auto d-block" src="assets/img/logo_light.svg">


  <div *ngIf="showSuccessMessage" class="text-center">
    <h5 class="">Thanks for signing up.</h5>
    <i class="fas fa-envelope m-t-10" style="font-size: 70px;color: #95b9a7;"></i>
    <h4 class="m-t-10">We have sent you an email with a link to set a password.</h4>

    <div [routerLink]="['/login']" class="btn btn-primary m-t-10">Login</div>


    <p style="margin-top: 40px"><strong>Didn't get our email?</strong> Check your spam / junk folders for e-mails coming from noreply&#64;eido.cloud. Contact us at support&#64;eido.cloud if you have any issues.</p>


  </div>



    <form (ngSubmit)="submit()" *ngIf="!showSuccessMessage">

      <h5 class="text-center">Sign up for a free 30 day trial!</h5>

      <p *ngIf="showFailedValidationMessage" class="text-center" style="border-radius: 10px; background-color: indianred;color: white;padding: 10px;margin-top: 14px;margin-bottom: 10px;font-size: 16px;">Please fill out all required fields!</p>
      <div class="mb-3">
        <label for="emailAddress" style="font-size: 15px;" class="form-label m-b-5">Name<span style="color:indianred"> *</span></label>
        <input [(ngModel)]="data.Name" type="text" class="form-control" id="Name" name="Name" required>
      </div>


      <div class="mb-3">
        <label for="emailAddress" style="font-size: 15px;" class="form-label m-b-5">Email<span style="color:indianred"> *</span></label>
        <input [(ngModel)]="data.EmailAddress" type="email" class="form-control" id="EmailAddress" name="EmailAddress" required>
      </div>

      <div class="mb-3">
        <label for="emailAddress" style="font-size: 15px;" class="form-label m-b-5">Company Name<span style="color:indianred"> *</span></label>
        <input [(ngModel)]="data.CompanyName" type="text" class="form-control" id="CompanyName" name="CompanyName" required>
      </div>


      <div class="d-grid gap-2">
        <button (click)="null" (keyup.enter)="null" type="submit" class="btn btn-primary">Sign Up</button>
      </div>

      <hr>
      <p [routerLink]="['/login']" class="divLink"><i class="fas fa-key m-r-5"></i> Already have an account? Click here to login</p>





    </form>
  </div>
</div>


