import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eidoFilter'
})
export class EidoFilterPipe implements PipeTransform {

  transform(items: any[], searchTerm: string, property: string): any[] {
    if (!items) return [];
    if (!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();

    return items.filter(item => {
      let itemProperty = item[property];
      if (typeof itemProperty === 'string') {
        return itemProperty.toLowerCase().includes(searchTerm);
      } else if (typeof itemProperty === 'number') {
        return itemProperty.toString().includes(searchTerm);
      } else {
        return false;
      }
    });
  }

}
