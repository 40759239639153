<div class="row">
  <div class="col-3">

    <div class="sectionBox p-10" style="
    margin-right: 15px;
    margin-bottom: 10px;
">
      <p style="font-size: 12px;"><i class="fas fa-info-circle m-r-5" style="color: #3a549d;
    font-size: 16px;
    padding-right: 5px;"></i>Insights & Trends is a BETA feature</p></div>

        <div class="row" style="margin-right: 10px">
          <div class="col">
            <div (click)="reportPayload.type = 'trend'; switchReportType()" class="smallTab divLink" [class.active]="reportPayload.type == 'trend'">
              <span class="figure">Trends</span>
            </div>
          </div>
          <div class="col">
            <div (click)="reportPayload.type = 'report'; switchReportType()" class="smallTab divLink" [class.active]="reportPayload.type == 'report'">
              <span class="figure">Report</span>
            </div>
          </div>
        </div>

    <div class="sectionBox" style="height: 85vh; margin-right: 15px">

      <div *ngIf="reportPayload.type == 'report'">
        <p class="m-t-15 insightsHeader">Date </p>
        <div class="p-5">
          <select [(ngModel)]="reportPayload.reportDateSetting" (ngModelChange)="reportDateSettingChanged()" class="form-control m-t-5" aria-label="Default select example">
    <!--        <option value="0">Select a Tenant</option>-->
            <option *ngFor="let type of dateTypes" [value]="type.value">{{ type.viewValue }}</option>
          </select>
        </div>



        <div *ngIf="reportPayload.reportDateSetting == 'custom'">



<!--          <p class="m-l-5">Date From: </p>-->
          <input style="display:none" matInput [(ngModel)]="reportPayload.reportDateFrom" [matDatepicker]="picker">
<!--          <mat-hint>MM/DD/YYYY</mat-hint>-->
<!--          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>-->
          <mat-datepicker touchUi  #picker></mat-datepicker>

<!--          <p class="m-l-5">Date To: </p>-->
          <input style="display:none" matInput [(ngModel)]="reportPayload.reportDateTo" [matDatepicker]="pickerTo">
<!--          <mat-hint>MM/DD/YYYY</mat-hint>-->
<!--          <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>-->
          <mat-datepicker touchUi  #pickerTo></mat-datepicker>



          <div class="row">
            <div class="col">
              <div class="dateLabel divLink" (click)="picker.open()">{{reportPayload.reportDateFrom | date:'yyyy-MM-dd'}}</div>
            </div>
            <div style="width: 50px;text-align: center;padding-top: 5px;font-size: 19px;"><i class="fas fa-arrow-to-right"></i></div>
            <div class="col">
              <div class="dateLabel divLink" (click)="pickerTo.open()">{{reportPayload.reportDateTo | date:'yyyy-MM-dd'}}</div>
            </div>
          </div>



        </div>
      </div>

      <div *ngIf="reportPayload.type == 'trend'">
        <p class="m-t-15 insightsHeader">Period </p>
        <div class="row p-5">
          <div class="col-3">
            <input type="number" [(ngModel)]="reportPayload.trendPeriod" class="form-control m-t-5">
          </div>
          <div style="width:6px"></div>
          <div class="col">
            <select [(ngModel)]="reportPayload.trendBy" (ngModelChange)="switchTrendBy()" class="form-control m-t-5" aria-label="Default select example">
              <option value="month">Months</option>
              <option value="day">Days</option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <p class="m-t-15 insightsHeader">Show </p>

        <div class="insightsSelector">
          <span>Compliance %</span>
          <div class="form-check form-switch form-switch-md float-end">
            <input [(ngModel)]="reportPayload.show.compliance_healthy_percent" (ngModelChange)="toggleShow();"  class="form-check-input" type="checkbox" id="compliance" checked>
            <label class="form-check-label" for="compliance" style="margin-top: 5px;">
            </label>
          </div>
        </div>

        <div class="insightsSelector">
          <span>Config Health %</span>
          <div class="form-check form-switch form-switch-md float-end">
            <input [(ngModel)]="reportPayload.show.config_healthy_percent" (ngModelChange)="toggleShow();"   class="form-check-input" type="checkbox" id="config" checked>
            <label class="form-check-label" for="config" style="margin-top: 5px;">
            </label>
          </div>
        </div>

        <div class="insightsSelector">
          <span>App Health %</span>
          <div class="form-check form-switch form-switch-md float-end">
            <input [(ngModel)]="reportPayload.show.app_healthy_percent" (ngModelChange)="toggleShow();"   class="form-check-input" type="checkbox" id="app" checked>
            <label class="form-check-label" for="app" style="margin-top: 5px;">
            </label>
          </div>
        </div>

        <div class="insightsSelector">
          <span>OS Patch Health %</span>
          <div class="form-check form-switch form-switch-md float-end">
            <input [(ngModel)]="reportPayload.show.patch_healthy_percent" (ngModelChange)="toggleShow();"   class="form-check-input" type="checkbox" id="patch" checked>
            <label class="form-check-label" for="patch" style="margin-top: 5px;">
            </label>
          </div>
        </div>


      </div>

      <div *ngIf="reportPayload.type == 'report'">
        <p class="m-t-15 insightsHeader">Group By </p>
        <div class="p-5">
          <select [(ngModel)]="reportPayload.reportGroupBy" class="form-control m-t-5" aria-label="Default select example">
            <!--        <option value="0">Select a Tenant</option>-->
            <option *ngFor="let type of groupByTypes" [value]="type.value">{{ type.viewValue }}</option>
          </select>
        </div>
      </div>

      <div>
        <p class="m-t-15 insightsHeader">Filters </p>

        <div class="insightsSelector">
          <span>Exclude Amber Issues</span>
          <div class="form-check form-switch form-switch-md float-end">
            <input [(ngModel)]="reportPayload.exclude.amber"  class="form-check-input" type="checkbox" id="excludeAmber" checked>
            <label class="form-check-label" for="excludeAmber" style="margin-top: 5px;">
            </label>
          </div>
        </div>

        <div class="insightsSelector">
          <span>Exclude Red Issues</span>
          <div class="form-check form-switch form-switch-md float-end">
            <input [(ngModel)]="reportPayload.exclude.red"  class="form-check-input" type="checkbox" id="excludeRed" checked>
            <label class="form-check-label" for="excludeRed" style="margin-top: 5px;">
            </label>
          </div>
        </div>

      </div>

      <div class="btn btn-primary" style="width: 100%; margin-top: 20px" (click)="runReport()">Run Report</div>

    </div>
  </div>
  <div class="col">
    <div class="row">
      <div *ngIf="!isLoadingDaysSinceFirstDevice && daysSinceFirstDevice <= 6" class="sectionBox p-20">
        <h5><i class="fas fa-info-circle m-r-5" style="color: #3a549d;
    font-size: 24px;
    padding-right: 5px;"></i>Insights & Trends are not available yet.</h5>
        <p>We're excited to provide you with insights and trends! As you recently signed up, we need a few more days to collect enough data. </p>

        <p> Please check back in <span style="font-size: 22px;background-color: #297d9d59;padding: 0px 9px;border-radius: 5px;font-weight: bold;">{{7 - daysSinceFirstDevice}}</span> days to access this feature.</p>
      </div>
      <div *ngIf="daysSinceFirstDevice > 6">

        <app-loading-spinner  *ngIf="isLoadingReport"></app-loading-spinner>

        <div *ngIf="reportPayload.type == 'trend' && reportDataLoaded">
          <div class="row">
            <div *ngIf="reportPayload.show.compliance_healthy_percent" class="row sectionBox insightsSection">
              <div class="col-3">
                <div class="">
                  <div class="label">Compliance %</div>
                  <div class="figure">{{loadedReportData.averages.compliance}}%</div>
                </div>
              </div>
              <div class="col">
                <app-eido-column-chart [dataArray]="loadedReportData.compliance" [labelPropertyName]="'date_friendly'" [valuePropertyName]="'compliance_healthy_percent'" [total]="reportPayload.trendPeriod" [prependMissing]="true"></app-eido-column-chart>
              </div>
            </div>


            <div *ngIf="reportPayload.show.config_healthy_percent" class="row sectionBox insightsSection">
              <div class="col-3">
                <div class="">
                  <div class="label">Config Health %</div>
                  <div class="figure">{{loadedReportData.averages.config}}%</div>
                </div>
              </div>
              <div class="col">
                <app-eido-column-chart [dataArray]="loadedReportData.config" [labelPropertyName]="'date_friendly'" [valuePropertyName]="'config_healthy_percent'" [total]="reportPayload.trendPeriod" [prependMissing]="true"></app-eido-column-chart>

              </div>
            </div>

            <div *ngIf="reportPayload.show.app_healthy_percent" class="row sectionBox insightsSection">
              <div class="col-3">
                <div class="">
                  <div class="label">App Health %</div>
                  <div class="figure">{{loadedReportData.averages.app}}%</div>
                </div>
              </div>
              <div class="col">
                <app-eido-column-chart [dataArray]="loadedReportData.app" [labelPropertyName]="'date_friendly'" [valuePropertyName]="'app_healthy_percent'" [total]="reportPayload.trendPeriod" [prependMissing]="true"></app-eido-column-chart>

              </div>
            </div>

            <div *ngIf="reportPayload.show.patch_healthy_percent" class="row sectionBox insightsSection">
              <div class="col-3">
                <div class="">
                  <div class="label">OS Patch Health %</div>
                  <div class="figure">{{loadedReportData.averages.patch}}%</div>
                </div>
              </div>
              <div class="col">
                <app-eido-column-chart [dataArray]="loadedReportData.patch" [labelPropertyName]="'date_friendly'" [valuePropertyName]="'patch_healthy_percent'" [total]="reportPayload.trendPeriod" [prependMissing]="true"></app-eido-column-chart>

              </div>
            </div>


          </div>
        </div>

        <div  *ngIf="reportPayload.type == 'report' && reportDataLoaded">


          <div class="row">
            <app-eido-grid
              [columns]="gridSettings.columns"
              [viewPortHeight]="'85vh'"
              [data]="loadedReportData"
              [actionButtons]="gridSettings.actionButtons"
              [multiSelect]="true"
              [multiSelectActions]="gridSettings.multiSelectActions"
              (rowClick)="gridRowClicked($event)"
              (actionButtonClick)="gridActionClicked($event)"
              (multiSelectAction)="gridMultiSelectAction($event)"

            ></app-eido-grid>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
