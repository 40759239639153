<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  size="blade-xlarge">


  <div *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
<div *ngIf="!isLoading">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedData.policy_name}}</h3>
  </div>
  <hr>

  <button *ngIf="!this.loadedData.created_from_template_id" (click)="createTemplate()" type="button" class="btn btn-light-grey btn-small active">Convert to Template</button>

  <button *ngIf="this.loadedData.created_from_template_id" (click)="deploy()" type="button" class="btn btn-light-grey btn-small active">Re-Deploy</button>

  <button (click)="backup()" type="button" class="btn btn-light-grey btn-small active">Sync</button>

  <hr>

  <div class="p-0">

    <div>
      <div (click)="expand.details = !expand.details" class="collapsableHeader divLink m-10"  style="background-color: #d5d7da52;padding: 9px 12px;font-size: 16px;border-radius: 10px;">
        <i class="fas m-r-10" [ngClass]="expand.details ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        Details
      </div>
      <div *ngIf="expand.details">
          <div class="row" style="padding: 8px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
            <div class="col-5">
              <span>
                <strong>Policy Name</strong>
              </span>
            </div>
            <div class="col-7">
              <span>{{this.loadedData.policy_name}}</span>
            </div>
          </div>

        <div class="row" style="padding: 8px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
          <div class="col-5">
              <span>
                <strong>Policy Type</strong>
              </span>
          </div>
          <div class="col-7">
            <span>{{this.loadedData.policy_type | titlecaseString}}</span>
          </div>
        </div>

        <div class="row" style="padding: 8px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
          <div class="col-5">
              <span>
                <strong>Policy Created</strong>
              </span>
          </div>
          <div class="col-7">
            <span>{{this.loadedData.policy_created}}</span>
          </div>
        </div>

        <div class="row" style="padding: 8px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
          <div class="col-5">
              <span>
                <strong>Policy Last Updated</strong>
              </span>
          </div>
          <div class="col-7">
            <span>{{this.loadedData.policy_last_updated}}</span>
          </div>
        </div>


        <div class="row" style="padding: 8px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
          <div class="col-5">
              <span>
                <strong>Last Sync / Backup
                        <i class="fas fa-question-circle" [matTooltip]="'Eido automatically backs up policies every 24 hours. To back up or sync immediately, click the sync button in the policy window.'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>

                </strong>
              </span>
          </div>
          <div class="col-7">
            <span>{{this.loadedData.most_recent_backup_date}}</span>
          </div>
        </div>

        <div class="row" style="padding: 8px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
          <div class="col-5">
              <span>
                <strong>Template:</strong>
              </span>
          </div>
          <div class="col-7">
            <span>{{this.loadedData.template_name}}</span>
            <span *ngIf="!this.loadedData.created_from_template_id">
                <button (click)="linkToTemplate()" type="button" class="btn btn-light-grey btn-small active">Link to Template</button>
            </span>

            <span *ngIf="this.loadedData.created_from_template_id">
                <button (click)="removeTemplateLink()" type="button" class="btn btn-light-grey btn-smaller active m-l-10">Unlink</button>
            </span>


          </div>
        </div>

        <div class="row" style="padding: 8px 12px; margin-left: 5px; margin-top: 4px; border-bottom: 1px solid #d5d7da7a; margin-right: 5px;">
          <div class="col-5">
              <span>
                <strong>Template Version:</strong>
              </span>
          </div>
          <div class="col-7">
            <span>

              <span style="margin-right: 20px">{{this.loadedData.template_version_no}}</span>

              <span *ngIf="this.loadedData.sync_status == 'In Sync'" [matTooltip]="'Policy is in sync'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="issueCountBadge green m-0"><i class="fas fa-check"></i> In Sync with version {{this.loadedData.template_version_no}}</span>
              <span (click)="comparePolicyToCurrentTemplateVersion()" *ngIf="this.loadedData.sync_status == 'Out of sync'" [matTooltip]="'Policy is out of sync with the template'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'" class="issueCountBadge amber m-0 divLink"><i class="fas fa-exclamation-triangle"></i> Out of sync</span>

              <button *ngIf="this.loadedData.sync_status == 'Out of sync'" (click)="comparePolicyToCurrentTemplateVersion()" type="button" class="btn btn-light-grey btn-smaller active m-l-10">Compare Changes</button>


              <span *ngIf="this.loadedData.version_status == 'New version available'" class="issueCountBadge green" style="margin-left: 20px"><i class="fas fa-arrow-up"></i> New version available</span>

            </span>
          </div>
        </div>


      </div>


      <div (click)="openPolicyViewer()" class="collapsableHeader divLink m-10"  style="background-color: #d5d7da52;padding: 9px 12px;font-size: 16px;border-radius: 10px;">
        Policy Data <i class="fas fa-external-link-alt"></i>
      </div>


      <div (click)="expand.backups = !expand.backups" class="collapsableHeader divLink m-10"  style="background-color: #d5d7da52;padding: 9px 12px;font-size: 16px;border-radius: 10px;">
        <i class="fas m-r-10" [ngClass]="expand.backups ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        Version History
      </div>

      <div *ngIf="expand.backups">

        <div class="row" style="padding: 8px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
          <div class="col">
            <span>
              <strong>Backup Date/Time</strong>
            </span>
          </div>
          <div class="col">
            <span>
              <strong>Action</strong>
            </span>
          </div>
          <div class="col">
            <span>
              <strong>Policy Changes</strong>
            </span>
          </div>

          <div class="col">
            <span>
              <strong>Assignment Changes</strong>
            </span>
          </div>
          <div class="col-2">

          </div>
        </div>

        <div *ngFor="let backup of this.loadedData.backups"  class="row" style="padding: 3px 12px;margin-left: 5px;margin-top: 4px;border-bottom: 1px solid #d5d7da7a;margin-right: 5px;">
          <div class="col">
              <span style="font-size: 12px;">
                <strong>{{backup.created_at}}</strong>
              </span>
          </div>
          <div class="col">
              <span *ngIf="backup.audit_description"  style="font-size: 12px;">
                {{ backup.audit_description | titlecaseString}}
              </span>
            <span *ngIf="!backup.audit_description"  style="font-size: 12px;">
                Backup
              </span>
            <span style="
    font-size: 12px;
    background-color: #d3d3d387;
    padding: 2px 7px;
    border-radius: 20px;
    margin-left: 5px;
" *ngIf="backup.audit_description">
              {{backup.user_email}}
            </span>
          </div>
          <div class="col">
            <span *ngIf="!backup.policy_changed" style="font-size:12px">No Changes</span>
            <span (click)="openPolicyComparison(backup)" *ngIf="backup.policy_changed" class="changesPill">
              <i style="color: #eaaf6d" class="fas fa-exclamation-triangle"></i>
              {{backup.policy_changed ? 'Changes' : 'No Change'}}
            </span>
          </div>
          <div class="col">
            <span *ngIf="!backup.assignments_changed" style="font-size:12px">No Changes</span>
            <span (click)="openAssignmentComparison(backup)" *ngIf="backup.assignments_changed" class="changesPill">
              <i  style="color: #eaaf6d" class="fas fa-exclamation-triangle"></i>
              {{backup.assignments_changed ? 'Changes' : 'No Change'}}
            </span>

          </div>
          <div class="col-2">
            <button (click)="restoreBackup(backup)" type="button" class="btn btn-light-grey btn-small active">Restore</button>
          </div>
        </div>
      </div>

    </div>


  </div>

<!--    <div class="m-t-20">-->
<!--      <div (click)="close()" class="btn btn-cancel">Cancel</div>-->
<!--    </div>-->

  </div>
</app-blade-right>
