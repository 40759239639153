import { Component } from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {AppDrillDownDialog} from '../../discovered-apps/app-dialog/app-drill-down-dialog';

@Component({
  selector: 'app-root-updates',
  templateUrl: './root-updates.component.html',
  styleUrl: './root-updates.component.css'
})
export class RootUpdatesComponent {

  public isLoading = true;
  public loadedData;

  public superTableSettings;

  constructor(public apiService: ApiService) {

      this.apiService.get('/api/root/updates').then(response => {
        this.loadedData = response;
        this.isLoading = false;
      })


    this.superTableSettings = {
      topMargin: 75,
      filterOptions: [
        {columnName: 'platform_identifier', friendlyName: 'Platform', isExpanded: true, showAllCheckbox: true, showSearchOption: false, template: 'default'},
        {columnName: 'group_name', friendlyName: 'Group', isExpanded: true, showAllCheckbox: true, showSearchOption: false, template: 'default'},
      ],
      columns: [
        {columnName: 'platform_identifier', friendlyName: 'Customer', isSortable: true, template: 'default', widthCol: 'col-1'},
        {columnName: 'major_build_friendly_name', friendlyName: 'Major', isSortable: true, template: 'default', widthCol: 'col'},
        {columnName: 'minor_build_friendly_name', friendlyName: 'Major', isSortable: true, template: 'default', widthCol: 'col'},
        {columnName: 'release_date', friendlyName: 'Release', isSortable: true, template: 'default', widthCol: 'col'},
        {columnName: 'end_of_service_date', friendlyName: 'EOL Date', isSortable: true, template: 'default', widthCol: 'col'},
        // {columnName: 'latest_revision_date', friendlyName: 'Latest Revision', isSortable: true, template: 'default', widthCol: 'col'},
        {columnName: 'sku_family', friendlyName: 'SKU', isSortable: true, template: 'default', widthCol: 'col'},


      ],
      sortByColumn: 'end_of_service_date',
      sortByAsc: true
    };


  }

  rowClicked(rowData:any) {
    console.log('row clicked', JSON.stringify(rowData));
  }




}
