import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApplicationApiService} from '../../../services/api/application/application-api.service';
import {ApiService} from '../../../services/api/api.service';

@Component({
  selector: 'policy-viewer',
  templateUrl: './app-drill-down-dialog.html',
  styleUrls: ['./app-drill-down-dialog.css']
})
export class AppDrillDownDialog implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  loadedData;

  isLoading = true;
  public superTableSettings;

  public editMode = false;

  constructor(public apiService: ApiService) {}

  saveApp(){
    this.apiService.put(`/api/apps/${this.loadedData.application_hash}`, this.loadedData).then(response => {
      this.refreshData();
      this.editMode = false;
    })
  }

  public close() {
    this.remove.emit();
  }

  ngOnInit(): void {

    // SELECT app_name, c.name as app_category_name, r.application_risk_category_name, rs.risk_level, count(*) as install_count

    this.superTableSettings = {
      topMargin: 250,
      dataLink: '/devices',
      dataLinkColumns: ['device_id'],
      filterOptions: [
        {columnName: 'version_name', friendlyName: 'Version', isExpanded: true, showAllCheckbox: true, showSearchOption: true, template: 'default', showCount: true},
      ],
      columns: [
        {columnName: 'app_name', friendlyName: 'App Name', isSortable: true, template: 'default', widthCol: 'col'},
        {columnName: 'version_name', friendlyName: 'Version', isSortable: true, template: 'default', widthCol: 'col-2'},
        {columnName: 'computer_name', friendlyName: 'Device', isSortable: true, template: 'default', widthCol: 'col-3'},

      ],
      sortByColumn: 'risk_level',
      sortByAsc: true
    };

    this.refreshData();
  }

  refreshData(){
    this.isLoading = true;
    this.apiService.get(`/api/apps/${this.data}`).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }


}
