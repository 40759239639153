import {Component, Input, Output, EventEmitter, ElementRef, HostListener, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-eido-multi-select',
  templateUrl: './eido-multi-select.component.html',
})

export class EidoMultiSelect {
  @Input() data: any[] = [];
  @Input() selectedData: any[] = [];
  @Input() filterName: string = 'Select';
  @Input() clearFilterOptionName: string = 'All';

  @Input() multiSelect: boolean = true;

  @Input() label: string = 'name';
  @Input() key: string = 'id';

  @Input() selectAllByDefault: true;
  @Output() selectedDataChange = new EventEmitter<any[]>();

  showDropdown = false;
  private toggleClicked = false;

  @ViewChild('dropdown', { static: false }) dropdownElement: ElementRef;

  constructor() {
    // if select all by default is true, then select all
    if (this.selectAllByDefault) {
      this.selectedData = this.data;
    }
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
    this.toggleClicked = true; // Set the flag when toggle is clicked
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    // Check if the click is outside and not on the toggle
    if (!this.toggleClicked && this.dropdownElement && !this.dropdownElement.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
    this.toggleClicked = false; // Reset the flag
  }

  // toggle option
  toggleItem(item: any): void {
    if(this.multiSelect){
      if (this.isSelected(item)) {
        this.selectedData = this.selectedData.filter(selectedItem => selectedItem.id !== item.id);
      } else {
        this.selectedData.push(item);
      }
    } else {
      this.selectedData = [];
      this.selectedData.push(item);

      // output this items key...
      const selectedKeys = this.selectedData.map(item => item[this.key]);
      console.log(selectedKeys);
      this.selectedDataChange.emit(selectedKeys);
      this.showDropdown = false;
    }
  }

  clear(){
    this.selectedData = [];
    this.selectedDataChange.emit([]);
    this.showDropdown = false;
  }

  apply(){
    if(this.multiSelect){
      // get flat array of keys
      const selectedKeys = this.selectedData.map(item => item[this.key]);
      this.selectedDataChange.emit(selectedKeys);
    }
  }
  isSelected(item: any): boolean {
    return this.selectedData.some(selectedItem => selectedItem.id === item.id);
  }
}
