// issue-type-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-issue-type-renderer',
  standalone: true,
  template: `
    <span class="dashboard-problematic-label">{{metricName}}
      <span class="dashboard-problematic-tag">{{metricType}}</span>
    </span>
  `
})
export class IssueTypeRendererComponent implements ICellRendererAngularComp {
  public metricName: string | null = null;
  public metricType: string | null = null;

  agInit(params: any): void {
    this.metricName = params.data.intune_metric_name;
    this.metricType = params.data.intune_metric_type;
  }

  refresh(params: any): boolean {
    this.metricName = params.data.intune_metric_name;
    this.metricType = params.data.intune_metric_type;
    return true;
  }
}
