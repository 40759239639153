<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div (click)="create()" class="btn btn-primary float-end">New Customer</div>
<h3>Customers</h3>

<div *ngIf="!isLoading">
  <div class="row m-t-20">
    <div class="col-4"><h4 style="color: #bdbdbd;">NAME</h4></div>
  </div>

  <div *ngFor="let row of this.data" class="divLink sectionBox row">
    <div class="col-4" (click)="edit(row.id)"><h4>{{row.customer_name}}</h4></div>
    <div class="col">
      <i (click)="delete(row.id)" class="fas fa-trash float-end fa-15x m-l-10 p-1 divLink"></i>
      <i (click)="edit(row.id)" class="fas fa-cog float-end fa-15x p-1 divLink"></i>
    </div>
  </div>
</div>
