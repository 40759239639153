import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionsService {

  // Define Windows version ranges
  private windowsVersions = [
    { min: '6.1.7600', max: '6.1.7601', version: 'Windows 7' },
    { min: '6.2.0', max: '6.3.9999', version: 'Windows 8/8.1' },
    { min: '10.0.10240', max: '10.0.21999', version: 'Windows 10' },
    { min: '10.0.22000', max: '10.0.99999', version: 'Windows 11' },
    // Future versions or updates would be added here
  ];

  constructor(public router: Router) { }

  public decodeJson(string: string|null){
    return JSON.parse(string);
  }

  public redirectTo(url: string) {
    setTimeout(() => {
      this.router.navigate([url]).then(r => {});
    }, 100);
  }

  // data is an array of objects
  public sortArrayOfObjects(data: any[], columnName:string, orderDesc = false) {

    console.log("OrderBy: "+columnName);

    // this.data.sort((a, b) => {
    //   return (a[this.orderBy] > b[this.orderBy]) ? 1 : -1;}
    // );

    data.sort((a:any, b:any) => {
      a = a[columnName];
      b = b[columnName];

      if (typeof a === 'string') {
        a = a.toLowerCase();
      }

      if (typeof b === 'string') {
        b = b.toLowerCase();
      }

      // equal items sort equally
      if (a === b) {
        return 0;
      }

      // nulls sort after anything else
      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      if (!orderDesc) {
        return a < b ? -1 : 1;
      }

      // if descending, highest sorts first
      return a < b ? 1 : -1;
    });

  }

  exportToCsv(filename: string, rows: object[]) {
    const csvData = this.convertToCsv(rows);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  convertToCsv(rows: object[]): string {
    if (!rows || !rows.length) {
      return '';
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    return csvContent;
  }
  getStatusColor(status){
    switch (status) {
      case 2:
        return 'green'
      case 3:
        return 'amber'
      case 4:
        return 'red'
      default:
        return 'grey'
    }

  }

  getStatusColourFromPercentage(percent:any) {
    if(percent < 80) {
      return 'red';
    } else if (percent < 90) {
      return 'amber';
    } else {
      return 'green';
    }
  }

  daysBetweenDateTimeAndNow(dateTimeStamp) {

    if(!dateTimeStamp){
      return null;
    }

    // Convert the dateTimeStamp to a Date object
    var inputDate = new Date(dateTimeStamp);

    // Get the time difference in milliseconds between the inputted date and the current date
    var timeDiff = Date.now() - inputDate.getTime();

    // Convert the time difference from milliseconds to days
    var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
  }

  // Function to determine Windows version
  public getWindowsVersion(versionString: string): string {

    if (!versionString) return 'Unknown Windows Version';
    let inputVersionParts = versionString.split('.').map(Number);

    for (let range of this.windowsVersions) {
      let minVersionParts = range.min.split('.').map(Number);
      let maxVersionParts = range.max.split('.').map(Number);

      if (this.isVersionInRange(inputVersionParts, minVersionParts, maxVersionParts)) {
        return range.version;
      }
    }

    return 'Unknown Windows Version';
  }

  private isVersionInRange(version: number[], min: number[], max: number[]): boolean {
    for (let i = 0; i < 3; i++) { // Only compare the first three parts (major, minor, build)
      if (version[i] < min[i]) return false;
      if (version[i] > max[i]) return false;
    }
    return true;
  }

  public formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
