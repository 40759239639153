

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from '../../../../shared/dialog/dialog';
import {ApiUserService} from '../../../../services/api/user-api/user-api.service';
import {OptionsService} from '../../../../services/options-service/options.service';
import {PortalSettingsService} from '../../../../services/portal-settings/portal-settings.service';

@Component({
  selector: 'app-team-create-edit-dialog',
  templateUrl: './team-create-edit-dialog.component.html'
})

export class UserComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  loadedData;

  constructor(
    private apiUserService: ApiUserService,
    public optionsService: OptionsService,
    public portalSettings: PortalSettingsService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        user_name: '',
        email:'',
        customer_id: null,
        is_admin: false
      }

      this.isLoading = false;

    }
  }

  resend(){
    this.apiUserService.resend(this.loadedId).then();
    this.close();
  }

  getData(){
    this.isLoading = true;
    this.apiUserService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiUserService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiUserService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
