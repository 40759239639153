import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';

@Component({
  selector: 'app-widget-visual',
  templateUrl: './widget-visual.component.html',
  styleUrl: './widget-visual.component.css'
})
export class WidgetVisualComponent implements OnInit{

  public data: any;

  public isLoading = true;

  @Input() item: any;
  @Input() editMode: any;

  public topIssuesMax = 10;


  ngOnInit(): void {
    this.getWidgetData();
  }

  constructor(public apiService: ApiService) {

  }

  getStatusFromPercent(percent:any) {
    if(percent < 80) {
      return 'red';
    } else if (percent < 90) {
      return 'amber';
    } else {
      return 'green';
    }
  }

  statusColour(statusNumber) {
    switch (statusNumber) {
      case 1:
        return 'grey';
      case 2:
        return 'green';
      case 3:
        return 'amber';
      case 4:
        return 'red';
      default:
        return 'grey';
    }
  }

  public getWidgetData(silent = false){
    if(!silent) {
      this.isLoading = true;
    }

    this.apiService.get(`/api/v2/dashboard/stats/${this.item.widgetName}`).then(response => {
      this.data = response;

      this.isLoading = false;

      // work out top issues max value
      if(this.item.widgetName == 'top-issues-compliance' || this.item.widgetName == 'top-issues-config' || this.item.widgetName == 'top-issues-app' || this.item.widgetName == 'top-issues-all'){
        this.data.forEach(issue => {
          if (issue.device_count > this.topIssuesMax) {
            this.topIssuesMax = issue.device_count;
          }
        });
      }

    })


  }


}
