import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {RootApiService} from '../../services/api/root-api/root-api.service';
import {Router} from '@angular/router';
import {ModalConfirmationComponent} from '../../shared/modal-confirmation/modal-confirmation.component';
import {DialogService} from '../../services/dialog-service/dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {

  public isLoading;
  public data;

  public tab = 'customer';

  public totalCustomers = 0;
  public totalTrials = 0;

  constructor(private rootApi: RootApiService, private router: Router, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getTenants(){
    return this.data.filter(tenant => tenant.tenant_lifecycle == this.tab);
  }

  switchTenant(id){
    this.rootApi.switchTenant(id).then(response => {
      this.router.navigate(['/']);
    })
  }

  deleteTenant(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.rootApi.deleteTenant(id).then(response => {
          this.getData();
        })
      }, cancelled => null)
  }

  getData(){
    this.isLoading = true;
    this.rootApi.get().then(response => {
      this.data = response;

      this.totalCustomers = this.data.filter(tenant => tenant.tenant_lifecycle == 'customer').length;
      this.totalTrials = this.data.filter(tenant => tenant.tenant_lifecycle == 'trial').length;

      this.isLoading = false;
    })
  }
}
