import {Component, OnInit} from '@angular/core';
import {AuthApiService} from "../services/api/authentication/auth-api-service";
import {AuthenticationService} from "../services/authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public branding;
  public loginMethod = 'Username-Password';

  // User/Password form
  public emailAddress: string;
  public password: string;
  public mfaToken: string;

  public screen: string = 'loginScreen'; // loginScreen / passwordResetScreen / passwordResetSuccessful / loginScreenOauth
  public loginScreenShowMfa: boolean = false;
  public mfaSaveDevice: boolean = false;

  public oauth_error = '';

  public showPasswordResetSuccessful: boolean = false;

  public returnPath: string;
  public showLoginFailedMessage = false;

  constructor(
    private authApi: AuthApiService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.emailAddress = localStorage.getItem('login_email');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnPath = params['returnPath'];
      this.oauth_error = params['oauth_error'];

      let authToken = params['oauth2_token'];

      if(authToken){
        this.authService.setAuthToken(authToken);

        if(this.returnPath){
          this.router.navigate([this.returnPath]).catch(error => {
            console.log("route does not exist:" + error);
            this.router.navigate(['/']);
          });
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }

  resetPassword(){
    this.authApi.resetPassword(this.emailAddress).then(response => {
        this.showPasswordResetSuccessful = true
      });
  }

  login(){
    // Clear login failed message
    this.showLoginFailedMessage = false;

      let deviceAuthToken = this.authService.returnDeviceMfaToken();

      // Call Login API
      this.authApi.login(this.emailAddress, this.password, deviceAuthToken, this.mfaToken, this.mfaSaveDevice).then(response => {
        const authToken = response?.AuthToken;
        const showMfa = response?.ShowMfa;
        const mfaDeviceSecret = response?.MfaDeviceSecret;

        if(showMfa){
          this.loginScreenShowMfa = true;
          return;
        }

        if(authToken) {
          this.authService.setAuthToken(authToken);
          this.authService.setDeviceMfaToken(mfaDeviceSecret)

          if(this.returnPath){
            this.router.navigate([this.returnPath]).catch(error => {
              console.log("route does not exist:" + error);
              this.router.navigate(['/']);
            });
          } else {
            this.router.navigate(['/']);
          }

        } else {
          this.showLoginFailedMessage = true;
        }

      }).catch(error => {
        this.showLoginFailedMessage = true;
      })

  }

}
