import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  private loginApi = "/api/account/login";
  private loginMethodApi = "/api/loginmethod";
  private resetPasswordApi = "/api/account/reset-password";
  private signupApi = "/api/signup";

  constructor(private api: ApiService) { }

  public getLoginMethod(emailAddress){
    return this.api.post(this.loginMethodApi, {EmailAddress: emailAddress});
  }

  public login(emailAddress, password, mfaDeviceSecret, mfaToken, mfaTokenSaveDevice){

    let loginPayload = {
      EmailAddress: emailAddress,
      Password:  password,
      MfaDeviceSecret: mfaDeviceSecret,
      MfaToken: mfaToken,
      MfaTokenSaveDevice: mfaTokenSaveDevice
    }

    return this.api.post(this.loginApi, loginPayload);

  }

  public resetPassword(emailAddress){
    let loginPayload = {
      EmailAddress: emailAddress,
    }

    return this.api.post(this.resetPasswordApi, loginPayload);
  }

  public signup(data){
    return this.api.post(this.signupApi, data);
  }

  public setPassword(activationCode, password, confirmPassword){
    let body = {
      activation_code: activationCode,
      password: password,
      confirm_password: confirmPassword
    }

    return this.api.post('/api/account/set-password', body);
  }

}
