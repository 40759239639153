import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyApiService {

  private policyApiUri = '/api/policies';

  constructor(private api: ApiService) { }

  listAll() {
    return this.api.get(this.policyApiUri);
  }

  get(id: any) {
    return this.api.get(`${this.policyApiUri}/${id}`);
  }
  getSuggestedLink(id: any) {
    return this.api.get(`${this.policyApiUri}/${id}/suggested-link`);
  }

  backup(id: any) {
    return this.api.post(`${this.policyApiUri}/${id}/backup`);
  }




  restore(backupId:number, restorePolicy:boolean, restoreAssignments:boolean){
    let body = {restorePolicy: restorePolicy, restoreAssignments: restoreAssignments};
    return this.api.post(`${this.policyApiUri}/backups/restore/${backupId}`, body);
  }
  link(backupId:number, templateId: any, templateVersionId: any){
    let body = {templateId: templateId, templateVersionId: templateVersionId};
    return this.api.post(`${this.policyApiUri}/${backupId}/link`, body);
  }
  removeTemplateLink(id: any) {
    return this.api.post(`${this.policyApiUri}/${id}/remove-template-link`);
  }

  createTemplate(policyId:number){
    let body = {  };
    return this.api.post(`${this.policyApiUri}/${policyId}/create-template`, body);
  }
}
