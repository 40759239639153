import { Component, OnInit } from '@angular/core';
import {BillingApiService} from '../../services/api/billing-api/billing-api.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  constructor(private billingApi: BillingApiService) { }

  ngOnInit(): void {

    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.loginToPortal();
    }, 2000);

  }

  loginToPortal(){
    this.billingApi.getStripeUrl().then(response => {
      console.log(response);
      window.location.href = response.redirectUrl;
    });
  }

}
