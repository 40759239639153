import { Injectable } from '@angular/core';
import {ApiService} from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class IntuneTenantsApiService {

  apiUri = '/api/admin/intune-tenants'

  constructor(private apiService: ApiService) {
  }

  listAll() {
    return this.apiService.get(this.apiUri);
  }

  getIntuneTenant(id){
    return this.apiService.get(`${this.apiUri}/${id}`);
  }

  updateIntuneTenant(id, data){
    return this.apiService.put(`${this.apiUri}/${id}`, data);
  }

  syncIntuneTenant(id){
    return this.apiService.post(`${this.apiUri}/${id}/sync`);
  }

  delete(id) {
    return this.apiService.delete(`${this.apiUri}/${id}`);
  }

}
