<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<span *ngIf="!isLoading">
  <div *ngIf="item.widget == 'tile'" style="height: 100%">
    <div *ngIf="item.widgetName == 'tile-compliance'" class="gridDashboardTile divLink" [routerLink]="editMode ? null : ['/devices']" [queryParams]="{'filter_intune_compliance_status_name': 'Red,Amber'}">
      <div class="float-end icon d-none d-xl-block">
        <i class="fas fa-shield-check fa-3x icon" [ngClass]="getStatusFromPercent(this.data.intune_compliance_percent)" aria-hidden="true" style="
      margin: 5px;
      font-size: 3.5em;
  "></i>
      </div>
      <h5 class="value">{{this.data.intune_compliance_percent}} %</h5><h6 class="label">Compliant</h6>
    </div>

    <div *ngIf="item.widgetName == 'tile-device-count'" class="gridDashboardTile divLink" [routerLink]="editMode ? null : ['/devices']">
      <h5 class="value">{{this.data.device_count ?? 0}}</h5>
      <h6 class="label">Devices</h6>
    </div>

    <div *ngIf="item.widgetName == 'tile-device-inactive'" class="gridDashboardTile divLink" [routerLink]="editMode ? null : ['/devices']"  [queryParams]="{filter_device_inactive_state: 'Inactive (>30d)'}">
      <h5 class="value">{{this.data.device_inactive_count ?? 0}}</h5>
      <h6 class="label">Inactive</h6>
    </div>

    <div *ngIf="item.widgetName == 'tile-device-lost'" class="gridDashboardTile divLink"  [routerLink]="editMode ? null : ['/devices']">
      <h5 class="value">{{this.data.device_lost_count ?? 0}}</h5>
      <h6 class="label">Lost</h6>
    </div>

    <div *ngIf="item.widgetName == 'tile-compliance-issues'" class="gridDashboardTile divLink"  [routerLink]="editMode ? null : ['/issues']"  [queryParams]="{filter_intune_metric_type: 'Compliance'}">
      <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
        <div *ngIf="this.data.intune_issues_compliance_red" class=" d-none d-xl-block" style=" font-size: 11px;background: indianred; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_issues_compliance_red}} Urgent</div>
        <div *ngIf="this.data.intune_issues_compliance_amber" class=" d-none d-xl-block" style="font-size: 11px;background: #eaaf6d; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_issue_breakdown}} Medium</div>
      </div>
      <h5 class="value">{{this.data.intune_issues_compliance_total ?? 0}}</h5><h6 class="label">Compliance Issues</h6>
    </div>

    <div *ngIf="item.widgetName == 'tile-config-issues'" class="gridDashboardTile divLink"  [routerLink]="editMode ? null : ['/issues']"  [queryParams]="{filter_intune_metric_type: 'Config'}">
      <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
        <div *ngIf="this.data.intune_issues_config_red" class="d-none d-xl-block" style=" font-size: 11px;background: indianred; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_issues_config_red}} Urgent</div>
        <div *ngIf="this.data.intune_issues_config_amber" class="d-none d-xl-block" style="font-size: 11px;background: #eaaf6d; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_issues_config_amber}} Medium</div>
      </div>
      <h5 class="value">{{this.data.intune_issues_config_total ?? 0}}</h5><h6 class="label">Config Issues</h6>
    </div>

    <div *ngIf="item.widgetName == 'tile-app-issues'" class="gridDashboardTile divLink"  [routerLink]="editMode ? null : ['/issues']"  [queryParams]="{filter_intune_metric_type: 'App'}">
      <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
        <div *ngIf="this.data.intune_issues_app_red" class="d-none d-xl-block" style=" font-size: 11px;background: indianred; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_issues_app_red}} Urgent</div>
        <div *ngIf="this.data.intune_issues_app_amber" class="d-none d-xl-block" style="font-size: 11px;background: #eaaf6d; color: white; padding: 6px 14px; border-radius: 25px;">{{this.data.intune_issues_app_amber}} Medium</div>
      </div>
      <h5 class="value">{{this.data.intune_issues_app_total ?? 0}}</h5><h6 class="label">App Issues</h6>
    </div>
  </div>

  <div *ngIf="item.widget == 'top'" class="widgetBackground">
    <div  *ngIf="item.widgetName == 'top-issues-all'" id="top-issues">
      <div class="row">
        <div class="col">
          <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">TOP ISSUES</p>
        </div>
      </div>
      <div class="p-0">
        <div *ngFor="let issue of data"   [routerLink]="editMode ? null : ['/issues']"  [queryParams]="{filter_intune_metric_type: issue.intune_metric_type, filter_intune_metric_name: issue.intune_metric_name}" class="row divLink topIssueItem">

          <div class="col">
            <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
            <div style="margin-right: 15px; margin-left: 0px">
              <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
            </div>
          </div>
          <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
            {{issue.device_count}}
          </div>

        </div>
      </div>

    </div>

    <div  *ngIf="item.widgetName == 'top-issues-compliance'" id="top-compliance">
      <div class="row">
        <div class="col">
          <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">TOP COMPLIANCE ISSUES</p>
        </div>
      </div>
      <div class="p-0">
        <div *ngFor="let issue of data"   [routerLink]="editMode ? null : ['/issues']"  [queryParams]="{filter_intune_metric_type: issue.intune_metric_type, filter_intune_metric_name: issue.intune_metric_name}" class="row divLink topIssueItem">

          <div class="col">
            <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
            <div style="margin-right: 15px; margin-left: 0px">
              <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
            </div>
          </div>
          <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
            {{issue.device_count}}
          </div>

        </div>
      </div>

    </div>

    <div  *ngIf="item.widgetName == 'top-issues-config'" id="top-config">
      <div class="row">
        <div class="col">
          <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">TOP CONFIG ISSUES</p>
        </div>
      </div>
      <div class="p-0">
        <div *ngFor="let issue of data"   [routerLink]="editMode ? null : ['/issues']"  [queryParams]="{filter_intune_metric_type: issue.intune_metric_type, filter_intune_metric_name: issue.intune_metric_name}" class="row divLink topIssueItem">

          <div class="col">
            <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
            <div style="margin-right: 15px; margin-left: 0px">
              <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
            </div>
          </div>
          <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
            {{issue.device_count}}
          </div>

        </div>
      </div>

    </div>

    <div  *ngIf="item.widgetName == 'top-issues-app'" id="top-app">
      <div class="row">
        <div class="col">
          <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">TOP APP ISSUES</p>
        </div>
      </div>
      <div class="p-0">
        <div *ngFor="let issue of data"   [routerLink]="editMode ? null : ['/issues']"  [queryParams]="{filter_intune_metric_type: issue.intune_metric_type, filter_intune_metric_name: issue.intune_metric_name}" class="row divLink topIssueItem">

          <div class="col">
            <div class="dashboard-problematic-label">{{issue.intune_metric_name}}    <span class="dashboard-problematic-tag">{{issue.intune_metric_type | titlecaseString }}</span></div>
            <div style="margin-right: 15px; margin-left: 0px">
              <div class="small-bar" style="" [style.width.%]="(issue.device_count / this.topIssuesMax) * 100" [ngClass]="statusColour(issue.severity)"></div>
            </div>
          </div>
          <div class="col-1 text-center dashboard-top-issues-badge" style="width: 65px;">
            {{issue.device_count}}
          </div>

        </div>
      </div>

    </div>

  </div>

  <div *ngIf="item.widget == 'table'" class="widgetBackground">

    <div *ngIf="item.widgetName == 'table-new-devices'" class="">
      <div id="new-devices">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">NEW DEVICES</p>
          </div>
        </div>
        <div class="p-0">
          <div *ngFor="let issue of data" [routerLink]="editMode ? null : ['/devices', issue.device_id]" class="row divLink topIssueItem" style="">
            <div class="col-9">
              <div class="table-label">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.created_at | amTimeAgo}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--            <div *ngIf="item.widgetName == 'table-new-devices'" class="">-->
    <!--              <div id="new-devices">-->
    <!--                <div class="row">-->
    <!--                  <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">-->
    <!--                    <p style="color: #818f95;font-size: 18px;">NEW DEVICES</p>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--                <div class="sectionBox p-0">-->
    <!--                  <div *ngFor="let issue of data.new_devices" [routerLink]="editMode ? null : ['/devices', issue.device_id]" class="row divLink" style="">-->
    <!--                    <div class="col-9">-->
    <!--                      <div class="dashboard-problematic-label" style="padding: 11px;">{{issue.computer_name}}</div>-->
    <!--                    </div>-->
    <!--                    <div class="col-3">-->
    <!--                      <div class="dashboard-neutral-badge">{{issue.created_at | amTimeAgo}}</div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->

    <div *ngIf="item.widgetName == 'table-inactive-devices'" class="">
      <div id="inactive-devices">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">INACTIVE DEVICES</p>
          </div>
        </div>
        <div class="p-0">
          <div *ngFor="let issue of data" [routerLink]="editMode ? null : ['/devices', issue.device_id]" class="row divLink topIssueItem" style="">
            <div class="col-9">
              <div class="table-label">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.intune_last_sync | amTimeAgo}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="item.widgetName == 'table-longest-non-compliant'" class="">
      <div id="longest-non-compliant-devices">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">LONGEST NON COMPLIANT</p>
          </div>
        </div>
        <div class="p-0">
          <div *ngFor="let issue of data" [routerLink]="editMode ? null : ['/devices', issue.device_id]" class="row divLink topIssueItem" style="">
            <div class="col-9">
              <div class="table-label">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.min_issue_start_date | amTimeAgo}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="item.widgetName == 'table-devices-with-most-issues'" class="">
      <div id="devices-with-most-issues">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">DEVICES WITH MOST ISSUES</p>
          </div>
        </div>
        <div class="p-0">
          <div *ngFor="let issue of data" [routerLink]="editMode ? null : ['/devices', issue.device_id]" class="row divLink topIssueItem" style="">
            <div class="col-9">
              <div class="table-label">{{issue.computer_name}}</div>
            </div>
            <div class="col-3">
              <div class="dashboard-neutral-badge">{{issue.issue_count}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="item.widget == 'chart'"  class="widgetBackground">
    <div *ngIf="item.widgetChartType == 'pie'">
      <div *ngIf="item.widgetName == 'chart-devices-by-os'">
        <div id="os-breakdown">
          <div class="row">
            <div class="col" style="">
              <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">DEVICES BY OS</p>
            </div>
          </div>

          <div class="p-0 m-0">

            <!--                <div class="row">-->
            <!--                  <div class="col text-center device-by-os-icon"><i class="fab fa-windows"></i></div>-->
            <!--                  <div class="col text-center device-by-os-icon"><i class="fab fa-android"></i></div>-->
            <!--                  <div class="col text-center device-by-os-icon"><i class="fab fa-apple"></i></div>-->
            <!--                  <div class="col text-center device-by-os-icon"><i class="fab fa-linux"></i></div>-->
            <!--                  <div class="col text-center device-by-os-icon"><i class="fab fa-blackberry"></i></div>-->
            <!--                  <div class="col text-center device-by-os-icon"><i class="fab fa-chrome"></i></div>-->
            <!--                </div>-->

            <app-highchart-pie [inputData]="data"  (onClick)="editMode ? null : redirectToPage('devices', 'filter_intune_os_name', $event)" [title]="'OS Breakdown'"></app-highchart-pie>
          </div>
        </div>
      </div>

      <div *ngIf="item.widgetName == 'chart-devices-by-manufacturer'">
        <div id="manufacturer-breakdown">
          <div class="row">
            <div class="col" style="">
              <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">DEVICES BY MANUFACTURER</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('devices', 'filter_intune_manufacturer_name', $event)" [title]="'OS Breakdown'"></app-highchart-pie>
          </div>
        </div>
      </div>

      <div *ngIf="item.widgetName == 'chart-devices-by-model'">
        <div id="device_by_model">
          <div class="row">
            <div class="col" style="">
              <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">DEVICES BY MODEL</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('devices', 'filter_intune_model_name', $event)"></app-highchart-pie>
          </div>
        </div>
      </div>

      <div *ngIf="item.widgetName == 'chart-devices-by-encryption'">
        <div id="device-by-encryption-status">
          <div class="row">
            <div class="col" style="">
              <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">ENCRYPTION</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('devices', 'filter_intune_is_encrypted', $event)" [title]="'Encryption Status'"></app-highchart-pie>
          </div>
        </div>
      </div>
      <div *ngIf="item.widgetName == 'chart-devices-by-os-support'">
        <div id="chart-devices-by-os-support">
          <div class="row">
            <div class="col" style="">
              <i (click)="showWindowsUpdateStatusHelp()" class="fas fa-info-circle divLink float-end" aria-hidden="true" style="
        color: #556c7f;
        font-size: 25px;
    "></i>
              <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">OS PATCH STATUS</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('reporting-updates/windows-updates-devices', 'filter_patch_status_name', $event)" [title]="'OS Patch Status'"></app-highchart-pie>
          </div>
        </div>
      </div>

      <div *ngIf="item.widgetName == 'chart-devices-by-warranty-support'">
        <div id="chart-devices-by-warranty-support">
          <div class="row">
            <div class="col" style="">
              <p style="color: #818f95;
      font-size: 20px;
      margin-bottom: 7px;">WARRANTY COVERAGE</p>
            </div>
          </div>

          <div class="p-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('reporting/warranty', 'filter_warranty_status_name', $event)" [title]="'Warranty Coverage'"></app-highchart-pie>
          </div>
        </div>
      </div>



      <div *ngIf="item.widgetName == 'chart-devices-by-owner-type'">
        <div id="device_by_owner_type">
          <div class="row">
            <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
              <p style="color: #818f95;font-size: 18px;">DEVICES BY OWNERSHIP</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('devices', 'filter_intune_ownership', $event)" [title]="'Encryption Status'"></app-highchart-pie>
          </div>
        </div>
      </div>



      <!--              // 'issues_by_department' => $IssuesByDepartment,-->
      <!--              // 'issues_by_office' => $IssuesByOffice,-->
      <!--              // 'issues_by_country' => $IssuesByCountry,-->
      <!--              // 'issues_by_state' => $IssuesByState,-->


      <div *ngIf="item.widgetName == 'chart-issues-by-department'">
        <div id="chart-issues-by-department">
          <div class="row">
            <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
              <p style="color: #818f95;font-size: 18px;">ISSUES BY DEPARTMENT</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('issues', 'filter_azure_department', $event)" [title]="'Issues by Department'"></app-highchart-pie>
          </div>
        </div>
      </div>

      <div *ngIf="item.widgetName == 'chart-issues-by-office'">
        <div id="chart-issues-by-office">
          <div class="row">
            <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
              <p style="color: #818f95;font-size: 18px;">ISSUES BY OFFICE</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('issues', 'filter_azure_office_location', $event)" [title]="'Issues by office'"></app-highchart-pie>
          </div>
        </div>
      </div>

      <div *ngIf="item.widgetName == 'chart-issues-by-country'">
        <div id="chart-issues-by-country">
          <div class="row">
            <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
              <p style="color: #818f95;font-size: 18px;">ISSUES BY DEPARTMENT</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('issues', 'filter_azure_country', $event)" [title]="'Issues by country'"></app-highchart-pie>
          </div>
        </div>
      </div>

      <div *ngIf="item.widgetName == 'chart-issues-by-state'">
        <div id="chart-issues-by-state">
          <div class="row">
            <div class="col" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
              <p style="color: #818f95;font-size: 18px;">ISSUES BY STATE</p>
            </div>
          </div>

          <div class="p-0 m-0">
            <app-highchart-pie [inputData]="data" (onClick)="editMode ? null : redirectToPage('issues', 'filter_azure_state', $event)" [title]="'Issues by state'"></app-highchart-pie>
          </div>
        </div>
      </div>






    </div>
  </div>

  <div *ngIf="item.widget == 'breakdown'" class="widgetBackground">

    <div *ngIf="item.widgetName == 'compliance_breakdown_by_department'">
      <div id="compliance_breakdown_by_department">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">COMPLIANCE BREAKDOWN BY DEPARTMENT</p>
          </div>
        </div>



        <div class="p-0">
          <div class="row divLink issueBreakdownItem" *ngFor="let item of data">
            <div class="col-3">
              <div class="dashboard-problematic-label">{{item.name}}    </div>
            </div>
            <div class="col">
              <div style="margin-right: 0px; margin-left: 0px">
                <app-vertical-percent-indicator
                  [red]="item.red"
                  [total]="item.green+item.red+item.amber"
                  [amber]="item.amber"
                  [showPercentages]="true"
                  [link]="'devices'"
                  [greenLinkParams]="{filter_intune_compliance_status_name: 'Green', filter_azure_department: item.name}"
                  [amberLinkParams]="{filter_intune_compliance_status_name: 'Amber', filter_azure_department: item.name}"
                  [redLinkParams]="{filter_intune_compliance_status_name: 'Red', filter_azure_department: item.name}"

                ></app-vertical-percent-indicator>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div *ngIf="item.widgetName == 'compliance_breakdown_by_office'">
      <div id="compliance_breakdown_by_office">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">COMPLIANCE BREAKDOWN BY OFFICE</p>
          </div>
        </div>



        <div class="p-0">
          <div class="row divLink issueBreakdownItem" *ngFor="let item of data">
            <div class="col-3">
              <div class="dashboard-problematic-label">{{item.name}}    </div>
            </div>
            <div class="col">
              <div style="margin-right: 0px; margin-left: 0px">
                <app-vertical-percent-indicator
                  [red]="item.red"
                  [total]="item.green+item.red+item.amber"
                  [amber]="item.amber"
                  [showPercentages]="true"
                  [link]="'devices'"
                  [greenLinkParams]="{filter_intune_compliance_status_name: 'Green', filter_azure_office_location: item.name}"
                  [amberLinkParams]="{filter_intune_compliance_status_name: 'Amber', filter_azure_office_location: item.name}"
                  [redLinkParams]="{filter_intune_compliance_status_name: 'Red', filter_azure_office_location: item.name}"

                ></app-vertical-percent-indicator>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div *ngIf="item.widgetName == 'compliance_breakdown_by_country'">
      <div id="compliance_breakdown_by_country">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">COMPLIANCE BREAKDOWN BY COUNTRY</p>
          </div>
        </div>



        <div class="p-0">
          <div class="row divLink issueBreakdownItem" *ngFor="let item of data">
            <div class="col-3">
              <div class="dashboard-problematic-label">{{item.name}}    </div>
            </div>
            <div class="col">
              <div style="margin-right: 0px; margin-left: 0px">
                <app-vertical-percent-indicator
                  [red]="item.red"
                  [total]="item.green+item.red+item.amber"
                  [amber]="item.amber"
                  [showPercentages]="true"
                  [link]="'devices'"
                  [greenLinkParams]="{filter_intune_compliance_status_name: 'Green', filter_azure_country: item.name}"
                  [amberLinkParams]="{filter_intune_compliance_status_name: 'Amber', filter_azure_country: item.name}"
                  [redLinkParams]="{filter_intune_compliance_status_name: 'Red', filter_azure_country: item.name}"

                ></app-vertical-percent-indicator>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div *ngIf="item.widgetName == 'compliance_breakdown_by_state'">
      <div id="compliance_breakdown_by_state">
        <div class="row">
          <div class="col">
            <p style="color: #818f95;font-size: 20px;margin-bottom: 7px;">COMPLIANCE BREAKDOWN BY STATE</p>
          </div>
        </div>



        <div class="p-0">
          <div class="row divLink issueBreakdownItem" *ngFor="let item of data">
            <div class="col-3">
              <div class="dashboard-problematic-label">{{item.name}}    </div>
            </div>
            <div class="col">
              <div style="margin-right: 0px; margin-left: 0px">
                <app-vertical-percent-indicator
                  [red]="item.red"
                  [total]="item.green+item.red+item.amber"
                  [amber]="item.amber"
                  [showPercentages]="true"
                  [link]="'devices'"
                  [greenLinkParams]="{filter_intune_compliance_status_name: 'Green', filter_azure_state: item.name}"
                  [amberLinkParams]="{filter_intune_compliance_status_name: 'Amber', filter_azure_state: item.name}"
                  [redLinkParams]="{filter_intune_compliance_status_name: 'Red', filter_azure_state: item.name}"

                ></app-vertical-percent-indicator>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</span>
