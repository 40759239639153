import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-percent-bar',
  templateUrl: './percent-bar.component.html',
  styleUrl: './percent-bar.component.css'
})
export class PercentBarComponent {

  @Input() percentage: number;
  @Input() number: number;

}
