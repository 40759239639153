import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrl: './json-editor.component.css'
})
export class JsonEditorComponent implements OnInit{
  @Input() readonly = true;
  @Input() json: string;
  @Output() jsonChange = new EventEmitter<string>();

  editorOptions = {
    readOnly: this.readonly,
    theme: 'vs-dark',
    language: 'json',
    minimap: { enabled: false }, // Hide minimap
    overviewRulerLanes: 0 // Hide overview ruler
  };

  onJsonChange(newValue: string) {
    this.jsonChange.emit(newValue);
  }

  constructor() {}

  ngOnInit(): void {
    this.json = JSON.stringify(JSON.parse(this.json), null, 2);
  }



}
