<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading" size="blade-xlarge">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Intune Thresholds</h3>
  </div>
  <hr class="m-b-10">

  <div class="col-12 m-b-20 " style="margin-top: 22px;">
    <ul class="nav dialogTabs">

      <li class="nav-item divLink" (click)="selectedThresholdType = 'main'">
        <a class="nav-link-thread" [class.active]="selectedThresholdType == 'main'">
          Tenant Settings
        </a>
      </li>

      <li class="nav-item divLink" (click)="selectedThresholdType = 'compliance'">
        <a class="nav-link-thread" [class.active]="selectedThresholdType == 'compliance'">
          Compliance Policies ({{countThresholdsForType('compliance')}})
        </a>
      </li>

      <li class="nav-item divLink" (click)="selectedThresholdType = 'config'">
        <a class="nav-link-thread" [class.active]="selectedThresholdType == 'config'">
          Configuration Profiles ({{countThresholdsForType('config')}})
        </a>
      </li>

      <li class="nav-item divLink" (click)="selectedThresholdType = 'app'">
        <a class="nav-link-thread" [class.active]="selectedThresholdType == 'app'">
          Managed Apps ({{countThresholdsForType('app')}})
        </a>
      </li>

    </ul>
  </div>

  <div class="container p-0" style="margin-top: 40px">

    <div>

      <div *ngIf="selectedThresholdType == 'main'">
        <div class="row m-t-20">
          <div class="col-sm-3 formHeader">
            Name <span class="text-danger">*</span>
          </div>
          <div class="col-lg-4">
            <input [(ngModel)]="loadedData.intune_tenant_friendly_name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
          </div>
        </div>

        <div class="row m-t-20">
          <div class="col-sm-3 formHeader">
            Link to Customer
          </div>
          <div class="col-lg-4">
            <select [(ngModel)]="loadedData.customer_id" class="form-control" aria-label="Default select example">
              <option value="0">Select a Customer</option>
              <option *ngFor="let customer of optionsService.customers" [value]="customer.id">{{ customer.customer_name }}</option>
            </select>
          </div>
        </div>

        <div class="row m-t-20">
          <div class="col-sm-3 formHeader">
            Backup Policies
          </div>
          <div class="col-sm">
            <div class="form-check form-switch form-switch-md">
              <input [(ngModel)]="loadedData.backup_enabled"  class="form-check-input" type="checkbox" id="backup_enabled" checked>
              <label class="form-check-label" for="backup_enabled" style="margin-top: 5px;">

              </label>
            </div>
          </div>
        </div>


        <hr>

        <h4>Missing security patch or unsupported OS</h4>
        <div class="row m-t-20">
          <div class="col-sm-3 formHeader">
            Days until Amber
          </div>
          <div class="col-sm">
            <input [(ngModel)]="loadedData.threshold_patch_amber_days" type="number" style="width:100px" class="form-control" id="threshold_patch_amber_days" placeholder="" autocomplete="off">
          </div>
        </div>

        <div class="row m-t-20">
          <div class="col-sm-3 formHeader">
            Days until Red
          </div>
          <div class="col-sm">
            <input [(ngModel)]="loadedData.threshold_patch_red_days" style="width:100px" type="number" class="form-control" id="threshold_patch_red_days" placeholder="" autocomplete="off">
          </div>
        </div>


        <hr>

        <div class="row m-t-20">
          <div class="col-sm-3 formHeader">
            Force Sync
          </div>
          <div class="col-sm">
            <div (click)="sync()" class="btn btn-primary btn-sm">Sync</div>
          </div>
        </div>
        <!-- Hiding this FOR NOW -->
        <div *ngIf="false">
          <div class="row m-t-20">
            <div class="col-sm-3 formHeader">
              Sync Discovered Apps
            </div>
            <div class="col-sm">
              <div class="form-check form-switch form-switch-md">
                <input [(ngModel)]="loadedData.sync_discovered_apps"  class="form-check-input" type="checkbox" id="sync_discovered_apps" checked>
                <label class="form-check-label" for="sync_discovered_apps" style="margin-top: 5px;">

                </label>
              </div>
            </div>
          </div>
          <hr>
          <div class="row m-t-20">
            <div class="col-sm-3 formHeader">
              Device Inactivity Threshold (Days)
            </div>
            <div class="col-sm">
              <input [(ngModel)]="loadedData.device_inactive_after_days" type="number" class="form-control" id="device_inactive_after_days" placeholder="" autocomplete="off">
            </div>
          </div>
          <div class="row m-t-20">
            <div class="col-sm-3 formHeader">
              Suppress issues for inactive devices
            </div>
            <div class="col-sm">
              <div class="form-check form-switch form-switch-md">
                <input [(ngModel)]="loadedData.device_inactive_suppress_issues"  class="form-check-input" type="checkbox" id="device_inactive_suppress_issues" checked>
                <label class="form-check-label" for="device_inactive_suppress_issues" style="margin-top: 5px;">

                </label>
              </div>          </div>
          </div>
          <div class="row m-t-20">
            <div class="col-sm-3 formHeader">
              Device Inactivity Status
            </div>
            <div class="col-sm">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn  status-selector" [class.grey]="loadedData.device_inactive_status == 1" (click)="loadedData.device_inactive_status = 1">Grey</button>
                <button type="button" class="btn  status-selector" [class.green]="loadedData.device_inactive_status == 2" (click)="loadedData.device_inactive_status = 2">Green</button>
                <button type="button" class="btn  status-selector" [class.amber]="loadedData.device_inactive_status == 3" (click)="loadedData.device_inactive_status = 3">Amber</button>
                <button type="button" class="btn  status-selector" [class.red]="loadedData.device_inactive_status == 4" (click)="loadedData.device_inactive_status = 4">Red</button>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="selectedThresholdType != 'main'" style="max-height: 600px; overflow-y: scroll">

        <div class="row p-10 m-t-5" style="">
          <div class="col"><h4 style="color: #bdbdbd;">NAME</h4></div>
          <div class="col-3"><h4 style="color: #bdbdbd;">NOT APPLIED / FAILED STATUS</h4></div>
<!--          <div *ngIf="selectedThresholdType == 'app'" class="col-3"><h4 style="color: #bdbdbd;">FAILED STATUS</h4></div>-->
        </div>

        <div *ngFor="let threshold of this.getThresholdsForType(); let i = index">
        <!-- Don't show 'Eido_DeviceOverallCompliance' it is a copy of the device_compliance state -->
          <div *ngIf="threshold.resource_id != 'Eido_DeviceOverallCompliance'" class="row p-10 m-t-5" style="background-color: #f1f1f1; border-radius: 20px; min-height: 65px;">
            <div class="col"><h4 style="margin-top: 10px; margin-left: 10px;">{{threshold.threshold_name}}</h4></div>
            <div class="col-3">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn  status-selector" [class.green]="threshold.not_applied_status == 2" (click)="threshold.not_applied_status = 2">Green</button>
                <button type="button" class="btn  status-selector" [class.amber]="threshold.not_applied_status == 3" (click)="threshold.not_applied_status = 3">Amber</button>
                <button type="button" class="btn  status-selector" [class.red]="threshold.not_applied_status == 4" (click)="threshold.not_applied_status = 4">Red</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
