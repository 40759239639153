<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} User</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.user_name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Email <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.email" [disabled]="this.loadedId" type="text" class="form-control" id="Email" placeholder="Email Address" autocomplete="off">

      </div>
    </div>

    <div *ngIf="portalSettings.showMspFeatures()">
      <div class="row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Customer
        </div>
        <div class="col-sm p-10">
                  <select [(ngModel)]="loadedData.customer_id" class="form-control" aria-label="Default select example">
                    <option [value]="null" >All Customers</option>
                    <option *ngFor="let customer of optionsService.customers" [value]="customer.id">{{ customer.customer_name }}</option>
                  </select>
        </div>
      </div>

      <div *ngIf="!this.loadedId && !loadedData.customer_id || loadedData.customer_id == 'null'" class="row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
        </div>
        <div class="col-sm p-10">
          <div style="
      padding: 10px;
      background-color: indianred;
      color: white;
      border-radius: 5px;
      margin-top: 0px;
  ">This will give the user access to ALL customers. Are you sure?</div>

        </div>
      </div>
    </div>

    <h5 class="p-10 m-t-20 p-b-0">Permissions</h5>
    <hr>


    <div class="row">
      <div class="col-sm-4 p-10">
        <input [(ngModel)]="loadedData.is_admin" class="form-check-input" id="is_admin" type="checkbox">
        <label for="is_admin" class="p-l-10">Admin</label>
      </div>
    </div>


    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
      <div *ngIf="loadedData.never_logged_in"  (click)="resend()" class="btn btn-primary float-end">Resend welcome email</div>
    </div>

  </div>
</app-blade-right>
