<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal [modalSize]="'modal-medium'">

  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{data}} drill down</h5>
  </div>

  <div class="container p-2">

    <div class="row divLink"  style="background-color: #f9f9f9; padding: 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
      <div class="col-7"><span>Require BitLocker</span></div>
      <div class="col-2"><span>    <i class="fal fa-times align-middle" style="background-color: indianred; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
</span></div>
      <div class="col-3"><span>Non Compliant</span></div>
    </div>

    <div class="row divLink"  style="background-color: #f9f9f9; padding: 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
      <div class="col-7"><span>Require Secure Boot to be enabled on the device</span></div>
      <div class="col-2"><span>    <i class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
</span></div>
      <div class="col-3"><span>Compliant</span></div>
    </div>

    <div class="row divLink"  style="background-color: #f9f9f9; padding: 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
      <div class="col-7"><span>Require code integrity</span></div>
      <div class="col-2"><span>    <i class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
</span></div>
      <div class="col-3"><span>Compliant</span></div>
    </div>

    <div class="row divLink"  style="background-color: #f9f9f9; padding: 12px; font-size: 14px; margin-top: 4px; border-radius: 12px">
      <div class="col-7"><span>Defender: Require the device to be at or under the machine risk score:</span></div>
      <div class="col-2"><span>    <i class="fal fa-check align-middle" style="background-color: #95b9a7; color: white; padding: 15px; padding-top: 8px; padding-bottom: 8px; border-radius: 10px; margin-left: 3px; width: 45px;text-align: center;"></i>
</span></div>
      <div class="col-3"><span>Compliant</span></div>
    </div>

  </div>

  <div class="modal-footer">
    <div (click)="close()" class="btn btn-cancel" data-bs-dismiss="modal">Close</div>
  </div>
</app-modal>
