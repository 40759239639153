<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal  modalSize="modal-small">

  <div class="modal-body">

    <p style="font-size: 18px;" class="">Windows Update Status Help</p>
    <hr>

    <p class=""><strong>No Support</strong></p>
    <p>Device is running an unsupported operating system.</p>

    <p class="m-t-10"><strong>Missing Updates</strong></p>
    <p>Device is missing important updates</p>

    <p class="m-t-10"><strong>Supported OS</strong></p>
    <p>Device's main OS is supported, though patch status is uncertain. Eido presently only tracks Windows updates.</p>

    <p class="m-t-10"><strong>Up to date</strong></p>
    <p>Percentage of devices with latest patches (if Windows)</p>

    <p class="m-t-10"><strong>Unknown</strong></p>
    <p>We are not sure if this OS is supported or not. Please contact support if you have any questions.</p>


  </div>
  <div class="modal-footer">
    <div (click)="close()" class="btn btn-cancel">Close</div>
  </div>

</app-modal>
