<div style="display:inline-block">
<div (click)="toggleDropdown()" class="divLink eidoFilterButton" [class.filtered]="selectedData.length">

  <span *ngIf="selectedData.length == 0">{{clearFilterOptionName}}</span>

  <span *ngFor="let item of selectedData; let isLast = last" class="tag" style="">
    <strong>{{filterName}}:</strong> {{item[this.label]}}{{isLast ? '' : ','}}
</span>

  <i  class="fa fa-chevron-down float-end" style="padding: 5px;" aria-hidden="true"></i>
</div>

  <div #dropdown *ngIf="showDropdown" class="eidoFilterDropdown">

    <div (click)="clear()" [class.selected]="selectedData.length == 0" class="row eidoFilterDropdownItem divLink">
      <div class="col-10">
        {{this.clearFilterOptionName}}
      </div>
    </div>

    <div (click)="toggleItem(option)" *ngFor="let option of this.data" [class.selected]="isSelected(option)" class="row eidoFilterDropdownItem divLink">
      <div class="col-10">
        {{option[this.label]}}
      </div>
    </div>

    <div class="divLink" *ngIf="multiSelect" (click)="apply()">Save</div>
  </div>

</div>

