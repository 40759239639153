import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewContainerRef} from '@angular/core';
import {OptionsService} from '../../services/options-service/options.service';
import {SharedFunctionsService} from '../../services/shared-functions/shared-functions.service';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {ColDef, ColumnEvent, GridApi, SortDirection} from 'ag-grid-community'; // Column Definition Type Interface

@Component({
  selector: 'app-eido-super-table-server-side',
  templateUrl: './eido-super-table-server-side.component.html',
  styleUrl: './eido-super-table-server-side.component.css'
})
export class EidoSuperTableServerSideComponent implements OnInit {

  @Input() data = [];
  @Input() columns = [];
  @Input() currentPage = 1;
  @Input() totalPages = 1;
  @Input() totalRows = 0;
  @Input() rowsReturned = 0;
  @Input() sortByColumn = null;
  @Input() sortByAsc = null;

  @Input() showExportCsv = true;

  // output columnChanged = new EventEmitter();
  // output pageChanged = new EventEmitter();

  @Input() settings = {
    topMargin: 175,
    onClickOpen: null,
    columns: []
  };

  public sortingOrder: SortDirection[] = ["desc", "asc"];


  @Output() columnChanged = new EventEmitter();
  @Output() pageChanged = new EventEmitter();

  gridApi!: GridApi;

  // disable sorting on all columns
  public defaultColDef = {
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      return null;
    }
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.setInitialSort();
  }

  setInitialSort() {

    console.log('Setting Initial Sort');
    console.log(this.sortByColumn);
    console.log(this.sortByAsc);


    if (this.sortByColumn && this.sortByAsc !== null) {
      this.gridApi.applyColumnState({
        state: [{
          colId: this.sortByColumn,
          sort: this.sortByAsc ? 'asc' : 'desc'
        }],
        defaultState: { sort: null }
      });
    }
  }


  sortchanged(event: ColumnEvent) {
    console.log('Sort Changed EVENTTTTT');

    console.log(event);

    let col = event.columns.find(col => col.getSort())

    console.log(col)


    console.log(this.columns);

    this.columnChanged.emit({
      column: col.getColId(),
      sortAsc: col.getSort() === 'asc' ? 1 : -1
    });
  }


  isLoading = false;

  constructor(public optionsService: OptionsService, public sharedFunctions: SharedFunctionsService, private cdr: ChangeDetectorRef, private dialogService: DialogService, private viewReference: ViewContainerRef) {

  }

  ngOnInit(): void {

  }

  click(row){

  }

  nextPage(){

    // return if already on last page
    if (this.currentPage === this.totalPages) {
      return;
    }

    this.changePage(this.currentPage + 1)

  }

  previousPage(){

// return if already on first page
    if (this.currentPage === 1) {
      return;
    }

    this.changePage(this.currentPage - 1)
  }

  changePage(pageNo) {
    this.pageChanged.emit(pageNo);
  }


}
