import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {GetStartedApiService} from '../../../services/api/get-started/get-started-api.service';
import {ActivatedRoute} from '@angular/router';
import {NavigationApiService} from '../../../services/api/navigation-api/navigation-api.service';
import {UserComponentDialog} from '../team/team-create-edit-dialog/team-create-edit-dialog.component';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {IntuneTenantFriendlyNameComponent} from './intune-tenant-friendly-name/intune-tenant-friendly-name.component';
import {IntuneTenantsApiService} from '../../../services/api/admin/intune-tenants/intune-tenants-api.service';
import {ModalConfirmationComponent} from '../../../shared/modal-confirmation/modal-confirmation.component';
import {EditThresholdDialogComponent} from './edit-threshold-dialog/edit-threshold-dialog.component';
import {IntuneChoosePermissionsComponent} from './intune-choose-permissions/intune-choose-permissions.component';

@Component({
  selector: 'app-intune-configuration',
  templateUrl: './intune-configuration.component.html',
  styleUrls: ['./intune-configuration.component.css']
})
export class IntuneConfigurationComponent implements OnInit {

  constructor(private intuneTenantApi: IntuneTenantsApiService, private getStartedApi: GetStartedApiService, private route: ActivatedRoute, private navApi: NavigationApiService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.loadTenantSettings();
  }

  public intuneError = null;
  public intuneErrorDescription = null;

  public isLoading = true;
  public tenants;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.state) {

        if (typeof params.error == 'undefined') {


          this.dialogService.createDialog(IntuneTenantFriendlyNameComponent, null, this.viewReference)
            .then((data: any) => {

              this.getStartedApi.handleIntuneResponse(params.tenant, params.state, params.admin_consent, params.error, params.error_description, data?.friendly_name, data?.customer_id).then(response => {
                if (params.error) {
                  this.intuneError = params.error;
                  this.intuneErrorDescription = params.error_description;
                }

                this.loadTenantSettings();

              });

            }, dialogCancelled => {

            })


        } else {

          var error = "Intune Sync Error:" + params.error + " Description:" + params.error_description + " " + "Please try again.";

          this.dialogService.createDialog(ModalConfirmationComponent, error, this.viewReference)
            .then(confirmed => {
            }, cancelled => null)
        }

      }
    });
  }


  loadTenantSettings(){
    this.isLoading = true;
    this.intuneTenantApi.listAll().then(response => {
      this.tenants = response;
      this.isLoading = false;
    })
  }

   delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.intuneTenantApi.delete(id).then(response => this.loadTenantSettings())
      }, cancelled => null)
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(EditThresholdDialogComponent, id, this.viewReference)
      .then(dialogSaved => {
        this.loadTenantSettings();
      }, dialogCancelled => {

      })
  }

  grantIntuneAccess() {
    // Replace null with Component
    this.dialogService.createDialog(IntuneChoosePermissionsComponent, null, this.viewReference)
      .then(accessScope => {
        this.getStartedApi.grantIntuneAccess(accessScope.toString(), 'admin/intune-config').then(response => {
          this.goToLink(response.intuneAuthoriseUrl, true);
        });
      })
  }

  goToLink(url: string, sameWindow = false) {
    if (sameWindow) {
      window.open(url, '_self');
    } else {
      window.open(url, '_blank');
    }
  }

}
