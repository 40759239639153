// status-label-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-status-label-renderer',
  standalone: true,
  imports: [
    NgClass
  ],
  template: `
    <span *ngIf="statusLabel != null" style="font-size: 12px; border: none;" class="m-0 text-shorten numberBadge" [ngClass]="{
      'grey': status == 1,
      'green': status == 2,
      'amber': status == 3,
      'red': status == 4
    }">{{statusLabel}}</span>
  `
})
export class StatusLabelRendererComponent implements ICellRendererAngularComp {
  public status: any;
  public statusLabel: string | null = null;

  agInit(params: any): void {
    this.status = params.data[params.colDef.statusColumn];
    this.statusLabel = params.value;
  }

  refresh(params: any): boolean {
    this.status = params.data[params.colDef.statusColumn];
    this.statusLabel = params.value;
    return true;
  }
}
