import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {timer} from 'rxjs';
import {DashboardApiService} from '../../services/api/dashboard/dashboard-api-service.service';
import {DeviceApiService} from '../../services/api/device/device-api.service';
import {OfficeApiService} from '../../services/api/office/office-api.service';
import {PortalSettingsService} from '../../services/portal-settings/portal-settings.service';
import {OptionsService} from '../../services/options-service/options.service';
import {CustomersComponentDialog} from '../admin/customers/team-create-edit-dialog/customers-create-edit-dialog.component';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {
  MspDashboardAllCustomersTabHelpComponent
} from './msp-dashboard-all-customers-tab-help/msp-dashboard-all-customers-tab-help.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-all-customers.component.html',
  styleUrls: ['./dashboard-all-customers.component.css']
})
export class DashboardAllCustomersComponent implements OnInit {

  public isLoading = true;
  public data;

  public tab = 'all-customers'

  // Automatic Refresh
  private refreshTimer = timer(60000, 60000);
  private timerSubscription;

  ngOnInit(): void {
  }

  constructor(private dashboardApiService: DashboardApiService, public portalSettings: PortalSettingsService, public optionsService: OptionsService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.refreshData();

    // Subscribe to the timer
     //this.timerSubscription = this.refreshTimer.subscribe(val => this.refreshData());
  }

  public refreshData(){
    this.dashboardApiService.getMspDashboardStats().then(response => {
      this.data = response;
      this.isLoading = false;
    });
  }

  showHelp(){
    // Replace null with Component
    this.dialogService.createDialog(MspDashboardAllCustomersTabHelpComponent, null, this.viewReference)
      .then(dialogSaved => {
      }, dialogCancelled => {

      })
  }
  ngOnDestroy() {
    // Unsubscribe from the timer
    //this.timerSubscription.unsubscribe();
  }

  statusColour(statusNumber) {
    switch (statusNumber) {
      case 1:
        return 'grey';
      case 2:
        return 'green';
      case 3:
        return 'amber';
      case 4:
        return 'red';
      default:
        return 'grey';
    }
  }

  getCustomerStatus(percent:any) {
    if(percent < 80) {
      return 'red';
    } else if (percent < 90) {
      return 'amber';
    } else {
      return 'green';
    }
  }

}
