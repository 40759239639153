import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToSpacePipe',
})
export class CamelCaseToSpacePipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
      return '';
    }

    // return if not a string
    if (typeof value !== 'string') {
      return value;
    }

    // Add a space before all caps and uppercase the first character
    return value.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });
  }

}
