<app-modal *ngIf="bulkChangeShowProgressWindow" modalSize="modal-medium">
  <div class="p-20 p-b-0">
    <i *ngIf="bulkChangeInProgress == 0" (click)="this.bulkChangeShowProgressWindow = false" class="fas fa-times fa-2x float-end divLink"></i>

    <h3>{{bulkActionName}}</h3>
  </div>

  <div class="dashboard-location-container" style="margin: 20px; margin-bottom:10px">


    <div *ngIf="this.bulkChangeCompletedCount > 0" [style.width.%]="(bulkChangeCompletedCount / bulkChangeTotalCount) * 100"
         [matTooltip]="bulkChangeCompletedCount+' green devices'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"
         class="divLink dashboard-location-bar green">
      <p class="dashboard-location-container-percentage">{{Math.round((bulkChangeCompletedCount /bulkChangeTotalCount) * 100)}}% Successful</p>
    </div>

    <div
      [style.width.%]="(bulkChangeFailedCount / bulkChangeTotalCount) * 100" *ngIf="bulkChangeFailedCount > 0"
      [matTooltip]="bulkChangeFailedCount+' Failed'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"
      class="divLink dashboard-location-bar red">
      <p class="dashboard-location-container-percentage">{{Math.round((bulkChangeFailedCount / bulkChangeTotalCount) * 100)}}% Failed</p>
    </div>

    <div
      [style.width.%]="(bulkChangeInProgressCount / bulkChangeTotalCount) * 100" *ngIf="bulkChangeInProgressCount > 0"
      [matTooltip]="bulkChangeInProgressCount+' Pending'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"
      class="divLink dashboard-location-bar grey">
      <p class="dashboard-location-container-percentage">{{Math.round((bulkChangeInProgressCount / bulkChangeTotalCount) * 100)}}% Pending</p>
    </div>
  </div>


  <div class="modal-body" style="margin: 10px; max-height: 60vh; overflow-y: auto;">
    <div *ngFor="let item of bulkChangePromises">
      <div class="row">
        <div class="col-1">
          <i class="fas fa-spinner fa-spin" style=" font-size: 18px" *ngIf="item.status == 'inProgress'"></i>
          <i class="fas fa-check" style="color: #176427; font-size: 18px" *ngIf="item.status == 'completed'"></i>
          <i class="fas fa-times" style="color: indianred; font-size: 18px" *ngIf="item.status == 'failed'"></i>
        </div>
        <div class="col-11">
          {{ item.policyName }}
        </div>
      </div>
    </div>
  </div>
</app-modal>


<div class="row">
  <div class="col-2">
    <div (click)="selectedTab = 'managed'" class="largeTab divLink" [class.active]="selectedTab == 'managed'">
      <span class="figure">{{this.managedPolicies.length}}</span>
      <div class="label">Managed Policies

        <i class="fas fa-question-circle" [matTooltip]="'Managed Policies are those either deployed using Eido or linked to a template.'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>

      </div>
    </div>

  </div>
  <div class="col-2">
    <div (click)="selectedTab = 'unmanaged'" class="largeTab divLink" [class.active]="selectedTab == 'unmanaged'">
      <span class="figure">{{this.unmanagedPolicies.length}}</span>
      <div class="label">Discovered Policies
        <i class="fas fa-question-circle" [matTooltip]="'Discovered Policies are auto-discovered from Intune and will still be backed up, but they were not deployed using Eido or linked to a template.'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div (click)="selectedTab = 'templates'" class="largeTab divLink" [class.active]="selectedTab == 'templates'">
      <span class="figure">{{this.templates.length}}</span>
      <div class="label">Templates
        <i class="fas fa-question-circle" [matTooltip]="'Templates are policies that have been converted to a template'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>

      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="sectionBox">
    <div *ngIf="selectedTab == 'managed'">
      <app-eido-grid
        [columns]="managedPoliciesGridSettings.columns"
        [viewPortHeight]="'85vh'"
        [data]="managedPolicies"
        [actionButtons]="managedPoliciesGridSettings.actionButtons"
        [multiSelect]="false"
        [multiSelectActions]="managedPoliciesGridSettings.multiSelectActions"
        (rowClick)="managedPoliciesGridSettingsRowClicked($event)"
        (actionButtonClick)="managedPoliciesGridSettingsActionClicked($event)"
        (multiSelectAction)="managedPoliciesGridSettingsMultiSelectAction($event)"

      ></app-eido-grid>
    </div>

    <div *ngIf="selectedTab == 'unmanaged'">
      <app-eido-grid
        [columns]="discoveredPoliciesGridSettings.columns"
        [viewPortHeight]="'85vh'"
        [data]="unmanagedPolicies"
        [actionButtons]="discoveredPoliciesGridSettings.actionButtons"
        [multiSelect]="true"
        [multiSelectActions]="discoveredPoliciesGridSettings.multiSelectActions"
        (rowClick)="managedPoliciesGridSettingsRowClicked($event)"
        (actionButtonClick)="managedPoliciesGridSettingsActionClicked($event)"
        (multiSelectAction)="managedPoliciesGridSettingsMultiSelectAction($event)"

      ></app-eido-grid>
    </div>

    <div *ngIf="selectedTab == 'templates'">
      <app-eido-grid
        [columns]="templatesGridSettings.columns"
        [viewPortHeight]="'85vh'"
        [data]="templates"
        [actionButtons]="templatesGridSettings.actionButtons"
        [multiSelect]="true"
        [multiSelectActions]="templatesGridSettings.multiSelectActions"
        (rowClick)="templatesGridSettingsRowClicked($event)"
        (actionButtonClick)="templatesGridSettingsActionClicked($event)"
        (multiSelectAction)="templatesGridSettingsMultiSelectAction($event)"

      ></app-eido-grid>
    </div>
  </div>
</div>
