<!--<div class="col divLink deviceDetailsTab" ><h3>Intune Health & Compliance</h3></div>-->

<div *ngIf="!isLoading">

  <div class="row">
    <div style="width: 150px;">
      <div class="dashboardTile divLink" (click)="tab = 'all-customers'">
        <h5 class="value">{{this.data.customer_count}}</h5><h6 class="label">Customers</h6>
      </div>
    </div>

    <div style="width: 150px;">
      <div class="dashboardTile divLink"  [routerLink]="['/devices']">
        <h5 class="value">{{this.data.device_count}}</h5><h6 class="label">Devices</h6>
      </div>
    </div>

    <div class="col">
      <div class="dashboardTile divLink" [routerLink]="['/issues']"  [queryParams]="{filter_intune_metric_type: 'Compliance'}">

        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
          <div style=" font-size: 12px; border-left: 4px solid #c97777; padding: 5px; padding-left: 10px;">{{this.data.intune_breakdown.intune_issues_compliance_red}} Urgent</div>
          <div style="font-size: 12px;border-left: 4px solid #eaaf6d;padding: 5px;margin-top: 5px;padding-left: 10px;">{{this.data.intune_breakdown.intune_issues_compliance_amber}} Medium</div>
        </div>
<!--        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">-->
<!--          <i class="fas fa-shield-alt fa-3x green" aria-hidden="true"></i>-->
<!--        </div>-->
        <h5 class="value">{{this.data.intune_breakdown.intune_issues_compliance_total}}</h5><h6 class="label">Compliance Issues</h6>
      </div>
    </div>

    <div class="col">
      <div class="dashboardTile divLink" [routerLink]="['/issues']"  [queryParams]="{filter_intune_metric_type: 'Config'}">
        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
          <div style=" font-size: 12px; border-left: 4px solid #c97777; padding: 5px; padding-left: 10px;">{{this.data.intune_breakdown.intune_issues_config_red}} Urgent</div>
          <div style="font-size: 12px;border-left: 4px solid #eaaf6d;padding: 5px;margin-top: 5px;padding-left: 10px;">{{this.data.intune_breakdown.intune_issues_config_amber}} Medium</div>
        </div>
        <h5 class="value">{{this.data.intune_breakdown.intune_issues_config_total}}</h5><h6 class="label">Config Issues</h6>
      </div>
    </div>

    <div class="col">
      <div class="dashboardTile divLink" [routerLink]="['/issues']" [queryParams]="{filter_intune_metric_type: 'App'}">
        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block p-0">
          <div style=" font-size: 12px; border-left: 4px solid #c97777; padding: 5px; padding-left: 10px;">{{this.data.intune_breakdown.intune_issues_app_red}} Urgent</div>
          <div style="font-size: 12px;border-left: 4px solid #eaaf6d;padding: 5px;margin-top: 5px;padding-left: 10px;">{{this.data.intune_breakdown.intune_issues_app_amber}} Medium</div>
        </div>
        <h5 class="value">{{this.data.intune_breakdown.intune_issues_app_total}}</h5><h6 class="label">App Issues</h6>
      </div>
    </div>



<!--    <div class="col-1">-->
<!--      <div class="dashboardTile divLink" [routerLink]="['/intune-health/devices']" [queryParams]="">-->
<!--        &lt;!&ndash;        <div class="float-end icon d-none d-sm-none d-md-none d-lg-block">&ndash;&gt;-->
<!--        &lt;!&ndash;          <i class="fas fa-shield-alt fa-3x green" aria-hidden="true"></i>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <h5 class="value">99</h5><h6 class="label">Inactive</h6>-->






<!--      </div>-->
<!--    </div>-->





  </div>


  <div class="row">
    <div class="col-12 p-r-10">

      <div *ngIf="tab == 'all-customers'">
        <div class="dashboardCustomerContainer">
          <div class="p-0">
            <div class="row m-t-5">
              <div class="col-3" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
                <p style="color: #818f95;font-size: 18px;">CUSTOMER</p>
              </div>

              <div class="col-1 text-center" style="padding: 15px; padding-bottom: 3px; padding-left: 5px;">
                <p style="color: #818f95;font-size: 18px;">DEVICES</p>
              </div>

              <div class="col text-center" style="padding: 15px; padding-bottom: 3px; padding-left: 5px;">
                <p style="color: #818f95;font-size: 18px;">COMPLIANCE (%)</p>

              </div>

              <div class="col text-center" style="padding: 15px; padding-bottom: 3px; padding-left: 5px;">
                <p style="color: #818f95;font-size: 18px;">CONFIG HEALTH (%)</p>

              </div>

              <div class="col text-center" style="padding: 15px; padding-bottom: 3px; padding-left: 5px;">
                <p style="color: #818f95;font-size: 18px;">APP HEALTH (%)</p>
              </div>

              <div class="col text-center" style="padding: 15px; padding-bottom: 3px; padding-left: 5px;">
                <i (click)="showHelp()" class="fas fa-info-circle divLink float-end" aria-hidden="true" style="
      color: #556c7f;
      font-size: 25px;
  "></i>
                <p style="color: #818f95;font-size: 18px;">PATCH HEALTH (%)</p>
              </div>

            </div>
          </div>


  <!--        <div *ngFor="let customer of data.customer_breakdown" class="sectionBox p-0">-->

  <!--          <div class="row m-t-5">-->
  <!--            <div class="col-3" style="padding: 15px">-->
  <!--              <p style="color: #707e85; font-size: 17px;">{{customer.customer_name}}</p>-->
  <!--            </div>-->

  <!--            <div class="col-1" style="">-->
  <!--              <div class="dashboardCustomerDeviceCountLabel">{{customer.tenant_count}}</div>-->
  <!--            </div>-->

  <!--            <div class="col-1" style="">-->
  <!--              <div class="dashboardCustomerDeviceCountLabel">{{customer.device_count}}</div>-->
  <!--            </div>-->

  <!--            <div class="col" style="">-->
  <!--              <div class="dashboardCustomerComplianceLabel">{{customer.intune_compliance_percent}}%</div>-->
  <!--            </div>-->

  <!--            <div class="col" style="">-->
  <!--              <div class="dashboardCustomerComplianceLabel">{{customer.intune_issues_config_total}}</div>-->
  <!--            </div>-->

  <!--            <div class="col" style="">-->
  <!--              <div class="dashboardCustomerComplianceLabel">{{customer.intune_issues_app_total}}</div>-->
  <!--            </div>-->



  <!--            &lt;!&ndash;            <div class="col" style="">&ndash;&gt;-->
  <!--&lt;!&ndash;              <app-vertical-percent-indicator&ndash;&gt;-->
  <!--&lt;!&ndash;                [total]="customer.device_count"&ndash;&gt;-->
  <!--&lt;!&ndash;                [red]="customer.device_issues_red"&ndash;&gt;-->
  <!--&lt;!&ndash;                [amber]="customer.device_issues_amber"&ndash;&gt;-->
  <!--&lt;!&ndash;                [routerLink]="'/intune-health/devices'"&ndash;&gt;-->
  <!--&lt;!&ndash;                [greenLinkParams]="{}"&ndash;&gt;-->
  <!--&lt;!&ndash;                [amberLinkParams]="{status: 'unhealthy'}"&ndash;&gt;-->
  <!--&lt;!&ndash;                [redLinkParams]="{status: 'unhealthy'}"&ndash;&gt;-->
  <!--&lt;!&ndash;              ></app-vertical-percent-indicator>&ndash;&gt;-->
  <!--&lt;!&ndash;            </div>&ndash;&gt;-->

  <!--            <div style="width: 60px; padding: 15px; font-size: 18px;"><i class="fas fa-search-plus"></i></div>-->
  <!--          </div>-->
  <!--        </div>-->

          <div *ngFor="let customer of data.customer_breakdown" class="p-0">

            <div class="row m-t-5">
              <div class="col-3" style="">
                <div class="sectionBox">
                  <p style="color: #707e85; font-size: 17px;">{{customer.customer_name}}</p>
                </div>
              </div>

              <div class="col-1 divLink" style="" [routerLink]="['/devices']"  [queryParams]="{filter_customer_name: customer.customer_name}">
                <div class="dashboardCustomerDeviceCountLabel">{{customer.device_count}}</div>
              </div>

              <div class="col divLink" style="" [routerLink]="['/devices']"  [queryParams]="{filter_customer_name: customer.customer_name, 'filter_intune_compliance_status_name': 'Red,Amber'}">
                <div class="dashboardCustomerComplianceLabel" [ngClass]="getCustomerStatus(customer.intune_compliance_percent)">{{customer.intune_compliance_percent}} %</div>
              </div>

              <div class="col divLink" style="" [routerLink]="['/devices']"  [queryParams]="{filter_customer_name: customer.customer_name, 'filter_intune_config_status_name': 'Red,Amber'}">
                <div class="dashboardCustomerComplianceLabel" [ngClass]="getCustomerStatus(customer.intune_config_health_percent)">{{customer.intune_config_health_percent}} %</div>
              </div>

              <div class="col divLink" style="" [routerLink]="['/devices']"  [queryParams]="{filter_customer_name: customer.customer_name, 'filter_intune_app_status_name': 'Red,Amber'}">
                <div class="dashboardCustomerComplianceLabel" [ngClass]="getCustomerStatus(customer.intune_app_health_percent)">{{customer.intune_app_health_percent}} %</div>
              </div>

              <div class="col divLink" style="" [routerLink]="['/reporting-updates/windows-updates-devices']"  [queryParams]="{filter_customer_name: customer.customer_name}">
                <div class="dashboardCustomerComplianceLabel" [ngClass]="getCustomerStatus(customer.patch_compliance_percent)">{{customer.patch_compliance_percent}} %</div>
              </div>



              <!--            <div class="col" style="">-->
              <!--              <app-vertical-percent-indicator-->
              <!--                [total]="customer.device_count"-->
              <!--                [red]="customer.device_issues_red"-->
              <!--                [amber]="customer.device_issues_amber"-->
              <!--                [routerLink]="'/intune-health/devices'"-->
              <!--                [greenLinkParams]="{}"-->
              <!--                [amberLinkParams]="{status: 'unhealthy'}"-->
              <!--                [redLinkParams]="{status: 'unhealthy'}"-->
              <!--              ></app-vertical-percent-indicator>-->
              <!--            </div>-->

            </div>
          </div>

        </div>
      </div>

      <div *ngIf="tab == 'devices'">
        <div class="dashboardCustomerContainer">
          <div class="p-0">
            <div class="row m-t-5">
              <div class="col-3" style="padding: 15px; padding-left: 5px; padding-bottom: 3px;">
                <p style="color: #818f95;font-size: 18px;">CUSTOMER</p>
              </div>

              <div class="col-1 text-center" style="padding: 15px; padding-bottom: 3px; padding-left: 5px;">
                <p style="color: #818f95;font-size: 18px;">DEVICES</p>
              </div>

              <div class="col text-center" style="padding: 15px; padding-bottom: 3px; padding-left: 5px;">
                <p style="color: #818f95;font-size: 18px;">DEVICE STATUS %
                </p>

              </div>


              <div style="width: 60px; padding: 15px; font-size: 18px; padding-bottom: 3px;"><i (click)="showHelp()" class="fas fa-info-circle divLink" aria-hidden="true" style="
      color: #556c7f;
      font-size: 25px;
  "></i></div>
            </div>
          </div>

          <div *ngFor="let customer of data.customer_breakdown" class="p-0">

            <div class="row m-t-5">
              <div class="col-3" style="">
                <div class="sectionBox">
                  <p style="color: #707e85; font-size: 17px;">{{customer.customer_name}}</p>
                </div>
              </div>

              <div class="col-1" style="margin-right: 3px">
                <div class="dashboardCustomerDeviceCountLabel">{{customer.device_count}}</div>
              </div>

                                      <div class="col" style="">
                            <app-vertical-percent-indicator
                              [total]="customer.device_count"
                              [red]="customer.device_issues_red"
                              [amber]="customer.device_issues_amber"
                              [routerLink]="'/intune-health/devices'"
                              [greenLinkParams]="{}"
                              [amberLinkParams]="{status: 'unhealthy'}"
                              [redLinkParams]="{status: 'unhealthy'}"
                            ></app-vertical-percent-indicator>
                          </div>



              <div style="width: 60px; padding: 10px; font-size: 18px;"><i class="fas fa-search-plus"></i></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

<!--  <div class="row m-t-20">-->
<!--    <div class="col">-->
<!--      <div class="col divLink deviceDetailsTab" ><h4>Compliance Trend</h4></div>-->
<!--      <div class="sectionBox" style="height: 100px">-->
<!--        <div class="row" style="height: 50px">-->
<!--          <div class="columnContainer col"-->
<!--               [matTooltip]="[0 + ' Issues']"-->
<!--               [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"><div [style.height.px]="100" class="trendGraph-column red"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="14" class="trendGraph-column red"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--          <div class="columnContainer col"><div [style.height.px]="4" class="trendGraph-column"></div></div>-->
<!--        </div>-->
<!--        <div class="row" style="margin-top: 6px">-->
<!--          <div class="col text-center trendGraph-border-right">00:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">1:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">2:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">3:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">4:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">5:00</div>-->
<!--          <div class="col text-center trendGraph-border-right">6:00</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</div>
