import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {PolicyApiService} from '../../../services/api/policy/policy-api.service';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {TemplatesApiService} from '../../../services/api/templates/templates-api.service';
import {IntuneTenantsApiService} from '../../../services/api/admin/intune-tenants/intune-tenants-api.service';
import {TemplateEditDialogComponent} from '../template-edit/template-edit-dialog.component';
import {TemplateDeployDialogComponent} from '../template-deploy-dialog/template-deploy-dialog.component';
import {PolicyDialogComponent} from '../../policies/policy-dialog/policy-dialog.component';


@Component({
  selector: 'app-template-view-edit',
  templateUrl: './template-view-dialog.component.html',
  styleUrls: ['./template-view-dialog.component.css']
})
export class TemplateViewDialogComponent implements OnInit {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  selectedTab = 'main';
  versionSelectedSubTab = 'deployments'

  isLoading = true;
  loadedId = null;

  versionsExpanded = [];

  loadedData;

  public policyGridSettings = {
    actionButtons: ['View', 'Deploy'],
    multiSelect: false,
    multiSelectActions: [],
    columns: [
      { field: 'intune_tenant_friendly_name', headerName: 'Tenant', checkboxSelection: false, headerCheckboxSelection: false, },
      { field: 'policy_name', headerName: 'Name' },
      { field: 'policy_type_name', headerName: 'Policy Type' },
      { field: 'version_no', headerName: 'Deployed Version' },
      { field: 'drift_status', headerName: 'Drift Status' },
      { field: 'created_at', headerName: 'Created', cellRenderer: 'amTimeAgoRenderer' },
      { field: 'updated_at', headerName: 'Last Updated', cellRenderer: 'amTimeAgoRenderer' }
    ]
  }

  constructor(
    private templatesApiService: TemplatesApiService, private viewReference: ViewContainerRef, private dialogService: DialogService, private intuneTenantApi: IntuneTenantsApiService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    }
  }

  getData(){
    this.isLoading = true;

    this.templatesApiService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })

  }

  getPoliciesByVersionNo(versionNo){
    return this.loadedData.policies.filter(policy => policy.version_no === versionNo);
  }

  save(){
    this.templatesApiService.update(this.loadedId, this.loadedData).then(response => {
      this.close();
    })
  }

  newVersion(){
    this.templatesApiService.newVersion(this.loadedId).then(response => {
      this.getData();
    })
  }
  close(){
    this.remove.emit(null);
  }

  toggleVersion(version){
    this.versionSelectedSubTab = 'deployments';
    if(this.versionsExpanded.includes(version)){
      this.versionsExpanded = this.versionsExpanded.filter(item => item !== version);
    } else {
      this.versionsExpanded.push(version);
    }
  }

  isVersionExpanded(version){
    return this.versionsExpanded.includes(version);
  }

  public actionClicked(data:any) {

    if(data.action === 'Deploy') {
      console.log(data);
      // Replace null with Component

      // prepare payload
      let payload = {
        template_id: this.loadedId,
        policy_uuid: data.rowData.policy_uuid,
        eido_intune_config_id: data.rowData.eido_intune_config_id
      }

      this.dialogService.createDialog(TemplateDeployDialogComponent, payload, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

    if(data.action === 'View') {
      console.log(data);
      this.dialogService.createDialog(PolicyDialogComponent, data.rowData.eido_policy_id, this.viewReference)
        .then(dialogSaved => {
          this.getData();
        }, dialogCancelled => {
          this.getData();
        })
    }

  }



}
