import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vertical-percent-indicator',
  templateUrl: './vertical-percent-indicator.component.html',
  styleUrls: ['./vertical-percent-indicator.component.css']
})
export class VerticalPercentIndicatorComponent implements OnInit {

  @Input() amber:number;
  @Input() red:number;
  @Input() total:number;

  @Input() showPercentages:boolean = false;


  @Input() link:any;
  @Input() amberLinkParams:object;
  @Input() redLinkParams:object;
  @Input() greenLinkParams:object;

  green(){
    return this.total - this.amber - this.red;
  }

  constructor(private router: Router) {

  }

  ngOnInit(): void {
  }

  redirectToPage(page: any, queryParams:any) {
    console.log("Redirecting to page: " + page);

    // page could contain / so we need to split it into an array
    let pages = page.split('/');

    let redirectTo = ['/'];
    // add pages to page
    pages.forEach(function (item, index) {
      redirectTo.push(item);
    });

    this.router.navigate(redirectTo, { queryParams });
  }

  protected readonly Math = Math;
}
