import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {RootApiService} from '../../../services/api/root-api/root-api.service';
import {Router} from '@angular/router';
import {DialogService} from '../../../services/dialog-service/dialog.service';

@Component({
  selector: 'app-root-agent-deploy',
  templateUrl: './root-agent-deploy.component.html',
  styleUrls: ['./root-agent-deploy.component.css']
})
export class RootAgentDeployComponent implements OnInit {

  public isLoading;
  public data;

  constructor(private rootApi: RootApiService, private router: Router, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getModuleVersions(module) {
    return this.data.filter(d => d.module == module);
  }

  deployModule(module, id) {
    this.rootApi.deploySoftware(id, module).then(response => {
      this.getData();
    })
  }

  getData() {
    this.isLoading = true;
    this.rootApi.getAgentVersions().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

}
