import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceApiService} from '../../../services/api/device/device-api.service';
import {PolicyApiService} from '../../../services/api/policy/policy-api.service';
import {Dialog} from '../../../shared/dialog/dialog';

@Component({
  selector: 'app-intune-drill-down-modal',
  templateUrl: './intune-drill-down-modal.component.html',
  styleUrls: ['./intune-drill-down-modal.component.css']
})
export class IntuneDrillDownModalComponent implements Dialog {

  @Input() data; // {device_id, current_policy_id}
  @Output() remove = new EventEmitter<any>();



  constructor(private deviceApi: DeviceApiService, private policyApi: PolicyApiService) { }

  ngOnInit(): void {

  }

  close(){
    this.remove.emit(null);
  }

}
