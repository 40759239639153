import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-highchart-pie-semi',
  templateUrl: './highchart-pie-semi.component.html',
  styleUrls: ['./highchart-pie-semi.component.css']
})
export class HighchartPieSemiComponent implements OnInit {

  @Input() inputData: [any];
  @Input() useStandardColours: Boolean = false;

  public showGraph = false;

  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartOptions;
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) { null } // optional function, defaults to null
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false

  ngOnInit(): void {

    // Colours:
    this.inputData.forEach(row => {
      if(!row.hasOwnProperty('Colour')){
        row.Colour = this.assignColour(row.name);
      }
    })

    let arrayOfColours = this.inputData.map(a => a.Colour);

    if(this.useStandardColours){
      arrayOfColours = [
        "#db946d",
        "#70ab9c",
        "#e59b98",
        "#a7a02d",];
    }

    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'rgba(0,0,0,0)',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {fontFamily: '"Poppins","Montserrat","Noto Sans","Helvetica Neue",Helvetica,Arial,sans-serif'}

      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)',
        style: {
          fontSize: '13px',
        }
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          borderWidth: 2,
          borderColor: 'white',
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f} %',
            style: {
              fontFamily: '"Poppins","Montserrat","Noto Sans","Helvetica Neue",Helvetica,Arial,sans-serif',
              fontSize: '15px',
              border: 'none',
              textOutline: 'none',
              color: '#858c90',
              fontWeight: 'none'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '110%'],
          size: '120%'
        }
      },
      series: [{
        name: 'Tickets',
        type: 'pie',
        colorByPoint: true,
        data: this.inputData,
        colors: arrayOfColours
      }]
    };

    this.showGraph = true;
  }

  assignColour(value){

    switch ((value || '').toLowerCase()) {
      case 'compliant':
        return '#95b9a7';
      case 'nonCompliant':
        return '#c97777';
      default:
        return '#c97777';
    }

  }

}
