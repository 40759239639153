// am-time-ago-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-am-time-ago-renderer',
  standalone: true,
  template: `<p class="m-0">{{timeAgo}}</p>`
})
export class AmTimeAgoRendererComponent implements ICellRendererAngularComp {
  public timeAgo: string;
  public params: any;

  agInit(params: any): void {
    this.params = params;
    this.timeAgo = params.value ? moment(params.value).fromNow() : '';
  }

  refresh(params: any): boolean {
    this.params = params;
    this.timeAgo = params.value ? moment(params.value).fromNow() : '';
    return true;
  }
}
