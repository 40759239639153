import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {ModalConfirmationComponent} from '../../../shared/modal-confirmation/modal-confirmation.component';
import {AlertsService} from '../../../services/api/admin/alerts/alerts.service';
import {AlertsComponentDialogComponent} from './alerts-component-dialog/alerts-component-dialog.component';
import {PortalSettingsService} from '../../../services/portal-settings/portal-settings.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  data;
  isLoading = true;

  tab = 'all';

  constructor(private apiAlertsService: AlertsService, private dialogService: DialogService, private viewReference: ViewContainerRef, public portalSettings: PortalSettingsService){
    this.getData();
  }

  ngOnInit(): void {
  }

  getFilteredData(){
    if(this.tab == 'all'){
      return this.data;
    }

    if(this.tab == 'tenant'){
      return this.data.filter(alert => alert.customer_id == null);
    }

    if(this.tab == 'customer'){
      return this.data.filter(alert => alert.customer_id);
    }
  }

  getData(){
    this.isLoading = true;
    this.apiAlertsService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AlertsComponentDialogComponent, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AlertsComponentDialogComponent, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAlertsService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }

}
