import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {OptionsService} from '../../services/options-service/options.service';
import * as moment from 'moment/moment';
import {SharedFunctionsService} from '../../services/shared-functions/shared-functions.service';
import {ActivatedRoute} from '@angular/router';
import {DialogService} from '../../services/dialog-service/dialog.service';
import {DeviceDetailsBladeComponent} from '../../portal/device-details-blade/device-details-blade.component';
import {StatusIconRendererComponent} from '../ag-grid-renderers/status-icon-renderer.component';
import {AmTimeAgoRendererComponent} from '../ag-grid-renderers/am-time-ago-renderer.component';
import {StatusBadgeRendererComponent} from '../ag-grid-renderers/status-badge-renderer.component';
import {NumberBadgeRendererComponent} from '../ag-grid-renderers/number-badge-renderer.component';
import {PercentBadgeRendererComponent} from '../ag-grid-renderers/percent-badge-renderer.component';
import {PercentBadgePatchRendererComponent} from '../ag-grid-renderers/percent-badge-patch-renderer.component';
import {TicketIdUrlRendererComponent} from '../ag-grid-renderers/ticket-id-url-renderer.component';
import {IssueTypeRendererComponent} from '../ag-grid-renderers/issue-type-renderer.component';
import {StatusLabelRendererComponent} from '../ag-grid-renderers/status-label-renderer.component';
import {ActionsParamsRenderer} from '../ag-grid-renderers/actions-renderer.component';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {CustomButtonComponent} from '../ag-grid-renderers/ag-grid-button-renderer/ag-grid-button-renderer.component';
import {PolicySyncStatusRendererComponent} from '../ag-grid-renderers/policy-sync-status';

@Component({
  selector: 'app-eido-grid',
  templateUrl: './eido-grid.component.html',
  styleUrl: './eido-grid.component.css'
})
export class EidoGridComponent implements OnInit {

  public api!: GridApi;

  @Input() data: any[] = [];
  @Input() columns: any[] = [];
  @Input() multiSelect: boolean = false;
  @Input() multiSelectActions: string[] = [];
  @Input() allowRowClick: boolean = false;
  @Input() actionButtons: string[] = [];

  @Input() viewPortHeight: string = null;


  public selectedRows: any[] = [];

  @Output() rowClick = new EventEmitter<any>();
  @Output() multiSelectAction = new EventEmitter<any>();
  @Output() actionButtonClick = new EventEmitter<any>();

  constructor(public optionsService: OptionsService, public sharedFunctions: SharedFunctionsService, private cdr: ChangeDetectorRef, private dialogService: DialogService, private viewReference: ViewContainerRef) {
  }

  ngOnInit(): void {

    console.log("columns", this.columns)

    this.columns = [...this.columns];

    this.columns.push({
      headerName: '',
      field: 'actions',
      pinned: 'right',
      cellRenderer: 'actionsParamsRenderer',
      cellRendererParams: {
        buttonLabels: this.actionButtons,
        onClick: (buttonLabel: string, rowData: any) => {
          this.actionButtonClicked(buttonLabel, rowData);
          console.log(`Button pressed: ${buttonLabel}`, rowData);
          // Implement your specific action here based on the button pressed
        }
      },
      resizable: false,
      sortable: false,
    });

    console.log("pushed actions column")

  }

  onGridReady = (event: GridReadyEvent) => {
    // Store the api for later use
    this.api = event.api;
  }

  rowClicked(event: any) {
    if(this.allowRowClick){
      this.rowClick.emit(event.data);
    }
  }

  onSelectionChanged() {
    // Use getSelectedRows instead of getSelectedNodes
    this.selectedRows = this.api.getSelectedRows();
  }

  multiSelectActionClicked(action: string) {
    let payload = {
      action: action,
      selectedRows: this.api.getSelectedRows()
    }
    this.multiSelectAction.emit(payload);
  }

  actionButtonClicked(action: string, rowData: any) {
    let payload = {
      action: action,
      rowData: rowData
    }
    this.actionButtonClick.emit(payload);
  }
  getSelectedRows(){
    console.log(this.api.getSelectedRows());
  }

  public frameworkComponents = {
    statusIconRenderer: StatusIconRendererComponent,
    amTimeAgoRenderer: AmTimeAgoRendererComponent,
    statusBadgeRenderer: StatusBadgeRendererComponent,
    numberBadgeRenderer: NumberBadgeRendererComponent,
    percentBadgeRenderer: PercentBadgeRendererComponent,
    percentBadgePatchRenderer: PercentBadgePatchRendererComponent,
    ticketIdUrlRenderer: TicketIdUrlRendererComponent,
    issueTypeRenderer: IssueTypeRendererComponent,
    statusLabelRenderer: StatusLabelRendererComponent,
    customButtonComponent: CustomButtonComponent,
    actionsParamsRenderer: ActionsParamsRenderer,
    policySyncStatusRendererComponent: PolicySyncStatusRendererComponent
  };


}
