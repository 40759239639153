<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal modalSize="modal-medium">
  <div class="p-20 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Policy Viewer</h3>
  </div>
  <hr>

  <div class="p-0 p-b-0">
    <app-json-tree [data]="data"></app-json-tree>
  </div>
</app-modal>
