import {Component, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {RaiseABugDialogComponent} from '../shared/raise-a-bug-dialog/raise-a-bug-dialog.component';
import {DialogService} from '../services/dialog-service/dialog.service';
import {Location} from '@angular/common';
import {AuthenticationService} from '../services/authentication/authentication.service';
import {GetStartedApiService} from '../services/api/get-started/get-started-api.service';
import {ActivatedRoute, Router, RouterEvent} from '@angular/router';
import {OptionsService} from '../services/options-service/options.service';
import {PortalSettingsService} from '../services/portal-settings/portal-settings.service';
import {CustomDashboardsService} from '../services/api/custom-dashboards/custom-dashboards.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit {

  public sideBarNavOpen = true;
  public getStartedStep = null;

  public adminExpanded = false;
  public winUpdateExpanded = false;
  public dashboardExpanded = false;
  public monitoringExpanded = false;

  public hideNewFeatures = false;

  public showCustomerFilter = false;

  public navigationExpanded = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );




  constructor(private router: Router,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              public location: Location,
              public auth: AuthenticationService,
              private getStartedApi: GetStartedApiService,
              public optionsService: OptionsService,
              public portalSettings: PortalSettingsService,
              private route: ActivatedRoute,
              private renderer: Renderer2
  ) {}

  ngOnInit(): void {

    // // Set dark mode
    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    // if(prefersDark){
    //   this.setDarkTheme();
    // }

    this.route.queryParams.subscribe(params => {
      if(params.selectedCustomer){
        this.portalSettings.mspCustomerSwitch.setCustomer(params.selectedCustomer, params.selectedCustomerName);
        this.optionsService.loadDashboards();
        // sleep 1 second

      }
    });

    console.log("Loading PortalComponent")
    this.auth.checkLoggedIn();
    this.portalSettings.refreshPermissionsAndSettings();
    this.refreshGetStartedData();

    if(this.isActive('/dashboard')){
      this.dashboardExpanded = true;
    }

    if(this.isActive('/admin')){
      this.adminExpanded = true;
    }
    if(this.isActive('/reporting-updates')){
      this.winUpdateExpanded = true;
    }

  }

  toggleDarkTheme() {
    this.portalSettings.darkMode = !this.portalSettings.darkMode;
    if (this.portalSettings.darkMode) {
      this.renderer.addClass(document.body, 'darkTheme');
    } else {
      this.renderer.removeClass(document.body, 'darkTheme');
    }
  }

  // setDarkTheme(){
  //   this.portalSettings.darkMode = true;
  //   this.renderer.addClass(document.body, 'darkTheme');
  // }



  changeCustomerFilter(customerId:any, customerName:any) {
    this.portalSettings.mspCustomerSwitch.setCustomer(customerId, customerName);

    this.optionsService.loadDashboards();

    this.showCustomerFilter = false;

    this.router.navigate(['/switch']).then(r => {});
  }

  refreshGetStartedData(){
    this.getStartedApi.get().then(response => {
      this.getStartedStep = response.get_started_step;

      if(this.getStartedStep) {

        if(location.pathname == '/get-started' || location.pathname == '/intune-config' || location.pathname == '/root'){
          console.log("NOT redirecting to get started because I am currently on this route:" + location.pathname)
        } else {
          console.log("Redirecting to get-started page" + location.pathname)
          this.router.navigate(['/get-started']).then(r => null);
        }
      }
    })
  }

  logout(){
    this.auth.logout();
  }

  fullScreen(){
    // /* Get the documentElement (<html>) to display the page in fullscreen */
    // var elem = document.documentElement;
    //
    // elem.requestFullscreen();


    if (!document.fullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }

  }

  isActive(path: string): boolean {
    // Check if the current route includes the specified path
    return this.router.url.includes(path);
  }

  raiseBug() {
    this.dialogService.createDialog(RaiseABugDialogComponent, null, this.viewReference)
      .then(dialogSaved => {

      }, dialogCancelled => {

      })
  }

}
