// percent-badge-renderer.component.ts
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-percent-badge-renderer',
  standalone: true,
  template: `
    <span *ngIf="percent != null" class="m-0 text-shorten numberBadge" [ngClass]="{
      'green': percent >= 90,
      'amber': percent >= 80 && percent <= 90,
      'red': percent < 80
    }">{{percent}} %</span>
  `,
  imports: [
    NgClass,
    NgIf
  ],
  styles: [`
    .numberBadge { /* Add your styles here */
    }
  `]
})
export class PercentBadgeRendererComponent implements ICellRendererAngularComp {
  public percent: any;

  agInit(params: any): void {
    this.percent = params.value;
  }

  refresh(params: any): boolean {
    this.percent = params.value;
    return true;
  }
}
