<div style="font-size: .875em; margin: 0px;padding: 10px;border-radius: 10px; text-wrap: none;    overflow: auto; max-height: 80vh;
font-family: var(--bs-font-monospace);
    direction: ltr;
    unicode-bidi: bidi-override;
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em;
">
  <div *ngIf="isObject(data)" style="margin-left: 10px;">
    <div *ngFor="let key of Object.keys(data)" style="padding-left:10px; border-bottom: solid 1px rgba(128,128,128,0.11); margin-bottom: 1px" [style.background-color]="getColor(key)">

      <span *ngIf="isObject(data[key])" (click)="toggleExpand(key)" style="font-weight: bold; cursor: pointer;">
        <i class="fas" style="width: 15px" [class.fa-caret-right]="!expandedStates[key]" [class.fa-caret-down]="expandedStates[key]"></i>
        <span style="color: #00616b; font-size: 13px;">{{ key }}:</span>
      </span>
      <span *ngIf="!isObject(data[key])">
              <i class="fas" style="width: 15px"></i>
        <span style="color: #00616b;  font-size: 13px;">{{ key }}:</span> <span style="margin-left: 15px; font-size: 12px;">{{ data[key] }}</span>
      </span>
      <div class="child" [style.display]="expandedStates[key] ? 'block' : 'none'">
        <app-json-tree [data]="data[key]" [level]="level + 1"></app-json-tree>
      </div>
    </div>
  </div>
  <div *ngIf="!isObject(data)" style="margin-left: 20px; font-size: 12px;">
    {{ data }}
  </div>
</div>
