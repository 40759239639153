import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-battery-percentage-bar',
  templateUrl: './battery-percentage-bar.component.html',
  styleUrls: ['./battery-percentage-bar.component.css']
})
export class BatteryPercentageBarComponent implements OnInit {
  @Input() percent: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  getStatusColor() {
    if(this.percent < 25){
      return 'red'
    }
    if(this.percent < 60){
      return 'amber'
    }
    return 'green'
  }
}
