import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-blade-right',
  templateUrl: './blade-right.component.html',
  styleUrls: ['./blade-right.component.css']
})
export class BladeRightComponent implements OnInit {

  @Input() size: 'blade-small' | 'blade-medium' | 'blade-large' | 'blade-xlarge' | 'blade-xxlarge';
  @Input() background: string = 'white';

  constructor() { }

  ngOnInit(): void {
  }

}
