import {Component, Input, OnInit} from '@angular/core';
import {SharedFunctionsService} from '../../services/shared-functions/shared-functions.service';

@Component({
  selector: 'app-eido-column-chart',
  templateUrl: './eido-column-chart.component.html',
  styleUrl: './eido-column-chart.component.css'
})
export class EidoColumnChartComponent implements OnInit{

  // input labels
  // input data

  @Input() dataArray: any[];
  @Input() labelPropertyName = '';
  @Input() valuePropertyName = '';

  @Input() total: number = 0;
  @Input() prependMissing: boolean = false;

  public labels: any[] = [];
  public values: any[] = [];

  constructor(public sharedFunctions: SharedFunctionsService) {
  }

  ngOnInit() {
    this.labels = this.dataArray.map(item => item[this.labelPropertyName]);
    this.values = this.dataArray.map(item => item[this.valuePropertyName]);

    // if prependMissing is true, we need to add missing labels. Count labels, if less than total, add missing labels to tbe beginning of the array
    if (this.prependMissing) {
      const count = this.labels.length;
      if (count < this.total) {
        const missing = this.total - count;
        for (let i = 0; i < missing; i++) {
          this.labels.unshift('?');
          this.values.unshift('Unknown');
        }
      }
    }

  }


}
