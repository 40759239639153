<div class="SDeskSidePanel-GreyBackground" (click)="close()"></div>

<app-blade-right [size]="'blade-xlarge'">

  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{loadedData.app_name}}     <div (click)="editMode = !editMode" class="btn btn-primary btn-small float-end" style="margin-right:25px">Edit</div>
    </h3>

  </div>
  <hr>

  <div *ngIf="!editMode" style="padding:5px">

    <div *ngIf="!loadedData.is_metered"  class="row">
      <div class="col-sm-3 formHeader">
        Is Metered?
      </div>
      <div class="col-lg-4">
        <i *ngIf="!loadedData.is_metered" class="fas fa-times" style="
    font-size: 20px;
    margin-top: 5px;
    color: grey;
"></i>
      </div>
    </div>

    <div *ngIf="loadedData.is_metered" class="row">
      <div class="col-sm-3 formHeader">
        Licenses (Using {{loadedData.install_count}} of {{loadedData.metered_license_count}})
      </div>
      <div class="col" style="margin-top:5px">
        <app-percent-bar [percentage]="loadedData.metered_license_usage_percent" [number]="loadedData.install_count"></app-percent-bar>
      </div>
    </div>





  </div>


  <div *ngIf="editMode" style="padding:5px">

      <div class="row">
        <div class="col-sm-3 formHeader">
          Is Metered?
        </div>
        <div class="col-lg-4">
          <div class="form-check form-switch form-switch-md">
            <input [(ngModel)]="loadedData.is_metered"  class="form-check-input" type="checkbox" id="backup_enabled" checked>
            <label class="form-check-label" for="backup_enabled" style="margin-top: 5px;">

            </label>
          </div>
        </div>
      </div>

      <div *ngIf="loadedData.is_metered" class="row">
        <div class="col-sm-3 formHeader">
          Licenses
        </div>
        <div class="col-2">
          <input [(ngModel)]="loadedData.metered_license_count" type="number" class="form-control" id="metered_license_count" placeholder="" autocomplete="off">
        </div>
      </div>

      <div (click)="saveApp()" class="btn btn-primary btn-small">Save</div>




    </div>



  <hr>

  <div class="container p-0">

    <div *ngIf="!isLoading">
      <app-eido-super-table [data]="loadedData.installs" [settings]="superTableSettings" [filterWidthClass]="'col-xl-3 col-4'"></app-eido-super-table>
    </div>

  </div>
</app-blade-right>
