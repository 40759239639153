import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-eido-dropdown-tree',
  templateUrl: './eido-dropdown-tree.component.html',
  styleUrl: './eido-dropdown-tree.component.css'
})
export class EidoDropdownTreeComponent implements OnInit, OnChanges{

  @Input() showClearIcon = false;
  @Input() options: any[] = [];
  @Input() optionsLabel: string = '';
  @Input() optionsValue: string = '';

  @Input() treeEnabled: boolean = true;
  @Input() treeCategoryField: string = '';
  @Input() treeExpand: string[] = [];
  groupedSuggestions: { [key: string]: any[] } = {};
  expandedCategories: Set<string> = new Set<string>();

  @Input() selectedValue: any = '';
  @Output() selectedValueChange = new EventEmitter<string>(); // Notifies parent of changes

  filteredSuggestions: string[] = [];

  public showSuggestions: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.filteredSuggestions = [];

    if(!this.options || this.options.length === 0){
      console.log('Suggestions array is empty.');
    } else {
      this.filteredSuggestions = this.options;
    }

    this.groupSuggestions();

    // add all treeExpand values to expandedCategories
    this.treeExpand.forEach(category => {
      this.expandedCategories.add(category);
    });
  }

  getSelectedValue(){

    if(!this.selectedValue){
      return '';
    } else{
      let selectedValueLabel = this.options.find(option => option[this.optionsValue] === this.selectedValue)[this.optionsLabel];

      if(selectedValueLabel){
        return selectedValueLabel;
      } else {
        return 'Unknown value';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
        // Check if `suggestions` or `userInput` changed
    if (changes['options'] || changes['selectedValue']) {
      this.resetAndFilterSuggestions();
    }
  }

  resetAndFilterSuggestions(): void {

    this.filteredSuggestions = this.options;


    // // Clear filteredSuggestions
    // this.filteredSuggestions = [];
    //
    // // Check if suggestions are defined and not empty
    // if (this.options && this.options.length > 0) {
    //   // Filter suggestions based on userInput
    //   this.filterSuggestions();
    // } else {
    //   console.log('Suggestions array is undefined or empty.');
    // }

    this.groupSuggestions();

  }


  onInputChange(): void {
    //this.filterSuggestions();
    this.showSuggestions = true;
  }

  onValueChange(event: any): void {
    this.selectedValueChange.emit(this.selectedValue); // Ensure this is emitted
  }

  toggleDropdown(event: Event){
    event.stopPropagation();

    this.showSuggestions = !this.showSuggestions;
    // if filteredSuggestions is empty - show all suggestions
    if(this.filteredSuggestions.length === 0){
      this.filteredSuggestions = this.options;
    }
  }

  filterSuggestions(): void {
    // Ensure the suggestions array is defined
    if (!this.options) {
      console.error('Suggestions array is undefined.');
      return;
    }

    // Convert the user input to a lower-case string to simplify comparison
    // If selectedValue is null or undefined, treat it as an empty string
    const inputValue = (this.selectedValue ?? '').toString().toLowerCase();

    // Filter suggestions based on the lower-case user input
    this.filteredSuggestions = this.options.filter(suggestion => {
      // Convert the suggestion to a string if it's not null or undefined, otherwise treat it as an empty string
      // This handles various data types (numbers, strings, etc.) and ensures operations like toLowerCase() work
      const suggestionStr = (suggestion ?? '').toString().toLowerCase();
      return suggestionStr.includes(inputValue);
    });
  }

  selectSuggestion(suggestion: string): void {
    this.selectedValue = suggestion; // This updates the bound property and reflects in the parent component due to ngModel.
    this.showSuggestions = false; // Hide the suggestions dropdown.
    this.onValueChange('');
  }

  hideSuggestions(): void {
    console.log('Hiding suggestions...');
    // Use a timeout to delay hiding so the click event on the suggestion can be registered
    setTimeout(() => {
      this.showSuggestions = false;
    }, 100);
  }

  groupSuggestions() {

    console.log('Grouping suggestions...')
    if (this.treeEnabled && this.treeCategoryField) {
      this.groupedSuggestions = this.filteredSuggestions.reduce((acc, suggestion) => {
        const category = suggestion[this.treeCategoryField] || 'Uncategorized';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(suggestion);
        return acc;
      }, {});
    } else {
      this.groupedSuggestions = { 'All': this.filteredSuggestions };
    }
  }

  toggleExpand(category: string) {
    if (this.expandedCategories.has(category)) {
      this.expandedCategories.delete(category);
    } else {
      this.expandedCategories.add(category);
    }
  }

  isExpanded(category: string): boolean {
    return this.expandedCategories.has(category);
  }

}
