<div >
  <div style="height: 100px">
    <div class="row" style="height: 80px; border-top: 1px dashed #d9dde1;">
      <div *ngFor="let point of values" class="columnContainer col"
           [matTooltip]="[point + ' %']"
           [matTooltipPosition]="'above'"
           [matTooltipClass]="'eido-tooltip'">
        <div [style.height.%]="point" class="trendGraph-column" [ngClass]="sharedFunctions.getStatusColourFromPercentage(point)"></div></div>


<!--      <div class="columnContainer col"><div [style.height.%]="89" class="trendGraph-column amber"></div></div>-->

    </div>
    <div class="row" style="margin-top: 4px">
      <div *ngFor="let label of labels" class="col text-center trendGraph-border-right">{{label}}</div>
    </div>
  </div>

</div>
