<!-- json-compare.component.html -->
<div class="change-list">
  <ul>
    <li *ngFor="let change of changes">
      <strong>{{ change.change }} - {{ change.path }}</strong>:
      <string *ngIf="change.change == 'Added'">{{ change.newValue }}</string>
      <string *ngIf="change.change == 'Modified'">old value "{{ change.oldValue }}" new value "{{ change.newValue }}"</string>
      <string *ngIf="change.change == 'Removed'">{{ change.newValue }}</string>
    </li>
  </ul>
</div>
