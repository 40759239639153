import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrl: './reporting.component.css'
})
export class ReportingComponent implements OnInit{

  public path = '';

  public isLoading = false;
  public reportData = [];
  public superTableSettings;

  public availableReports = [
    { name: 'OS', description: 'Operating System support status', route: 'os' },
    { name: 'Warranty', description: 'Device warranty status', route: 'warranty' },
    { name: 'Inventory', description: 'List of devices', route: 'inventory' },
    { name: 'Encryption', description: 'Encryption and TPM info', route: 'encryption' },
    { name: 'Mobile', description: 'Mobile device details', route: 'mobile' },
    { name: 'Battery', description: 'Battery health status', route: 'battery' },
    { name: 'Device Guard', description: 'Virtualization-based security', route: 'device-guard' },
    { name: 'Disk', description: 'Disk space and capacity', route: 'disk-capacity' },
    { name: 'Intune Cert', description: 'Intune management certificate expiry', route: 'intune-mgmt-cert' },
    // { name: 'Windows Updates - Devices', description: 'Patch Status by Device', route: 'windows-updates-devices' },
    // { name: 'Windows Updates - OS', description: 'Major OS Support Status', route: 'windows-updates-os' },
    // { name: 'Windows Updates - Patches', description: 'Patch Compliance Status', route: 'windows-updates-patches' },
  ];

  constructor(private route: ActivatedRoute, private apiService: ApiService) { }


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.path = params.get("reportName");

      if(!params.get("reportName")){
        return;
      }

      this.getReportData(this.path);
    })
  }

  getReportData(reportName){
    this.isLoading = true;
    let apiUrl = `/api/reports/${reportName}`
    this.apiService.get(apiUrl).then(response => {
      this.reportData = response.data;
      this.superTableSettings = response.superTableSettings;
      this.isLoading = false;
    })
  }


}
