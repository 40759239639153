import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';


@Component({
  selector: 'app-device-details-blade',
  templateUrl: './device-details-blade.component.html',
  styleUrls: ['./device-details-blade.component.css']
})
export class DeviceDetailsBladeComponent implements OnInit {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  ngOnInit() {
  }

  close(){
    this.remove.emit(null);
  }

}
