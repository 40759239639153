import { Component, OnInit } from '@angular/core';
import {AuthApiService} from '../services/api/authentication/auth-api-service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {

  public newPassword;
  public confirmNewPassword;
  private authToken;

  public showSetPassword = false

  public showLoginMessage = false;

  constructor(private authApi: AuthApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.authToken = params['token'];
    });
  }

  submit(){
    this.authApi.setPassword(this.authToken, this.newPassword, this.confirmNewPassword).then(response => {
      this.showLoginMessage = true;
    })

  }

}
