import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from '../../../../shared/dialog/dialog';
import {OptionsService} from '../../../../services/options-service/options.service';
import {PortalSettingsService} from '../../../../services/portal-settings/portal-settings.service';
import {ApiCustomerService} from '../../../../services/api/admin/customers/customer-api.service';
import {CustomersComponentDialog} from '../../customers/team-create-edit-dialog/customers-create-edit-dialog.component';
import {DialogService} from '../../../../services/dialog-service/dialog.service';

@Component({
  selector: 'app-intune-tenant-friendly-name',
  templateUrl: './intune-tenant-friendly-name.component.html',
  styleUrls: ['./intune-tenant-friendly-name.component.css']
})
export class IntuneTenantFriendlyNameComponent implements Dialog {

  @Input() data = "";
  @Output() remove = new EventEmitter<any>();

  outputData = {
    customer_id: null,
    friendly_name: ""
  }

  customerList = []

  constructor(public optionsService: OptionsService, private dialogService: DialogService, private viewReference: ViewContainerRef, public portalSettings: PortalSettingsService, public customerApi: ApiCustomerService) { }

  ngOnInit(): void {
    this.refreshCustomerList();
  }

  refreshCustomerList(){
    this.customerApi.listAll().then(response => {
      this.customerList = response;
    })
  }

  newCustomer(){
    // Replace null with Component
    this.dialogService.createDialog(CustomersComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.refreshCustomerList();
        this.optionsService.loadCustomers();

      }, dialogCancelled => {

      })
  }

  customerChanged(){
    // IF output data friendly name is empty, set it to the customer name with "Intune Tenant" appended. Look through customer_list and find the customer_id
      this.customerList.forEach(customer => {
        if(customer.id == this.outputData.customer_id){
          this.outputData.friendly_name = customer.customer_name + " Intune Tenant";
        }
      })

  }

  save(){
    this.remove.emit(this.outputData);
  }
}
