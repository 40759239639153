import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-switching-customers-page',
  templateUrl: './switching-customers-page.component.html',
  styleUrls: ['./switching-customers-page.component.css']
})
export class SwitchingCustomersPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['/dashboard']).then(r => {});
    }, 100);

  }

}
