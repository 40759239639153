<div *ngIf="!query.eido_intune_tenant_id">

  <h5>Select an Intune Tenant</h5>

  <div class="row">
    <div class="col-xl-5">
      <div *ngFor="let tenant of tenants" [class.active]="null" (click)="changeTenant(tenant.eido_intune_tenant_id)"
           class="divLink sectionBox"
           style="padding: 8px 10px!important; font-size: 12px;"
      >
        <span>{{tenant.intune_tenant_friendly_name}}</span>
       <i *ngIf="tenant.queryhub_enabled" class="fas fa-arrow-circle-right float-end m-l-10" style="color: #699981;font-size: 20px;"></i> <span *ngIf="tenant.queryhub_enabled" class="float-end m-l-10">QueryHub Enabled </span>
       <i *ngIf="!tenant.queryhub_enabled" class="fas fa-times-circle float-end m-l-10" style="color: indianred;font-size: 20px;"></i> <span *ngIf="!tenant.queryhub_enabled" class="float-end m-l-10">QueryHub Disabled </span>
      </div>
    </div>
  </div>
</div>




<div *ngIf="!isLoadingSchema">

  <div class="row">
    <div *ngIf="selectedTab == 'Filter'" class="col-2" style="border-right: 1px solid rgb(215, 215, 215); height: calc(-35px + 100vh); margin-right: 0px; overflow-y: auto; padding-right:0px">

      <h5>Select Collection</h5>

      <div *ngFor="let collection of schema.collections" [class.active]="query.collection == collection.name" (click)="changeCollection(collection.name, collection.friendly_name)"
      class="divLink sectionBox row"
           style="padding: 8px 10px!important; font-size: 12px;"
      >{{collection.friendly_name}}</div>

    </div>
    <div *ngIf="query.collection" class="col p-l-10">

      <div *ngIf="selectedTab == 'Filter'" class="row">


        <div class="col">
          <h5>Visualisation </h5>
          <button [disabled]="this.query.visualisation != 'table' && !query.groupBy" (click)="execute()" class="float-end btn btn-primary" style="
    padding: 15px 25px;
">
            <i class="fas fa-play m-r-10"></i> <span>Run Query</span></button>
          

          <div class="m-b-10">
            <div class="row">
              <div style="max-width: 200px">
                <div class="queryHubSelectVisualisationTab" [class.selected]="this.query.visualisation == 'table'" (click)="this.query.visualisation = 'table'">
                  <i class="fas fa-table fa-12x divLink"></i>
                  Table
                </div>
              </div>
              <div style="max-width: 200px">
                <div class="queryHubSelectVisualisationTab" [class.selected]="this.query.visualisation == 'pie'" (click)="this.query.visualisation = 'pie'">
                  <i class="fas fa-chart-pie fa-12x divLink"></i>
                  Pie Chart
                </div>
              </div>
              <div style="max-width: 200px">
                <div class="queryHubSelectVisualisationTab" [class.selected]="this.query.visualisation == 'bar'" (click)="this.query.visualisation = 'bar'">
                  <i class="fas fa-chart-bar fa-12x divLink"></i>
                  Bar Chart
                </div>
              </div>
            </div>

          </div>

          <h5>Filters </h5>
          <div class="m-b-10 sectionBox p-20">
            <div class="row" *ngFor="let filter of query.filters; let i = index" style="padding: 10px 20px; border-radius: 10px; margin-bottom: 10px; background: #dfe2e578;">
              <div class="col-1 p-5">
                <span>{{ i === 0 ? 'Where' : 'And' }}</span>
              </div>
              <div class="col p-r-20">

                <app-eido-dropdown-tree  [(selectedValue)]="filter.field" (selectedValueChange)="filterFieldChanged(filter.field); filter.value = '' ; " [options]="availableColumns" [optionsLabel]="'name'" [optionsValue]="'field'" [treeEnabled]="true" [treeCategoryField]="'column_collection_friendly_name'" [treeExpand]="[query.collectionFriendlyName]"></app-eido-dropdown-tree>


<!--                <select [(ngModel)]="filter.field" (ngModelChange)="filterFieldChanged(filter.field); filter.value = '' ; " class="form-control input-sm" aria-label="Default select example">-->
<!--                  <option value="0">Select a field</option>-->
<!--                  <option *ngFor="let column of availableColumns" [value]="column.field">{{ column.name }}</option>-->
<!--                </select>-->
              </div>
              <div class="col p-r-20">
                <select *ngIf="filter.field" [(ngModel)]="filter.operator" class="form-control input-sm" aria-label="Default select example">
                  <option value="0">Select an operator</option>
                  <option *ngFor="let operator of mongoDbOperators[getColumnType(filter.field)]" [value]="operator.operator">{{ operator.friendlyName }}</option>
                </select>
              </div>
              <div class="col p-r-20">

                <div *ngIf="filter.field && (filter.operator == '$in' || filter.operator == '$nin')" style="
    padding: 14px;
    background-color: #dee2e945;
    border-radius: 9px;
    border: solid 1px #d7d7d7;
">
                  <div *ngFor="let value of filter.arrayValues" style="margin-bottom:3px;">
                    <app-eido-autocomplete  [(selectedValue)]="value.value" [type]="getColumnType(filter.field)" [suggestions]="uniqueFieldValuesCache[filter.field]"></app-eido-autocomplete>

                  </div>

                  <span (click)="addArrayValue(filter)" style="padding: 5px 14px;background-color: #ffffffb8;border-radius: 8px;border: solid 1px lightgrey;margin-top: 6px;display: inline-block;">+ Add Value</span>


                </div>
                <app-eido-autocomplete *ngIf="filter.field && (filter.operator != '$in' && filter.operator != '$nin')" [(selectedValue)]="filter.value" [type]="getColumnType(filter.field)" [suggestions]="uniqueFieldValuesCache[filter.field]"></app-eido-autocomplete>


              </div>
              <div class="col-1 p-5">
                <i class="fas fa-trash fa-12x divLink" (click)="deleteFilter(i)"></i>
              </div>
            </div>

            <span (click)="addFilter()" class="divLink" style="padding: 4px 14px;background-color: #d3d3d373;border-radius: 8px;font-size: 13px;">+ Add Filter</span>
          </div>

          <h5>Group & Order</h5>
          <div class="m-b-10 sectionBox p-20">

            <div class="row" style=" border-radius: 10px; margin-bottom: 10px;">
              <div class="col-1 p-5">
                <span>Group By</span>
              </div>
              <div class="col-3 p-r-20">


                <app-eido-dropdown-tree  [(selectedValue)]="query.groupBy" (selectedValueChange)="groupByChanged($event)" [options]="availableColumns" [showClearIcon]="true" [optionsLabel]="'name'" [optionsValue]="'field'" [treeEnabled]="true" [treeCategoryField]="'column_collection_friendly_name'" [treeExpand]="[query.collectionFriendlyName]"></app-eido-dropdown-tree>



                <!--                <select [(ngModel)]="query.groupBy" class="form-control input-sm" aria-label="Default select example">-->
<!--                  <option value="">Select a field</option>-->
<!--                  <option *ngFor="let column of availableColumns" [value]="column.field">{{ column.name }}</option>-->
<!--                </select>-->
              </div>

              <div class="col">
                <span *ngIf="!query.groupBy && this.query.visualisation != 'table'" style="
    background: indianred;
    font-size: 12px;
    padding: 2px 11px;
    border-radius: 5px;
    color: white;
">Required</span>
              </div>


              <!--              <div class="col-1 p-5">-->
              <!--                <i class="fas fa-trash fa-12x divLink" (click)="deleteFilter(i)"></i>-->
              <!--              </div>-->
            </div>


            <div class="row" style=" border-radius: 10px; margin-bottom: 0px; margin-top:10px;">
              <div class="col-1 p-5">
                <span>Order By</span>
              </div>
              <div class="col-3 p-r-20">

                <app-eido-dropdown-tree  [(selectedValue)]="query.orderBy.field" [options]="getOrderByColumns()" [showClearIcon]="true" [optionsLabel]="'name'" [optionsValue]="'field'" [treeEnabled]="true" [treeCategoryField]="'column_collection_friendly_name'" [treeExpand]="[query.collectionFriendlyName]"></app-eido-dropdown-tree>


<!--                <select [(ngModel)]="query.orderBy.field" class="form-control input-sm" aria-label="Default select example">-->
<!--                  <option value="">Select a field to sort by</option>-->
<!--                  <option *ngFor="let column of availableColumns" [value]="column.field">{{ column.name }}</option>-->
<!--                </select>-->


              </div>
              <div class="col-3 p-r-20">
                <select [(ngModel)]="query.orderBy.sortAsc" class="form-control input-sm" aria-label="Default select example">
                  <option value="1">Ascending</option>
                  <option value="-1">Descending</option>
                </select>
              </div>
            </div>

          </div>

          <div *ngIf="this.query.visualisation == 'table'">
            <h5>Columns</h5>
            <div *ngIf="!query.groupBy" class="m-b-20 sectionBox p-20">
              <div class="m-b-20" *ngFor="let columnCollection of this.availableColumnCollections">

                <p class="m-b-10">{{columnCollection.friendly_name}} - <span (click)="checkAllColumns(columnCollection.name)" class="divLink" style="
      font-size: 11px;
      padding-bottom: 0px;
  ">(Toggle All)</span></p>


                <span class="divLink columnList" style="width: 240px; padding: 5px 20px !important;background-color: #edeff1;margin-right: 5px;font-size: 12px;height: 33px;margin-bottom: 7px;display: inline-block;"  [class.active]="isColumnChecked(field)" (click)="toggleColumn(field);" *ngFor="let field of getColumns(columnCollection.name)">
            <input [checked]="isColumnChecked(field)"  class="form-check-input" type="checkbox" id="backup_enabled" checked>

              <span class="text-shorten" style=" max-width: 175px;
      padding: 4px;
      padding-top: 2px;
      display: inline-block;
  ">{{field.name}}</span>
            </span>

              </div>


          </div>

            <div *ngIf="query.groupBy" class="m-b-20 sectionBox p-20">

              <span class="divLink columnList" style="width: 240px; padding: 5px 20px !important;background-color: #edeff1;margin-right: 5px;font-size: 12px;height: 33px;margin-bottom: 7px;display: inline-block;"  [class.active]="1" (click)="null">
            <input [checked]="1" disabled="disabled"  class="form-check-input" type="checkbox" checked>

              <span class="text-shorten" style=" max-width: 175px;
      padding: 4px;
      padding-top: 2px;
      display: inline-block;
  ">{{query.groupBy}}</span>
            </span>

              <span class="divLink columnList" style="width: 240px; padding: 5px 20px !important;background-color: #edeff1;margin-right: 5px;font-size: 12px;height: 33px;margin-bottom: 7px;display: inline-block;"  [class.active]="1" (click)="null">
            <input [checked]="1" disabled="disabled"  class="form-check-input" type="checkbox" checked>

              <span class="text-shorten" style=" max-width: 175px;
      padding: 4px;
      padding-top: 2px;
      display: inline-block;
  ">Count</span>
            </span>

          </div>
          </div>
        </div>








      </div>

      <div *ngIf="selectedTab == 'Results'" class="row">


<!--        @Input() data = [];-->
<!--        @Input() columns = [];-->
<!--        @Input() currentPage = 1;-->
<!--        @Input() totalPages = 1;-->
<!--        @Input() totalRows = 0;-->
<!--        @Input() rowsReturned = 0;-->
<!--        @Input() sortByColumn = null;-->
<!--        @Input() sortByAsc = null;-->
<!--        -->

        <app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>

        <div *ngIf="!isLoadingResults">

          <button (click)="selectedTab = 'Filter'" class="btn btn-primary m-b-10">Edit Query</button>


          <div *ngIf="this.query.visualisation == 'pie'">
            <div class="sectionBox p-20 m-b-20">
              <app-highchart-pie [inputData]="transformedPieColumnChartData"  [title]="'Pie Chart'"></app-highchart-pie>
            </div>
          </div>

          <div *ngIf="this.query.visualisation == 'bar'">
            <div class="sectionBox p-20 m-b-20">
              <app-highchart-column [inputData]="transformedPieColumnChartData"  [title]="'Bar Chart'"></app-highchart-column>
            </div>
          </div>

          <div></div>
          <app-eido-super-table-server-side
            [data]="queryResults.results"
            [columns]="queryResults.columnsObjects"
            [currentPage]="queryResults.page"
            [totalPages]="queryResults.totalPages"
            [totalRows]="queryResults.totalRows"
            [rowsReturned]="queryResults.rowsReturned"
            [sortByColumn]="queryResults.sortBy.field"
            [sortByAsc]="queryResults.sortBy.sortByAsc"
            [showExportCsv]="false"

            (columnChanged)="columnChanged($event)"
            (pageChanged)="pageChanged($event)"
          ></app-eido-super-table-server-side>

          <div class="row">
            <div class="col">
              <div (click)="execute(true)" class="btn btn-primary float-end">Export as CSV</div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>


</div>
