import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  constructor(private api: ApiService) { }

  public getDashboardStats(){
    return this.api.get('/api/dashboard');
  }

  public getMspDashboardStats(){
    return this.api.get('/api/dashboard-msp');
  }
}
