<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right   size="blade-xlarge">

  <app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>


  <div *ngIf="!isLoading">
    <div class="p-10 p-b-0">
      <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
      <h3>{{loadedData.policy_name}}</h3>
<!--      <div *ngIf="loadedData.new_version_available" style="margin: 0px; background-color: #ffedcd; padding: 9px; font-size: 12px; margin-top: 10px; margin-bottom:20px; border-radius: 5px;">-->
<!--        <strong>New Version Available:</strong>-->
<!--        There is a new version of this policy available.-->

<!--        <div (click)="newVersion()" class="btn btn-primary btn-small m-l-20">Create new version</div>-->

<!--      </div>-->

    </div>





    <hr>


    <div class="p-0">

      <div class="row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Friendly Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          {{loadedData.policy_name}}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Description
        </div>
        <div class="col-sm p-10">
          {{loadedData.policy_description}}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Master Policy
        </div>
        <div class="col-sm p-10">
          {{loadedData.parent_policy_name}}

          <div class="float-end">
            <i *ngIf="!loadedData.new_version_available" style="
    font-size: 20px;
    margin-top: 5px;
    color: #68809d;
" class="fas fa-question-circle" [matTooltip]="'The master policy is the same as the template. Update the master policy to create a new version.'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>
            <i *ngIf="loadedData.new_version_available" style="
    font-size: 20px;
    margin-top: 5px;
    color: #68809d;
" class="fas fa-question-circle" [matTooltip]="'The master policy has been updated. Click here to create a new version. Creating a new version will not auto deploy it to tenants.'" [matTooltipPosition]="'above'" [matTooltipClass]="'eido-tooltip'"></i>
            <button  (click)="newVersion()" [disabled]="!loadedData.new_version_available" class="btn btn-primary btn-small m-l-5 float-end">Create new version</button>

          </div>

        </div>
      </div>

      <hr>

  <!--    <div class="row">-->
  <!--      <div class="col-sm-2 formHeader alignMiddle p-10">-->
  <!--        Linked to Policy-->
  <!--      </div>-->
  <!--      <div class="col-sm p-10">-->
  <!--       Mac Compliance <span class="eido-info-button">Template update available</span>-->

  <!--      </div>-->
  <!--    </div>-->




      <div class="col-12 m-b-20 " style="margin-top: 22px;">
        <ul class="nav dialogTabs">

          <li class="nav-item divLink" (click)="selectedTab = 'main'">
            <a class="nav-link-thread" [class.active]="selectedTab == 'main'">
              Versions ({{loadedData.versions.length}})
            </a>
          </li>

          <li class="nav-item divLink" (click)="selectedTab = 'deployments'">
            <a class="nav-link-thread" [class.active]="selectedTab == 'deployments'">
              Deployments ({{loadedData.policies.length}})
            </a>
          </li>

        </ul>
      </div>


      <div *ngIf="selectedTab == 'main'">

  <!--      <div class="row">-->
  <!--        <div class="col-1"></div>-->
  <!--        <div class="col-3">-->
  <!--          <span style="color: #9f9f9f; font-size: 16px;">Version #</span>-->
  <!--        </div>-->

  <!--        <div class="col-3">-->
  <!--          <span  style="color: #9f9f9f; font-size: 16px;">Active Deployments</span>-->
  <!--        </div>-->

  <!--        <div class="col-3">-->
  <!--          <span  style="color: #9f9f9f; font-size: 16px;">Created</span>-->
  <!--        </div>-->
  <!--      </div>-->

        <div *ngFor="let version of loadedData.versions" class="eido-expand-parent">
            <div (click)="toggleVersion(version.version_no)" class="eido-expand-header divLink">
              <div class="row">
                <div class="col-1">
                  <i class="fas m-r-10" style="margin-top: 8px;" [ngClass]="isVersionExpanded(version.version_no) ? 'fa-chevron-down' : 'fa-chevron-right'"></i></div>
                <div class="col-3 eido-cell">
                  <span class="">Version # {{version.version_no}}
                    <span class="" *ngIf="version.version_no == loadedData.latest_version_no" style="margin-left: 10px; font-size: 12px;background: #4a6987;padding: 2px 8px;border-radius: 10px;color: white;">Latest</span>
                  </span>
                </div>

                <div class="col-3 eido-cell">
                  <span class="">Deployments <span style="padding: 3px 10px;
      background-color: #4a6987;
      min-width: 35px;
      color: white;
      border-radius: 10px;
      margin-left: 10px;
      font-size: 14px;" [style.background-color]="getPoliciesByVersionNo(version.version_no).length == 0 ? 'lightgrey' : '#4a6987'">{{getPoliciesByVersionNo(version.version_no).length}}</span></span>
                </div>

                <div class="col-3 eido-cell">
                  <span class="">Created: {{version.created_at | date:'medium'}}</span>
                </div>
              </div>

            </div>

            <div *ngIf="isVersionExpanded(version.version_no)" class="row eido-expand-content" style="width:98%">


              <div class="col-12 m-b-10 " style="">
                <ul class="nav dialogTabs">

                  <li class="nav-item divLink" (click)="versionSelectedSubTab = 'deployments'">
                    <a class="nav-link-thread small" [class.active]="versionSelectedSubTab == 'deployments'">
                      Deployments ({{getPoliciesByVersionNo(version.version_no).length}})
                    </a>
                  </li>

                  <li class="nav-item divLink" (click)="versionSelectedSubTab = 'policyJson'">
                    <a class="nav-link-thread small" [class.active]="versionSelectedSubTab == 'policyJson'">
                      Policy Data
                    </a>
                  </li>

                </ul>
              </div>


              <app-eido-grid *ngIf="versionSelectedSubTab == 'deployments'"
                viewPortHeight="40vh"
                [columns]="policyGridSettings.columns"
                [data]="version.policies"
                [actionButtons]="policyGridSettings.actionButtons"
                [multiSelect]="true"
                [multiSelectActions]="policyGridSettings.multiSelectActions"
                (rowClick)="null"
                (actionButtonClick)="actionClicked($event)"
                (multiSelectAction)="null"
              ></app-eido-grid>

              <app-json-editor *ngIf="versionSelectedSubTab == 'policyJson'" [(json)]="version.policy_json" [readonly]="true" style="height: 60vh;"></app-json-editor>
            </div>
        </div>
      </div>

      <div *ngIf="selectedTab == 'deployments'">
        <app-eido-grid
          viewPortHeight="60vh"
          [columns]="policyGridSettings.columns"
          [data]="loadedData.policies"
          [actionButtons]="policyGridSettings.actionButtons"
          [multiSelect]="true"
          [multiSelectActions]="policyGridSettings.multiSelectActions"
          (rowClick)="null"
          (actionButtonClick)="actionClicked($event)"
          (multiSelectAction)="null"
        ></app-eido-grid>
      </div>










      <div class="m-t-20">
        <div (click)="save()" class="btn btn-primary">Save</div>
        <div (click)="close()" class="btn btn-cancel">Cancel</div>
      </div>

    </div>
  </div>
</app-blade-right>
