<app-blade-right *ngIf="showColumnSelection" size="blade-small">
  <div class="p-10 p-b-0">
    <i (click)="showColumnSelection = false" class="fas fa-times fa-2x float-end divLink"></i>
    <h3> Select Columns</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="columnList divLink" [class.selected]="isColumnSelected(column)" *ngFor="let column of availableColumns" (click)="toggleColumn(column)">
      {{column.header}}
    </div>


    <div class="m-t-20">
      <div (click)="showColumnSelection = false" class="btn btn-primary">Close</div>
    </div>

  </div>
</app-blade-right>



<div class="row">
  <h4>REPORTS
    <div class="reportingButton float-end divLink">Export</div>
    <div class="reportingButton float-end divLink" (click)="showColumnSelection = true">Select Columns</div>
  </h4>

</div>
<div class="row">
  <div class="col-2">
    <div class="sectionBox" style="margin-left: -20px;">
      <div class="reportList divLink" routerLink="/reports/inventory" routerLinkActive="active">Device Inventory</div>
      <div class="reportList divLink" routerLink="/reports/battery-health" routerLinkActive="active">Battery Health</div>
      <div class="reportList divLink" routerLink="/reports/uptime" routerLinkActive="active">Uptime</div>
      <div class="reportList divLink" routerLink="/reports/os-version" routerLinkActive="active">OS Version</div>
      <div class="reportList divLink" routerLink="/reports/disk-encryption" routerLinkActive="active">Disk Encryption</div>
<!--      <div class="reportList divLink" routerLink="/reports/internet-speed" routerLinkActive="active">Internet Speed</div>-->
    </div>
  </div>
  <div class="col-10">
    <div class="sectionBox" style="padding-top: 5px;">

      <p-table class="eido-table"
        [columns]="selectedColumns"
        [value]="data"
        [tableStyle]="{ 'min-width': '50rem' }"
        [globalFilterFields]="['Name']">
<!--        <ng-template pTemplate="caption">-->
<!--          <div class="flex">-->
<!--            <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>-->
<!--            <span class="p-input-icon-left ml-auto">-->
<!--                <i class="pi pi-search"></i>-->
<!--                <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />-->
<!--            </span>-->
<!--          </div>-->
<!--        </ng-template>-->
        <ng-template pTemplate="header" let-columns>
          <tr class="eido-table-header">
            <th [pSortableColumn]="col.field" *ngFor="let col of columns" [width]="col.field == 'data.batteryHealth' ? '85%' : '200px'">
              {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
<!--              <p-columnFilter type="text" [field]="col.field" display="menu"></p-columnFilter>-->
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr class="eido-table-row divLink">
            <td *ngFor="let col of columns">

              <div [ngSwitch]="col.field">
                <!-- the same view can be shown in more than one case -->
                <div *ngSwitchCase="'data.CsPhyicallyInstalledMemory'">
                  {{ parseFieldInData2(rowData, col.field) ? (parseFieldInData2(rowData, col.field)/1024/1024+'GB') : '' }}
                </div>
                <div *ngSwitchCase="'OsInstallDate'">
                  {{parseFieldInData2(rowData, col.field) | date: 'dd/MM/yyyy'}}
                </div>
                <div *ngSwitchCase="'OsLastBootUpTime'">
                  {{parseFieldInData2(rowData, col.field) | date: 'dd/MM/yyyy'}}
                </div>
                <div *ngSwitchCase="'bootTime'">
                  {{parseFieldInData2(rowData, col.field) | date: 'dd/MM/yyyy'}}
                  {{parseFieldInData2(rowData, col.field) ? '('+this.daysBetween(parseFieldInData2(rowData, col.field))+' days)' : ''}}
                </div>
                <div *ngSwitchCase="'data.OsEncryptionLevel'">
                  {{parseFieldInData2(rowData, col.field) ? parseFieldInData2(rowData, col.field)+'-bit' : ''}}
                </div>
                <div *ngSwitchCase="'data.isEncrypted'">
                  <i *ngIf="parseFieldInData2(rowData, col.field)" class="fas fa-lock" style="color: #95b9a7"></i>
                  <i *ngIf="!parseFieldInData2(rowData, col.field)" class="fas fa-lock-open" style="color: indianred"></i>
                  {{ parseFieldInData2(rowData, col.field) ? ' Encrypted' : ' Not Encrypted'}}
                </div>

                <div *ngSwitchCase="'data.batteryHealth'">

                  <app-battery-percentage-bar [percent]="parseFieldInData2(rowData, col.field)"></app-battery-percentage-bar>

                </div>

                <div *ngSwitchCase="'test'">...</div>
                <!--default case when there are no matches -->
                <div *ngSwitchDefault>{{ parseFieldInData2(rowData, col.field) }}</div>
              </div>






            </td>
          </tr>
        </ng-template>
      </p-table>














    </div>
  </div>
</div>
