<div class="row justify-content-md-center">
  <div class="m-t-10 col-md-8 col-lg-4 col-xl-3 loginFormWrapper">

    <div>
    <h4>Recommended:</h4>
    <a href="/api/oauth2/login-url" class="btn btn-primary" style="background-color: #0a2983;width: 100%;padding: 15px;margin-bottom: 12px;"><i class="fab fa-windows" style="font-size: 18px; margin-right:10px"></i>Login with Entra ID</a>

    <hr>

    <p *ngIf="!showSetPassword" (click)="showSetPassword = true" class="divLink"><i class="fa fa-key m-r-5 m-t-10"></i> Set a password instead</p>
  </div>


    <div *ngIf="showLoginMessage" [routerLink]="'/login'" class="divLink" style="
    padding: 15px;
    background-color: #4a6987;
    color: white;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 10px;">Password set successfully, click here to login.</div>

    <form *ngIf="!showLoginMessage && showSetPassword">
      <h4>Set Password</h4>

      <div class="mb-3">
        <label for="newPassword" class="form-label">New Password</label>
        <input [(ngModel)]="this.newPassword" type="password" class="form-control" id="newPassword" name="newPassword">
      </div>
      <div class="mb-3">
        <label for="newPassword" class="form-label">Confirm New Password</label>
        <input [(ngModel)]="this.confirmNewPassword" type="password" class="form-control" id="confirmNewPassword" name="confirmNewPassword">
      </div>

      <div class="d-grid gap-2">
        <button (click)="submit()" (keyup.enter)="submit()" class="btn btn-primary">Set Password</button>
      </div>

      <hr>
      <p [routerLink]="'/login'" class="divLink"><i class="fa fa-key m-r-5 m-t-10"></i> Go to the login screen</p>
    </form>
  </div>
</div>


