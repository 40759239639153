<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoadingTenants" size="blade-large">

  <div style="margin: 5px;background-color: #ffedcd;padding: 9px;font-size: 13px;margin-top: 0px;border-radius: 5px;">
    <strong>Notice:</strong> Eido will deploy the latest version of the following templates, creating new policies rather than updating existing ones. To update an existing policy, open the template or the policy in question and select "re-deploy."

    Please note, Eido will not create any assignments. The policies will be unassigned, and you will need to assign them manually in the Intune portal.
  </div>



  <div class="m-t-20">

    <div class="row m-10">
      <div class="col-sm-3 formHeader">
        Select Intune Tenant
      </div>
      <div class="col-lg-4">
        <select [(ngModel)]="selectedTenantId" class="form-control" aria-label="Default select example">
          <option value="0">Select a Tenant</option>
          <option *ngFor="let tenant of tenants" [value]="tenant.id">{{ tenant.intune_tenant_friendly_name }}</option>
        </select>
      </div>
    </div>

    <div class="row">

      <div class="" style="margin: 5px; max-height: 60vh; overflow-y: auto;">
        <div style="padding: 10px;background-color: #cfd4db5c;border-radius: 10px; margin-bottom:5px" *ngFor="let item of data">
          <div class="row">
            <div class="col">
              {{ item.policy_name }} (Latest)
            </div>
          </div>
        </div>
      </div>

    </div>


    <button (click)="selectedTenantId ? deploy() : null" type="button" class="btn btn-light-grey active float-end" [class.disabled]="!selectedTenantId">Deploy Templates</button>

    <div (click)="close()" class="btn btn-cancel">Cancel</div>
  </div>


</app-blade-right>
