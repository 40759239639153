<div class="row justify-content-md-center">
  <div class="m-t-10 col-md-8 col-lg-4 col-xl-3 loginFormWrapper">
    <img alt="Logo" style="max-height:90px; margin-bottom: 30px" class="mx-auto d-block" src="assets/img/logo_light.svg">

    <p *ngIf="showLoginFailedMessage" class="text-danger">
      Incorrect login details provided.
    </p>

    <p *ngIf="oauth_error" class="text-danger m-b-10">
      {{oauth_error}}
    </p>

    <p *ngIf="showPasswordResetSuccessful" class="text-success">
      Your password has been reset. Please check your email for further instructions.
    </p>

    <form>

      <div *ngIf="screen == 'loginScreenOauth'">


        <hr>
        <p (click)="screen = 'loginScreen'" class="divLink"><i class="fa fa-lock m-r-5"></i> Login with User/Password</p>

      </div>

      <div *ngIf="screen == 'loginScreen'">

        <a href="/api/oauth2/login-url" class="btn btn-primary" style="background-color: #0a2983;width: 100%;padding: 15px;margin-bottom: 12px;"><i class="fab fa-windows" style="font-size: 18px; margin-right:10px"></i>Login with Entra ID</a>

        <hr>

        <div class="mb-3">
          <label for="emailAddress" style="font-size: 15px;" class="form-label">Email</label>
          <input [(ngModel)]="this.emailAddress" type="email" class="form-control" id="emailAddress" name="emailAddress">
        </div>

      <div *ngIf="loginMethod == 'Username-Password' || screen == 'passwordResetScreen'" class="mb-3">
        <label for="password" style="font-size: 15px;"  class="form-label">Password</label>
        <input [(ngModel)]="this.password" name="password" type="password" class="form-control" id="password">
      </div>

      <div *ngIf="loginScreenShowMfa">
        <div class="text-muted" style="border: solid 5px #cb000024;padding: 20px;ba;background: white;rgin-bottom: 10px;border-radius: 15px;"><p style="
    margin-bottom: 12px;
">A Multi-Factor Authentication code has been sent to your email address. Please enter it below.</p>

          <div class="mb-3">
            <input [(ngModel)]="this.mfaToken" name="mfaCode" type="text" class="form-control" id="mfaCode">
          </div>

          <div class="form-check">
            <input [(ngModel)]="this.mfaSaveDevice" name="rememberDevice" type="checkbox" class="form-check-input" id="rememberDevice">
            <label class="form-check" for="rememberDevice" style="font-size:12px">Remember this device for 7 days</label>
          </div>

        </div>


      </div>

      <div class="d-grid gap-2" style="margin-top:20px">
        <button (click)="login()" (keyup.enter)="login()" *ngIf="loginMethod == 'Username-Password'" class="btn btn-primary">Login</button>
      </div>

      <div *ngIf="loginScreenShowMfa" class="d-grid gap-2" style="margin-top: 20px">
        <p (click)="login()" class="divLink"><i class="fa fa-mail m-r-5"></i> Resend MFA Token</p>

      </div>

      <hr>
      <p (click)="screen = 'passwordResetScreen'" class="divLink"><i class="fa fa-lock m-r-5"></i> Forgot your password?</p>

    </div>

      <div *ngIf="screen == 'passwordResetScreen'">



        <div class="mb-3">
          <label for="emailAddress" style="font-size: 15px;" class="form-label">Email</label>
          <input [(ngModel)]="this.emailAddress" type="email" class="form-control" id="emailAddresspwreset" name="emailAddress">
        </div>

        <div class="d-grid gap-2">
          <button (click)="resetPassword()" (keyup.enter)="resetPassword()" class="btn btn-primary">Reset Password</button>
        </div>

        <hr>
        <p (click)="screen = 'loginScreen'" class="divLink"><i class="fa fa-lock m-r-5"></i> Remembered your password? Click here to Login</p>

      </div>



    </form>
  </div>
</div>


