<div class="dashboard-location-container" style="
    background-color: #c6d1db;
    border-radius: 5px;
">
  <div        [ngClass]="{
         'green': percentage <= 75,
         'amber': percentage > 75 && percentage <= 90,
         'red': percentage > 90
       }"
       [style.width.%]="percentage"
       [matTooltip]="percentage + '% (' + number + ' devices)'"
       [matTooltipPosition]="'above'"
       [matTooltipClass]="'eido-tooltip'"
       class="divLink dashboard-location-bar">
    <p class="dashboard-location-container-percentage">{{percentage}}%</p>
  </div>
</div>
