<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-xlarge">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Edit Template</h3>
  </div>
  <hr>


  <div class="p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Policy Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.policy_name" type="text" class="form-control" id="policy_name" placeholder="policy_name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Description
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.policy_description" type="text" class="form-control" id="policy_description" placeholder="policy_description" autocomplete="off">

      </div>
    </div>

<!--    <div class="row">-->
<!--      <div class="col-sm-2 formHeader alignMiddle p-10">-->
<!--        Linked to Policy-->
<!--      </div>-->
<!--      <div class="col-sm p-10">-->
<!--       Mac Compliance <span class="eido-info-button">Template update available</span>-->

<!--      </div>-->
<!--    </div>-->



<!--<div *ngFor="let version of loadedData.versions" class="eido-expand-parent">-->
<!--    <div (click)="toggleVersion(version.version_no)" class="eido-expand-header divLink">-->
<!--      <i class="fas m-r-10" [ngClass]="isVersionExpanded(version.version_no) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>-->
<!--      <span *ngIf="version.version_no == loadedData.latest_version_no" style="font-weight: bold">(Current)</span>-->
<!--      Version Number: {{version.version_no}}-->
<!--      Created: {{version.created_at | date:'medium'}}-->
<!--    </div>-->

<!--  <div *ngIf="isVersionExpanded(version.version_no)" class="row eido-expand-content" style="width:98%">-->

<!--    <app-json-editor [(json)]="version.policy_json" [readonly]="true" style="height: 60vh;"></app-json-editor>-->
<!--  </div>-->



<!--</div>-->









    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
