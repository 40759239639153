<div class="SDeskSidePanel-GreyBackground"></div>

<app-modal modalSize="modal-medium">
  <div class="p-20 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>New Template</h3>
  </div>


  <div style="margin: 20px;background-color: #ffedcd;padding: 9px;font-size: 12px;margin-top: 0px;border-radius: 5px;">
    <strong>Disclaimer:</strong> Please note that the feature for creating policy templates is currently in its BETA phase. We advise users to proceed with caution and thoroughly review the provided payload to ensure it meets your requirements before proceeding.
    <br>
    Eido will take a snapshot of the policy and its settings and create a template that can be used to deploy the policy to other environments.
  </div>

  <div *ngIf="createInProgress" style="margin: 20px;">
    <app-loading-spinner> </app-loading-spinner>
  </div>

  <div *ngIf="createFailed" style="margin: 20px;background-color: indianred;padding: 9px;font-size: 13px; color:white;margin-top: 0px;border-radius: 5px;">
    <strong>Create Template Failed:</strong> {{createError}}
  </div>

  <div *ngIf="createSuccessful" style="margin: 20px;background-color: #82a996;padding: 9px;font-size: 13px; color:white;margin-top: 0px;border-radius: 5px;">
    Template created!
  </div>

  <div class="modal-body" style="margin: 10px">

<!--    <p>Template Friendly Name: <span style="-->
<!--    font-size: 12px;-->
<!--    background: #b1bdc14f;-->
<!--    padding: 0px 9px;-->
<!--    border-radius: 10px;-->
<!--    margin-left: 10px;-->
<!--">This is not the name of the policy that will be deployed - see policy json below</span></p>-->
<!--    <div class="col-sm m-b-20">-->
<!--      <input [(ngModel)]="policyName" type="text" class="form-control" id="policyName" placeholder="" autocomplete="off">-->
<!--    </div>-->

<!--    <p>Template Friendly Description:</p>-->
<!--    <div class="col-sm m-b-20">-->
<!--      <input [(ngModel)]="policyDescription" type="text" class="form-control" id="policyDescription" placeholder="" autocomplete="off">-->
<!--    </div>-->


    <h4 style="padding-left:10px ;">Review Policy JSON
<!--      <button (click)="null" type="button" class="btn btn-light-grey btn-small active float-end">Toggle Edit</button>-->
      <span style="
    font-size: 12px;
    background: #b1bdc14f;
    padding: 0px 9px;
    border-radius: 10px;
    margin-left: 10px;">To edit the template - edit the original policy in Intune and click "Sync"</span>
    </h4>

<!--    <span style="margin-left:10px ; margin-bottom:10px" *ngIf="this.editorOptions.readOnly"><i class="fas fa-lock m-r-5"></i> Policy is read only</span>-->
<!--    <span style="margin-left:10px ; margin-bottom:10px" *ngIf="!this.editorOptions.readOnly"><i class="fas fa-edit m-r-5"></i>You can now edit the policy before deploying</span>-->

    <app-json-editor style="max-height: 30vh" [json]="this.data.generalised_policy_json" [readonly]="true"></app-json-editor>



<!--    <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="policyData" style="height: 55vh;"></ngx-monaco-editor>-->


    <div *ngIf="!createInProgress && !createSuccessful" (click)="create()" class="btn btn-primary">Create Template</div>


  </div>


</app-modal>
